import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import AdminCheckbox from '../AdminCheckbox';

class AdminCheckboxes extends Component {
    render() {
        const {
            classes,
            checkboxes,
            checked,
            CheckboxProps,
            error,
            helperText,
        } = this.props;
        return (
            <Grid
                container
                direction="row"
                spacing={16}
                justify="space-between"
            >
                {checkboxes.map(({ name = '', label }) => {
                    return (
                        <Grid
                            item
                            key={name}
                            className={classes.checkboxGridItem}
                        >
                            <AdminCheckbox
                                label={label || name}
                                CheckboxProps={{
                                    name: `${name}`,
                                    checked: checked.includes(name),
                                    ...CheckboxProps,
                                }}
                            />
                        </Grid>
                    );
                })}
                <Grid item style={{ height: 24 }}>
                    <Typography
                        color={error ? 'error' : 'primary'}
                        style={{ fontWeight: 'bold' }}
                    >
                        {error || helperText}
                    </Typography>
                </Grid>
            </Grid>
        );
    }
}

AdminCheckboxes.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    checkboxes: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
            label: PropTypes.node, // If no label is supplied, the name prop value will be used as the label.
        }),
    ),

    // An array that should contain the name(s) of any boxes that are checked.
    checked: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),

    // Any extra props to be passed to every checkbox.
    CheckboxProps: PropTypes.object,

    // Text to indicate there was an error.
    error: PropTypes.string,

    // Optional Helper text
    helperText: PropTypes.string,
};

AdminCheckboxes.defaultProps = {
    checkboxes: [],
    checked: [],
    CheckboxProps: {},
};

export default withStyles(styles)(AdminCheckboxes);
