export default (theme) => ({
    resultHeader: {
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        marginLeft: 25,
        marginTop: 10,
    },
    container: {
        flex: 1,
    },
});
