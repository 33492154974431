import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import { Checkbox, Grid, Typography, withStyles } from '@material-ui/core';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { DateTime, Interval } from 'luxon';
import { getDayOfYear, getMonthInterval } from '../../utils/dates';
import styles from './styles';

// Class name in styles.js that is used to style a start day of a selected range.
const startClass = 'activeTileStart';

// Class name in styles.js that is used to style an end day of a selected range.
const endClass = 'activeTileEnd';

class CalendarPicker extends Component {
    state = {
        // This will be an array of luxon Intervals.
        ranges: [],

        /*
         * Store the first date that's available to be selected in the view so we can use it when the select entire month checkbox is used.
         * By default it starts as today, so we use now but adjust the time to 00:00:00:000.
         */
        activeStartDate: DateTime.fromJSDate(this.props.minDate),

        // Allow for overriding the ranges with initialState.
        ...this.props.initialState,
    };

    updateValue = (ranges) => {
        const { fieldName, setFieldValue } = this.props;
        if (fieldName && setFieldValue) {
            setFieldValue(fieldName, ranges);
        }
    };

    /**
     * Function called when a new range is selected in the calendar.
     * Will remove the range if its the same range as one already selected,
     * and will merge it into the existing selected ranges stored in the local state otherwise.
     *
     * @param payloadStart
     * @param payloadEnd
     */
    handleRangeChange = ([payloadStart, payloadEnd]) => {
        this.mergeOrRemoveInterval(
            Interval.fromDateTimes(
                DateTime.fromJSDate(payloadStart),
                DateTime.fromJSDate(payloadEnd),
            ),
        );
    };

    /**
     * Merges or removes the given interval from the ranges in local state.
     * If any range has the same start/end as the given interval or the range starts before the interval
     * and has the same end as the given interval, it will be removed. (This is to cover when an entire month is removed).
     * Otherwise, the given interval will be merged with the rest of the intervals in the ranges array stored in the local state.
     *
     * @param {Interval} interval
     */
    mergeOrRemoveInterval = (interval) => {
        const { ranges } = this.state;
        const shouldRemove = ranges.reduce(
            (shouldRemovePrev, rangeInterval) => {
                return (
                    shouldRemovePrev ||
                    rangeInterval.equals(interval) ||
                    (rangeInterval.start < interval.start &&
                        rangeInterval.end.equals(interval.end))
                );
            },
            false,
        );
        const newRanges = shouldRemove
            ? ranges.filter(
                  (rangeInterval) =>
                      !rangeInterval.equals(interval) &&
                      !(
                          rangeInterval.start < interval.start &&
                          rangeInterval.end.equals(interval.end)
                      ),
              )
            : Interval.merge([...ranges, interval]);
        this.setState({ ranges: newRanges });
        this.updateValue(newRanges);
    };

    /**
     * Function called when a day is clicked in the calendar. Removes any intervals
     * stored in local state that contain the clicked day.
     *
     * @param {Date} date
     */
    onClickDay = (date) => {
        const { ranges } = this.state;
        const dateTime = DateTime.fromJSDate(date);
        const newRanges = ranges.filter(
            (interval) => !interval.contains(dateTime),
        );
        this.setState({
            ranges: newRanges,
        });
        this.updateValue(newRanges);
    };

    /**
     * Function called when the month being viewed is changed. Updates the
     * activeStartDay in local state which is needed so that when the
     * checkbox to select the entire month is used, we can know which month is being viewed.
     *
     * @param activeStartDate
     */
    onViewChange = ({ activeStartDate }) => {
        if (activeStartDate < Date.now()) {
            // If the active date is before today, revert to today at 00:00:00
            this.setState({
                activeStartDate: DateTime.local().set({
                    hours: 0,
                    minutes: 0,
                    seconds: 0,
                }),
            });
        } else {
            // The day is after today (or is today) so we can use the active day given.
            this.setState({
                activeStartDate: DateTime.fromJSDate(activeStartDate),
            });
        }
    };

    /**
     * Function called when the select entire month checkbox is checked. Will
     * remove the entire month if its already selected or merge it into the
     * selected intervals stored in the local state ranges property otherwise.
     */
    toggleMonthSelect = () => {
        this.mergeOrRemoveInterval(
            getMonthInterval(this.state.activeStartDate),
        );
    };

    /**
     * Function called to determine if the checkbox for selecting the entire month
     * should be checked (or not).
     *
     * @returns {boolean} true if the entire month is selected, false if not.
     */
    isEntireMonthSelected = () => {
        const { activeStartDate, ranges } = this.state;
        const monthInterval = getMonthInterval(activeStartDate);
        return ranges.reduce((prevEqualsMonth, interval) => {
            return (
                prevEqualsMonth ||
                interval.equals(monthInterval) ||
                interval.engulfs(monthInterval)
            );
        }, false);
    };

    /**
     * Function used to format the weekday headers displayed at the top of the calendar.
     *
     * @param locale
     * @param {Date} date
     * @returns {string} A single letter representing the weekday.
     */
    formatWeekday = (locale, date) => {
        return DateTime.fromJSDate(date)
            .toLocal()
            .toFormat('ccccc');
    };

    /**
     * Function called to determine what class name should be used for a given date tile.
     * Considers the tile as 'active' aka included in a selected interval in the local states ranges property,
     * and also checks to see if its a start/end (or both) of a given interval so that the correct styling can be applied.
     *
     * @param {Date} date
     * @returns {*}
     */
    tileClassName = ({ date }) => {
        const { classes } = this.props;
        if (this.dateInSelectedIntervals(date)) {
            // Start by getting start/end class(es) if the date is a start/end
            const classNames = this.getStartEndClasses(date);
            // Its in a range so it will get the active tile class.
            classNames.push(classes.activeTile);
            return classNames;
        }
        // Its not in the range so return the tile class.
        return classes.tile;
    };

    /**
     * Determines if the given date is the
     * start/end or both of any selected intervals stored in the local state ranges property
     * and returns an array of the appropriate styling class(es).
     * @param date
     * @returns {Array}
     */
    getStartEndClasses = (date) => {
        const { classes } = this.props;
        const { ranges } = this.state;
        const dateDayOfYear = getDayOfYear(date);
        let startOrEnd = [];
        ranges.forEach((interval) => {
            // Convert to the day of year format so we can compare
            const start = getDayOfYear(interval.start);
            const end = getDayOfYear(interval.end);
            if (start === dateDayOfYear && end === dateDayOfYear) {
                // If its both a start and an end, we'll add both start and end classes.
                startOrEnd.push(classes[startClass]);
                startOrEnd.push(classes[endClass]);
            } else if (start === dateDayOfYear) {
                // Else if its a start, we'll add just the start class
                startOrEnd.push(classes[startClass]);
            } else if (end === dateDayOfYear) {
                // Else if its end, we'll add just the end class
                startOrEnd.push(classes[endClass]);
            }
        });
        /*
         * Now we'll return the array of classes - which will be empty if not a start or end,
         * or it will have 1 value if its just a start or an end, or 2 values if its both a start and an end.
         */
        return startOrEnd;
    };

    /**
     * Determine if the given JS Date is in any of the ranges stored in the local state.
     * @param {Date} date
     * @returns {boolean}
     */
    dateInSelectedIntervals = (date) => {
        return this.state.ranges.reduce((dateInRanges, interval) => {
            return dateInRanges || interval.contains(DateTime.fromJSDate(date));
        }, false);
    };

    render() {
        const { classes, showCheckbox, checkboxLabel, ...props } = this.props;

        return (
            <Grid container direction="column" alignItems="center">
                <Grid item>
                    <Calendar
                        className={classes.calendar}
                        selectRange
                        calendarType="US"
                        showNeighboringMonth={false}
                        minDetail="month"
                        prevLabel={<NavigateBefore color="primary" />}
                        nextLabel={<NavigateNext color="primary" />}
                        formatShortWeekday={this.formatWeekday}
                        tileClassName={(date) => this.tileClassName(date)}
                        onChange={this.handleRangeChange}
                        onClickDay={this.onClickDay}
                        onActiveDateChange={this.onViewChange}
                        {...props}
                    />
                </Grid>
                {showCheckbox && (
                    <Grid
                        container
                        item
                        direction="row"
                        justify="center"
                        alignItems="center"
                        className={classes.checkboxGrid}
                    >
                        <Grid item>
                            <Typography className={classes.checkboxLabel}>
                                {checkboxLabel}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Checkbox
                                classes={{ root: classes.checkbox }}
                                onClick={this.toggleMonthSelect}
                                checked={this.isEntireMonthSelected()}
                            />
                        </Grid>
                    </Grid>
                )}
                <Grid item>
                    <Typography className={classes.removalInstructionText}>
                        Click any date in a date range to remove the range.
                    </Typography>
                </Grid>
            </Grid>
        );
    }
}

CalendarPicker.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // Whether or not to display the checkbox to select the entire month, defaults to true.
    showCheckbox: PropTypes.bool,

    // The label for the checkbox can be overridden if desired using this property.
    checkboxLabel: PropTypes.string,

    // Allows for supplying an initial set of selected ranges to the calendar.
    initialState: PropTypes.shape({
        ranges: PropTypes.arrayOf(PropTypes.instanceOf(Interval)),
    }),

    // Provide the field name you want setFieldValue to set, must be provided for setFieldValue to be called.
    fieldName: PropTypes.string,

    // Function to call when the state changes in this component, so you can set the current array of ranges to fieldName's value
    setFieldValue: PropTypes.func,

    minDate: PropTypes.object,
};

CalendarPicker.defaultProps = {
    // By default the minimum date we'll let you pick is today.
    minDate: DateTime.local()
        .set({ hour: 0, minutes: 0, seconds: 0, milliseconds: 0 })
        .toJSDate(),

    // No next/previous on higher level icons rendered by default.
    prev2Label: null,
    next2Label: null,

    // Default is to display the checkbox that will select the whole month.
    showCheckbox: true,

    // Default value for the checkbox label.
    checkboxLabel: 'Select Entire Month',

    // Default initialState is no ranges are selected.
    initialState: {
        ranges: [],
    },

    fieldName: null,
    setFieldValue: null,
};

export default withStyles(styles)(CalendarPicker);
