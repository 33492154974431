import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, withStyles } from '@material-ui/core';
import styles from './styles';

export const BUTTON_TYPE_POSITIVE = 'positive';
export const BUTTON_TYPE_NEGATIVE = 'negative';
export const BUTTON_TYPE_EDIT = 'edit';
export const BUTTON_TYPES = [
    BUTTON_TYPE_POSITIVE,
    BUTTON_TYPE_NEGATIVE,
    BUTTON_TYPE_EDIT,
];

class AdminButton extends Component {
    render() {
        const { classes, children, buttonType, ...props } = this.props;
        return (
            <Button
                classes={{
                    root: classNames([
                        classes.adminButton,
                        buttonType ? classes[buttonType] : null,
                    ]),
                    sizeSmall: classes.smallButton,
                    sizeLarge: classes.largeButton,
                }}
                {...props}
            >
                {children}
            </Button>
        );
    }
}

AdminButton.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // The children that will be rendered inside the button.
    children: PropTypes.node,

    // The type can be either for something like a confirm/update button, a cancel/delete button or an edit button.
    buttonType: PropTypes.oneOf(BUTTON_TYPES),
};

AdminButton.defaultProps = {
    // No type by default
    type: null,

    // Default type is contained.
    variant: 'contained',
};

export default withStyles(styles)(AdminButton);
