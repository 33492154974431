export const DEFAULT_PER_PAGE = 15;
export const ORDER_BY_DIR_ASC = 'asc';
export const ORDER_BY_DIR_DESC = 'desc';
export const ORDER_BY_DIRS = [ORDER_BY_DIR_ASC, ORDER_BY_DIR_DESC];

/**
 * @typedef {{
 *      total: number,
 *      perPage: number,
 *      data: {}[],
 *      lastPage: number,
 *      from: null|number,
 *      to: null|number,
 *      currentPage: number,
 * }} RigParkApiPageData
 */

/**
 * @typedef {{
 *      pagination: {
 *          page: number,
 *          perPage: number,
 *      },
 *      order: string[][],
 *      filters: {}
 * }} RigParkApiPageContext
 */

/**
 * A well-formed default page of API data with default values.
 *
 * An API page data selector created with `createApiPageDataSelector` will return an object with these
 * keys (shallow) and only these keys, using these values as its default values.
 *
 * @see createApiPageDataSelector
 *
 * @type {RigParkApiPageData}
 */
export const DEFAULT_API_PAGE_DATA = {
    data: [],
    total: 0,
    perPage: DEFAULT_PER_PAGE,
    from: 0,
    to: 0,
    currentPage: 1,
    lastPage: 1,
};

/**
 * A well-formed default api page context object with default values.
 *
 * An API page context selector created with `createApiPageContextSelector` will return an object with these
 * keys (deep) and only these keys, using these values as its default values.
 *
 * @see createApiPageContextSelector
 *
 * @type {RigParkApiPageContext}
 */
export const DEFAULT_API_PAGE_CONTEXT = {
    pagination: {
        page: 1,
        perPage: DEFAULT_PER_PAGE,
    },
    filters: {},
    filterErrors: [],
    order: [],
};
