import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AdminAvatar from '../../common/AdminAvatar';
import DataTableColumn from '../DataTable/DataTableColumn';
import DataTableLink from '../DataTableLink';
import RigParkDataTable from '../RigParkDataTable';
import TablePropTypes from '../propTypes';
import { SORT_ASC, SORT_DESC } from '../DataTable/constants';
import {
    ACTIONS as USER_ACTIONS,
    SET_STATUS_ACTIVE,
} from '../../users/constants';
import styles from './styles';
import { formatDate } from '../../utils/dates';
import {
    USER_ACTIVE,
    USER_CREATED,
    USER_GROUP_NAME,
    USER_ID,
} from '../../stores/users/constants';

/**
 * A function that returns a uniquely identifying key for a user object.
 *
 * @param {{userId: *}} user
 * @return {*}
 */
const getKeyForUser = (user) => user.userId;

const AdminUsersDataTable = ({
    classes,
    tableData,
    tableContext,
    onFetchDataRequested,
    multisort,
    ...rest
}) => {
    return (
        <RigParkDataTable
            tableData={tableData}
            tableContext={tableContext}
            getKey={getKeyForUser}
            onFetchDataRequested={onFetchDataRequested}
            selectable
            searchable
            actions={USER_ACTIONS}
            initialActionValue={SET_STATUS_ACTIVE}
            {...rest}
        >
            {({
                createOnSingleSort,
                createOnMultiSortToggle,
                createSortGetter,
            }) => {
                const createOnSort = multisort
                    ? createOnMultiSortToggle
                    : createOnSingleSort;
                return (
                    <Fragment>
                        <DataTableColumn
                            headCellContent="ID"
                            headCellClassName={classes.idHeadCell}
                            getSort={createSortGetter(USER_ID)}
                            onSort={createOnSort(USER_ID)}
                            initialSort={SORT_DESC}
                        >
                            {({ userId }) => (
                                <DataTableLink
                                    component={Link}
                                    to={`/users/${userId}`}
                                >
                                    {userId}
                                </DataTableLink>
                            )}
                        </DataTableColumn>
                        <DataTableColumn
                            headCellClassName={classes.photoHeadCell}
                        >
                            {({ userId, photoURL, displayName }) => {
                                return (
                                    <DataTableLink
                                        component={Link}
                                        to={`/users/${userId}`}
                                    >
                                        <AdminAvatar
                                            src={photoURL || null}
                                            alt={displayName}
                                        />
                                    </DataTableLink>
                                );
                            }}
                        </DataTableColumn>
                        <DataTableColumn
                            headCellContent="Name"
                            headCellClassName={classes.nameHeadCell}
                        >
                            {({ userId, displayName, deletedMetadata }) => (
                                <DataTableLink
                                    component={Link}
                                    to={`/users/${userId}`}
                                >
                                    {deletedMetadata?.displayName ||
                                        displayName}
                                </DataTableLink>
                            )}
                        </DataTableColumn>
                        <DataTableColumn
                            headCellContent="Type"
                            headCellClassName={classes.typeHeadCell}
                            getSort={createSortGetter(USER_GROUP_NAME)}
                            onSort={createOnSort(USER_GROUP_NAME)}
                            initialSort={SORT_ASC}
                        >
                            {({ group: { groupName } = {} }) =>
                                groupName || null
                            }
                        </DataTableColumn>
                        <DataTableColumn
                            headCellContent="Registration Date"
                            headCellClassName={classes.registrationDateHeadCell}
                            getSort={createSortGetter(USER_CREATED)}
                            onSort={createOnSort(USER_CREATED)}
                            initialSort={SORT_DESC}
                        >
                            {({ created }) => {
                                return created ? formatDate(created) : null;
                            }}
                        </DataTableColumn>
                        <DataTableColumn
                            headCellContent="Status"
                            headCellClassName={classes.statusHeadCell}
                            getSort={createSortGetter(USER_ACTIVE)}
                            onSort={createOnSort(USER_ACTIVE)}
                            initialSort={SORT_ASC}
                        >
                            {({ userActive }) => {
                                return userActive ? 'Active' : 'Inactive';
                            }}
                        </DataTableColumn>
                    </Fragment>
                );
            }}
        </RigParkDataTable>
    );
};

AdminUsersDataTable.propTypes = {
    classes: PropTypes.object.isRequired,
    tableData: TablePropTypes.tablePageData.isRequired,
    tableContext: TablePropTypes.tablePageContext.isRequired,
    onFetchDataRequested: PropTypes.func.isRequired,
    multisort: PropTypes.bool,
};

AdminUsersDataTable.defaultProps = {
    multisort: false,
};

export default withStyles(styles)(AdminUsersDataTable);
