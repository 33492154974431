import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TablePropTypes from '../../tables/propTypes';
import AdminContainer from '../../common/AdminContainer';
import AdminButton from '../../common/AdminButton';
import {
    propertiesListViewLoadRequested,
    fetchPropertiesListPageRequested,
    updatePropertiesListFilters,
    clearPropertiesListFilters,
    filtersValidationFailed,
} from '../../stores/properties/actions';
import AdminPropertiesDataTable from '../../tables/AdminPropertiesDataTable';
import ListErrors from '../../common/ListErrors';
import {
    getMultiDeleteErrors,
    getPropertiesListErrors,
    getPropertiesListPageContext,
    getPropertiesListTablePageData,
} from '../../stores/properties/selectors';
import {
    clearDeleteErrors,
    deletePropertiesRequested,
    updatePropertiesStatusRequested,
} from '../../stores/properties/apiActions';
import {
    DELETE_PROPERTIES,
    PROPERTY_STATUS_ACTIVE,
    PROPERTY_STATUS_INACTIVE,
    SET_PROPERTY_STATUS_ACTIVE,
    SET_PROPERTY_STATUS_INACTIVE,
} from '../constants';
import PropertiesTableFilters from '../PropertiesTableFilters';
import { getRegions, areRegionsLoading } from '../../stores/regions/selectors';
import { fetchRegionsRequested } from '../../stores/regions/actions';
import { isEmpty, without } from 'ramda';
import { Grid, Typography } from '@material-ui/core';
import { hasDay, hasMonth, hasYear, NONE } from '../../utils/dates';
import {
    COUNTRY_FILTERS,
    SELECT_A_COUNTRY,
    SELECT_A_STATE,
    SELECT_COUNTRY_FIRST,
} from '../PropertiesTableFilters/constants';
import {
    END,
    START,
    MONTH,
    DAY,
    YEAR,
    APPLY,
    CLEAR,
    DOCKS,
    RESERVATIONS,
    MIN,
    MAX,
    START_MONTH_REQUIRED_ERROR_MESSAGE,
    START_DAY_REQUIRED_ERROR_MESSAGE,
    START_YEAR_REQUIRED_ERROR_MESSSGE,
    END_MONTH_REQUIRED_ERROR_MESSAGE,
    END_DAY_REQUIRED_ERROR_MESSAGE,
    END_YEAR_REQUIRED_ERROR_MESSAGE,
} from './constants';
import ConfirmDialog from '../../common/ConfirmDialog';

class PropertiesListContainer extends Component {
    initialState = {
        showDeleteDialog: false,
        deletingPropertyIds: [],
    };

    state = {
        ...this.initialState,
    };

    toggleConfirmationForDelete = () => {
        const { showDeleteDialog } = this.state;
        this.setState({
            showDeleteDialog: !showDeleteDialog,
        });
    };

    static propTypes = {
        tableData: TablePropTypes.tablePageData.isRequired,
        tableContext: TablePropTypes.tablePageContext.isRequired,
        onLoad: PropTypes.func.isRequired,
        onPageRequested: PropTypes.func.isRequired,
        errors: PropTypes.arrayOf(PropTypes.string).isRequired,
        onSetPropertiesStatusSelected: PropTypes.func.isRequired,
        onClickApprovePropertyRow: PropTypes.func.isRequired,
        regions: PropTypes.object.isRequired,
        onDeletePropertiesSelected: PropTypes.func.isRequired,
        deleteErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
        onConfirmDeleteErrors: PropTypes.func.isRequired,
        onUpdateFilters: PropTypes.func.isRequired,
        onClearFilters: PropTypes.func.isRequired,
        fetchRegionsForCountry: PropTypes.func.isRequired,
        onFiltersValidationFailed: PropTypes.func.isRequired,
        areRegionsLoading: PropTypes.bool,
    };

    componentDidMount() {
        this.props.onLoad();
    }

    onActionSelect = (selectedIds, action) => {
        const { onSetPropertiesStatusSelected } = this.props;
        if (action === SET_PROPERTY_STATUS_ACTIVE) {
            onSetPropertiesStatusSelected(selectedIds, PROPERTY_STATUS_ACTIVE);
        } else if (action === SET_PROPERTY_STATUS_INACTIVE) {
            onSetPropertiesStatusSelected(
                selectedIds,
                PROPERTY_STATUS_INACTIVE,
            );
        } else if (action === DELETE_PROPERTIES) {
            // Let's show them a modal to be absolutely sure they intend to delete these properties.
            this.setState({
                deletingPropertyIds: selectedIds,
            });
            this.toggleConfirmationForDelete();
        }
    };

    onFilterCheckboxChange = (event) => {
        const { filters } = this.props.tableContext;
        const checkboxValue = event.target.value;
        const oldChecked = filters.checked;
        const checked = oldChecked.includes(checkboxValue)
            ? without(checkboxValue, oldChecked)
            : [...oldChecked, checkboxValue];
        this.props.onUpdateFilters({ ...filters, checked });
    };

    renderEmpty = () => {
        return (
            <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
                spacing={16}
            >
                <Grid item>
                    <Typography color="primary">
                        There are no properties to show that match the selected
                        filters.
                    </Typography>
                </Grid>
                <Grid item>
                    <AdminButton
                        size="medium"
                        buttonType="positive"
                        onClick={this.props.onClearFilters}
                    >
                        Clear Filters
                    </AdminButton>
                </Grid>
            </Grid>
        );
    };

    onFilterDateChange = (event, prop, monthDayYear) => {
        const { value } = event.target;
        const { filters } = this.props.tableContext;
        const updates = {
            [prop]: {
                ...filters[prop],
                [monthDayYear]: value,
                [`${monthDayYear}Error`]: false,
            },
        };
        if (
            monthDayYear === MONTH ||
            (monthDayYear === YEAR && value === NONE)
        ) {
            // If the month changes or the year is blank, remove the day
            updates[prop] = without(DAY, updates[prop]);
        }
        this.props.onUpdateFilters({ ...filters, ...updates });
    };

    onRangeChange = (event, range, minOrMax) => {
        const { filters } = this.props.tableContext;
        const value = event.target.value;
        this.props.onUpdateFilters({
            ...filters,
            [range]: {
                ...filters[range],
                [minOrMax]: parseInt(value),
            },
        });
    };

    onCountryChange = (event) => {
        const {
            tableContext: { filters },
            onUpdateFilters,
            regions,
            fetchRegionsForCountry,
        } = this.props;
        const selectedCountryAbbr = event.target.value;
        if (selectedCountryAbbr !== SELECT_A_COUNTRY) {
            onUpdateFilters({
                ...filters,
                selectedCountryAbbr,
                selectedStates: [],
            });
            if (
                isEmpty(regions) ||
                !regions[selectedCountryAbbr] ||
                regions[selectedCountryAbbr].length === 0
            ) {
                // Now fetch states for new country if they aren't available in the store yet
                fetchRegionsForCountry(selectedCountryAbbr);
            }
        } else {
            onUpdateFilters({
                ...filters,
                selectedCountryAbbr: '',
                selectedStates: [],
            });
        }
    };

    onDeleteState = (chipName) => {
        const {
            filters,
            filters: { selectedStates },
        } = this.props.tableContext;
        this.props.onUpdateFilters({
            ...filters,
            selectedStates: without(chipName, selectedStates),
        });
    };

    onStateSelected = (event) => {
        const selectedState = event.target.value;
        const {
            tableContext: {
                filters,
                filters: { selectedStates },
            },
            onUpdateFilters,
        } = this.props;
        if (selectedState !== SELECT_A_STATE) {
            if (selectedStates.includes(selectedState)) {
                onUpdateFilters({
                    ...filters,
                    selectedStates: without(selectedState, selectedStates),
                });
            } else {
                onUpdateFilters({
                    ...filters,
                    selectedStates: [...selectedStates, selectedState],
                });
            }
        }
    };

    validateFilters = () => {
        const { filters } = this.props.tableContext;
        const { start, end } = filters;
        const filterErrors = [];
        if (!isEmpty(start)) {
            if (!hasMonth(start) || start.month === NONE) {
                filterErrors.push(START_MONTH_REQUIRED_ERROR_MESSAGE);
                start.monthError = true;
            }
            if (!hasDay(start) || start.day === NONE) {
                filterErrors.push(START_DAY_REQUIRED_ERROR_MESSAGE);
                start.dayError = true;
            }
            if (!hasYear(start) || start.year === NONE) {
                filterErrors.push(START_YEAR_REQUIRED_ERROR_MESSSGE);
                start.yearError = true;
            }
        }
        if (!isEmpty(end)) {
            if (!hasMonth(end) || end.month === NONE) {
                filterErrors.push(END_MONTH_REQUIRED_ERROR_MESSAGE);
                end.monthError = true;
            }
            if (!hasDay(end) || end.day === NONE) {
                filterErrors.push(END_DAY_REQUIRED_ERROR_MESSAGE);
                end.dayError = true;
            }
            if (!hasYear(end) || end.year === NONE) {
                filterErrors.push(END_YEAR_REQUIRED_ERROR_MESSAGE);
                end.yearError = true;
            }
        }
        if (filterErrors.length > 0) {
            this.props.onFiltersValidationFailed(filterErrors, {
                ...filters,
                start,
                end,
            });
        }
        return filterErrors.length === 0;
    };

    onClickFilterButtons = (buttonName) => {
        if (buttonName === 'clear') {
            this.props.onClearFilters();
        } else {
            if (this.validateFilters()) {
                this.props.onLoad();
            }
        }
    };

    renderFilters = () => {
        const {
            regions,
            areRegionsLoading,
            tableContext: { filters, filterErrors },
        } = this.props;
        return (
            <PropertiesTableFilters
                checked={filters.checked}
                onCheckboxChange={this.onFilterCheckboxChange}
                start={{
                    ...filters.start,
                    onMonthChange: (event) =>
                        this.onFilterDateChange(event, START, MONTH),
                    onDayChange: (event) =>
                        this.onFilterDateChange(event, START, DAY),
                    onYearChange: (event) =>
                        this.onFilterDateChange(event, START, YEAR),
                }}
                end={{
                    ...filters.end,
                    onMonthChange: (event) =>
                        this.onFilterDateChange(event, END, MONTH),
                    onDayChange: (event) =>
                        this.onFilterDateChange(event, END, DAY),
                    onYearChange: (event) =>
                        this.onFilterDateChange(event, END, YEAR),
                }}
                onClickApply={() => this.onClickFilterButtons(APPLY)}
                onClickClear={() => this.onClickFilterButtons(CLEAR)}
                docks={{
                    ...filters.docks,
                    onMinChange: (event) =>
                        this.onRangeChange(event, DOCKS, MIN),
                    onMaxChange: (event) =>
                        this.onRangeChange(event, DOCKS, MAX),
                }}
                reservations={{
                    ...filters.reservations,
                    onMinChange: (event) =>
                        this.onRangeChange(event, RESERVATIONS, MIN),
                    onMaxChange: (event) =>
                        this.onRangeChange(event, RESERVATIONS, MAX),
                }}
                StateSelectProps={{
                    options: regions[filters.selectedCountryAbbr]
                        ? [
                              SELECT_A_STATE,
                              ...regions[filters.selectedCountryAbbr].map(
                                  ({ regionName }) => regionName,
                              ),
                          ]
                        : [SELECT_COUNTRY_FIRST],
                    loading: areRegionsLoading,
                    selectedOptions: filters.selectedStates,
                    value: filters.selectedCountryAbbr
                        ? SELECT_A_STATE
                        : SELECT_COUNTRY_FIRST,
                    handleDelete: this.onDeleteState,
                    handleSelectedOption: this.onStateSelected,
                    disabled: !filters.selectedCountryAbbr,
                }}
                CountrySelectProps={{
                    value: filters.selectedCountryAbbr || SELECT_A_COUNTRY,
                    onChange: this.onCountryChange,
                    options: COUNTRY_FILTERS,
                }}
                errors={filterErrors}
            />
        );
    };

    render() {
        const {
            tableData,
            tableContext,
            onLoad,
            onPageRequested,
            errors,
            onClickApprovePropertyRow,
            onDeletePropertiesSelected,
            deleteErrors,
            onConfirmDeleteErrors,
            ...rest
        } = this.props;
        return (
            <AdminContainer
                innerHeader
                innerHeaderProps={{
                    title: 'Properties',
                }}
                {...rest}
            >
                <ListErrors errors={errors} />
                <AdminPropertiesDataTable
                    tableData={tableData}
                    tableContext={tableContext}
                    onFetchDataRequested={onPageRequested}
                    onActionSelect={this.onActionSelect}
                    renderFilters={this.renderFilters()}
                    renderEmpty={this.renderEmpty}
                    onClickApprovePropertyRow={onClickApprovePropertyRow}
                />
                <ConfirmDialog
                    open={this.state.showDeleteDialog}
                    onConfirm={() => {
                        this.toggleConfirmationForDelete();
                        onDeletePropertiesSelected &&
                            onDeletePropertiesSelected(
                                this.state.deletingPropertyIds,
                            );
                    }}
                    onCancel={() => {
                        this.toggleConfirmationForDelete();
                    }}
                    title="Confirm Delete Properties"
                    text={`You are attempting to delete ${this.state.deletingPropertyIds.length} properties.`}
                    subText="This action will permanently delete these properties and their spots."
                />
                <ConfirmDialog
                    open={!!this.props.deleteErrors.length}
                    onConfirm={onConfirmDeleteErrors}
                    onCancel={onConfirmDeleteErrors}
                    showCancel={false}
                    confirmLabel="OK"
                    title="Delete Properties Failed"
                    text={deleteErrors}
                />
            </AdminContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    tableData: getPropertiesListTablePageData(state),
    tableContext: getPropertiesListPageContext(state),
    errors: getPropertiesListErrors(state),
    regions: getRegions(state),
    areRegionsLoading: areRegionsLoading(state),
    deleteErrors: getMultiDeleteErrors(state),
});

const mapDispatchToProps = (dispatch) => ({
    onLoad: () => dispatch(propertiesListViewLoadRequested()),
    onPageRequested: (context = {}) =>
        dispatch(fetchPropertiesListPageRequested(context)),
    onSetPropertiesStatusSelected: (propertyIds, status) =>
        dispatch(updatePropertiesStatusRequested(propertyIds, status)),
    onUpdateFilters: (filters) =>
        dispatch(updatePropertiesListFilters(filters)),
    onClearFilters: () => dispatch(clearPropertiesListFilters()),
    onFiltersValidationFailed: (errors, filters) =>
        dispatch(filtersValidationFailed(errors, filters)),
    fetchRegionsForCountry: (countryAbbr) =>
        dispatch(fetchRegionsRequested(countryAbbr)),
    onClickApprovePropertyRow: (propertyId) =>
        dispatch(
            updatePropertiesStatusRequested(
                [propertyId],
                PROPERTY_STATUS_ACTIVE,
            ),
        ),
    onDeletePropertiesSelected: (propertyIds) =>
        dispatch(deletePropertiesRequested(propertyIds)),
    onConfirmDeleteErrors: () => dispatch(clearDeleteErrors()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PropertiesListContainer);
