const styles = (theme) => ({
    avatar: {
        height: '88px',
        width: '88px',
    },
    imageUploadButton: {
        marginTop: 'auto',
        marginLeft: 45,
    },
});

export default styles;
