const styles = (theme) => ({
    label: {
        textAlign: 'right',
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        letterSpacing: 4,
    },
    stateSelect: {
        width: 185,
    },
    countrySelect: {
        width: 185,
    },
    stripeCustomerButton: {
        marginRight: 16,
    },
    addButtonIcon: {
        marginRight: 25,
    },
    topLabel: {
        marginTop: 20,
    },
    tooltip: {
        fontSize: 14,
    },
    reportLabelContainer: {
        marginTop: 20,
        '& > *': {
            color: theme.palette.primary.main,
        },
        '& > p': {
            fontWeight: 'bold',
            textTransform: 'uppercase',
            letterSpacing: '2px',
            paddingTop: 1,
            paddingRight: 4,
        },
    },
    smallTopMargin: {
        marginTop: 6,
    },
    reportRecipientsRoot: {
        width: '70%',
    },
    reportConfig: {
        marginTop: 20,
        width: 'calc(100% + 10px)',
        '& > div': {
            marginRight: 10,
        },
    },
    reportConfigPrefix: {
        marginRight: 10,
    },
    reportConfigTime: {
        width: 130,
        '& > div > div': {
            minWidth: 130,
        },
    },
    reportConfigTimezone: {
        maxWidth: '50%',
    },
    reportConfigTimezoneSelect: {
        paddingRight: 35,
        overflow: 'hidden',
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
    },
    reportConfigDeleteButton: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
        color: theme.custom.red,
        minWidth: 'initial',
        '&:hover': {
            color: theme.custom.red,
            opacity: 1,
            backgroundColor: 'transparent',
        },
        '&:active': {
            color: theme.custom.darkRed,
            boxShadow: 'none',
        },
    },
    reportButton: {
        marginRight: 16,
    },
    error: {
        color: theme.custom.red,
        fontWeight: 'bold',
    },
    helperTextError: {
        marginTop: 4,
        fontWeight: 'bold',
    },
});

export default styles;
