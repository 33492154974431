import {
    all,
    call,
    takeLatest,
    takeEvery,
    put,
    select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { DateTime } from 'luxon';
import { isEmpty, isNil } from 'ramda';
import {
    CREATE_USER_REQUESTED,
    createUserFailed,
    createUserSucceeded,
    DELETE_PROPERTY_REQUESTED,
    DELETE_USER_ACTIONS,
    DELETE_USER_TYPES,
    deletePropertyFailed,
    deletePropertySucceeded,
    FETCH_USER_ACTIONS,
    FETCH_USER_TYPES,
    UPDATE_USER_REQUESTED,
    UPDATE_USERS_STATUS_REQUESTED,
    UPDATE_USERS_STATUS_SUCCEEDED,
    UPDATE_USERS_TYPE_REQUESTED,
    UPDATE_USERS_TYPE_SUCCEEDED,
    updateUserFailed,
    updateUserPhotoFailed,
    updateUserPhotoRequested,
    updateUserPhotoSucceeded,
    updateUsersStatusFailed,
    updateUsersStatusSucceeded,
    updateUsersTypeFailed,
    updateUsersTypeSucceeded,
    updateUserSucceeded,
} from './apiActions';
import {
    USERS_LIST_VIEW_LOAD_REQUESTED,
    FETCH_USERS_LIST_PAGE_REQUESTED,
    fetchUsersListPageRequested,
    fetchUsersListPageSucceeded,
    fetchUsersListPageFailed,
    CLEAR_USERS_LIST_FILTERS,
    USER_DETAIL_VIEW_LOAD_REQUESTED,
    FETCH_USER_PAYMENT_METHODS_REQUESTED,
    fetchUserPaymentMethodsSucceeded,
    fetchUserPaymentMethodsFailed,
    sendUserReportFailed,
    sendUserReportSucceeded,
    SEND_USER_REPORT_REQUESTED,
} from './actions';
import {
    makeRequestWithAuth,
    createPaginatedRequestSaga,
    doUploadWithAuth,
    fetchWithAuth,
} from '../api/sagas';
import {
    UPLOAD_CONTEXT_PARAM,
    UPLOAD_CONTEXT_USER_PHOTO_VALUE,
    UPLOAD_PUBLIC_PARAM,
    UPLOAD_PUBLIC_PARAM_TRUE_VALUE,
    UPLOAD_PARAM_UPLOAD,
    USER_TYPE_TRUCKER,
} from './constants';
import {
    USER_STATUS_FILTERS,
    USER_TYPE_FILTERS,
    USER_STATUS_ACTIVE,
    MODEL_ID,
    USER_STATUS_PENDING_DELETE,
} from '../../users/UsersTableFilters/constants';
import {
    getEndOfDateTimestamp,
    getStartOfDateTimestamp,
    hasMonth,
    hasDay,
    hasYear,
    getEndOfTodayTimestamp,
    MIN_YEAR,
    getMonthDayAndYearFromObject,
} from '../../utils/dates';
import { getErrorsFromPossibleAPIErrorResponse } from '../../utils/api';
import { getUserCountryAbbr, getUsersListPageContext } from './selectors';
import { getUserGroups } from '../userGroups/selectors';
import { getCurrentUserId } from '../auth/selectors';
import { fetchRegionsRequested } from '../regions/actions';
import { matchPath } from 'react-router';
import { getPathname } from '../router/selectors';
import {
    bookReservationUserSearchFailed,
    bookReservationUserSearchSucceeded,
    BOOK_RESERVATION_SEARCH_USER_REQUESTED,
} from '../reservations/actions';

export function* loadUsersListView() {
    const currentContext = yield select(getUsersListPageContext);
    yield put(fetchUsersListPageRequested(currentContext));
}

export const fetchUsersListPage = createPaginatedRequestSaga({
    path: '/user',
    actions: {
        succeeded: fetchUsersListPageSucceeded,
        failed: fetchUsersListPageFailed,
    },
    applyFilters: (query, filters) => {
        const { search, checked, start, end, vehicleFilters } = filters;
        const queryFilters = {};
        queryFilters.groupName = checked.filter((checkedItem) =>
            USER_TYPE_FILTERS.includes(checkedItem),
        );
        const status = checked.filter((checkedItem) =>
            USER_STATUS_FILTERS.includes(checkedItem),
        );
        if (status.includes(USER_STATUS_PENDING_DELETE)) {
            queryFilters.userDeleted = true;
        } else if (status.length === 1) {
            // We only need to filter if only one status is provided, otherwise its none, or both which is the same as none!
            queryFilters.userActive = status.includes(USER_STATUS_ACTIVE);
        }
        Object.entries(vehicleFilters).forEach(([filterKey, filterValue]) => {
            if (filterKey === MODEL_ID && !isEmpty(filterValue.value)) {
                // Model Id is set up as an object for error validation, so we have to pull the value
                queryFilters[filterKey] = filterValue.value;
            } else if (
                filterKey !== MODEL_ID &&
                !isEmpty(filterValue) &&
                !isNil(filterValue)
            ) {
                queryFilters[filterKey] = filterValue;
            }
        });
        if (!isEmpty(start) || !isEmpty(end)) {
            const createdStart = getStartOfDateTimestamp(
                hasMonth(start) && hasDay(start) && hasYear(start)
                    ? DateTime.fromObject(getMonthDayAndYearFromObject(start))
                    : DateTime.fromObject({ month: 1, day: 1, year: MIN_YEAR }),
            );
            const createdEnd =
                hasMonth(end) && hasDay(end) && hasYear(end)
                    ? getEndOfDateTimestamp(
                          DateTime.fromObject(
                              getMonthDayAndYearFromObject(end),
                          ),
                      )
                    : getEndOfTodayTimestamp();
            queryFilters.created = [createdStart, createdEnd];
        }
        return {
            ...(query || {}),
            search: search || '',
            ...queryFilters,
        };
    },
});

export function* searchUsersForBookingReservation({ search }) {
    try {
        const results = yield call(fetchWithAuth, '/user', {
            method: 'GET',
            query: {
                search: search,
                groupName: USER_TYPE_TRUCKER,
            },
        });
        yield put(bookReservationUserSearchSucceeded(results.data || []));
    } catch (error) {
        yield put(bookReservationUserSearchFailed());
    }
}

export function* sendUserReport({ userId, recipients }) {
    try {
        const response = yield call(fetchWithAuth, `user/${userId}/report`, {
            method: 'GET',
            query: { recipients },
        });
        if (!response || response.error) {
            yield put(
                sendUserReportFailed(
                    response?.error || 'An error occurred sending the report.',
                ),
            );
        } else {
            yield put(sendUserReportSucceeded());
        }
    } catch (error) {
        const errors = yield call(getErrorsFromPossibleAPIErrorResponse, error);
        const { _error } = (error.getError && error.getError()) || {};
        const message =
            errors[0] ||
            error?.json?.message ||
            (_error || error)?.message ||
            'An error occurred sending the report.';
        yield put(sendUserReportFailed(message));
    }
}

export function* loadUserDetailView() {
    const pathname = yield select(getPathname);
    const { params: { userId } = {} } = matchPath(pathname, {
        path: '/users/:userId',
    });
    if (!isNaN(userId)) {
        yield put(FETCH_USER_ACTIONS.requested(userId));
    }
}

export function* loadUserRegions() {
    const countryAbbr = yield select(getUserCountryAbbr);
    yield put(fetchRegionsRequested(countryAbbr));
}

export function* fetchUser({ userId }) {
    yield call(makeRequestWithAuth, {
        actions: FETCH_USER_ACTIONS,
        path: `user/${userId}`,
        method: 'GET',
    });
}

export function* createUser({ user }) {
    const { photoFile, userPhoneNumber, ...userData } = user;
    userData.userPhoneNumber = userPhoneNumber
        .replace(/-/g, '')
        .replace(/\s/g, '');
    if (photoFile) {
        // If there's a file here that means they've selected a new photo to upload.
        yield put(updateUserPhotoRequested());
        const formData = new FormData();
        formData.append(UPLOAD_CONTEXT_PARAM, UPLOAD_CONTEXT_USER_PHOTO_VALUE);
        formData.append(UPLOAD_PUBLIC_PARAM, UPLOAD_PUBLIC_PARAM_TRUE_VALUE);
        formData.append(UPLOAD_PARAM_UPLOAD, photoFile);
        try {
            const { uploadId } = yield call(doUploadWithAuth, 'upload', {
                method: 'POST',
                body: formData,
            });
            yield put(updateUserPhotoSucceeded());
            userData.photoUploadId = uploadId;
        } catch (error) {
            const errors = yield call(
                getErrorsFromPossibleAPIErrorResponse,
                error,
            );
            yield put(
                updateUserPhotoFailed([
                    'Upload of User Photo failed.',
                    ...errors,
                ]),
            );
        }
    }
    try {
        const createdUser = yield call(fetchWithAuth, 'user', {
            method: 'POST',
            body: userData,
        });
        yield put(createUserSucceeded(createdUser));
        // Now push to edit view for the newly created user.
        yield put(push(`/users/${createdUser.userId}`));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Creation of new user has failed.',
        );
        yield put(createUserFailed(errors, user));
    }
}

export function* updateUser({ user }) {
    const { photoFile, userPhoneNumber, ...userData } = user;
    userData.userPhoneNumber = userPhoneNumber
        .replace(/-/g, '')
        .replace(/\s/g, '');
    if (photoFile) {
        // If there's a file here that means they've selected a new photo to upload.
        yield put(updateUserPhotoRequested());
        const formData = new FormData();
        formData.append(UPLOAD_CONTEXT_PARAM, UPLOAD_CONTEXT_USER_PHOTO_VALUE);
        formData.append(UPLOAD_PUBLIC_PARAM, UPLOAD_PUBLIC_PARAM_TRUE_VALUE);
        formData.append(UPLOAD_PARAM_UPLOAD, photoFile);
        try {
            const { uploadId } = yield call(doUploadWithAuth, 'upload', {
                method: 'POST',
                body: formData,
            });
            yield put(updateUserPhotoSucceeded());
            userData.photoUploadId = uploadId;
        } catch (error) {
            const errors = yield call(
                getErrorsFromPossibleAPIErrorResponse,
                error,
            );
            yield put(
                updateUserPhotoFailed([
                    'Update of User Photo failed.',
                    ...errors,
                ]),
            );
        }
    }
    try {
        const updatedUser = yield call(fetchWithAuth, `user/${user.userId}`, {
            method: 'PUT',
            body: userData,
        });
        yield put(updateUserSucceeded(updatedUser));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to update the user.',
        );
        yield put(updateUserFailed(errors, user));
    }
}

export function* deleteUser({ userId }) {
    yield call(makeRequestWithAuth, {
        actions: DELETE_USER_ACTIONS,
        path: `user/${userId}`,
        method: 'DELETE',
    });
}

export function* redirectToUsers() {
    yield put(push('/users'));
}

export function* updateUsersStatus({ userIds, status }) {
    try {
        const currentUserId = yield select(getCurrentUserId);
        yield call(fetchWithAuth, `user/${status}`, {
            method: 'PUT',
            body: {
                userIds: userIds.filter((userId) => userId !== currentUserId),
            },
        });
        yield put(updateUsersStatusSucceeded(userIds));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            "Failed to update the user's status.",
        );
        yield put(updateUsersStatusFailed(userIds, errors));
    }
}

export function* updateUsersType({ userIds, groupName }) {
    try {
        const currentUserId = yield select(getCurrentUserId);
        const userGroups = yield select(getUserGroups);
        const group = userGroups.find((group) => group.groupName === groupName);
        const groupId = group ? group.groupId : null;
        if (groupId) {
            yield call(fetchWithAuth, 'user/group', {
                method: 'PUT',
                body: {
                    userIds: userIds.filter(
                        (userId) => userId !== currentUserId,
                    ),
                    groupId,
                },
            });
            yield put(updateUsersTypeSucceeded(userIds));
        } else {
            yield put(updateUsersTypeFailed(userIds, ['Invalid Group Name.']));
        }
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            "Failed to update the user's type.",
        );
        yield put(updateUsersTypeFailed(userIds, errors));
    }
}

export function* deleteProperty({ userId, propertyId, propertyName }) {
    try {
        yield call(fetchWithAuth, `property/${propertyId}`, {
            method: 'DELETE',
        });
        yield put(deletePropertySucceeded(propertyId, propertyName));
        yield put(FETCH_USER_ACTIONS.requested(userId));
    } catch (error) {
        yield put(deletePropertyFailed(propertyId, propertyName));
    }
}

export function* fetchPaymentMethods({ userId }) {
    try {
        const paymentMethods = yield call(fetchWithAuth, `/cards/${userId}`, {
            method: 'GET',
        });
        yield put(fetchUserPaymentMethodsSucceeded(paymentMethods));
    } catch (error) {
        yield put(fetchUserPaymentMethodsFailed());
    }
}

export default function*() {
    yield all([
        takeEvery(USERS_LIST_VIEW_LOAD_REQUESTED, loadUsersListView),
        takeEvery(USER_DETAIL_VIEW_LOAD_REQUESTED, loadUserDetailView),
        takeLatest(SEND_USER_REPORT_REQUESTED, sendUserReport),
        takeEvery(FETCH_USER_TYPES.succeeded, loadUserRegions),
        takeLatest(FETCH_USERS_LIST_PAGE_REQUESTED, fetchUsersListPage),
        takeLatest(FETCH_USER_TYPES.requested, fetchUser),
        takeLatest(UPDATE_USER_REQUESTED, updateUser),
        takeLatest(DELETE_USER_TYPES.requested, deleteUser),
        takeLatest(DELETE_USER_TYPES.succeeded, redirectToUsers),
        takeEvery(UPDATE_USERS_STATUS_REQUESTED, updateUsersStatus),
        // If we decide to implement normalization via RIG-290 the line below should be removed
        takeLatest(UPDATE_USERS_STATUS_SUCCEEDED, loadUsersListView),
        takeEvery(UPDATE_USERS_TYPE_REQUESTED, updateUsersType),
        // If we decide to implement normalization via RIG-290 the line below should be removed
        takeLatest(UPDATE_USERS_TYPE_SUCCEEDED, loadUsersListView),
        takeLatest(CLEAR_USERS_LIST_FILTERS, loadUsersListView),
        takeLatest(CREATE_USER_REQUESTED, createUser),
        takeLatest(FETCH_USER_PAYMENT_METHODS_REQUESTED, fetchPaymentMethods),
        takeLatest(
            BOOK_RESERVATION_SEARCH_USER_REQUESTED,
            searchUsersForBookingReservation,
        ),
        takeEvery(DELETE_PROPERTY_REQUESTED, deleteProperty),
    ]);
}
