import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth/reducer';
import dashboard from './dashboard/reducer';
import users from './users/reducer';
import properties from './properties/reducer';
import docks from './docks/reducer';
import userGroups from './userGroups/reducer';
import regions from './regions/reducer';
import amenities from './amenities/reducer';
import rules from './rules/reducer';
import reservations from './reservations/reducer';
import vehicles from './vehicles/reducer';
import stripe from './stripe/reducer';

export default (history) => {
    return combineReducers({
        router: connectRouter(history),
        auth,
        dashboard,
        users,
        properties,
        docks,
        userGroups,
        regions,
        amenities,
        rules,
        reservations,
        vehicles,
        stripe,
    });
};
