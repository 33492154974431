import {
    ADD_STRIPE_PAYMENT_METHOD_FAILED,
    ADD_STRIPE_PAYMENT_METHOD_REQUESTED,
    ADD_STRIPE_PAYMENT_METHOD_SUCCEEDED,
} from './actions';

const initialStripeState = {
    isAddingPaymentMethod: false,
    error: null,
};

export default function(state = initialStripeState, action = {}) {
    const { type } = action;

    switch (type) {
        case ADD_STRIPE_PAYMENT_METHOD_REQUESTED: {
            return { isAddingPaymentMethod: true, error: null };
        }
        case ADD_STRIPE_PAYMENT_METHOD_SUCCEEDED: {
            return { isAddingPaymentMethod: false, error: null };
        }
        case ADD_STRIPE_PAYMENT_METHOD_FAILED: {
            const { error } = action;
            return { isAddingPaymentMethod: false, error };
        }
        default:
            return state;
    }
}
