import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import AdminSelect, { SIZE_COMPACT } from '../AdminSelect';
import styles from './styles';
import DatePropTypes from '../../propTypes/dates';
import { daysInMonthOptions, currentYear, NONE } from '../../utils/dates';

class DayOfMonthPicker extends Component {
    render() {
        const { classes, value, month, year, ...props } = this.props;
        const options = daysInMonthOptions(month, year);
        return (
            <AdminSelect
                type={SIZE_COMPACT}
                className={classes.root}
                value={value}
                options={options}
                {...props}
            />
        );
    }
}

DayOfMonthPicker.propTypes = {
    // From withStyles we expect to get classes.
    classes: PropTypes.object.isRequired,

    // Value must be a number or a string when its 'none'
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([NONE])]),

    // Month value needs to be a valid month number.
    month: DatePropTypes.month.isRequired,

    // Year must be 'none' or a valid year.
    year: DatePropTypes.year,
};

DayOfMonthPicker.defaultProps = {
    value: NONE,
    year: currentYear,
};

export default withStyles(styles)(DayOfMonthPicker);
