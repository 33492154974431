import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Divider, Typography, withStyles } from '@material-ui/core';
import AdminButton from '../AdminButton';
import styles from './styles';
import { noop } from '../../utils';

class ActionableListRow extends React.Component {
    render() {
        const {
            classes,
            label,
            positiveActionLabel,
            onNegativeAction,
            negativeActionLabel,
            onPositiveAction,
            PositiveButtonProps,
            NegativeButtonProps,
        } = this.props;

        return (
            <Grid container direction="row" className={classes.container}>
                <Grid container direction="row">
                    <Grid item className={classes.labelWrapper}>
                        <Typography color="secondary" className={classes.label}>
                            {label}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        spacing={16}
                        direction="row"
                        className={classes.buttonWrapper}
                    >
                        <Grid
                            container
                            spacing={16}
                            direction="row"
                            className={classes.buttonWrapper}
                        >
                            <Grid item>
                                <AdminButton
                                    buttonType="edit"
                                    size="small"
                                    onClick={onPositiveAction}
                                    {...PositiveButtonProps}
                                >
                                    {positiveActionLabel}
                                </AdminButton>
                            </Grid>
                            {onNegativeAction && (
                                <Grid item>
                                    <AdminButton
                                        buttonType="negative"
                                        size="small"
                                        onClick={onNegativeAction}
                                        {...NegativeButtonProps}
                                    >
                                        {negativeActionLabel}
                                    </AdminButton>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.divider}>
                    <Divider light />
                </Grid>
            </Grid>
        );
    }
}

ActionableListRow.propTypes = {
    classes: PropTypes.object.isRequired,
    label: PropTypes.string,
    positiveActionLabel: PropTypes.string,
    negativeActionLabel: PropTypes.string,
    onPositiveAction: PropTypes.func,
    onNegativeAction: PropTypes.func,
    PositiveButtonProps: PropTypes.object,
    NegativeButtonProps: PropTypes.object,
};
ActionableListRow.defaultProps = {
    positiveActionLabel: 'Edit',
    negativeActionLabel: 'Delete',
    onPositiveAction: noop,
    onNegativeAction: null,
    PositiveButtonProps: {},
    NegativeButtonProps: {},
};

export default withStyles(styles)(ActionableListRow);
