import initialState from './initialState';
import {
    UPDATE_VEHICLE_PHOTOS_REQUESTED,
    UPDATE_VEHICLE_PHOTOS_SUCCEEDED,
    UPDATE_VEHICLE_PHOTOS_FAILED,
    REMOVE_FORM_MESSAGE,
    FETCH_VEHICLE_MODELS_SUCCEEDED,
    FETCH_VEHICLE_MAKES_SUCCEEDED,
} from './actions';
import {
    createMessagesFromErrors,
    removeMessage,
} from '../common/reducerUtils';

export default (state = initialState, action = {}) => {
    const { type } = action;
    switch (type) {
        case UPDATE_VEHICLE_PHOTOS_REQUESTED: {
            return {
                ...state,
                updateInProgress: true,
                messages: [],
            };
        }
        case UPDATE_VEHICLE_PHOTOS_SUCCEEDED: {
            return {
                ...state,
                updateInProgress: false,
            };
        }
        case UPDATE_VEHICLE_PHOTOS_FAILED: {
            const { errors = [] } = action;
            return {
                ...state,
                updateInProgress: false,
                messages: createMessagesFromErrors(errors),
            };
        }
        case REMOVE_FORM_MESSAGE:
            return {
                ...state,
                messages: removeMessage(state.messages, action.messageKey),
            };
        case FETCH_VEHICLE_MODELS_SUCCEEDED: {
            const { vehicleModels } = action;
            return {
                ...state,
                vehicleModels,
            };
        }
        case FETCH_VEHICLE_MAKES_SUCCEEDED: {
            const { vehicleMakes } = action;
            return {
                ...state,
                vehicleMakes,
            };
        }
        default: {
            return state;
        }
    }
};
