import React from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    CircularProgress,
    withStyles,
    Typography,
} from '@material-ui/core';
import styles from './styles';
import AdminContainer from '../../common/AdminContainer';

function AppGate({ classes, initializing, checkInProgress, errors }) {
    return (
        <AdminContainer exterior>
            <Grid
                container
                alignItems="center"
                justify="center"
                direction="column"
                className={classes.container}
            >
                {initializing || checkInProgress ? (
                    <Grid
                        item
                        component={CircularProgress}
                        className={classes.loading}
                    />
                ) : null}
                {checkInProgress ? (
                    <Grid item>
                        <Typography
                            variant="subtitle1"
                            className={classes.loading}
                        >
                            {"You're being logged in..."}
                        </Typography>
                    </Grid>
                ) : null}
                {errors
                    ? errors.map((error) => (
                          <Grid item key={error}>
                              <Typography
                                  color="error"
                                  className={classes.error}
                              >
                                  {error}
                              </Typography>
                          </Grid>
                      ))
                    : null}
            </Grid>
        </AdminContainer>
    );
}

AppGate.propTypes = {
    classes: PropTypes.object.isRequired,
    initializing: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.string),
    checkInProgress: PropTypes.bool,
};

AppGate.defaultProps = {
    loading: true,
    errors: [],
    checkInProgress: false,
};

export default withStyles(styles)(AppGate);
