import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { weekdays, months, years, NONE } from '../utils/dates';

export const time = PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(DateTime),
]);

export const weekday = PropTypes.oneOf(
    weekdays.map((weekday, index) => index + 1),
);

export const weekdayAndTime = PropTypes.shape({
    day: weekday,
    time: time,
});

export const month = PropTypes.oneOf([
    NONE,
    ...months.map((month, index) => index + 1),
]);

export const year = PropTypes.oneOf([NONE, ...years]);

export const day = PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf([NONE]),
]);

export const filterDate = PropTypes.shape({
    month: month,
    onMonthChange: PropTypes.func,
    monthError: PropTypes.bool,
    day: day,
    onDayChange: PropTypes.func,
    dayError: PropTypes.bool,
    year: year,
    onYearChange: PropTypes.func,
    yearError: PropTypes.bool,
});

export const DatePropTypes = {
    time,
    weekday,
    weekdayAndTime,
    month,
    day,
    year,
    filterDate,
};

export default DatePropTypes;
