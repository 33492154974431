import {
    ADD_DOCK_PHOTOS_FAILED,
    ADD_DOCK_PHOTOS_REQUESTED,
    ADD_DOCK_PHOTOS_SUCCEEDED,
    CREATE_DOCK_FAILED,
    CREATE_DOCK_REQUESTED,
    CREATE_DOCK_SUCCEEDED,
    DELETE_DOCK_TYPES,
    FETCH_DOCK_TYPES,
    UPDATE_DOCK_REQUESTED,
    UPDATE_DOCK_FAILED,
    UPDATE_DOCK_SUCCEEDED,
} from './apiActions';
import { LOGOUT } from '../auth/actions';
import { docksInitialState } from './initialState';
import { CLEANUP_DETAILED_STATE, REMOVE_FORM_MESSAGE } from './actions';
import {
    createMessagesFromErrors,
    removeMessage,
} from '../common/reducerUtils';

export default function(state = docksInitialState, action = {}) {
    const { type, dock, errors } = action;
    switch (type) {
        case FETCH_DOCK_TYPES.requested:
            return {
                ...state,
                loading: true,
            };
        case FETCH_DOCK_TYPES.succeeded:
            return {
                ...state,
                loading: false,
                dock,
            };
        case FETCH_DOCK_TYPES.failed:
            return {
                ...state,
                loading: false,
                errors,
            };
        case CREATE_DOCK_REQUESTED:
            return { ...state, creating: true };
        case CREATE_DOCK_SUCCEEDED:
            return { ...state, creating: false, dock };
        case CREATE_DOCK_FAILED: {
            const { errors } = action;
            return {
                ...state,
                creating: false,
                messages: createMessagesFromErrors(errors),
                dock,
            };
        }
        case UPDATE_DOCK_REQUESTED:
            return {
                ...state,
                loading: true,
            };
        case UPDATE_DOCK_SUCCEEDED:
            return {
                ...state,
                messages: [
                    {
                        message: 'Update of dock has succeeded.',
                        type: 'success',
                        key: 'successMsgKey',
                    },
                ],
                loading: false,
                dock,
            };
        case UPDATE_DOCK_FAILED:
            return {
                ...state,
                loading: false,
                messages: createMessagesFromErrors(action.errors),
                dock,
            };
        case DELETE_DOCK_TYPES.failed:
            return {
                ...state,
                messages: createMessagesFromErrors(errors),
            };
        case ADD_DOCK_PHOTOS_REQUESTED:
            return { ...state, photoUpdateInProgress: true };
        case ADD_DOCK_PHOTOS_SUCCEEDED: {
            const { photos } = action;
            return {
                ...state,
                photoUpdateInProgress: false,
                dock: {
                    ...state.dock,
                    photos,
                },
            };
        }
        case ADD_DOCK_PHOTOS_FAILED: {
            const { errors } = action;
            return {
                ...state,
                photoUpdateInProgress: false,
                messages: [...state.messages, createMessagesFromErrors(errors)],
            };
        }
        case REMOVE_FORM_MESSAGE:
            return {
                ...state,
                messages: removeMessage(state.messages, action.messageKey),
            };
        case CLEANUP_DETAILED_STATE: // Intentional fall through to revert to initial state.
        case LOGOUT:
            return docksInitialState;
        default:
            return state;
    }
}
