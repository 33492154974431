const styles = (theme) => ({
    submitButton: {
        color: theme.custom.darkBlue,
        backgroundColor: theme.custom.darkBlue,
        marginLeft: -5,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        borderRadius: 3,
        height: '100%',
        width: 17,
    },
    flex: {
        flex: 1,
    },
    arrowIcon: {
        color: theme.palette.primary.contrastText,
        fontSize: 26,
    },
    inputLabel: {
        color: theme.palette.secondary.main,
        paddingLeft: 8,
        opacity: 0.5,
    },
    inputStyle: {
        backgroundColor: theme.palette.primary.contrastText,
        borderRadius: 3,
        color: theme.custom.darkBlue,
        height: 17,
    },
    inputNotchedOutline: {
        borderWidth: 0,
    },
    progress: {
        color: theme.palette.primary.contrastText,
        opacity: 1,
    },
    shrinkInputLabel: {
        paddingLeft: 0,
        marginTop: 0,
    },
});

export default styles;
