import { createSelector } from 'reselect';
import {
    createApiPageContextSelector,
    createApiPageDataSelector,
} from '../../utils/api';
import { createTablePageDataSelector } from '../../tables/utils';
import {
    transformOwnedPropertiesForSelect,
    transformProperty,
    transformReportReservations,
} from './transforms';

export const getPropertiesState = (state) => state.properties;
export const isPropertiesListLoading = (state) => state.properties.list.loading;
export const getPropertiesListPage = (state) => state.properties.list.page;
export const getPropertiesListApiPageData = createApiPageDataSelector(
    getPropertiesListPage,
);
export const getPropertiesListTablePageData = createTablePageDataSelector(
    getPropertiesListApiPageData,
    isPropertiesListLoading,
);
export const getPropertiesListPageContext = createApiPageContextSelector(
    getPropertiesListPage,
);
export const getPropertiesListErrors = (state) => state.properties.list.errors;

export const isOwnedPropertiesListLoading = (state) =>
    state.properties.ownerList.loading;
export const getOwnedPropertiesListPage = (state) =>
    state.properties.ownerList.page;
export const getOwnedPropertiesForSelect = createSelector(
    getOwnedPropertiesListPage,
    ({ data }) => transformOwnedPropertiesForSelect(data),
);
export const getOwnedPropertiesListApiPageData = createApiPageDataSelector(
    getOwnedPropertiesListPage,
);
export const getOwnedPropertiesListPageContext = createApiPageContextSelector(
    getOwnedPropertiesListPage,
);
export const getOwnedPropertiesListErrors = (state) =>
    state.properties.ownerList.errors;

export const getPropertyDetails = createSelector(
    (state) => state.properties.property,
    (property) => {
        return property ? transformProperty(property) : {};
    },
);

export const getPropertyCountryAbbr = createSelector(
    (state) => getPropertyDetails(state),
    (property) => {
        return property.country ? property.country.countryAbbr : '';
    },
);

export const isPropertyDetailLoading = (state) =>
    state.properties.propertyLoading ||
    state.properties.photoUpdateInProgress ||
    state.properties.dockDeleteInProgress ||
    state.properties.creating ||
    state.amenities.loading ||
    state.rules.loading ||
    state.properties.ownerLoading;

export const isPropertyReportLoading = (state) =>
    state.properties.loadingReport;
export const getPropertyReport = createSelector(
    getPropertiesState,
    (properties) => properties.report || {},
);
export const getPropertyReportReservations = createSelector(
    getPropertyReport,
    (report) => transformReportReservations(report.reservations || []),
);

export const isSendingPropertyReport = (state) =>
    state.properties.sendingReport;
export const wasPropertyReportSent = (state) => state.properties.reportSent;
export const propertyReportError = (state) => state.properties.reportFailed;

export const getOwnerForNewProperty = (state) => state.properties.owner;

export const getOwnerEmail = createSelector(
    (state) => getOwnerForNewProperty(state),
    (owner = {}) => {
        return owner.email;
    },
);

export const getOwnerCountry = createSelector(
    (state) => getOwnerForNewProperty(state),
    (owner = {}) => {
        return owner.region && owner.region.country ? owner.region.country : {};
    },
);

export const getPropertyOwnerCountryAbbr = createSelector(
    (state) => getOwnerCountry(state),
    (country) => country.countryAbbr || '',
);

export const getMultiDeleteErrors = createSelector(
    (state) => state.properties,
    (properties) => properties.multiDeleteErrors || [],
);
