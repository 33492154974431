import React from 'react';
import { withStyles } from '@material-ui/core';
import AdminButton, { BUTTON_TYPE_EDIT } from '../../common/AdminButton';

const styles = (theme) => ({
    root: {
        minWidth: 80,
        [theme.breakpoints.down('md')]: {
            minWidth: 40,
            marginLeft: 5,
        },
    },
});

const ApproveButton = ({ classes, ...props }) => {
    return (
        <AdminButton
            buttonType={BUTTON_TYPE_EDIT}
            size="small"
            className={classes.root}
            {...props}
        >
            Approve
        </AdminButton>
    );
};

export default withStyles(styles)(ApproveButton);
