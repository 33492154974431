import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import styles from './styles';
import AccordionMenu from '../AccordionMenu';
import AdminSelect, { SIZE_COMPACT } from '../AdminSelect';
import AdminButton from '../AdminButton';
import { noop } from '../../utils';

class ActionsMenu extends Component {
    state = {
        selectedAction: this.props.initValue,
    };

    handleActionChange = (event) => {
        this.setState({ selectedAction: event.target.value });
    };

    render() {
        const {
            classes,
            actions,
            onClickApply,
            onAccordionChange,
            initValue,
            disabled,
            ...restProps
        } = this.props;
        const { selectedAction } = this.state;
        return (
            <AccordionMenu
                container
                label="Actions"
                classes={{
                    root: classes.root,
                    expanded: classes.expanded,
                }}
                onChange={onAccordionChange}
                {...restProps}
            >
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="stretch"
                    className={classes.actionsMenuGrid}
                >
                    <Grid item className={classes.selectGridItem}>
                        <AdminSelect
                            type={SIZE_COMPACT}
                            options={actions}
                            value={selectedAction}
                            onChange={this.handleActionChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item className={classes.buttonGridItem}>
                        <AdminButton
                            color="secondary"
                            size="medium"
                            fullWidth
                            className={classes.applyButton}
                            onClick={() => onClickApply(selectedAction)}
                            disabled={disabled}
                        >
                            Apply
                        </AdminButton>
                    </Grid>
                </Grid>
            </AccordionMenu>
        );
    }
}

ActionsMenu.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // The actions to render
    actions: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.shape({
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                ]),
                label: PropTypes.node,
            }),
        ]),
    ),

    // The initial value for actions dropdown
    initValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    // The handler called when "apply" is clicked.
    onClickApply: PropTypes.func,

    // An explicit handler for when the accordion's expanded state changes (renamed from onChange for clarity's sake)
    onAccordionChange: PropTypes.func,

    // Disable the actions menu submit
    disabled: PropTypes.bool,
};

ActionsMenu.defaultProps = {
    actions: [],
    initValue: '',
    onClickApply: noop,
    onAccordionChange: noop,
    disabled: false,
};

export default withStyles(styles)(ActionsMenu);
