const styles = (theme) => ({
    clearIcon: {
        color: theme.custom.successBlue,
        fontSize: 12,
    },
    chip: {
        fontSize: 12,
        height: 24,
    },
});

export default styles;
