const styles = (theme) => ({
    container: {
        width: '100%',
        maxWidth: theme.custom.containerMaxWidth,
    },
    interiorContainer: {
        backgroundColor: theme.palette.primary.contrastText,
    },
    exteriorContainer: {},
    innerContainer: {
        width: '100%',
        margin: '0 auto',
        padding: `0 ${theme.custom.containerPadding}px`,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    menuAndLogoutGridItem: {
        flexGrow: 1,
    },
});

export default styles;
