import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Grid,
    withStyles,
    Typography,
} from '@material-ui/core';
import AdminButton from '../AdminButton';
import styles from './styles';

class ConfirmDialog extends Component {
    render() {
        const {
            classes,
            title,
            text,
            subText,
            onConfirm,
            onCancel,
            additionalContent,
            confirmLabel,
            cancelLabel,
            showCancel,
            ...props
        } = this.props;
        return (
            <Dialog PaperProps={{ className: classes.contentPaper }} {...props}>
                <DialogTitle disableTypography className={classes.title}>
                    {title}
                </DialogTitle>
                <DialogContent className={classes.content}>
                    <DialogContentText
                        variant="body1"
                        gutterBottom
                        className={classNames([
                            classes.contentText,
                            classes.text,
                        ])}
                    >
                        {Array.isArray(text)
                            ? text.map((textItem) => {
                                  return (
                                      <Typography
                                          variant="body1"
                                          gutterBottom
                                          className={classNames([
                                              classes.contentText,
                                              classes.text,
                                          ])}
                                          key={textItem}
                                      >
                                          {textItem}
                                      </Typography>
                                  );
                              })
                            : text}
                    </DialogContentText>
                    <DialogContentText
                        variant="body2"
                        gutterBottom
                        className={classNames([
                            classes.contentText,
                            classes.subText,
                        ])}
                    >
                        {subText}
                    </DialogContentText>
                    {additionalContent}
                    <DialogActions>
                        <Grid
                            container
                            direction="row"
                            spacing={24}
                            justify="center"
                            alignItems="center"
                        >
                            <Grid item>
                                <AdminButton
                                    buttonType="positive"
                                    size="large"
                                    onClick={onConfirm}
                                >
                                    {confirmLabel}
                                </AdminButton>
                            </Grid>
                            {showCancel && (
                                <Grid item>
                                    <AdminButton
                                        buttonType="negative"
                                        size="large"
                                        onClick={onCancel}
                                    >
                                        {cancelLabel}
                                    </AdminButton>
                                </Grid>
                            )}
                        </Grid>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        );
    }
}

ConfirmDialog.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // The title for the dialog.
    title: PropTypes.string.isRequired,

    // The text that will be displayed inside the dialog in bold.
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),

    // The text that will be displayed in the dialog, below the text.
    subText: PropTypes.string,

    // Function that will be called when the Confirm button is clicked.
    onConfirm: PropTypes.func.isRequired,

    // Function that will be called when the Cancel button is clicked.
    onCancel: PropTypes.func.isRequired,

    // Any additional content to be rendered below the subText.
    additionalContent: PropTypes.node,

    // Optional param to control the label on the Confirm button
    confirmLabel: PropTypes.string,

    // Optional param to control the label on the Cancel button
    cancelLabel: PropTypes.string,

    // Optional param to control whether or not the cancel button will appear
    showCancel: PropTypes.bool,
};

ConfirmDialog.defaultProps = {
    // By default, the dialog is not open.
    open: false,

    text: '',
    subText: '',
    additionalContent: null,
    confirmLabel: 'Confirm',
    cancelLabel: 'Cancel',
    showCancel: true,
};

export default withStyles(styles)(ConfirmDialog);
