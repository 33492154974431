import { call, put, takeEvery, select } from 'redux-saga/effects';
import {
    FETCH_REGIONS_REQUESTED,
    fetchRegionsSucceeded,
    fetchRegionsFailed,
    fetchRegionsFinished,
} from './actions';
import { fetchWithAuth } from '../api/sagas';
import { getRegions } from './selectors';
import { isEmpty } from 'ramda';

export function* doFetchRegions({ countryAbbr = 'US' }) {
    const regions = yield select(getRegions);
    if (
        !regions ||
        isEmpty(regions) ||
        !regions[countryAbbr] ||
        regions[countryAbbr].length === 0
    ) {
        try {
            const response = yield call(fetchWithAuth, '/region', {
                query: { countryAbbr },
            });
            yield put(fetchRegionsSucceeded(countryAbbr, response));
        } catch {
            yield put(fetchRegionsFailed());
        }
    } else {
        yield put(fetchRegionsFinished());
    }
}

export default function*() {
    yield takeEvery(FETCH_REGIONS_REQUESTED, doFetchRegions);
}
