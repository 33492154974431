import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import CoordinateTextField from './CoordinateTextField';
import { noop } from '../../utils';

const styles = () => ({
    errorText: {
        fontWeight: 'bold',
        marginLeft: 8,
        fontSize: 12,
    },
});

class CoordinatesInput extends Component {
    render() {
        const { latitude, longitude, onChange, error, classes } = this.props;
        return (
            <Grid container direction="column">
                <Grid
                    item
                    container
                    direction="row"
                    spacing={16}
                    justify="center"
                    alignItems="center"
                    wrap="nowrap"
                >
                    <Grid item container direction="column">
                        <Grid item>
                            <Typography color="secondary" gutterBottom>
                                Latitude
                            </Typography>
                        </Grid>
                        <Grid item>
                            <CoordinateTextField
                                name="latitude"
                                value={latitude}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction="column">
                        <Grid item>
                            <Typography color="secondary" gutterBottom>
                                Longitude
                            </Typography>
                        </Grid>
                        <Grid item>
                            <CoordinateTextField
                                name="longitude"
                                value={longitude}
                                onChange={onChange}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {error ? (
                    <Grid item>
                        <Typography color="error" className={classes.errorText}>
                            {error}
                        </Typography>
                    </Grid>
                ) : null}
            </Grid>
        );
    }
}

CoordinatesInput.propTypes = {
    classes: PropTypes.object,
    onChange: PropTypes.func,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    error: PropTypes.string,
};

CoordinatesInput.defaultProps = {
    onChange: noop,
    error: '',
};

export default withStyles(styles)(CoordinatesInput);
