import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uuidv4 from 'uuid/v4';
import { ButtonBase, TableCell, withStyles } from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';
import { noop } from '../../../utils';
import { SORT_ASC, SORT_DESC, SORT_NONE } from '../constants';
import styles from './styles';

export const COMPONENT_NAME = 'DataTableColumn';

export const RENDER_TARGET_HEAD = 'head';
export const RENDER_TARGET_BODY = 'body';
export const RENDER_TARGETS = [RENDER_TARGET_BODY, RENDER_TARGET_HEAD];

class DataTableColumn extends Component {
    static propTypes = {
        /*
         * What to render in this cell in each row.
         *
         * If this is a function will be called with three arguments:
         *   - the data for this row
         *   - the index of this row
         *   - the current table state
         */
        children: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
            .isRequired,

        /*
         * A function that will render the head cell contents for this column or a react element to render instead.
         * If a function is provided, it will be called with the following arguments:
         *   - the current table state
         * Default: render nothing
         */
        headCellContent: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),

        /*
         * A function that must render an actual @material-ui/core/TableCell for the head cell for this column.
         * Used to override styling of cells on a per cell basis for example. Note if this doesn't render the
         * `children` prop it receives, the cell contents set internally won't be rendered, including the sort arrows
         * for example.
         * Function will be called with three arguments:
         *   - props that can and should be spread on a @material-ui/core/TableCell including a children prop.
         *   - the current table state
         */
        renderHeadCell: PropTypes.func,

        /*
         * A function that will be called to wrap the contents of the head cell if the column is sortable.
         * Called with:
         *   - an object full of props:
         *      - onSort: Should be called on click or on any kind of indication that a sort should happen
         *      - classes: Classes from the DataTableColumn styles
         *      - sort: The current sort direction/state. One of `SORTS`.
         *   - the cell content to render inside
         *   - the current table state
         * Default: Wraps the contents in a button base with a new sibling arrow icon that reflects the current state.
         */
        renderSortableHeadCellWrapper: PropTypes.func,

        /*
         * A function that must render an actual @material-ui/core/TableCell for the body cell for this column in a row.
         * Used to override styling of cells on a per cell basis for example. Note if this doesn't render the
         * `children` prop it receives, the cell contents set internally won't be rendered.
         * Function will be called with four arguments:
         *   - props that can and should be spread on a @material-ui/core/TableCell including a children prop.
         *   - the data for this row
         *   - the index of this row
         *   - the current table state
         */
        renderBodyCell: PropTypes.func,

        /*
         * A function that returns one of the following (one of `SORTS`):
         *   - `false` or `SORT_NONE`: table is not being actively sorted by this column
         *   - `'asc'` or `SORT_ASC`: table is being actively sorted ascending by this column
         *   - `'desc'` or `SORT_DESC`: table is being actively sorted descending by this column
         * Default: this column is not sortable.
         */
        getSort: PropTypes.func,

        /*
         * A function that will be called when a sortable column header is clicked.
         * Function will be called with:
         *   - the current sort value
         * Default: no op.
         */
        onSort: PropTypes.func,

        /*
         * The initial sort to use when switching to this column for the active sort.
         * Default: ascending (`SORT_ASC`)
         */
        initialSort: PropTypes.oneOf([SORT_ASC, SORT_DESC]),

        /*
         * The ID of the column. This is used as the prefix to the literal HTML id of the head and body cells
         * as well as to generate their keys.
         * Default: random ID is used.
         */
        id: PropTypes.string,

        /*
         * withStyles classes. Overridable by passing in an object into this prop (will be merged with default
         * classes) or via the MUI theme. All per normal MUI behaviour.
         */
        classes: PropTypes.object.isRequired,

        // The class name to apply to the rendered body cell (<TableCell />)
        className: PropTypes.string,

        // The class name to apply to the rendered head cell (<TableCell />)
        headCellClassName: PropTypes.string,

        /*
         * Internal use.
         * Determines what to render in the render method. Set when the element is cloned.
         */
        _render: PropTypes.oneOf(RENDER_TARGETS),
        _tableState: PropTypes.object,
        _row: PropTypes.any,
        _rowIndex: PropTypes.number,
    };

    static defaultProps = {
        headCellContent: null,
        renderHeadCell: null,
        renderSortableHeadCellWrapper: (
            { onSort, sort, classes },
            children,
        ) => {
            const arrowClassName =
                sort === SORT_NONE
                    ? classes.sortArrow
                    : classNames([
                          classes.sortArrow,
                          classes.sortArrowActive,
                          sort === SORT_ASC ? classes.sortArrowAsc : null,
                          sort === SORT_DESC ? classes.sortArrowDesc : null,
                      ]);
            return (
                <ButtonBase
                    component="span"
                    onClick={onSort}
                    className={classes.sortableWrapper}
                >
                    {children}
                    <ArrowRight className={arrowClassName} />
                </ButtonBase>
            );
        },
        renderBodyCell: null,
        getSort: null,
        onSort: noop,
        initialSort: SORT_ASC,
        id: null,
        classes: {},
        className: null,
        headCellClassName: null,
        _render: null,
        _tableState: {},
        _row: null,
        _rowIndex: null,
    };

    state = {
        randomId: uuidv4(),
    };

    /**
     * Generate the ID of this table or parts of the table.
     *
     * @return {string}
     */
    generateId = (...parts) =>
        [COMPONENT_NAME, this.props.id || this.state.randomId, ...parts].join(
            '--',
        );

    /**
     * Determines whether or not the column is sortable. I.e. can be clicked to dispatch sort actions.
     * @return {boolean}
     */
    isSortable = () => !!this.props.getSort;

    /**
     * Calls into provided onSort prop with the next sort value.
     *
     * @see SORTS
     * @return {*}
     */
    onSort = (event) => {
        if (event) {
            event.preventDefault();
        }
        const { onSort, getSort, initialSort } = this.props;
        const currentSort = getSort();
        if (!currentSort) {
            onSort(initialSort);
        } else {
            onSort(currentSort === SORT_ASC ? SORT_DESC : SORT_ASC);
        }
    };

    /**
     * Returns a function that will call a render prop if it's a function or simply attempt to render it
     * as a renderable node otherwise.
     *
     * @param propKey
     * @return {function(...[*]): React.ReactNode|React.ReactNodeArray}
     */
    createRenderPropDelegator = (propKey) => (...args) => {
        if (!this.props[propKey]) {
            return null;
        } else {
            return typeof this.props[propKey] === 'function' &&
                !React.isValidElement(this.props[propKey])
                ? this.props[propKey](...args)
                : this.props[propKey];
        }
    };

    /**
     * Render the content of a head cell.
     * @function
     * @type {function(...[*]): React.ReactNode|React.ReactNodeArray}
     */
    renderHeadCellContent = this.createRenderPropDelegator('headCellContent');

    /**
     * Render the content of a body cell.
     * @function
     * @type {function(...[*]): React.ReactNode|React.ReactNodeArray}
     */
    renderBodyCellContent = this.createRenderPropDelegator('children');

    /**
     * Render a head cell for this column.
     *
     * @param {DataTableState} tableState The state of the table.
     * @return {React.ReactNode}
     */
    renderHeadCell = (tableState) => {
        const {
            headCellClassName = null,
            classes,
            renderHeadCell,
            renderSortableHeadCellWrapper,
            getSort,
        } = this.props;
        const sortable = this.isSortable();
        const sort = sortable ? getSort() : null;

        const content = this.renderHeadCellContent(tableState);

        const children = sortable
            ? renderSortableHeadCellWrapper(
                  { onSort: this.onSort, sort, classes },
                  content,
                  tableState,
              )
            : content;

        const id = this.generateId('head');
        const tableCellProps = {
            id,
            key: id,
            className: classNames(classes.headCell, headCellClassName),
            children,
        };

        return renderHeadCell ? (
            renderHeadCell(tableCellProps, tableState)
        ) : (
            <TableCell {...tableCellProps} />
        );
    };

    /**
     * Render a head cell for this column.
     *
     * @param {*} row The piece of data for this row.
     * @param {number} rowIndex The index of this row.
     * @param {DataTableState} tableState The state of the table.
     * @return {React.ReactNode}
     */
    renderBodyCell = (row, rowIndex, tableState) => {
        const { className = null, classes, renderBodyCell } = this.props;

        const key = this.generateId('body');

        const tableCellProps = {
            key,
            className: classNames(classes.bodyCell, className),
            children: this.renderBodyCellContent(row, rowIndex, tableState),
        };

        return renderBodyCell ? (
            renderBodyCell(tableCellProps, row, rowIndex, tableState)
        ) : (
            <TableCell {...tableCellProps} />
        );
    };

    /**
     * This component is declarative and uses render props to render out head cells and body cells for the
     * column it describes. It does not itself render out to anything.
     * @return {null}
     */
    render() {
        const { _render, _tableState, _row, _rowIndex } = this.props;
        if (_render === RENDER_TARGET_HEAD) {
            return this.renderHeadCell(_tableState);
        } else if (_render === RENDER_TARGET_BODY) {
            return this.renderBodyCell(_row, _rowIndex, _tableState);
        } else {
            return null;
        }
    }
}

export default withStyles(styles, { name: COMPONENT_NAME })(DataTableColumn);
