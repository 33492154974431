import { createSelector } from 'reselect';
import {
    convertDataForDetailView,
    transformVehicleForDetailView,
} from './transforms';
import {
    createApiPageContextSelector,
    createApiPageDataSelector,
} from '../../utils/api';
import { createTablePageDataSelector } from '../../tables/utils';
import { getPathname } from '../router/selectors';

export const isUserDetailLoading = (state) =>
    state.users.loading ||
    state.users.photoUpdateInProgress ||
    state.users.creating ||
    state.userGroups.loading;

export const getUser = (state) => state.users.user;
export const getMessages = (state) => state.users.messages;

export const getUsersForDetailView = createSelector(
    (state) => state.users.user,
    (user) => {
        return user ? convertDataForDetailView(user) : {};
    },
);

export const getUserCountryAbbr = createSelector(
    (state) => getUsersForDetailView(state),
    (user) => {
        return user.country ? user.country.countryAbbr : '';
    },
);

export const isCreatingUser = createSelector(
    (state) => getUsersForDetailView(state),
    (user) => {
        return !user.userId;
    },
);

export const getFormMessages = createSelector(
    (state) => state.users.messages,
    (messages) => messages || [],
);

export const isSendingUserReport = (state) => state.users.sendingReport;
export const wasUserReportSent = (state) => state.users.reportSent;
export const userReportError = (state) => state.users.reportFailed;

export const isUsersListLoading = (state) => state.users.list.loading;
export const getUsersListPage = (state) => state.users.list.page;
export const getUsersListApiPageData = createApiPageDataSelector(
    getUsersListPage,
);
export const getUsersListTablePageData = createTablePageDataSelector(
    getUsersListApiPageData,
    isUsersListLoading,
);
export const getUsersListPageContext = createApiPageContextSelector(
    getUsersListPage,
);
export const getUsersListErrors = (state) => state.users.list.errors;

export const getVehicleDetails = createSelector(
    getUser,
    getPathname,
    (user, path) => {
        const pathArray = path.split('/');
        const pathArrayLength = pathArray.length;
        const userId = pathArrayLength > 2 ? pathArray[2] : null;
        const vehicleId = pathArrayLength > 4 ? pathArray[4] : null;
        if (`${user.userId}` === `${userId}`) {
            if (user.vehicles && user.vehicles.length) {
                const foundVehicle = user.vehicles.find((vehicle) => {
                    return `${vehicle.vehicleId}` === `${vehicleId}`;
                });
                if (foundVehicle) {
                    return transformVehicleForDetailView(foundVehicle);
                }
            }
        }
        return {};
    },
);
