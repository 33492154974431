const styles = (theme) => ({
    container: {
        width: theme.custom.actionsAndFiltersWidth,
        [theme.breakpoints.down('md')]: {
            width: theme.custom.actionsAndFiltersWidthForMediumAndDown,
        },
    },
    filterLabel: {
        color: theme.palette.primary.contrastText,
        fontSize: 14,
    },
    panel: {
        padding: '10px 20px',
        backgroundColor: theme.custom.successBlue,
    },
    stateAndCountrySelect: {
        width: 150,
        marginBottom: 10,
    },
    stateAndCountryLabels: {
        marginLeft: 16,
    },
    rangeArrow: {
        color: theme.palette.primary.contrastText,
        marginTop: 24,
    },
});

export default styles;
