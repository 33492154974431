import { createSelector } from 'reselect';
import { transformDock } from './transforms';

export const getDockDetails = createSelector(
    (state) => state.docks.dock,
    (dock) => {
        return dock ? transformDock(dock) : {};
    },
);

export const isDockDetailLoading = (state) =>
    state.docks.loading ||
    state.docks.photoUpdateInProgress ||
    state.docks.creating;

export const isCreatingDock = createSelector(
    (state) => getDockDetails(state),
    (dock) => {
        return !dock.dockId;
    },
);
