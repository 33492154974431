import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Grid, withStyles } from '@material-ui/core';

import styles from './styles';
import { menuItems } from './constants';

import Header from '../Header';
import Footer from '../Footer';
import AdminInnerHeader, {
    PropTypes as AdminInnerHeaderPropTypes,
} from '../AdminInnerHeader';

class AdminContainer extends React.Component {
    render() {
        const {
            classes,
            exterior,
            isAdmin,
            path,
            children,
            innerHeader,
            innerHeaderProps,
            onLogoutRequested,
        } = this.props;
        return (
            <Grid
                container
                direction="column"
                wrap="nowrap"
                className={classNames([
                    classes.container,
                    exterior
                        ? classes.exteriorContainer
                        : classes.interiorContainer,
                ])}
            >
                <Grid
                    item
                    container
                    direction="row"
                    alignItems="stretch"
                    justify="center"
                    className={
                        exterior
                            ? classes.exteriorHeaderGridItem
                            : classes.interiorHeaderGridItem
                    }
                >
                    <Header
                        exterior={exterior}
                        menuItems={menuItems}
                        path={path}
                        isAdmin={isAdmin}
                        onLogoutRequested={onLogoutRequested}
                    />
                </Grid>
                {innerHeader && (
                    <Grid item>
                        <AdminInnerHeader {...(innerHeaderProps || {})} />
                    </Grid>
                )}
                <Grid
                    item
                    container
                    direction="column"
                    alignItems="stretch"
                    className={
                        exterior
                            ? classNames([
                                  classes.innerContainer,
                                  classes.exteriorInnerContainer,
                              ])
                            : classes.innerContainer
                    }
                >
                    <Grid item>{children}</Grid>
                </Grid>
                <Grid item container direction="row" alignItems="flex-end">
                    <Grid item component={Footer} />
                </Grid>
            </Grid>
        );
    }
}

AdminContainer.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // Used to indicate whether or not this is an exterior facing page.
    exterior: PropTypes.bool,

    // Whether the authenticated user is an admin
    isAdmin: PropTypes.bool,

    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),

    // The current path of the react router, passed to the header so it can determine which tab is active.
    path: PropTypes.string,

    // Whether or not an sub header should be readers.
    innerHeader: PropTypes.bool,

    // Properties to apply to the inner header.
    innerHeaderProps: PropTypes.shape(AdminInnerHeaderPropTypes),

    // Function we'll pass to the header that will be called when the logout button is clicked
    onLogoutRequested: PropTypes.func,
};

AdminContainer.defaultProps = {
    exterior: false,
    isAdmin: true,
    path: '/',
    innerHeader: false,
    innerHeaderProps: null,
};

export default withStyles(styles)(AdminContainer);
