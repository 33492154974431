import propertiesInitialState, { initialFiltersState } from './initialState';
import { LOGOUT } from '../auth/actions';
import {
    FETCH_PROPERTIES_LIST_PAGE_FAILED,
    FETCH_PROPERTIES_LIST_PAGE_REQUESTED,
    FETCH_PROPERTIES_LIST_PAGE_SUCCEEDED,
    CLEANUP_DETAILED_STATE,
    UPDATE_PROPERTIES_LIST_FILTERS,
    CLEAR_PROPERTIES_LIST_FILTERS,
    FILTERS_VALIDATION_FAILED,
    REMOVE_FORM_MESSAGE,
    SEND_PROPERTY_REPORT_REQUESTED,
    SEND_PROPERTY_REPORT_SUCCEEDED,
    SEND_PROPERTY_REPORT_FAILED,
    FETCH_OWNER_PROPERTIES_REQUESTED,
    FETCH_OWNER_PROPERTIES_SUCCEEDED,
    FETCH_OWNER_PROPERTIES_FAILED,
    FETCH_PROPERTY_REPORT_REQUESTED,
    FETCH_PROPERTY_REPORT_SUCCEEDED,
    FETCH_PROPERTY_REPORT_FAILED,
} from './actions';
import {
    FETCH_PROPERTY_TYPES,
    ADD_PROPERTY_PHOTOS_REQUESTED,
    ADD_PROPERTY_PHOTOS_SUCCEEDED,
    ADD_PROPERTY_PHOTOS_FAILED,
    DELETE_DOCK_REQUESTED,
    DELETE_DOCK_SUCCEEDED,
    DELETE_DOCK_FAILED,
    UPDATE_PROPERTIES_STATUS_REQUESTED,
    UPDATE_PROPERTIES_STATUS_SUCCEEDED,
    UPDATE_PROPERTIES_STATUS_FAILED,
    FETCH_OWNER_FOR_NEW_PROPERTY_REQUESTED,
    FETCH_OWNER_FOR_NEW_PROPERTY_SUCCEEDED,
    FETCH_OWNER_FOR_NEW_PROPERTY_FAILED,
    CREATE_PROPERTY_REQUESTED,
    CREATE_PROPERTY_SUCCEEDED,
    CREATE_PROPERTY_FAILED,
    UPDATE_PROPERTY_REQUESTED,
    UPDATE_PROPERTY_SUCCEEDED,
    UPDATE_PROPERTY_FAILED,
    DELETE_PROPERTIES_REQUESTED,
    DELETE_PROPERTIES_SUCCEEDED,
    DELETE_PROPERTIES_FAILED,
    CLEAR_DELETE_ERRORS,
} from './apiActions';
import { createSliceUpdater } from '../utils';
import {
    setLoadingUpdate,
    createMessagesFromErrors,
    removeMessage,
} from '../common/reducerUtils';
import { USER_NOT_FOUND } from './constants';

const createMessage = (message, key, error = false) => ({
    key,
    message,
    type: error ? 'error' : 'success',
});
const doneProperty = (state, data = {}) => ({
    ...state,
    ...data,
    propertyLoading: false,
});

export default function(state = propertiesInitialState, action = {}) {
    const { type, property, propertyIds } = action;

    const updateSlice = createSliceUpdater(state);

    switch (type) {
        // Fetch properties list
        case FETCH_PROPERTIES_LIST_PAGE_REQUESTED:
            return updateSlice('list', { loading: true });
        case FETCH_PROPERTIES_LIST_PAGE_SUCCEEDED:
            return updateSlice('list', {
                loading: false,
                page: {
                    ...action.data,
                    filters: action.filters,
                    order: action.order,
                },
            });
        case FETCH_PROPERTIES_LIST_PAGE_FAILED:
            return updateSlice('list', {
                loading: false,
                errors: action.errors,
            });

        // Fetch owned properties
        case FETCH_OWNER_PROPERTIES_REQUESTED:
            return updateSlice('ownerList', { loading: true });
        case FETCH_OWNER_PROPERTIES_SUCCEEDED:
            return updateSlice('ownerList', {
                loading: false,
                page: {
                    ...action.data,
                    filters: action.filters,
                    order: action.order,
                },
            });
        case FETCH_OWNER_PROPERTIES_FAILED:
            return updateSlice('ownerList', {
                loading: false,
                errors: action.errors,
            });

        case FETCH_PROPERTY_REPORT_REQUESTED:
            return {
                ...state,
                loadingReport: true,
                reportFailed: '',
            };
        case FETCH_PROPERTY_REPORT_SUCCEEDED: {
            const { report } = action;
            return {
                ...state,
                loadingReport: false,
                report,
            };
        }
        case FETCH_PROPERTY_REPORT_FAILED: {
            const { errors } = action;
            return {
                ...state,
                loadingReport: false,
                reportFailed: errors?.length ? errors[0] : '',
            };
        }

        case SEND_PROPERTY_REPORT_REQUESTED:
            return {
                ...state,
                sendingReport: true,
                reportSent: false,
                reportFailed: '',
            };
        case SEND_PROPERTY_REPORT_SUCCEEDED:
            return {
                ...state,
                sendingReport: false,
                reportSent: true,
            };
        case SEND_PROPERTY_REPORT_FAILED: {
            const { error } = action;
            return {
                ...state,
                sendingReport: false,
                reportFailed: error,
            };
        }

        // Fetch Property Detail
        case FETCH_PROPERTY_TYPES.requested:
            return { ...state, propertyLoading: true, reportSent: false };
        case FETCH_PROPERTY_TYPES.succeeded: {
            const { property = {} } = action;
            return doneProperty(state, { property });
        }
        case FETCH_PROPERTY_TYPES.failed: {
            return doneProperty(state, {
                messages: [
                    createMessage(
                        'Failed To Fetch this Property',
                        'fetch_property_failed',
                        true,
                    ),
                ],
            });
        }

        // Update Property
        case UPDATE_PROPERTY_REQUESTED:
            return { ...state, propertyLoading: true };
        case UPDATE_PROPERTY_SUCCEEDED: {
            return doneProperty(state, {
                property,
                messages: [
                    createMessage(
                        'Successfully updated this Property',
                        'update_property_success',
                    ),
                ],
            });
        }
        case UPDATE_PROPERTY_FAILED: {
            const { errors, property } = action;
            return doneProperty(state, {
                property,
                messages: createMessagesFromErrors(errors),
            });
        }
        case ADD_PROPERTY_PHOTOS_REQUESTED:
            return { ...state, photoUpdateInProgress: true };
        case ADD_PROPERTY_PHOTOS_SUCCEEDED: {
            const { photos } = action;
            return {
                ...state,
                photoUpdateInProgress: false,
                property: {
                    ...state.property,
                    photos,
                },
            };
        }
        case ADD_PROPERTY_PHOTOS_FAILED: {
            const { errors } = action;
            return {
                ...state,
                photoUpdateInProgress: false,
                messages: [
                    ...state.messages,
                    ...createMessagesFromErrors(errors),
                ],
            };
        }

        case DELETE_DOCK_REQUESTED:
            return { ...state, dockDeleteInProgress: true };
        case DELETE_DOCK_SUCCEEDED: {
            const { dockId, dockName } = action;
            return {
                ...state,
                dockDeleteInProgress: false,
                messages: [
                    {
                        message: `Successfully deleted ${dockName}`,
                        key: dockId,
                        type: 'success',
                    },
                ],
            };
        }
        case DELETE_DOCK_FAILED: {
            const { dockId, dockName } = action;
            return {
                ...state,
                dockDeleteInProgress: false,
                messages: [
                    {
                        message: `Failed to delete ${dockName}`,
                        key: dockId,
                        type: 'error',
                    },
                ],
            };
        }

        case CLEANUP_DETAILED_STATE: {
            return doneProperty(state, { property: {}, messages: [] });
        }

        // Property multi actions - changing status
        case UPDATE_PROPERTIES_STATUS_REQUESTED:
            return setLoadingUpdate(state, propertyIds);
        case UPDATE_PROPERTIES_STATUS_SUCCEEDED:
            return setLoadingUpdate(state, propertyIds, false);
        case UPDATE_PROPERTIES_STATUS_FAILED:
            return setLoadingUpdate(
                updateSlice('list', { errors: action.errors }),
                propertyIds,
                false,
            );

        // Property multi actions - deleting properties
        case DELETE_PROPERTIES_REQUESTED:
            return {
                ...state,
                multiDeleteErrors: [],
            };
        case DELETE_PROPERTIES_SUCCEEDED:
            return {
                ...state,
                multiDeleteErrors: action.errors,
            };
        case DELETE_PROPERTIES_FAILED:
            return {
                ...state,
                multiDeleteErrors: action.errors,
            };
        case CLEAR_DELETE_ERRORS:
            return {
                ...state,
                multiDeleteErrors: [],
            };

        // Properties list filter actions
        case UPDATE_PROPERTIES_LIST_FILTERS: {
            const { filters } = action;
            return updateSlice('list', {
                page: {
                    ...state.list.page,
                    filters,
                    filterErrors: [],
                },
            });
        }
        case CLEAR_PROPERTIES_LIST_FILTERS:
            return updateSlice('list', {
                page: {
                    ...state.list.page,
                    filters: initialFiltersState,
                    filterErrors: [],
                },
            });
        case FILTERS_VALIDATION_FAILED: {
            const { filterErrors, filters } = action;
            return updateSlice('list', {
                page: {
                    ...state.list.page,
                    filters,
                    filterErrors: filterErrors.map((filterError) => {
                        return {
                            type: 'error',
                            key: filterError,
                            message: filterError,
                        };
                    }),
                },
            });
        }

        case FETCH_OWNER_FOR_NEW_PROPERTY_REQUESTED:
            return { ...state, ownerLoading: true };
        case FETCH_OWNER_FOR_NEW_PROPERTY_SUCCEEDED:
            return { ...state, ownerLoading: false, owner: action.owner };
        case FETCH_OWNER_FOR_NEW_PROPERTY_FAILED: {
            return {
                ...state,
                ownerLoading: false,
                owner: {
                    email: `${USER_NOT_FOUND} (#${action.userId})`,
                },
                messages: createMessagesFromErrors([action.error]),
            };
        }

        case CREATE_PROPERTY_REQUESTED:
            return { ...state, creating: true };
        case CREATE_PROPERTY_SUCCEEDED:
            return { ...state, creating: false, property: action.property };
        case CREATE_PROPERTY_FAILED:
            return {
                ...state,
                creating: false,
                property,
                messages: createMessagesFromErrors(action.errors),
            };

        case REMOVE_FORM_MESSAGE:
            return {
                ...state,
                messages: removeMessage(state.messages, action.messageKey),
            };

        // Clear on logout
        case LOGOUT:
            return propertiesInitialState;

        default:
            return state;
    }
}
