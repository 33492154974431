import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import ConfirmDialog from '../ConfirmDialog';
import { noop } from '../../utils';
import styles from './styles';
import { Grid } from '@material-ui/core';

class Photo extends Component {
    state = {
        showConfirmDialog: false,
    };

    toggleConfirmationForClearPhoto = () => {
        const { showConfirmDialog } = this.state;
        this.setState({
            showConfirmDialog: !showConfirmDialog,
        });
    };

    renderImage = () => {
        const { classes, src, alt } = this.props;
        return <img className={classes.photo} src={src} alt={alt} />;
    };

    onClear = () => {
        this.props.onClear();
        this.toggleConfirmationForClearPhoto();
    };

    render() {
        const { classes, showClearButton } = this.props;
        return (
            <div className={classes.photoContainer}>
                {this.renderImage()}
                {showClearButton ? (
                    <IconButton
                        className={classes.clearButton}
                        aria-label="Clear"
                        onClick={this.toggleConfirmationForClearPhoto}
                    >
                        <ClearIcon className={classes.clearButtonIcon} />
                    </IconButton>
                ) : null}
                <ConfirmDialog
                    open={this.state.showConfirmDialog}
                    onConfirm={this.onClear}
                    onCancel={this.toggleConfirmationForClearPhoto}
                    title="Confirm Photo Delete"
                    text="You are attempting to delete this photo."
                    subText="This action will permanently delete this photo."
                    additionalContent={
                        <Grid container item justify="center">
                            {this.renderImage()}
                        </Grid>
                    }
                />
            </div>
        );
    }
}
Photo.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // The uri for image src
    src: PropTypes.string.isRequired,

    // Determines if the button to clear the image renders
    showClearButton: PropTypes.bool,

    // Callback function for clear button
    onClear: PropTypes.func,

    // Alternative text for the image.
    alt: PropTypes.string,
};
Photo.defaultProps = {
    showClearButton: true,
    onClear: noop,
    alt: '',
};
export default withStyles(styles)(Photo);
