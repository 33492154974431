import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import LuxonUtils from '@date-io/luxon';
import initializeStore from './stores';
import './App.css';
import theme from './theme';
import Root from './Root';

const { store, history } = initializeStore();

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <CssBaseline />
                <MuiThemeProvider theme={theme}>
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <Root history={history} />
                    </MuiPickersUtilsProvider>
                </MuiThemeProvider>
            </Provider>
        );
    }
}

export default App;
