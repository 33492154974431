/**
 * Transform an array of regions from the API to objects
 * to be used with a drop down select.
 * @param regions
 * @returns {{label, value}[]}
 */
export const transformRegionsForSelect = (regions = []) => {
    return regions.map(({ regionName, regionAbbr }) => {
        return {
            label: regionName,
            value: regionAbbr,
        };
    });
};
