import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import TextField from '../../common/TextField';

const styles = (theme) => ({
    root: {
        minWidth: 240,
        color: theme.palette.secondary.main,
    },
});

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            allowNegative
            suffix="°"
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

class CoordinateTextField extends React.Component {
    handleChange = (name) => (event) => {
        event.target.name = name;
        this.props.onChange(event);
    };

    render() {
        const { classes, name, onChange, ...props } = this.props;

        return (
            <TextField
                classes={{ largeInput: classes.root }}
                onChange={this.handleChange(name)}
                InputProps={{
                    inputComponent: NumberFormatCustom,
                }}
                {...props}
            />
        );
    }
}

export default withStyles(styles)(CoordinateTextField);
