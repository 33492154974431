import { createMuiTheme } from '@material-ui/core/styles';
import { generateTableColumnStyles } from './utils/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#003283',
            contrastText: '#FFF',
        },
        secondary: {
            main: '#4173C4',
            contrastText: '#FCBA00',
        },
    },
    typography: {
        fontFamily: '"proxima-nova", sans-serif',
        useNextVariants: true,
        subtitle1: {
            fontWeight: 100,
            textTransform: 'uppercase',
            letterSpacing: 4,
            fontSize: 28,
        },
    },
    // Custom variables
    custom: {
        headerPadding: 6,
        containerPadding: 30,
        exteriorContainerPadding: 80,
        containerMaxWidth: 1460,
        darkBlue: '#022B6E',
        successBlue: '#5C8CD9',
        red: '#FF4141',
        darkRed: '#b70909',
        editGreen: '#00C14A',
        buttonHoverOpacity: 0.75,
        paginationFontSize: 12,
        lightGray: '#F9F9F9',
        grey: '#EEEEEE',
        white: '#FFFFFF',
        lightGreen: '#e9f9ed',
        actionsAndFiltersWidth: 300,
        actionsAndFiltersWidthForMediumAndDown: 230,
    },
    // Custom component styling
    rigParkAdmin: {
        select: {
            largeHeight: 54,
            largeMinWidth: 150,
            compactHeight: 25,
            compactMinWidth: 125,
        },
        // Common head cell styling for columns that show up in multiple tables
        tableHeadCells: {
            checkbox: generateTableColumnStyles(70),
            id: generateTableColumnStyles(50),
            name: generateTableColumnStyles(240),
            photo: generateTableColumnStyles(70),
            status: generateTableColumnStyles(120),
            // Note that "auto" essentially dictates this cell is the cell that will stretch to fill remaining space
            date: generateTableColumnStyles('auto'),
        },
    },
    // Global props for app-wide changes.
    props: {
        MuiButtonBase: {
            disableRipple: true, // We want to disable the button ripple, for the whole app.
        },
    },
    // Overrides for Material UI components
    overrides: {
        MuiPrivateTabScrollButton: {
            root: {
                width: 6,
                color: '#003283',
                marginLeft: 3,
                marginRight: 3,
            },
        },
        MuiInputBase: {
            input: {
                '&:-webkit-autofill': {
                    transitionDelay: '9999s',
                    transitionProperty: 'background-color, color',
                },
            },
        },
        MuiFilledInput: {
            input: {
                marginBottom: 12,
            },
        },
        MuiDialogContentText: {
            root: {
                textAlign: 'center',
            },
        },
        MuiDialogTitle: {
            root: {
                textAlign: 'center',
            },
        },
        MuiDialogActions: {
            root: {
                paddingTop: 20,
            },
        },
        MuiTableCell: {
            root: {
                paddingLeft: 10,
                paddingRight: 10,
            },
        },
        DataTable: {
            root: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
            },
            meta: {
                padding: '20px 0',
                margin: '0 20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                justifyContent: 'flex-start',
                width: 320,
            },
            filters: {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                justifyContent: 'flex-start',
            },
            searchContainer: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
            },
            search: {
                flex: 1,
            },
            searchInput: {
                'label + &': {
                    marginTop: 0,
                },
            },
            searchControl: {
                width: '100%',
            },
            tableBodyWithDataRow: {
                '&:nth-child(2n)': {
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                },
            },
        },
        DataTableColumn: {
            sortArrow: {
                width: 'auto',
                height: '1em',
                marginTop: '-0.5em',
                marginBottom: '-0.5em',
            },
        },
    },
});

theme.overrides.DataTable = {
    ...theme.overrides.DataTable,
    checkboxHeadCell: theme.rigParkAdmin.tableHeadCells.checkbox,
};

theme.overrides.DataTableColumn = {
    ...theme.overrides.DataTableColumn,
    headCell: {
        whiteSpace: 'nowrap',
        textTransform: 'uppercase',
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
    },
    bodyCell: {
        color: theme.palette.primary.main,
    },
};

export default theme;
