const styles = (theme) => ({
    checkbox: {
        backgroundColor: theme.custom.grey,
        color: theme.custom.grey,
        height: 37,
        width: 37,
        borderRadius: 3,
        '&:hover': {
            backgroundColor: theme.custom.grey,
        },
    },
    checkIcon: {
        color: theme.custom.editGreen,
        fontSize: 34,
    },
    labelGridItem: {
        maxWidth: 100,
    },
});

export default styles;
