import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TablePropTypes from '../../tables/propTypes';
import AdminContainer from '../../common/AdminContainer';
import AdminButton from '../../common/AdminButton';
import {
    clearBookingReservationState,
    clearReservationsListFilters,
    fetchReservationsListPageRequested,
    filtersValidationFailed,
    reservationsListViewLoadRequested,
    updateReservationsListFilters,
} from '../../stores/reservations/actions';
import ReservationsDataTable from '../../tables/ReservationsDataTable';
import ListErrors from '../../common/ListErrors';
import {
    arePropertiesAndDocksForFiltersLoading,
    areUsersForFiltersLoading,
    getDocksForFilters,
    getPropertiesAndDocksForFilters,
    getReservationsListErrors,
    getReservationsListPageContext,
    getReservationsListTablePageData,
    getUsersForFilters,
} from '../../stores/reservations/selectors';
import { isEmpty, without } from 'ramda';
import { Grid, Typography } from '@material-ui/core';
import { hasDay, hasMonth, hasYear, NONE } from '../../utils/dates';

import {
    ACTIVE_DAY_REQUIRED_ERROR_MESSAGE,
    ACTIVE_MONTH_REQUIRED_ERROR_MESSAGE,
    ACTIVE_TIME_REQUIRED_ERROR_MESSAGE,
    ACTIVE_YEAR_REQUIRED_ERROR_MESSAGE,
    DAY,
    END_DAY_REQUIRED_ERROR_MESSAGE,
    END_MONTH_REQUIRED_ERROR_MESSAGE,
    END_YEAR_REQUIRED_ERROR_MESSAGE,
    MONTH,
    START_DAY_REQUIRED_ERROR_MESSAGE,
    START_MONTH_REQUIRED_ERROR_MESSAGE,
    START_YEAR_REQUIRED_ERROR_MESSSGE,
    TIME,
    YEAR,
} from './constants';
import ReservationsTableFilters from '../ReservationsTableFilters';
import {
    ACTIVE,
    APPLY,
    CLEAR,
    END,
    START,
} from '../../properties/PropertiesListContainer/constants';
import {
    SELECT_DOCKS,
    SELECT_PROPERTY_FIRST,
    SELECT_USERS,
} from '../ReservationsTableFilters/constants';
import ReservationPropTypes from '../../propTypes/reservations';
import AddReservationButton from '../AddReservationButton';

const filterDocks = (properties = [], propertyId = null) => {
    const foundProperty = properties.find(({ value }) => value === propertyId);
    return foundProperty && foundProperty.docks ? foundProperty.docks : [];
};

class ReservationsListContainer extends Component {
    initialState = {
        showDeleteDialog: false,
        deletingReservationIds: [],
        docks: [],
    };

    state = {
        ...this.initialState,
    };

    toggleConfirmationForDelete = () => {
        const { showDeleteDialog } = this.state;
        this.setState({
            showDeleteDialog: !showDeleteDialog,
        });
    };

    static propTypes = {
        tableData: TablePropTypes.tablePageData.isRequired,
        tableContext: TablePropTypes.tablePageContext.isRequired,
        onLoad: PropTypes.func.isRequired,
        onPageRequested: PropTypes.func.isRequired,
        errors: PropTypes.arrayOf(PropTypes.string).isRequired,
        onUpdateFilters: PropTypes.func.isRequired,
        onClearFilters: PropTypes.func.isRequired,
        onFiltersValidationFailed: PropTypes.func.isRequired,
        arePropertiesAndDocksLoading: PropTypes.bool,
        areUsersLoading: PropTypes.bool,
        onClickApproveReservationRow: PropTypes.func,
        users: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                value: PropTypes.number,
            }),
        ),
        properties: ReservationPropTypes.properties,
        docks: ReservationPropTypes.docks,
    };

    componentDidMount() {
        this.props.onLoad();
    }

    onFilterCheckboxChange = (event) => {
        const { filters } = this.props.tableContext;
        const checkboxValue = event.target.value;
        const oldChecked = filters.checked;
        const checked = oldChecked.includes(checkboxValue)
            ? without(checkboxValue, oldChecked)
            : [...oldChecked, checkboxValue];
        this.props.onUpdateFilters({ ...filters, checked });
    };

    renderEmpty = () => {
        return (
            <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
                spacing={16}
            >
                <Grid item>
                    <Typography color="primary">
                        There are no reservations to show that match the
                        selected filters.
                    </Typography>
                </Grid>
                <Grid item>
                    <AdminButton
                        size="medium"
                        buttonType="positive"
                        onClick={this.props.onClearFilters}
                    >
                        Clear Filters
                    </AdminButton>
                </Grid>
            </Grid>
        );
    };

    onFilterDateChange = (event, prop, monthDayYearTime) => {
        const value =
            event.target && event.target.value ? event.target.value : event;
        const { filters } = this.props.tableContext;
        const updates = {
            [prop]: {
                ...filters[prop],
                [monthDayYearTime]: value,
                [`${monthDayYearTime}Error`]: false,
            },
        };
        if (
            monthDayYearTime === MONTH ||
            (monthDayYearTime === YEAR && value === NONE)
        ) {
            // If the month changes or the year is blank, remove the day
            updates[prop] = without(DAY, updates[prop]);
        }
        this.props.onUpdateFilters({ ...filters, ...updates });
    };

    validateFilters = () => {
        const { filters } = this.props.tableContext;
        const { start, end, active } = filters;
        const filterErrors = [];
        if (!isEmpty(start)) {
            if (!hasMonth(start) || start.month === NONE) {
                filterErrors.push(START_MONTH_REQUIRED_ERROR_MESSAGE);
                start.monthError = true;
            }
            if (!hasDay(start) || start.day === NONE) {
                filterErrors.push(START_DAY_REQUIRED_ERROR_MESSAGE);
                start.dayError = true;
            }
            if (!hasYear(start) || start.year === NONE) {
                filterErrors.push(START_YEAR_REQUIRED_ERROR_MESSSGE);
                start.yearError = true;
            }
        }
        if (!isEmpty(end)) {
            if (!hasMonth(end) || end.month === NONE) {
                filterErrors.push(END_MONTH_REQUIRED_ERROR_MESSAGE);
                end.monthError = true;
            }
            if (!hasDay(end) || end.day === NONE) {
                filterErrors.push(END_DAY_REQUIRED_ERROR_MESSAGE);
                end.dayError = true;
            }
            if (!hasYear(end) || end.year === NONE) {
                filterErrors.push(END_YEAR_REQUIRED_ERROR_MESSAGE);
                end.yearError = true;
            }
        }

        if (!isEmpty(active)) {
            if (!hasMonth(active) || active.month === NONE) {
                filterErrors.push(ACTIVE_MONTH_REQUIRED_ERROR_MESSAGE);
                active.monthError = true;
            }
            if (!hasDay(active) || active.day === NONE) {
                filterErrors.push(ACTIVE_DAY_REQUIRED_ERROR_MESSAGE);
                active.dayError = true;
            }
            if (!hasYear(active) || active.year === NONE) {
                filterErrors.push(ACTIVE_YEAR_REQUIRED_ERROR_MESSAGE);
                active.yearError = true;
            }
            if (!active.time) {
                filterErrors.push(ACTIVE_TIME_REQUIRED_ERROR_MESSAGE);
                active.timeError = true;
            }
        }
        if (filterErrors.length > 0) {
            this.props.onFiltersValidationFailed(filterErrors, {
                ...filters,
                start,
                end,
            });
        }
        return filterErrors.length === 0;
    };

    onClickFilterButtons = (buttonName) => {
        if (buttonName === 'clear') {
            this.setState({ docks: [] });
            this.props.onClearFilters();
        } else {
            if (this.validateFilters()) {
                this.props.onLoad();
            }
        }
    };

    onPropertyChange = (event) => {
        const {
            tableContext: { filters },
            onUpdateFilters,
        } = this.props;
        const selectedPropertyId = event.target.value;
        this.setState({
            docks: filterDocks(this.props.properties, selectedPropertyId),
        });
        onUpdateFilters({
            ...filters,
            selectedPropertyId,
        });
    };

    onDockChange = (event) => {
        const selectedDock = event.target.value;
        const {
            tableContext: {
                filters,
                filters: { selectedDockIds },
            },
            onUpdateFilters,
        } = this.props;
        if (selectedDock !== SELECT_DOCKS) {
            if (selectedDockIds.includes(selectedDock)) {
                onUpdateFilters({
                    ...filters,
                    selectedDockIds: selectedDockIds.filter(
                        (id) => id !== selectedDock,
                    ),
                });
            } else {
                onUpdateFilters({
                    ...filters,
                    selectedDockIds: [...selectedDockIds, selectedDock],
                });
            }
        }
    };

    onDeleteDock = (chipName) => {
        const {
            filters,
            filters: { selectedDockIds },
        } = this.props.tableContext;
        const removed = this.props.docks.find(
            ({ value }) => value === chipName,
        );
        if (removed && removed.value) {
            this.props.onUpdateFilters({
                ...filters,
                selectedDockIds: selectedDockIds.filter(
                    (id) => id !== removed.value,
                ),
            });
        }
    };

    onUserChange = (event) => {
        const selectedUser = event.target.value;
        const {
            tableContext: {
                filters,
                filters: { selectedUserIds },
            },
            onUpdateFilters,
        } = this.props;
        if (selectedUser !== SELECT_USERS) {
            if (selectedUserIds.includes(selectedUser)) {
                onUpdateFilters({
                    ...filters,
                    selectedUserIds: selectedUserIds.filter(
                        (id) => id !== selectedUser,
                    ),
                });
            } else {
                onUpdateFilters({
                    ...filters,
                    selectedUserIds: [...selectedUserIds, selectedUser],
                });
            }
        }
    };

    onDeleteUser = (chipName) => {
        const {
            filters,
            filters: { selectedUserIds },
        } = this.props.tableContext;
        const removed = this.props.users.find(
            ({ value }) => value === chipName,
        );
        if (removed && removed.value) {
            this.props.onUpdateFilters({
                ...filters,
                selectedUserIds: selectedUserIds.filter(
                    (id) => id !== removed.value,
                ),
            });
        }
    };

    renderFilters = () => {
        const {
            properties,
            users,
            arePropertiesAndDocksLoading,
            areUsersLoading,
            tableContext: { filters, filterErrors },
        } = this.props;
        const { docks } = this.state;
        return (
            <ReservationsTableFilters
                checked={filters.checked}
                onCheckboxChange={this.onFilterCheckboxChange}
                start={{
                    ...filters.start,
                    onMonthChange: (event) =>
                        this.onFilterDateChange(event, START, MONTH),
                    onDayChange: (event) =>
                        this.onFilterDateChange(event, START, DAY),
                    onYearChange: (event) =>
                        this.onFilterDateChange(event, START, YEAR),
                    onTimeChange: (event) =>
                        this.onFilterDateChange(event, START, TIME),
                }}
                end={{
                    ...filters.end,
                    onMonthChange: (event) =>
                        this.onFilterDateChange(event, END, MONTH),
                    onDayChange: (event) =>
                        this.onFilterDateChange(event, END, DAY),
                    onYearChange: (event) =>
                        this.onFilterDateChange(event, END, YEAR),
                    onTimeChange: (event) =>
                        this.onFilterDateChange(event, END, TIME),
                }}
                active={{
                    ...filters.active,
                    onMonthChange: (event) =>
                        this.onFilterDateChange(event, ACTIVE, MONTH),
                    onDayChange: (event) =>
                        this.onFilterDateChange(event, ACTIVE, DAY),
                    onYearChange: (event) =>
                        this.onFilterDateChange(event, ACTIVE, YEAR),
                    onTimeChange: (event) =>
                        this.onFilterDateChange(event, ACTIVE, TIME),
                }}
                onClickApply={() => this.onClickFilterButtons(APPLY)}
                onClickClear={() => this.onClickFilterButtons(CLEAR)}
                PropertySelectProps={{
                    loading: arePropertiesAndDocksLoading,
                    options: properties,
                    value: filters.selectedPropertyId,
                    onChange: this.onPropertyChange,
                }}
                DockSelectProps={{
                    loading: arePropertiesAndDocksLoading,
                    options:
                        docks && docks.length
                            ? [
                                  { value: SELECT_DOCKS, label: SELECT_DOCKS },
                                  ...docks,
                              ]
                            : [SELECT_PROPERTY_FIRST],
                    value:
                        docks && docks.length
                            ? SELECT_DOCKS
                            : SELECT_PROPERTY_FIRST,
                    selectedOptions: filters.selectedDockIds,
                    handleSelectedOption: this.onDockChange,
                    handleDelete: this.onDeleteDock,
                    getChipLabel: (option) => {
                        const dock = docks.find(
                            ({ value }) => value === option,
                        );
                        return dock ? dock.label : option;
                    },
                }}
                UserSelectProps={{
                    options: [
                        { label: SELECT_USERS, value: SELECT_USERS },
                        ...users,
                    ],
                    loading: areUsersLoading,
                    handleSelectedOption: this.onUserChange,
                    handleDelete: this.onDeleteUser,
                    selectedOptions: filters.selectedUserIds,
                    value: SELECT_USERS,
                    getChipLabel: (option) => {
                        const user = users.find(
                            ({ value }) => value === option,
                        );
                        return user ? user.label : option;
                    },
                }}
                errors={filterErrors}
            />
        );
    };

    render() {
        const {
            tableData,
            tableContext,
            onLoad,
            onPageRequested,
            errors,
            onClickApproveReservationRow,
            ...rest
        } = this.props;
        return (
            <AdminContainer
                innerHeader
                innerHeaderProps={{
                    title: 'Reservations',
                    rightContent: <AddReservationButton />,
                }}
                {...rest}
            >
                <ListErrors errors={errors} />
                <ReservationsDataTable
                    tableData={tableData}
                    tableContext={tableContext}
                    onFetchDataRequested={onPageRequested}
                    onActionSelect={this.onActionSelect}
                    renderFilters={this.renderFilters()}
                    renderEmpty={this.renderEmpty}
                    onClickApproveReservationRow={onClickApproveReservationRow}
                />
            </AdminContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    tableData: getReservationsListTablePageData(state),
    tableContext: getReservationsListPageContext(state),
    errors: getReservationsListErrors(state),
    arePropertiesAndDocksLoading: arePropertiesAndDocksForFiltersLoading(state),
    properties: getPropertiesAndDocksForFilters(state),
    docks: getDocksForFilters(state),
    areUsersLoading: areUsersForFiltersLoading(state),
    users: getUsersForFilters(state),
});

const mapDispatchToProps = (dispatch) => ({
    onLoad: () => {
        dispatch(reservationsListViewLoadRequested());
        dispatch(clearBookingReservationState());
    },
    onPageRequested: (context = {}) =>
        dispatch(fetchReservationsListPageRequested(context)),
    onUpdateFilters: (filters) =>
        dispatch(updateReservationsListFilters(filters)),
    onClearFilters: () => dispatch(clearReservationsListFilters()),
    onFiltersValidationFailed: (errors, filters) =>
        dispatch(filtersValidationFailed(errors, filters)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReservationsListContainer);
