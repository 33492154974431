export const COUNTRY_NAME_UNIED_STATES = 'United States';
export const COUNTRY_ABBR_UNITED_STATES = 'US';
export const COUNTRY_ABBR_CANADA = 'CA';
export const COUNTRY_NAME_CANADA = 'Canada';
export const COUNTRY_ABBR_MEXICO = 'MX';
export const COUNTRY_NAME_MEXICO = 'Mexico';

/*
 * Since we're only supporting these three countries right now, they're just a hard coded constant.
 * If/when more countries are supported they should be pulled from the API.
 */
export const COUNTRY_NAMES_AND_ABBREVIATIONS = [
    { label: COUNTRY_NAME_UNIED_STATES, value: COUNTRY_ABBR_UNITED_STATES },
    { label: COUNTRY_NAME_CANADA, value: COUNTRY_ABBR_CANADA },
    { label: COUNTRY_NAME_MEXICO, value: COUNTRY_ABBR_MEXICO },
];
