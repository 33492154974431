const styles = (theme) => ({
    inputLabel: {
        color: `${theme.palette.primary.contrastText} !important`, // Important so that the label is white also when focused.
        fontWeight: 'bold',
        paddingLeft: 12,
        marginTop: -7,
    },
    shrinkInputLabel: {
        paddingLeft: 0,
        marginTop: 0,
    },
    input: {
        width: theme.spacing.unit * 45,
        color: theme.palette.primary.contrastText,
        [theme.breakpoints.down('md')]: {
            width: theme.spacing.unit * 37.5,
        },
    },
    inputNotchedOutline: {
        borderColor: `${theme.palette.primary.contrastText} !important`,
        borderRadius: 50,
    },
    inputStyle: {
        padding: '5px 10px',
        height: 30,
        textIndent: 12,
    },
    disabled: {
        color: theme.palette.primary.contrastText,
        opacity: 0.75,
    },
    helperTextError: {
        marginTop: 4,
        fontWeight: 'bold',
    },
});

export default styles;
