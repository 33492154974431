// Maximum string length used for a user or property's city value in the user/property form used to create/update a user/property.
export const MAX_LENGTH_CITY = 64;

// Maximum string length used for a user or property's zip code value in the user/property form used to create/update a user /property.
export const MAX_LENGTH_ZIPCODE = 12;

// Maximum string length for a property's name, used in the property form for both create/update of a property.
export const MAX_LENGTH_PROPERTY_NAME = 32;

// Maximum string length for a dock's name, used in the dock form for both create/update of a dock.
export const MAX_LENGTH_DOCK_NAME = 32;

/*
 * Default maximum amount an hourly rate can be when creating/updating a dock using the dock details form.
 * Only used if Firebase remote config for `maxMonthlyRate` is not found.
 */
export const MAX_HOURLY_RATE_DEFAULT = 9999.99;

/*
 * Default maximum amount an daily rate can be when creating/updating a dock using the dock details form.
 * Only used if Firebase remote config for `maxMonthlyRate` is not found.
 */
export const MAX_DAILY_RATE = 9999.99;

/*
 * Default maximum amount an monthly rate can be when creating/updating a dock using the dock details form.
 * Only used if Firebase remote config for `maxMonthlyRate` is not found.
 */
export const MAX_MONTHLY_RATE = 9999.99;
