import {
    Grid,
    Typography,
    CircularProgress,
    withStyles,
} from '@material-ui/core';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import SearchBar from '../../common/SearchBar';
import AdminButton, {
    BUTTON_TYPE_NEGATIVE,
    BUTTON_TYPE_POSITIVE,
} from '../../common/AdminButton';
import SearchResult from '../../common/SearchResult';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { bookReservationUserSearchRequested } from '../../stores/reservations/actions';
import {
    getReservationUserSearchResults,
    isReservationUserSearchLoading,
} from '../../stores/reservations/selectors';

class ReservationUserSearch extends Component {
    state = {
        search: '',
        selectedUser: null,
    };

    onSearchChange = (event) => {
        this.setState({ search: event.target.value });
    };

    onSearchSubmit = () => {
        this.props.onSearch(this.state.search);
    };

    onUserSelect = (userId) => {
        this.setState({ selectedUser: userId });
    };

    onUserConfirm = (userId) => {
        const { users = [] } = this.props;
        const selectedUser = users.find((user) => user.id === userId);
        if (userId && selectedUser) {
            this.props.onSelect(selectedUser);
        }
    };

    render() {
        const { classes, onCancel, users, loading } = this.props;
        return (
            <Grid
                container
                direction="row"
                className={classes.container}
                spacing={24}
            >
                <Grid
                    container
                    item
                    direction="row"
                    justify="center"
                    wrap="nowrap"
                >
                    <Grid item className={classes.labelWrapper}>
                        <Typography className={classes.label}>
                            Choose User
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        direction="column"
                        className={classes.componentWrapper}
                    >
                        <SearchBar
                            loading={false}
                            onSubmit={this.onSearchSubmit}
                            onChange={this.onSearchChange}
                            value={this.state.search}
                            containerClass={classes.searchBar}
                        />
                        {loading && (
                            <CircularProgress className={classes.loading} />
                        )}
                        {users && users.length > 0 ? (
                            <Grid item container className={classes.results}>
                                {users.map((userResult) => {
                                    return (
                                        <SearchResult
                                            id={userResult.id}
                                            key={userResult.id}
                                            onSelectResult={this.onUserSelect}
                                            selected={
                                                this.state.selectedUser ===
                                                userResult.id
                                            }
                                            {...userResult}
                                        />
                                    );
                                })}
                            </Grid>
                        ) : null}
                        <Grid
                            container
                            item
                            direction="row"
                            justify="space-between"
                            wrap="nowrap"
                        >
                            <AdminButton
                                buttonType={BUTTON_TYPE_POSITIVE}
                                size="large"
                                onClick={() =>
                                    this.onUserConfirm(this.state.selectedUser)
                                }
                                disabled={!this.state.selectedUser}
                            >
                                Confirm
                            </AdminButton>
                            <AdminButton
                                buttonType={BUTTON_TYPE_NEGATIVE}
                                size="large"
                                onClick={onCancel}
                            >
                                Cancel
                            </AdminButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

ReservationUserSearch.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // Function that will be called when the user searches
    onSearch: PropTypes.func.isRequired,

    // Function that handles cancel action
    onCancel: PropTypes.func.isRequired,

    // Bool that determines this component is loading
    loading: PropTypes.bool,

    // The search results
    users: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            imageUrl: PropTypes.string,
            resultHeader: PropTypes.string.isRequired,
            labels: PropTypes.arrayOf(
                PropTypes.shape({
                    label: PropTypes.string.isRequired,
                    value: PropTypes.string.isRequired,
                }),
            ),
        }),
    ),

    // Callback function when a user is chosen
    onSelect: PropTypes.func.isRequired,
};

const mapStateToProps = function(state) {
    return {
        users: getReservationUserSearchResults(state),
        loading: isReservationUserSearchLoading(state),
    };
};

const mapDispatchToProps = function(dispatch) {
    return {
        onSearch: (searchText) =>
            dispatch(bookReservationUserSearchRequested(searchText)),
    };
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(ReservationUserSearch);
