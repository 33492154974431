import { formatDate } from '../../utils/dates';
import { transformPhotos } from '../api/transforms';
import { groupBy, isEmpty } from 'ramda';
export function convertDataForDetailView(user) {
    if (!user) {
        return {};
    }

    const {
        displayName = '',
        created,
        region = null,
        userZipcode,
        utilizationReportConfigs = [],
        deletedMetadata = {},
        ...userProps
    } = user;
    const propertiesWithUtilReportConfigs = groupBy(
        (config) => config.property.propertyName,
    )(utilizationReportConfigs.filter((config) => !!config.property));
    const { country = {} } = region || {};
    return {
        displayName,
        createdAt: created ? formatDate(created) : 'N/A',
        regionAbbr: region ? region.regionAbbr : '',
        userZipcode: userZipcode ? userZipcode.toString() : '',
        country,
        utilizationReportConfigs: utilizationReportConfigs.filter(
            (config) => !config.property,
        ),
        utilizationReportRecipients: '',
        propertiesWithUtilReportConfigs,
        ...userProps,
        ...(userProps.deletedAt && !isEmpty(deletedMetadata)
            ? deletedMetadata
            : {}),
    };
}

export function transformVehicleForDetailView(vehicle) {
    const {
        model: { make: { makeName = '' } = {} } = {},
        model: { modelName = '' } = {},
        photos = [],
        ...rest
    } = vehicle;
    return {
        modelName,
        makeName,
        photos: transformPhotos(photos),
        ...rest,
    };
}

export function transformUserVehiclesForBookingReservation(user = {}) {
    const { vehicles = [] } = user;
    return vehicles.map((vehicle) => {
        // User could have multiple vehicles with same name so ensure uniqueness by using id
        const vehicleLabel = `${vehicle.vehicleId} - ${vehicle.vehicleName}`;
        return { label: vehicleLabel, value: vehicle.vehicleId };
    });
}
