export default (theme) => ({
    container: {
        width: 'auto',
        height: '100%',
        paddingTop: 250,
        [theme.breakpoints.down('md')]: {
            paddingTop: 150,
        },
    },
    error: {
        fontWeight: 'bold',
        fontSize: 20,
    },
    loading: {
        color: theme.palette.primary.contrastText,
    },
});
