import { call, put } from 'redux-saga/effects';
import {
    coercePageContext,
    requestWithAuth,
    uploadWithAuth,
    createAPIErrorResponse,
    getErrorsFromPossibleAPIErrorResponse,
    logAPIErrorResponse,
} from '../../utils/api';
import firebase from '../../firebase/config';
import { DEFAULT_PER_PAGE } from '../../constants/api';

const DEFAULT_ERROR_MESSAGE =
    'Something is not right... Please try again later';

/**
 * Make an authenticated API call grabbing a firebase ID token from the currently logged in user
 * and forming a request with it.
 *
 * Will return the response or if `json` is true (default) the JSON parsed response body on successful request and if
 * `response.ok`. On failure or `!response.ok` the response itself is thrown.
 *
 * @param {string} path The relative path on the API server to make a request to.
 * @param {string=} method The HTTP method of the request (default: `'GET'`)
 * @param {boolean=} json Whether or not the response should be attempted to parse to JSON (default: `true`)
 * @param {({}|null)=} query Query parameters to generate a query string with.
 * @param {*|null} body The JSON-serializable data to use in the body of the request.
 * @return {*}
 */
export function* fetchWithAuth(
    path,
    { method = 'GET', json = true, query = null, body = null } = {},
) {
    const auth = firebase.auth();
    const token = yield call([auth.currentUser, auth.currentUser.getIdToken]);
    const response = yield call(
        requestWithAuth,
        path,
        method,
        token,
        query,
        body,
    );
    if (response.ok) {
        return json ? yield call([response, response.json]) : response;
    } else {
        const errorResponse = yield call(createAPIErrorResponse, response);
        yield call(logAPIErrorResponse, errorResponse);
        throw errorResponse;
    }
}

/**
 * Default behaviour for apply paginated context `filters` to a query string object. Simply spreads
 * filters on top of the current query.
 *
 * @param {{}} query
 * @param {{}} filters
 */
const defaultPaginatedRequestApplyFilters = (query, filters) => {
    return {
        ...(query || {}),
        ...filters,
    };
};

/**
 * Make an authenticated GET request to a paginated endpoint.
 *
 * @see fetchWithAuth
 *
 * @param path
 * @param context
 * @param query
 * @param json
 * @param applyFilters
 * @param rest
 * @return {*}
 */
export function* fetchPaginatedWithAuth(
    path,
    {
        context = {},
        query = null,
        json = true,
        applyFilters = defaultPaginatedRequestApplyFilters,
        ...rest
    },
) {
    // Extract the relevant data from the page context.
    const {
        pagination: { page = 1, perPage = DEFAULT_PER_PAGE },
        filters = {},
        order = [],
    } = coercePageContext(context);
    // Generate a query containing pagination and order parameters
    const paginationQuery = {
        page,
        perPage,
        order,
        ...(query || {}),
    };
    // Apply the filters to the pagination query to get the final query
    const finalQuery = applyFilters(paginationQuery, filters);
    // Delegate the remaining work to `fetchWithAuth`
    return yield call(fetchWithAuth, path, {
        method: 'GET',
        query: finalQuery,
        json,
        ...rest,
    });
}

/**
 * Make a request, putting success/failure actions as appropriate.
 *
 * @param path
 * @param failed
 * @param succeeded
 * @param fetcher
 * @param rest
 * @return {*}
 */
export function* makeRequestWithAuth({
    path,
    actions: { failed, succeeded },
    fetcher = fetchWithAuth,
    ...rest
}) {
    try {
        const responseData = yield call(fetcher, path, rest);
        yield put(succeeded(responseData));
    } catch (errorResponse) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            errorResponse,
            DEFAULT_ERROR_MESSAGE,
        );
        yield put(failed(errors));
    }
}

/**
 * Create a saga that can handle a request action
 *
 * @see createApiPageRequestActionCreators which creates a `requested` action creator that
 *      creates actions the returned saga can handle
 *
 * @param succeeded
 * @param failed
 * @param {...} fetcherOptions
 * @return {Function}
 */
export const createPaginatedRequestSaga = ({
    actions: { succeeded, failed },
    ...fetcherOptions
}) => {
    return function*({ context }) {
        yield call(makeRequestWithAuth, {
            fetcher: fetchPaginatedWithAuth,
            context,
            actions: {
                succeeded: (data) => succeeded(data, context),
                failed,
            },
            ...fetcherOptions,
        });
    };
};

/**
 * Create a saga that can make an upload request.
 *
 * @param path
 * @param method
 * @param json
 * @param body
 * @returns {IterableIterator<*>}
 */
export function* doUploadWithAuth(
    path,
    { method = 'PUT', json = true, body = null } = {},
) {
    const auth = firebase.auth();
    const token = yield call([auth.currentUser, auth.currentUser.getIdToken]);
    const response = yield call(uploadWithAuth, path, method, token, body);
    if (response.ok) {
        return json ? yield call([response, response.json]) : response;
    } else {
        const errorResponse = yield call(createAPIErrorResponse, response);
        yield call(logAPIErrorResponse, errorResponse);
        throw errorResponse;
    }
}
