import { Grid, Typography, Checkbox, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { Component } from 'react';
import SearchResultHeader from '../SearchResultHeader';
import styles from './styles';

class SearchResult extends Component {
    render() {
        const {
            imageUrl,
            resultHeader,
            labels = [],
            classes,
            selected = false,
            onSelectResult,
            id,
        } = this.props;
        return (
            <Grid
                container
                className={clsx(
                    classes.container,
                    selected && classes.selected,
                )}
                flexDirection="row"
            >
                <Checkbox
                    classes={{ root: classes.checkbox }}
                    checked={selected}
                    onClick={() => onSelectResult(id)}
                />
                <Grid container item direction="column">
                    <SearchResultHeader
                        imageUrl={imageUrl}
                        resultHeader={resultHeader}
                        containerClass={classes.header}
                        avatarClass={classes.avatar}
                    />
                    <Grid
                        container
                        item
                        direction="column"
                        className={classes.resultInfo}
                    >
                        {labels &&
                            labels.length > 0 &&
                            labels.map(({ label, value: values = [] }) => {
                                return (
                                    <Grid
                                        container
                                        key={label}
                                        className={classes.labelContainer}
                                    >
                                        <Grid item>
                                            <Typography
                                                className={classes.label}
                                            >
                                                {label}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            direction={'column'}
                                        >
                                            {values.map((value) => {
                                                return (
                                                    <Typography
                                                        key={value}
                                                        className={
                                                            classes.value
                                                        }
                                                    >
                                                        {value}
                                                    </Typography>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>
                                );
                            })}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(SearchResult);
