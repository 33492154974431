import React, { Component, Fragment } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import {
    CardElement,
    Elements,
    ElementsConsumer,
} from '@stripe/react-stripe-js';
import {
    Grid,
    Typography,
    CircularProgress,
    withStyles,
} from '@material-ui/core';
import AdminButton, { BUTTON_TYPE_POSITIVE } from '../AdminButton';
import StripeCardField from './StripeCardField/index';
import styles from './styles';
import FormMessages, {
    TYPE_ERROR,
    TYPE_INFO,
    TYPE_SUCCESS,
} from '../FormMessages';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN);
export const ADDRESS_FIELD = 'address';
export const ADDRESS_FIELD_2 = 'address2';
export const NAME_FIELD = 'name';

class StripeCardForm extends Component {
    state = {
        name: '',
        address: '',
        address2: '',
        cardDataComplete: false,
        loadingStripeToken: false,
        stripeApiError: {},
    };

    handleFieldChange = (event, field) => {
        const val = event?.target?.value;
        if (val) {
            this.setState({ [field]: val });
        }
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        const {
            stripe,
            elements,
            addPaymentMethod,
            history,
            userId,
        } = this.props;
        const { name, address, address2 } = this.state;
        this.setState({ loadingStripeToken: true, stripeApiError: {} });

        if (elements == null) {
            return;
        }
        if (name && address && addPaymentMethod) {
            const options = {
                name,
                address_line1: address,
                address_line2: address2 || '',
            };
            const cardElement = elements.getElement(CardElement);
            const { error, token } = await stripe.createToken(
                cardElement,
                options,
            );
            if (!error && token?.id) {
                addPaymentMethod(token.id, history, userId);
            } else if (error?.message) {
                this.setState({
                    loadingStripeToken: false,
                    stripeApiError: {
                        key: 'stripe' + new Date().toString(),
                        message: error.message,
                        type: TYPE_ERROR,
                    },
                });
            }
        }
    };

    cardElementOnChange(event) {
        // Stripe element is complete
        if (event.complete) {
            this.setState({ cardDataComplete: true });
        }
    }

    render() {
        const {
            stripe,
            classes,
            addingPaymentMethodInProgress,
            rigparkApiError,
        } = this.props;
        const {
            loadingStripeToken,
            name,
            address,
            cardDataComplete,
            stripeApiError,
        } = this.state;
        const errors = [];
        if (stripeApiError?.key) {
            errors.push(stripeApiError);
        }
        if (rigparkApiError?.key) {
            errors.push(rigparkApiError);
        }
        const cardElementOptions = {
            classes: {
                base: classes.cardInput,
            },
            hidePostalCode: true,
        };
        return (
            <Fragment>
                <Grid container className={classes.errorContainer}>
                    <FormMessages messages={errors} />
                </Grid>
                <Grid
                    container
                    direction="column"
                    className={classes.cardContainer}
                >
                    <StripeCardField
                        handleFieldChange={this.handleFieldChange}
                        fieldName={NAME_FIELD}
                        fieldLabel={'Name'}
                    />
                    <StripeCardField
                        handleFieldChange={this.handleFieldChange}
                        fieldName={ADDRESS_FIELD}
                        fieldLabel={'Address'}
                    />
                    <StripeCardField
                        handleFieldChange={this.handleFieldChange}
                        fieldName={ADDRESS_FIELD_2}
                        fieldLabel={'Address 2'}
                    />
                    <Grid item container className={classes.labelWrapper}>
                        <Typography className={classes.label}>Card</Typography>
                        <Grid item className={classes.field}>
                            <CardElement
                                options={cardElementOptions}
                                onChange={(e) => this.cardElementOnChange(e)}
                            />
                        </Grid>
                    </Grid>
                    <AdminButton
                        buttonType={BUTTON_TYPE_POSITIVE}
                        disabled={
                            !stripe ||
                            loadingStripeToken ||
                            addingPaymentMethodInProgress ||
                            !name ||
                            !address ||
                            !cardDataComplete
                        }
                        onClick={this.handleSubmit}
                    >
                        {loadingStripeToken || addingPaymentMethodInProgress ? (
                            <CircularProgress />
                        ) : (
                            'Submit Payment Method'
                        )}
                    </AdminButton>
                </Grid>
            </Fragment>
        );
    }
}

const InjectedStripeContainer = ({ classes, ...props }) => (
    <ElementsConsumer>
        {({ stripe, elements }) => (
            <StripeCardForm
                stripe={stripe}
                elements={elements}
                classes={classes}
                {...props}
            />
        )}
    </ElementsConsumer>
);

const StripeContainer = ({ classes, ...props }) => (
    <Elements stripe={stripePromise}>
        <InjectedStripeContainer classes={classes} {...props} />
    </Elements>
);

StripeContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    userId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    addPaymentMethod: PropTypes.func.isRequired,
    addingPaymentMethodInProgress: PropTypes.bool,
    rigparkApiError: PropTypes.shape({
        key: PropTypes.string,
        message: PropTypes.string.isRequired,
        type: PropTypes.oneOf([TYPE_ERROR, TYPE_INFO, TYPE_SUCCESS]).isRequired,
    }),
};

StripeContainer.defaultProps = {
    addingPaymentMethodInProgress: false,
    rigparkApiError: {},
};

export default withStyles(styles)(StripeContainer);
