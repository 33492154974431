import { all, call, put, takeLatest } from 'redux-saga/effects';
import { fetchWithAuth } from '../api/sagas';
import {
    addStripePaymentMethodFailed,
    ADD_STRIPE_PAYMENT_METHOD_REQUESTED,
} from './actions';

export function* addPaymentMethod({ token, userId, history }) {
    try {
        // We got a stripe token, so now we can add the card to the user's stripe customer
        yield call(fetchWithAuth, `cards/${userId}`, {
            method: 'POST',
            body: { token: token },
        });
        history.push(`/users/${userId}`);
    } catch (error) {
        yield put(
            addStripePaymentMethodFailed(
                'Could not add payment method. Please try again later.',
            ),
        );
    }
}

export default function*() {
    yield all([
        takeLatest(ADD_STRIPE_PAYMENT_METHOD_REQUESTED, addPaymentMethod),
    ]);
}
