const styles = (theme) => ({
    root: {
        marginTop: 40,
        marginBottom: 20,
        padding: 16,
    },
    text: {
        fontSize: 24,
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        marginLeft: 25,
    },
    subtext: {
        fontSize: 14,
        color: theme.palette.primary.main,
        marginLeft: 10,
        marginRight: 25,
    },
    buttonContainer: {
        height: 255,
        marginTop: -225,
    },
    button: {
        width: 365,
        backgroundColor: theme.custom.darkBlue,
    },
    secondLastChild: {
        opacity: 0.6,
    },
    lastChild: {
        opacity: 0.25,
    },
});

export default styles;
