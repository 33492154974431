import { TYPES_PREFIX } from './constants';

export const DASHBOARD_LOAD_REQUESTED = `${TYPES_PREFIX}DASHBOARD_LOAD_REQUESTED`;

export const FETCH_USERS_REQUESTED = `${TYPES_PREFIX}FETCH_USERS_REQUESTED`;
export const FETCH_USERS_SUCCEEDED = `${TYPES_PREFIX}FETCH_USERS_SUCCEEDED`;
export const FETCH_USERS_FAILED = `${TYPES_PREFIX}FETCH_USERS_FAILED`;

export const FETCH_NEW_USERS_COUNT_REQUESTED = `${TYPES_PREFIX}FETCH_NEW_USERS_COUNT_REQUESTED`;
export const FETCH_NEW_USERS_COUNT_SUCCEEDED = `${TYPES_PREFIX}FETCH_NEW_USERS_COUNT_SUCCEEDED`;
export const FETCH_NEW_USERS_COUNT_FAILED = `${TYPES_PREFIX}FETCH_NEW_USERS_COUNT_FAILED`;

export const FETCH_PROPERTIES_REQUESTED = `${TYPES_PREFIX}FETCH_PROPERTIES_REQUESTED`;
export const FETCH_PROPERTIES_SUCCEEDED = `${TYPES_PREFIX}FETCH_PROPERTIES_SUCCEEDED`;
export const FETCH_PROPERTIES_FAILED = `${TYPES_PREFIX}FETCH_PROPERTIES_FAILED`;

export const FETCH_NEW_PROPERTIES_COUNT_REQUESTED = `${TYPES_PREFIX}FETCH_NEW_PROPERTIES_COUNT_REQUESTED`;
export const FETCH_NEW_PROPERTIES_COUNT_SUCCEEDED = `${TYPES_PREFIX}FETCH_NEW_PROPERTIES_COUNT_SUCCEEDED`;
export const FETCH_NEW_PROPERTIES_COUNT_FAILED = `${TYPES_PREFIX}FETCH_NEW_PROPERTIES_COUNT_FAILED`;

export const FETCH_RESERVATIONS_REQUESTED = `${TYPES_PREFIX}FETCH_RESERVATIONS_REQUESTED`;
export const FETCH_RESERVATIONS_SUCCEEDED = `${TYPES_PREFIX}FETCH_RESERVATIONS_SUCCEEDED`;
export const FETCH_RESERVATIONS_FAILED = `${TYPES_PREFIX}FETCH_RESERVATIONS_FAILED`;

export const FETCH_NEW_RESERVATIONS_COUNT_REQUESTED = `${TYPES_PREFIX}FETCH_NEW_RESERVATIONS_COUNT_REQUESTED`;
export const FETCH_NEW_RESERVATIONS_COUNT_SUCCEEDED = `${TYPES_PREFIX}FETCH_NEW_RESERVATIONS_COUNT_SUCCEEDED`;
export const FETCH_NEW_RESERVATIONS_COUNT_FAILED = `${TYPES_PREFIX}FETCH_NEW_RESERVATIONS_COUNT_FAILED`;

export function dashboardLoadRequested() {
    return {
        type: DASHBOARD_LOAD_REQUESTED,
    };
}

export function fetchUsersRequested() {
    return {
        type: FETCH_USERS_REQUESTED,
    };
}

export function fetchUsersSucceeded(users, total) {
    return {
        type: FETCH_USERS_SUCCEEDED,
        users,
        total,
    };
}

export function fetchUsersFailed() {
    return {
        type: FETCH_USERS_FAILED,
    };
}

export function fetchNewUsersCountRequested() {
    return {
        type: FETCH_NEW_USERS_COUNT_REQUESTED,
    };
}

export function fetchNewUsersCountSucceeded(count) {
    return {
        type: FETCH_NEW_USERS_COUNT_SUCCEEDED,
        count,
    };
}

export function fetchNewUsersCountFailed() {
    return {
        type: FETCH_NEW_USERS_COUNT_FAILED,
    };
}

export function fetchPropertiesRequested() {
    return {
        type: FETCH_PROPERTIES_REQUESTED,
    };
}

export function fetchPropertiesSucceeded(properties, total) {
    return {
        type: FETCH_PROPERTIES_SUCCEEDED,
        properties,
        total,
    };
}

export function fetchPropertiesFailed() {
    return {
        type: FETCH_PROPERTIES_FAILED,
    };
}

export function fetchNewPropertiesCountRequested() {
    return {
        type: FETCH_NEW_PROPERTIES_COUNT_REQUESTED,
    };
}

export function fetchNewPropertiesCountSucceeded(count) {
    return {
        type: FETCH_NEW_PROPERTIES_COUNT_SUCCEEDED,
        count,
    };
}

export function fetchNewPropertiesCountFailed() {
    return {
        type: FETCH_NEW_PROPERTIES_COUNT_FAILED,
    };
}

export function fetchReservationsRequested() {
    return {
        type: FETCH_RESERVATIONS_REQUESTED,
    };
}

export function fetchReservationsSucceeded(reservations, total) {
    return {
        type: FETCH_RESERVATIONS_SUCCEEDED,
        reservations,
        total,
    };
}

export function fetchReservationsFailed() {
    return {
        type: FETCH_RESERVATIONS_FAILED,
    };
}

export function fetchNewReservationsCountRequested() {
    return {
        type: FETCH_NEW_RESERVATIONS_COUNT_REQUESTED,
    };
}

export function fetchNewReservationsCountSucceeded(count) {
    return {
        type: FETCH_NEW_RESERVATIONS_COUNT_SUCCEEDED,
        count,
    };
}

export function fetchNewReservationsCountFailed() {
    return {
        type: FETCH_NEW_RESERVATIONS_COUNT_FAILED,
    };
}
