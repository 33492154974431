import { createSelector } from 'reselect';
import { TYPE_ERROR } from '../../common/FormMessages';

export const getStripeState = (state) => state.stripe;

export const getIsAddingPaymentMethod = createSelector(
    getStripeState,
    (stripe) => stripe.isAddingPaymentMethod,
);

export const getAddPaymentMethodError = createSelector(
    getStripeState,
    (stripe) => {
        if (stripe.error) {
            return {
                key: 'addCardError' + new Date().toString(),
                message: stripe.error,
                type: TYPE_ERROR,
            };
        }
        return {};
    },
);
