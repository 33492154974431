import PropTypes from 'prop-types';

export const docks = PropTypes.arrayOf(
    PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
    }),
);

export const properties = PropTypes.arrayOf(
    PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
        docks: docks,
    }),
);

export const ReservationPropTypes = {
    docks,
    properties,
};

export default ReservationPropTypes;
