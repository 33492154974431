import { Interval } from 'luxon';
import {
    RATE_TYPE_DAILY,
    RATE_TYPE_HOURLY,
    RATE_TYPE_MONTHLY,
} from '../docks/DockForm/constants';
import { getDateTime } from './dates';

const RATE_TYPE_NEEDED_LENGTHS = {
    [RATE_TYPE_HOURLY]: 'hours',
    [RATE_TYPE_DAILY]: 'days',
    [RATE_TYPE_MONTHLY]: 'months',
};

/**
 * This function is used to calculate the price of the desired reservation based on the provided params.
 * The function mimics the functionality that is present in the mobile app.
 *
 * Note: Ideally, the calculation of the price is done in one place (API). Given time constraints and
 * customer wishes, we decided to replicate the calculation piece from the mobile app and use it
 * here in the Admin Panel.
 * @param {number} rate - The rate for the dock (how much money per hr/day/month)
 * @param {object} start - Desired start of the reservation
 * @param {object} end - Desired end of the reservation
 * @param {string} rateType - The rate type (hourly, daily, monthly)
 * @returns
 */
export const getReservationTotal = (
    rate,
    start,
    end,
    rateType = RATE_TYPE_HOURLY,
) => {
    if (!start || !end || !start.time || !end.time) {
        return '';
    }
    const {
        day: startDay,
        month: startMonth,
        year: startYear,
        time: {
            c: { hour: startHour, minute: startMinute },
        },
    } = start;
    const {
        day: endDay,
        month: endMonth,
        year: endYear,
        time: {
            c: { hour: endHour, minute: endMinute },
        },
    } = end;
    const startDateTime = getDateTime(
        startMinute,
        startHour,
        startDay,
        startMonth,
        startYear,
    );
    const endDateTime = getDateTime(
        endMinute,
        endHour,
        endDay,
        endMonth,
        endYear,
    );
    let length = Interval.fromDateTimes(startDateTime, endDateTime).length(
        RATE_TYPE_NEEDED_LENGTHS[rateType],
    );
    // Round the calculated length to avoid 'partial' months and days
    const roundLength =
        rateType === RATE_TYPE_DAILY || rateType === RATE_TYPE_MONTHLY;
    length = roundLength ? Math.round(length) : length;
    const rateIsNumber = rate !== null && !isNaN(rate);
    const lengthIsNumber = length !== null && !isNaN(length);
    const num = (parseFloat(rate) * parseFloat(length)).toFixed(2);
    return rateIsNumber && lengthIsNumber ? num : '';
};
