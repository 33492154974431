import { call, put, takeEvery } from 'redux-saga/effects';
import {
    FETCH_RULES_REQUESTED,
    fetchRulesSucceeded,
    fetchRulesFailed,
} from './apiActions';
import { fetchWithAuth } from '../api/sagas';

export function* fetchRules() {
    try {
        const data = yield call(fetchWithAuth, '/rule');
        yield put(fetchRulesSucceeded(data));
    } catch (error) {
        yield put(fetchRulesFailed('Fetching available rules failed.'));
    }
}

export default function*() {
    yield takeEvery(FETCH_RULES_REQUESTED, fetchRules);
}
