import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import MonthPicker from '../MonthPicker';
import DayOfMonthPicker from '../DayOfMonthPicker';
import YearPicker from '../YearPicker';
import DatePropTypes from '../../propTypes/dates';
import { getDateTime, NONE } from '../../utils/dates';
import { noop } from '../../utils';
import TimePicker from '../TimePicker';
import styles from './styles';

class FilterDatePicker extends Component {
    componentDidUpdate({
        month: prevMonth,
        day: prevDay,
        year: prevYear,
        time: prevTime,
    }) {
        const { month, day, year, time, fieldValueCallback } = this.props;
        const requiredPropsPresent =
            month !== NONE &&
            day !== NONE &&
            year !== NONE &&
            time &&
            fieldValueCallback &&
            fieldValueCallback !== noop;
        const propsChanged =
            month !== prevMonth ||
            day !== prevDay ||
            year !== prevYear ||
            time !== prevTime;
        if (requiredPropsPresent && propsChanged) {
            const { hour = 0, minute = 0 } = time.c;
            const value = getDateTime(minute, hour, day, month, year);
            fieldValueCallback(value);
        }
    }
    render() {
        const {
            month,
            day,
            year,
            time,
            onMonthChange,
            onDayChange,
            onYearChange,
            onTimeChange,
            monthError,
            dayError,
            yearError,
            dateAndTime,
            containerClass,
            classes,
            error,
            monthEnabled,
        } = this.props;
        return (
            <Grid
                item
                container
                direction="column"
                spacing={8}
                justify="center"
                alignItems="center"
                className={containerClass}
            >
                {dateAndTime && (
                    <Grid item>
                        <TimePicker
                            small
                            value={time}
                            onChange={onTimeChange}
                        />
                    </Grid>
                )}
                <Grid item>
                    <MonthPicker
                        value={month}
                        onChange={onMonthChange}
                        error={monthError}
                    />
                </Grid>
                {monthEnabled && (
                    <Grid item>
                        <DayOfMonthPicker
                            value={day}
                            onChange={onDayChange}
                            month={month}
                            year={year}
                            error={dayError}
                        />
                    </Grid>
                )}
                <Grid item>
                    <YearPicker
                        value={year}
                        onChange={onYearChange}
                        error={yearError}
                    />
                </Grid>
                {error && (
                    <Typography className={classes.errorText}>
                        {error}
                    </Typography>
                )}
            </Grid>
        );
    }
}

FilterDatePicker.propTypes = {
    month: DatePropTypes.month,
    onMonthChange: PropTypes.func,
    monthError: PropTypes.bool,
    day: DatePropTypes.day,
    time: DatePropTypes.time,
    onDayChange: PropTypes.func,
    dayError: PropTypes.bool,
    year: DatePropTypes.year,
    onYearChange: PropTypes.func,
    yearError: PropTypes.bool,
    onTimeChange: PropTypes.func,
    dateAndTime: PropTypes.bool,
    containerClass: PropTypes.string,
    fieldValueCallback: PropTypes.func,
    classes: PropTypes.object.isRequired,
    error: PropTypes.string,
    monthEnabled: PropTypes.bool,
};

FilterDatePicker.defaultProps = {
    month: NONE,
    day: NONE,
    year: NONE,
    time: null,
    onMonthChange: noop,
    onDayChange: noop,
    onYearChange: noop,
    onTimeChange: noop,
    monthError: false,
    dayError: false,
    yearError: false,
    dateAndTime: false,
    containerClass: '',
    fieldValueCallback: noop,
    error: '',
    monthEnabled: true,
};

export default withStyles(styles)(FilterDatePicker);
