const styles = (theme) => ({
    checkboxGridItem: {
        padding: '10px 0',
    },
    label: {
        color: theme.palette.primary.contrastText,
        fontSize: 14,
    },
    checkbox: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.contrastText,
        borderRadius: 3,
        height: 10,
        width: 10,
    },
});

export default styles;
