import React from 'react';
import PropTypes from 'prop-types';

import { Button, Typography, withStyles } from '@material-ui/core';

import { noop } from '../../../utils';
import styles from './styles';

const LogoutButton = ({ classes, onLogoutRequested }) => {
    return (
        <Button
            color="secondary"
            className={classes.logoutButton}
            onClick={onLogoutRequested}
        >
            <Typography color="secondary" className={classes.logoutButtonText}>
                Logout
            </Typography>
        </Button>
    );
};

LogoutButton.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // Function to be called when the logout button is clicked
    onLogoutRequested: PropTypes.func,
};

LogoutButton.defaultProps = {
    onLogoutRequested: noop,
};

export default withStyles(styles)(LogoutButton);
