import PropTypes from 'prop-types';

export const RESERVATION_PROP_ID = 'reservationId';
export const RESERVATION_PROP_DOCK_NAME = 'dockName';
export const RESERVATION_PROP_VEHICLE_COLOR = 'vehicleColor';
export const RESERVATION_PROP_VEHICLE_MAKE = 'vehicleMake';
export const RESERVATION_PROP_VEHICLE_PLATE = 'vehiclePlate';
export const RESERVATION_PROP_RESERVATION_START = 'reservationStart';
export const RESERVATION_PROP_RESERVATION_END = 'reservationEnd';
export const RESERVATION_PROP_RESERVATION_COST = 'reservationCost';
export const RESERVATION_PROP_LABELS = {
    [RESERVATION_PROP_DOCK_NAME]: 'Dock',
    [RESERVATION_PROP_VEHICLE_COLOR]: 'Truck Color',
    [RESERVATION_PROP_VEHICLE_MAKE]: 'Truck Make',
    [RESERVATION_PROP_VEHICLE_PLATE]: 'License Plate',
    [RESERVATION_PROP_RESERVATION_START]: 'Start',
    [RESERVATION_PROP_RESERVATION_END]: 'End',
    [RESERVATION_PROP_RESERVATION_COST]: 'Cost',
};

export const RESERVATION_PROP_TYPES = {
    [RESERVATION_PROP_ID]: PropTypes.number,
    [RESERVATION_PROP_DOCK_NAME]: PropTypes.string,
    [RESERVATION_PROP_VEHICLE_COLOR]: PropTypes.string,
    [RESERVATION_PROP_VEHICLE_MAKE]: PropTypes.string,
    [RESERVATION_PROP_VEHICLE_PLATE]: PropTypes.string,
    [RESERVATION_PROP_RESERVATION_START]: PropTypes.string,
    [RESERVATION_PROP_RESERVATION_END]: PropTypes.string,
    [RESERVATION_PROP_RESERVATION_COST]: PropTypes.string,
};
