import {
    COUNTRY_ABBR_CANADA,
    COUNTRY_ABBR_MEXICO,
    COUNTRY_ABBR_UNITED_STATES,
} from './country';

// Timezones by country
export const TIMEZONES = {
    [COUNTRY_ABBR_CANADA]: [
        'America/Atikokan',
        'America/Blanc-Sablon',
        'America/Cambridge_Bay',
        'America/Creston',
        'America/Dawson',
        'America/Dawson_Creek',
        'America/Edmonton',
        'America/Fort_Nelson',
        'America/Glace_Bay',
        'America/Goose_Bay',
        'America/Halifax',
        'America/Inuvik',
        'America/Iqaluit',
        'America/Moncton',
        'America/Nipigon',
        'America/Pangnirtung',
        'America/Rainy_River',
        'America/Rankin_Inlet',
        'America/Regina',
        'America/Resolute',
        'America/St_Johns',
        'America/Swift_Current',
        'America/Thunder_Bay',
        'America/Toronto',
        'America/Vancouver',
        'America/Whitehorse',
        'America/Winnipeg',
        'America/Yellowknife',
    ],

    [COUNTRY_ABBR_MEXICO]: [
        'America/Bahia_Banderas',
        'America/Cancun',
        'America/Chihuahua',
        'America/Hermosillo',
        'America/Matamoros',
        'America/Mazatlan',
        'America/Merida',
        'America/Mexico_City',
        'America/Monterrey',
        'America/Ojinaga',
        'America/Santa_Isabel',
        'America/Tijuana',
    ],

    [COUNTRY_ABBR_UNITED_STATES]: [
        'America/Adak',
        'America/Anchorage',
        'America/Boise',
        'America/Chicago',
        'America/Denver',
        'America/Detroit',
        'America/Indiana/Indianapolis',
        'America/Indiana/Knox',
        'America/Indiana/Marengo',
        'America/Indiana/Petersburg',
        'America/Indiana/Tell_City',
        'America/Indiana/Vevay',
        'America/Indiana/Vincennes',
        'America/Indiana/Winamac',
        'America/Juneau',
        'America/Kentucky/Louisville',
        'America/Kentucky/Monticello',
        'America/Los_Angeles',
        'America/Menominee',
        'America/Metlakatla',
        'America/New_York',
        'America/Nome',
        'America/North_Dakota/Beulah',
        'America/North_Dakota/Center',
        'America/North_Dakota/New_Salem',
        'America/Phoenix',
        'America/Sitka',
        'America/Yakutat',
        'Pacific/Honolulu',
    ],
};

// All of the timezones combined
export const ALL_TIMEZONES = [
    ...TIMEZONES[COUNTRY_ABBR_UNITED_STATES],
    ...TIMEZONES[COUNTRY_ABBR_CANADA],
    ...TIMEZONES[COUNTRY_ABBR_MEXICO],
];
