import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import launchIcon from './external.svg';
import launchIconWhite from './external-white.svg';

export const COLOR_WHITE = 'white';

const styles = () => ({
    root: {
        height: 10,
    },
});

const ExternalIcon = ({ classes, color }) => {
    const src = color === COLOR_WHITE ? launchIconWhite : launchIcon;
    return <img src={src} alt="Launch" className={classes.root} />;
};

ExternalIcon.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,
    color: PropTypes.string,
};

ExternalIcon.defaultProps = {
    color: null,
};

export default withStyles(styles, { name: 'RigParkExternalIcon' })(
    ExternalIcon,
);
