import {
    COUNTRY_ABBR_UNITED_STATES,
    COUNTRY_NAME_UNIED_STATES,
} from '../../constants/country';

export const DEFAULT_USER_ACTIVE = true;
export const DEFAULT_USER_COUNTRY = {
    countryName: COUNTRY_NAME_UNIED_STATES,
    countryAbbr: COUNTRY_ABBR_UNITED_STATES,
};
