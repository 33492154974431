import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import LoginPage from '../../login/LoginPage';
import Dashboard from '../../dashboard/Dashboard';
import UserDetailContainer from '../../users/UserDetailContainer';
import {
    isUserAdmin,
    isUserLoggedIn,
    isUserOwner,
} from '../../stores/auth/selectors';
import { clearError, loginRequested, logout } from '../../stores/auth/actions';
import LoginFormPropTypes from '../../propTypes/loginForm';
import UsersListContainer from '../../users/UsersListContainer';
import PropertiesListContainer from '../../properties/PropertiesListContainer';
import DockDetailsContainer from '../../docks/DockDetailsContainer';
import PropertyDetailsContainer from '../../properties/PropertyDetailsContainer';
import VehicleDetailsContainer from '../../vehicles/VehicleDetailsContainer';
import ReservationsListContainer from '../../reservations/ReservationsListContainer';
import ReservationDetailsContainer from '../../reservations/ReservationDetailsContainer';
import PropertyReportContainer from '../../properties/PropertyReportContainer';
import CardDetailsContainer from '../../cards/CardDetailsContainer';

class AdminRouter extends Component {
    render() {
        const {
            loggedIn,
            isAdmin,
            isOwner,
            history,
            errors,
            onClearErrorRequested,
            login,
            logout,
            loginInProgress,
        } = this.props;

        const LoginFormProps = {
            onSubmit: login,
            errors: errors,
            onClearErrorRequested,
            loginInProgress,
        };

        const routeSwitch =
            loggedIn && isAdmin ? (
                <Switch>
                    <Route
                        path="/dashboard"
                        render={({ match: { path } }) => (
                            <Dashboard path={path} />
                        )}
                    />
                    <Route
                        path="/users/:userId/cards/new"
                        render={({ match }) => (
                            <CardDetailsContainer
                                path="/users/:userId/cards/new"
                                history={history}
                                {...match.params}
                            />
                        )}
                    />
                    <Route
                        path="/users/:userId/vehicles/:vehicleId"
                        render={({ match }) => (
                            <VehicleDetailsContainer
                                path="/users/:userId/vehicles/:vehicleId"
                                {...match.params}
                            />
                        )}
                    />
                    <Route
                        path="/users/:userId"
                        render={({ match }) => (
                            <UserDetailContainer
                                path="/users/:userId"
                                onLogoutRequested={logout}
                                {...match.params}
                            />
                        )}
                    />
                    <Route
                        path="/users"
                        render={() => (
                            <UsersListContainer
                                path="/users"
                                onLogoutRequested={logout}
                            />
                        )}
                    />
                    <Route
                        path={[
                            '/properties/:propertyId/dock/:dockId',
                            '/properties/:propertyId/dock/',
                        ]}
                        render={({ match: { path, params } }) => (
                            <DockDetailsContainer
                                path={path}
                                onLogoutRequested={logout}
                                {...params}
                            />
                        )}
                    />
                    <Route
                        path={[
                            '/properties/new/:userId',
                            '/properties/:propertyId',
                        ]}
                        render={({ match: { path, params } }) => (
                            <PropertyDetailsContainer
                                path={path}
                                onLogoutRequested={logout}
                                {...params}
                            />
                        )}
                    />
                    <Route
                        path="/properties"
                        render={() => (
                            <PropertiesListContainer
                                path="/properties"
                                onLogoutRequested={logout}
                            />
                        )}
                    />
                    <Route
                        path="/reservations/:reservationId"
                        render={() => (
                            <ReservationDetailsContainer
                                path="/reservations/:reservationId"
                                onLogoutRequested={logout}
                                history={history}
                            />
                        )}
                    />
                    <Route
                        path="/reservations"
                        render={() => (
                            <ReservationsListContainer
                                path="/reservations"
                                onLogoutRequested={logout}
                            />
                        )}
                    />
                    <Redirect to="/dashboard" />
                </Switch>
            ) : loggedIn && isOwner ? (
                <Switch>
                    <Route
                        path="/propertyReport"
                        render={() => (
                            <PropertyReportContainer
                                path="/propertyReport"
                                onLogoutRequested={logout}
                            />
                        )}
                    />
                    <Redirect to="/propertyReport" />
                </Switch>
            ) : (
                <Route
                    path="/"
                    render={() => <LoginPage LoginFormProps={LoginFormProps} />}
                />
            );
        return (
            <ConnectedRouter history={history}>{routeSwitch}</ConnectedRouter>
        );
    }
}

AdminRouter.propTypes = {
    // loggedIn property comes from mapStateToProps below, via a call to the isUserLoggedIn selector.
    loggedIn: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isOwner: PropTypes.bool.isRequired,

    // History comes from connect via redux & react-connected-router in reducers.
    history: PropTypes.object.isRequired,

    // Login and Logout functions
    login: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,

    onClearErrorRequested: PropTypes.func.isRequired,

    errors: LoginFormPropTypes.loginFormErrors,

    loginInProgress: PropTypes.bool.isRequired,
};

const mapStateToProps = function(state) {
    return {
        loggedIn: isUserLoggedIn(state),
        isAdmin: isUserAdmin(state),
        isOwner: isUserOwner(state),
        errors: state.auth.errors,
        loginInProgress: state.auth.loginInProgress,
    };
};

const mapDispatchToProps = function(dispatch) {
    return {
        onClearErrorRequested: (fieldName) => dispatch(clearError(fieldName)),
        login: (email, password) => dispatch(loginRequested(email, password)),
        logout: () => dispatch(logout()),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    AdminRouter,
);
