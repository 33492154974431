import { generateTableColumnStyles } from '../../utils/styles';

export default (theme) => ({
    idHeadCell: theme.rigParkAdmin.tableHeadCells.id,
    photoHeadCell: theme.rigParkAdmin.tableHeadCells.photo,
    nameHeadCell: theme.rigParkAdmin.tableHeadCells.name,
    typeHeadCell: generateTableColumnStyles(120),
    registrationDateHeadCell: theme.rigParkAdmin.tableHeadCells.date,
    statusHeadCell: theme.rigParkAdmin.tableHeadCells.status,
});
