import React from 'react';
import { Grid, Avatar, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';
import AdminButton from '../../../common/AdminButton';

const PhotoUpload = ({ value, onChange, classes, ...inputProps }) => {
    let uploadRef;
    return (
        <Grid container spacing={16}>
            <Grid item>
                <Avatar
                    src={value ? value.photoURL : ''}
                    className={classes.avatar}
                />
                <input
                    hidden
                    type="file"
                    onChange={onChange}
                    ref={(ref) => {
                        uploadRef = ref;
                    }}
                    accept="image/*"
                    {...inputProps}
                />
            </Grid>
            <Grid item className={classes.imageUploadButton}>
                <AdminButton
                    buttonType="edit"
                    size="small"
                    onClick={() => uploadRef.click()}
                >
                    Choose File
                </AdminButton>
            </Grid>
        </Grid>
    );
};

PhotoUpload.propTypes = {
    value: PropTypes.shape({
        photoURL: PropTypes.string,
        photoFile: PropTypes.object,
    }),
    onChange: PropTypes.func,
    classes: PropTypes.object.isRequired,
    inputProps: PropTypes.object,
};

export default withStyles(styles)(PhotoUpload);
