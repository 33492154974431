import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, IconButton, withStyles } from '@material-ui/core';
import { AddCircleOutline, Clear } from '@material-ui/icons';
import WeekdayTimeRangePicker from '../WeekdayTimeRangePicker';
import AdminButton from '../AdminButton';
import styles from './styles';
import DatePropTypes from '../../propTypes/dates';
import { noop } from '../../utils';

class MultipleWeekdayTimeRangePicker extends Component {
    render() {
        const { classes, ranges, onRemove, onAdd, onRangeChange } = this.props;
        return (
            <Grid container direction="column" spacing={16}>
                {ranges.map(({ key, start, end }) => (
                    <Fragment key={key}>
                        <Grid
                            item
                            container
                            direction="column"
                            alignItems="flex-end"
                        >
                            <Grid item>
                                <IconButton
                                    className={classes.clearButton}
                                    onClick={() => onRemove(key)}
                                >
                                    <Clear className={classes.clearIcon} />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <WeekdayTimeRangePicker
                                    start={start}
                                    end={end}
                                    onStartDayChange={(event) =>
                                        onRangeChange(
                                            key,
                                            'start',
                                            'day',
                                            event.target.value,
                                        )
                                    }
                                    onStartTimeChange={(time) =>
                                        onRangeChange(
                                            key,
                                            'start',
                                            'time',
                                            time,
                                        )
                                    }
                                    onEndDayChange={(event) =>
                                        onRangeChange(
                                            key,
                                            'end',
                                            'day',
                                            event.target.value,
                                        )
                                    }
                                    onEndTimeChange={(time) =>
                                        onRangeChange(key, 'end', 'time', time)
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Divider />
                        </Grid>
                    </Fragment>
                ))}
                <Grid item>
                    <AdminButton
                        buttonType="edit"
                        size="large"
                        fullWidth
                        onClick={onAdd}
                    >
                        <AddCircleOutline className={classes.addIcon} />
                        Add Availability
                    </AdminButton>
                </Grid>
            </Grid>
        );
    }
}

MultipleWeekdayTimeRangePicker.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // The ranges to be displayed
    ranges: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
                .isRequired,
            start: DatePropTypes.weekdayAndTime,
            end: DatePropTypes.weekdayAndTime,
        }),
    ),

    // Function to be called when a range is removed
    onRemove: PropTypes.func.isRequired,

    // Function to be called when the add button is clicked.
    onAdd: PropTypes.func.isRequired,

    // Function that is called when a range day/time changes
    onRangeChange: PropTypes.func.isRequired,
};

MultipleWeekdayTimeRangePicker.defaultProps = {
    ranges: [],
    onRemove: noop,
    onAdd: noop,
    onRangeChange: noop,
};

export default withStyles(styles)(MultipleWeekdayTimeRangePicker);
