import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Chip, Grid, withStyles } from '@material-ui/core';
import { Clear as ClearIcon } from '@material-ui/icons';
import AdminSelect from '../AdminSelect';
import styles from './styles';

class AdminMultipleSelect extends Component {
    render() {
        const {
            classes,
            selectedOptions,
            handleSelectedOption,
            handleDelete,
            getChipLabel,
            ...AdminSelectProps
        } = this.props;
        return (
            <Grid container direction="row" spacing={8} justify="center">
                <Grid item>
                    <AdminSelect
                        onChange={handleSelectedOption}
                        {...AdminSelectProps}
                    />
                </Grid>
                <Grid
                    container
                    direction="row"
                    item
                    spacing={8}
                    justify="center"
                >
                    {selectedOptions.map((option) => {
                        return (
                            <Grid item key={option}>
                                <Chip
                                    className={classes.chip}
                                    label={
                                        getChipLabel
                                            ? getChipLabel(option)
                                            : option
                                    }
                                    onDelete={() => handleDelete(option)}
                                    deleteIcon={
                                        <ClearIcon
                                            className={classes.clearIcon}
                                        />
                                    }
                                    variant="default"
                                    color="primary"
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        );
    }
}

AdminMultipleSelect.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    handleSelectedOption: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    options: PropTypes.array,
    selectedOptions: PropTypes.array,
    getChipLabel: PropTypes.func,
};

AdminMultipleSelect.defaultProps = {
    options: [],
    selectedOptions: [],
    getChipLabel: null,
};

export default withStyles(styles)(AdminMultipleSelect);
