import {
    call,
    all,
    put,
    takeLatest,
    takeEvery,
    select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { isEmpty } from 'ramda';
import { DateTime } from 'luxon';
import { matchPath } from 'react-router';
import {
    PROPERTIES_LIST_VIEW_LOAD_REQUESTED,
    FETCH_PROPERTIES_LIST_PAGE_REQUESTED,
    fetchPropertiesListPageRequested,
    fetchPropertiesListPageSucceeded,
    fetchPropertiesListPageFailed,
    EDIT_DOCK,
    CLEAR_PROPERTIES_LIST_FILTERS,
    PROPERTY_DETAIL_VIEW_LOAD_REQUESTED,
    sendPropertyReportSucceeded,
    sendPropertyReportFailed,
    SEND_PROPERTY_REPORT_REQUESTED,
    fetchOwnerPropertiesFailed,
    fetchOwnerPropertiesSucceeded,
    FETCH_OWNER_PROPERTIES_REQUESTED,
    FETCH_PROPERTY_REPORT_REQUESTED,
    fetchPropertyReportSucceeded,
    fetchPropertyReportFailed,
} from './actions';
import {
    addPropertyPhotosFailed,
    addPropertyPhotosRequested,
    addPropertyPhotosSucceeded,
    CREATE_PROPERTY_REQUESTED,
    createPropertyFailed,
    createPropertySucceeded,
    DELETE_DOCK_REQUESTED,
    DELETE_PROPERTY_ACTIONS,
    DELETE_PROPERTY_TYPES,
    deleteDockFailed,
    deleteDockSucceeded,
    FETCH_OWNER_FOR_NEW_PROPERTY_REQUESTED,
    FETCH_PROPERTY_ACTIONS,
    FETCH_PROPERTY_TYPES,
    fetchOwnerForNewPropertyFailed,
    fetchOwnerForNewPropertySucceeded,
    UPDATE_PROPERTIES_STATUS_REQUESTED,
    UPDATE_PROPERTIES_STATUS_SUCCEEDED,
    UPDATE_PROPERTY_REQUESTED,
    updatePropertiesStatusFailed,
    updatePropertiesStatusSucceeded,
    updatePropertyFailed,
    updatePropertySucceeded,
    fetchOwnerForNewPropertyRequested,
    FETCH_OWNER_FOR_NEW_PROPERTY_SUCCEEDED,
    deletePropertiesSucceeded,
    DELETE_PROPERTIES_REQUESTED,
    DELETE_PROPERTIES_SUCCEEDED,
    deletePropertiesFailed,
} from './apiActions';
import {
    createPaginatedRequestSaga,
    doUploadWithAuth,
    fetchWithAuth,
    makeRequestWithAuth,
} from '../api/sagas';
import {
    getPropertiesListPageContext,
    getPropertyCountryAbbr,
    getPropertyOwnerCountryAbbr,
} from './selectors';
import { PROPERTY_STATUS_ACTIVE } from '../../properties/constants';
import {
    getEndOfDateTimestamp,
    getEndOfTodayTimestamp,
    getMonthDayAndYearFromObject,
    getStartOfDateTimestamp,
    hasDay,
    hasMonth,
    hasYear,
    MIN_YEAR,
} from '../../utils/dates';
import { getErrorsFromPossibleAPIErrorResponse } from '../../utils/api';
import { fetchRegionsRequested } from '../regions/actions';
import { fetchAmenitiesRequested } from '../amenities/apiActions';
import { fetchRulesRequested } from '../rules/apiActions';
import { getPathname } from '../router/selectors';

export function* loadPropertiesListView() {
    const currentContext = yield select(getPropertiesListPageContext);
    yield put(fetchPropertiesListPageRequested(currentContext));
}

const fetchPropertiesListPage = createPaginatedRequestSaga({
    path: '/property',
    actions: {
        succeeded: fetchPropertiesListPageSucceeded,
        failed: fetchPropertiesListPageFailed,
    },
    applyFilters: (query, filters) => {
        const {
            search,
            checked,
            start,
            end,
            docks,
            reservations,
            selectedCountryAbbr,
            selectedStates,
        } = filters;
        const queryFilters = {};
        const status = checked.map(
            (statusFilter) => statusFilter === PROPERTY_STATUS_ACTIVE,
        );
        if (status.length === 1) {
            // We only need to filter if only one status is provided, otherwise its none, or both which is the same as none!
            queryFilters.userActive = status[0];
        }
        if (!isEmpty(start) || !isEmpty(end)) {
            const createdStart = getStartOfDateTimestamp(
                hasMonth(start) && hasDay(start) && hasYear(start)
                    ? DateTime.fromObject(getMonthDayAndYearFromObject(start))
                    : DateTime.fromObject({ month: 1, day: 1, year: MIN_YEAR }),
            );
            const createdEnd =
                hasMonth(end) && hasDay(end) && hasYear(end)
                    ? getEndOfDateTimestamp(
                          DateTime.fromObject(
                              getMonthDayAndYearFromObject(end),
                          ),
                      )
                    : getEndOfTodayTimestamp();
            queryFilters.createdAt = [createdStart, createdEnd];
        }
        if (!isEmpty(docks)) {
            const { min, max } = docks;
            if (min || min === 0) {
                queryFilters.dockCountMin = min;
            }
            if (max || max === 0) {
                queryFilters.dockCountMax = max;
            }
        }
        if (!isEmpty(reservations)) {
            const { min, max } = reservations;
            if (min || min === 0) {
                queryFilters.reservationCountMin = min;
            }
            if (max || max === 0) {
                queryFilters.reservationCountMax = max;
            }
        }
        if (selectedStates && selectedStates.length > 0) {
            queryFilters.regionName = selectedStates;
        }
        if (selectedCountryAbbr) {
            queryFilters.countryAbbr = selectedCountryAbbr;
        }
        return {
            ...(query || {}),
            search: search || '',
            ...queryFilters,
        };
    },
});

export function* sendPropertyReport({ propertyId, recipients }) {
    try {
        const response = yield call(
            fetchWithAuth,
            `property/${propertyId}/report`,
            {
                method: 'GET',
                query: { recipients },
            },
        );
        if (!response || response.error) {
            yield put(
                sendPropertyReportFailed(
                    response?.error || 'An error occurred sending the report.',
                ),
            );
        } else {
            yield put(sendPropertyReportSucceeded());
        }
    } catch (error) {
        const errors = yield call(getErrorsFromPossibleAPIErrorResponse, error);
        const { _error } = (error.getError && error.getError()) || {};
        const message =
            errors[0] ||
            error?.json?.message ||
            (_error || error)?.message ||
            'An error occurred sending the report.';
        yield put(sendPropertyReportFailed(message));
    }
}

export function* loadPropertyDetailView() {
    yield put(fetchAmenitiesRequested());
    yield put(fetchRulesRequested());
    const pathname = yield select(getPathname);
    const { params: { propertyId } = {} } = matchPath(pathname, {
        path: '/properties/:propertyId',
    });
    if (!isNaN(propertyId)) {
        yield put(FETCH_PROPERTY_ACTIONS.requested(propertyId));
    } else {
        const { params: { userId } = {} } = matchPath(pathname, {
            path: '/properties/new/:userId',
        });
        if (!isNaN(userId)) {
            yield put(fetchOwnerForNewPropertyRequested(userId));
        }
    }
}

const fetchOwnerProperties = createPaginatedRequestSaga({
    path: '/property/mine',
    actions: {
        succeeded: fetchOwnerPropertiesSucceeded,
        failed: fetchOwnerPropertiesFailed,
    },
    applyFilters: (query, filters) => {
        const { search } = filters;
        return {
            ...(query || {}),
            search: search || '',
        };
    },
});

export function* loadRegionsForProperty() {
    const countryAbbr = yield select(getPropertyCountryAbbr);
    yield put(fetchRegionsRequested(countryAbbr));
}

export function* loadRegionsForPropertyOwner() {
    const countryAbbr = yield select(getPropertyOwnerCountryAbbr);
    yield put(fetchRegionsRequested(countryAbbr));
}

export function* fetchProperty({ propertyId }) {
    yield call(makeRequestWithAuth, {
        actions: FETCH_PROPERTY_ACTIONS,
        path: `property/${propertyId}`,
        method: 'GET',
    });
}

export function* createProperty({ property }) {
    const { photos, propertyPhoneNumber, ...propertyData } = property;
    propertyData.propertyPhoneNumber = propertyPhoneNumber
        .replace(/-/g, '')
        .replace(/\s/g, '');
    try {
        const newProperty = yield call(fetchWithAuth, 'property', {
            method: 'POST',
            body: propertyData,
        });
        const { propertyId } = newProperty;
        if (photos && photos.length > 0) {
            yield put(addPropertyPhotosRequested());
            const formData = new FormData();
            photos.forEach(({ file }) => {
                formData.append('photo', file);
            });
            try {
                const uploadResponse = yield call(
                    doUploadWithAuth,
                    `property/${propertyId}/photo/upload`,
                    { method: 'POST', body: formData },
                );
                yield put(addPropertyPhotosSucceeded(uploadResponse));
                newProperty.photos = uploadResponse;
            } catch (error) {
                const errors = yield call(
                    getErrorsFromPossibleAPIErrorResponse,
                    error,
                );
                yield put(
                    addPropertyPhotosFailed([
                        'Upload of new Property Photo(s) failed.',
                        ...errors,
                    ]),
                );
            }
        }
        yield put(createPropertySucceeded(newProperty));
        yield put(push(`/properties/${propertyId}`));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Creation of new property has failed.',
        );
        yield put(createPropertyFailed(errors, property));
    }
}

export function* updateProperty({ property }) {
    const { photos, propertyPhoneNumber, ...propertyData } = property;
    propertyData.propertyPhoneNumber = propertyPhoneNumber
        .replace(/-/g, '')
        .replace(/\s/g, '');

    const photosForSync = photos.filter((photo) => photo.uploadId);
    propertyData.photoUploadIds = photosForSync.map(({ uploadId }) => uploadId);

    try {
        const updatedProperty = yield call(
            fetchWithAuth,
            `property/${property.propertyId}`,
            {
                method: 'PUT',
                body: propertyData,
            },
        );
        yield put(updatePropertySucceeded(updatedProperty));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Failed to update property.',
        );
        yield put(updatePropertyFailed(errors, property));
    }

    // Pick out the new photos which will have a file prop holding the photo file
    const newPhotos = photos.filter((photo) => photo.file);

    // If there are any new photos, upload them as property photos
    if (newPhotos && newPhotos.length > 0) {
        yield put(addPropertyPhotosRequested());
        const formData = new FormData();
        newPhotos.forEach(({ file }) => {
            formData.append('photo', file);
        });
        try {
            const uploadResponse = yield call(
                doUploadWithAuth,
                `property/${property.propertyId}/photo/upload`,
                { method: 'POST', body: formData },
            );
            yield put(addPropertyPhotosSucceeded(uploadResponse));
        } catch (error) {
            const errors = yield call(
                getErrorsFromPossibleAPIErrorResponse,
                error,
            );
            yield put(
                addPropertyPhotosFailed([
                    'Upload of new Property Photo(s) failed.',
                    ...errors,
                ]),
            );
        }
    }
}

export function* deleteProperty({ propertyId }) {
    yield call(makeRequestWithAuth, {
        actions: DELETE_PROPERTY_ACTIONS,
        path: `property/${propertyId}`,
        method: 'DELETE',
    });
}

export function* navigateToDockDetailedView({ dockId, propertyId }) {
    if (propertyId && dockId) {
        yield put(push(`/properties/${propertyId}/dock/${dockId}`));
    }
}

export function* redirectToProperties() {
    yield put(push('/properties'));
}

export function* deleteDock({ propertyId, dockId, dockName }) {
    try {
        yield call(fetchWithAuth, `dock/${dockId}`, { method: 'DELETE' });
        yield put(deleteDockSucceeded(propertyId, dockId, dockName));
        yield put(FETCH_PROPERTY_ACTIONS.requested(propertyId));
    } catch (error) {
        yield put(deleteDockFailed(dockId, dockName));
    }
}

export function* updatePropertiesStatus({ propertyIds, status }) {
    try {
        yield call(fetchWithAuth, `property/${status}`, {
            method: 'PUT',
            body: { propertyIds },
        });
        yield put(updatePropertiesStatusSucceeded(propertyIds));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            "Updating the property's status failed.",
        );
        yield put(updatePropertiesStatusFailed(errors));
    }
}

export function* fetchOwner({ userId }) {
    try {
        const { email, region } = yield call(fetchWithAuth, `/user/${userId}`);
        yield put(fetchOwnerForNewPropertySucceeded({ email, region }));
    } catch (error) {
        yield put(
            fetchOwnerForNewPropertyFailed(
                'Retrieving owner email failed.',
                userId,
            ),
        );
    }
}

export function* fetchPropertyReport({ propertyId }) {
    try {
        const report = yield call(
            fetchWithAuth,
            `/reservation/report/${propertyId}`,
        );
        yield put(fetchPropertyReportSucceeded(report));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Fetching the property report failed.',
        );
        yield put(fetchPropertyReportFailed(errors));
    }
}

export function* deleteProperties({ propertyIds }) {
    try {
        const response = yield call(fetchWithAuth, `property`, {
            method: 'DELETE',
            body: { propertyIds },
        });
        const failedDeleteErrorMessages = response.reduce(
            (accumulatedErrorMessages, responseItem) => {
                const { errorMessage } = responseItem;
                if (errorMessage) {
                    return [...accumulatedErrorMessages, errorMessage];
                } else {
                    return accumulatedErrorMessages;
                }
            },
            [],
        );
        yield put(deletePropertiesSucceeded(failedDeleteErrorMessages));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Deleting the selected properties failed. Please try again later',
        );
        yield put(deletePropertiesFailed(errors));
    }
}

export default function*() {
    yield all([
        takeEvery(PROPERTIES_LIST_VIEW_LOAD_REQUESTED, loadPropertiesListView),
        takeEvery(PROPERTY_DETAIL_VIEW_LOAD_REQUESTED, loadPropertyDetailView),
        takeEvery(FETCH_OWNER_PROPERTIES_REQUESTED, fetchOwnerProperties),
        takeLatest(FETCH_PROPERTY_REPORT_REQUESTED, fetchPropertyReport),
        takeLatest(SEND_PROPERTY_REPORT_REQUESTED, sendPropertyReport),
        takeEvery(FETCH_PROPERTY_TYPES.succeeded, loadRegionsForProperty),
        takeEvery(
            FETCH_OWNER_FOR_NEW_PROPERTY_SUCCEEDED,
            loadRegionsForPropertyOwner,
        ),
        takeLatest(
            FETCH_PROPERTIES_LIST_PAGE_REQUESTED,
            fetchPropertiesListPage,
        ),
        takeLatest(FETCH_PROPERTY_TYPES.requested, fetchProperty),
        takeLatest(UPDATE_PROPERTY_REQUESTED, updateProperty),
        takeLatest(DELETE_PROPERTY_TYPES.requested, deleteProperty),
        takeLatest(EDIT_DOCK, navigateToDockDetailedView),
        takeLatest(DELETE_PROPERTY_TYPES.succeeded, redirectToProperties),
        takeEvery(DELETE_DOCK_REQUESTED, deleteDock),
        takeEvery(UPDATE_PROPERTIES_STATUS_REQUESTED, updatePropertiesStatus),
        // If we decide to implement normalization via RIG-290 the line below should be removed
        takeLatest(UPDATE_PROPERTIES_STATUS_SUCCEEDED, loadPropertiesListView),
        takeLatest(CLEAR_PROPERTIES_LIST_FILTERS, loadPropertiesListView),
        takeEvery(FETCH_OWNER_FOR_NEW_PROPERTY_REQUESTED, fetchOwner),
        takeLatest(CREATE_PROPERTY_REQUESTED, createProperty),
        takeLatest(DELETE_PROPERTIES_REQUESTED, deleteProperties),
        takeLatest(DELETE_PROPERTIES_SUCCEEDED, loadPropertiesListView),
    ]);
}
