import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import AdminContainer from '../../common/AdminContainer';
import AddStripeCard from '../../common/AddStripeCard';
import { addStripePaymentMethodRequested } from '../../stores/stripe/actions';
import {
    getAddPaymentMethodError,
    getIsAddingPaymentMethod,
} from '../../stores/stripe/selectors';
import { TYPE_ERROR, TYPE_INFO, TYPE_SUCCESS } from '../../common/FormMessages';

const CardDetailsContainer = ({
    loading,
    history,
    addPaymentMethod,
    error,
    path,
    userId,
    ...rest
}) => {
    const renderForm = useCallback(() => {
        return loading ? (
            <CircularProgress />
        ) : (
            <AddStripeCard
                history={history}
                userId={userId}
                addingPaymentMethodInProgress={loading}
                addPaymentMethod={addPaymentMethod}
                rigparkApiError={error}
            />
        );
    }, [loading, history, userId, addPaymentMethod, error]);

    return (
        <AdminContainer
            path={path}
            innerHeader
            innerHeaderProps={{
                title: 'Add Card',
                backText: '< Back to User',
                backTo: `/users/${userId}`,
            }}
            {...rest}
        >
            {renderForm()}
        </AdminContainer>
    );
};

CardDetailsContainer.propTypes = {
    // The path which should be passed down from the router.
    path: PropTypes.string.isRequired,

    // The ID of the user who owns the vehicle being viewed, a route parameter.
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    // Indication of whether or not the data is loading.
    loading: PropTypes.bool,

    history: PropTypes.object.isRequired,

    // Error adding payment method, if any
    error: PropTypes.shape({
        key: PropTypes.string,
        message: PropTypes.string.isRequired,
        type: PropTypes.oneOf([TYPE_ERROR, TYPE_INFO, TYPE_SUCCESS]).isRequired,
    }),

    // Add payment function
    addPaymentMethod: PropTypes.func.isRequired,
};

const mapStateToProps = function(state) {
    return {
        loading: getIsAddingPaymentMethod(state),
        error: getAddPaymentMethodError(state),
    };
};

const mapDispatchToProps = function(dispatch) {
    return {
        addPaymentMethod: (token, history, userId) =>
            dispatch(addStripePaymentMethodRequested(token, history, userId)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    CardDetailsContainer,
);
