import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import AdminButton from '../../common/AdminButton';
import DashboardPanelCard from './DashboardPanelCard';
import DashboardPropTypes from '../../propTypes/dashboard';

class AdminDashboardPanel extends Component {
    render() {
        const {
            classes,
            title,
            count,
            statusText,
            items,
            showButton,
            AdminButtonProps: adminButtonProps,
        } = this.props;
        const itemsLength = items.length;
        return (
            <Grid
                container
                direction="column"
                className={classes.root}
                spacing={8}
                component={Paper}
            >
                <Grid
                    container
                    item
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    wrap="nowrap"
                >
                    <Grid item container direction="row" alignItems="flex-end">
                        <Typography className={classes.text} variant="body1">
                            {title}
                        </Typography>
                        {count && (
                            <Typography
                                className={classes.subtext}
                                gutterBottom
                            >
                                ( {count.toLocaleString()} )
                            </Typography>
                        )}
                    </Grid>
                    <Grid item>
                        <Typography className={classes.subtext} noWrap>
                            {statusText}
                        </Typography>
                    </Grid>
                </Grid>
                {items.map((child, index) => {
                    const {
                        key,
                        text,
                        subtext,
                        rightContent,
                        imageUrl,
                    } = child;
                    const extraProps = {};
                    if (index === itemsLength - 1) {
                        extraProps.className = classes.lastChild;
                    } else if (index === itemsLength - 2) {
                        extraProps.className = classes.secondLastChild;
                    }
                    return (
                        <Grid item key={key} {...extraProps}>
                            <DashboardPanelCard
                                text={text}
                                subtext={subtext}
                                rightContent={rightContent}
                                imageUrl={imageUrl}
                            />
                        </Grid>
                    );
                })}
                {showButton && items.length > 0 && (
                    <Grid
                        container
                        item
                        direction="row"
                        justify="center"
                        alignItems="flex-end"
                        className={classes.buttonContainer}
                    >
                        <Grid item>
                            <AdminButton
                                buttonType="positive"
                                size="large"
                                className={classes.button}
                                {...adminButtonProps}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    }
}

AdminDashboardPanel.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // Panel title
    title: PropTypes.string.isRequired,

    // Count of the number of items that exist, not necessarily the number of items passed to this component
    count: PropTypes.number,

    // Status of the items
    statusText: PropTypes.string,

    // An array of child cards to be rendered
    items: DashboardPropTypes.dashboardItems,

    // Show a button?
    showButton: PropTypes.bool,

    // Props to forward onto the admin button
    AdminButtonProps: PropTypes.object,
};

AdminDashboardPanel.defaultProps = {
    items: [],
    showButton: true,
};

export default withStyles(styles)(AdminDashboardPanel);
