export const PREFIX = 'vehicles/';

export const UPDATE_VEHICLE_PHOTOS_REQUESTED = `${PREFIX}UPDATE_VEHICLE_PHOTOS_REQUESTED`;
export const UPDATE_VEHICLE_PHOTOS_SUCCEEDED = `${PREFIX}UPDATE_VEHICLE_PHOTOS_SUCCEEDED`;
export const UPDATE_VEHICLE_PHOTOS_FAILED = `${PREFIX}UPDATE_VEHICLE_PHOTOS_FAILED`;

export const REMOVE_FORM_MESSAGE = `${PREFIX}REMOVE_FORM_MESSAGE`;

export const FETCH_VEHICLE_MAKES_REQUESTED = `${PREFIX}/FETCH_VEHICLE_MAKES_REQUESTED`;
export const FETCH_VEHICLE_MAKES_SUCCEEDED = `${PREFIX}/FETCH_VEHICLE_MAKES_SUCCEEDED`;
export const FETCH_VEHICLE_MAKES_FAILED = `${PREFIX}/FETCH_VEHICLE_MAKES_FAILED`;

export const FETCH_VEHICLE_MODELS_REQUESTED = `${PREFIX}/FETCH_VEHICLE_MODELS_REQUESTED`;
export const FETCH_VEHICLE_MODELS_SUCCEEDED = `${PREFIX}/FETCH_VEHICLE_MODELS_SUCCEEDED`;
export const FETCH_VEHICLE_MODELS_FAILED = `${PREFIX}/FETCH_VEHICLE_MODELS_FAILED`;

export const updateVehiclePhotosRequested = (vehicleId, photos = []) => ({
    type: UPDATE_VEHICLE_PHOTOS_REQUESTED,
    vehicleId,
    photos,
});

export const updateVehiclePhotosSucceeded = (vehicleId, updatedPhotos) => ({
    type: UPDATE_VEHICLE_PHOTOS_SUCCEEDED,
    vehicleId,
    updatedPhotos,
});

export const updateVehiclePhotosFailed = (errors = []) => ({
    type: UPDATE_VEHICLE_PHOTOS_FAILED,
    errors,
});

export const removeFormMessage = (messageKey) => {
    return {
        type: REMOVE_FORM_MESSAGE,
        messageKey,
    };
};

export const getVehicleMakesRequested = () => ({
    type: FETCH_VEHICLE_MAKES_REQUESTED,
});

export const getVehicleMakesSucceeded = (vehicleMakes) => ({
    type: FETCH_VEHICLE_MAKES_SUCCEEDED,
    vehicleMakes,
});

export const getVehicleMakesFailed = () => ({
    type: FETCH_VEHICLE_MAKES_FAILED,
});

export const getVehicleModelsRequested = () => ({
    type: FETCH_VEHICLE_MODELS_REQUESTED,
});

export const getVehicleModelsSucceeded = (vehicleModels) => ({
    type: FETCH_VEHICLE_MODELS_SUCCEEDED,
    vehicleModels,
});

export const getVehicleModelsFailed = () => ({
    type: FETCH_VEHICLE_MODELS_FAILED,
});
