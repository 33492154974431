const styles = (theme) => ({
    text: {
        color: theme.palette.primary.contrastText,
        letterSpacing: 4,
    },
    welcomeText: {
        fontWeight: 'bold',
        fontSize: 36,
        [theme.breakpoints.down('md')]: {
            fontSize: 24,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
        },
    },
    descriptionText: {
        fontSize: 36,
        marginBottom: 30,
        [theme.breakpoints.down('md')]: {
            fontSize: 24,
            marginBottom: 14,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
        },
    },
    loginText: {
        fontWeight: 'bold',
        lineHeight: 1,
        [theme.breakpoints.down('md')]: {
            fontSize: 20,
        },
    },
    formGridItem: {
        marginTop: 10,
        marginBottom: 10,
    },
});

export default styles;
