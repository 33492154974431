import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './reducers';
import rootSaga from './sagas.js';

/**
 *
 * @param preloadedState
 * @returns {{store: any, history: {createHref, goBack, length, replace, go, action, location, goForward, block, push, listen}}}
 */
export default function(preloadedState) {
    // Create browser history object for tracking browser state
    const history = createBrowserHistory();

    // Create saga middleware for connecting the store to our root saga
    const sagaMiddleware = createSagaMiddleware();

    const rootReducer = createRootReducer(history);

    // Gather a list of our middleware
    const middleware = [routerMiddleware(history), sagaMiddleware];

    // Create our store with our enhanced root reducer, all of our middleware, and dev tools
    const store = createStore(
        rootReducer,
        preloadedState,
        composeWithDevTools(applyMiddleware(...middleware)),
    );

    // Run the root saga
    sagaMiddleware.run(rootSaga);

    // Return our store and history
    return { store, history };
}
