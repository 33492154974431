const styles = (theme) => {
    const textField = {
        color: `${theme.palette.secondary.main}`,
        opacity: 1,
        backgroundColor: theme.custom.grey,
    };
    return {
        label: {
            textAlign: 'right',
            textTransform: 'uppercase',
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            letterSpacing: 4,
        },
        textField: {
            ...textField,
            width: 500,
        },
        shortTextField: {
            ...textField,
            width: 190,
            minWidth: 190,
        },
        cost: {
            ...textField,
            width: 75,
            minWidth: 75,
        },
        reservationId: {
            marginRight: 160,
        },
        chooseUserButton: {
            marginRight: 50,
        },
        avatar: {
            width: 'fit-content',
        },
        datePickerLeft: {
            alignItems: 'flex-start',
        },
        datePickerRight: {
            alignItems: 'flex-end',
        },
        datePickerWrapper: {
            width: 500,
        },
    };
};

export default styles;
