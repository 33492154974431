import PropTypes from 'prop-types';

const Copyright = ({ symbol, name }) => {
    return `${symbol} ${new Date().getFullYear()} ${name}`;
};

Copyright.propTypes = {
    // The symbol to use as a string. Defaults to the copyright symbol.
    symbol: PropTypes.string,

    // The company name to show.
    name: PropTypes.string.isRequired,
};

Copyright.defaultProps = {
    symbol: String.fromCharCode(169),
};

export default Copyright;
