import React from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import styles from './styles';

const PaginationText = ({ classes, children }) => (
    <Typography
        variant="body2"
        color="secondary"
        className={classes.paginationText}
    >
        {children}
    </Typography>
);

PaginationText.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,
    // The text to render
    children: PropTypes.string.isRequired,
};

export default withStyles(styles)(PaginationText);
