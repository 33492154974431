const styles = (theme) => ({
    addIcon: {
        marginRight: 25,
    },
    clearButton: {
        backgroundColor: theme.custom.red,
        height: 21,
        width: 21,
        padding: 2,
        marginBottom: -24,
    },
    clearIcon: {
        color: theme.palette.primary.contrastText,
        fontSize: 12,
    },
});

export default styles;
