const styles = (theme) => ({
    container: {
        backgroundColor: theme.palette.primary.contrastText,
        padding: 10,
        width: 825,
        margin: 'auto',
    },
    label: {
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        letterSpacing: 4,
        marginLeft: 'auto',
    },
    labelWrapper: {
        marginTop: 15,
        textAlign: 'left',
    },
    componentWrapper: {
        flex: 4,
        marginRight: 60,
        marginLeft: 40,
    },
    searchBar: {
        marginBottom: 15,
        backgroundColor: '#F6F6F6',
    },
    results: {
        height: '50vh',
        overflowY: 'auto',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        marginBottom: 15,
    },
    loading: {
        alignSelf: 'center',
    },
});
export default styles;
