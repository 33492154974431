const styles = () => ({
    logoutButton: {
        marginRight: -8,
    },
    logoutButtonText: {
        opacity: 0.5,
        fontWeight: 'bold',
        letterSpacing: 2,
        fontSize: 10,
    },
});
export default styles;
