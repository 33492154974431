export const PREFIX = 'rules/';

// Actions
export const FETCH_RULES_REQUESTED = `${PREFIX}FETCH_RULES_REQUESTED`;
export const FETCH_RULES_SUCCEEDED = `${PREFIX}FETCH_RULES_SUCCEEDED`;
export const FETCH_RULES_FAILED = `${PREFIX}FETCH_RULES_FAILED`;

// Functions for each action
export function fetchRulesRequested(param) {
    return {
        type: FETCH_RULES_REQUESTED,
        param,
    };
}

export function fetchRulesSucceeded(data) {
    return {
        type: FETCH_RULES_SUCCEEDED,
        data,
    };
}

export function fetchRulesFailed(error) {
    return {
        type: FETCH_RULES_FAILED,
        error,
    };
}
