const styles = (theme) => ({
    container: {
        width: theme.custom.actionsAndFiltersWidth,
        [theme.breakpoints.down('md')]: {
            width: theme.custom.actionsAndFiltersWidthForMediumAndDown,
        },
    },
    dateLabel: {
        color: theme.palette.primary.contrastText,
        fontSize: 14,
        fontWeight: 'bold',
    },
    panel: {
        padding: '10px 20px',
        backgroundColor: theme.custom.successBlue,
    },
    errorMessages: {
        backgroundColor: theme.custom.successBlue,
    },
    filterField: {
        alignSelf: 'center',
    },
    vehicleFilters: {
        minWidth: '160px',
        height: '30px',
        padding: '2px',
        marginLeft: '0px',
    },
});

export default styles;
