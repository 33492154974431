import { capitalizeFirstLetter } from '../../utils';
import { DateTime } from 'luxon';

export const PULLTHROUGH_OPTIONS = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
];

export const RATE_TYPE_HOURLY = 'hourly';
export const RATE_TYPE_DAILY = 'daily';
export const RATE_TYPE_MONTHLY = 'monthly';

export const RATE_TYPES = [
    { name: RATE_TYPE_HOURLY, label: capitalizeFirstLetter(RATE_TYPE_HOURLY) },
    { name: RATE_TYPE_DAILY, label: capitalizeFirstLetter(RATE_TYPE_DAILY) },
    {
        name: RATE_TYPE_MONTHLY,
        label: capitalizeFirstLetter(RATE_TYPE_MONTHLY),
    },
];

export const DEFAULT_DOCK_TIMES = [
    {
        key: 'default',
        start: {
            day: 1,
            time: DateTime.fromObject({ hour: 0, minute: 0, second: 0 }),
        },
        end: {
            day: 7,
            time: DateTime.fromObject({ hour: 23, minute: 59, second: 59 }),
        },
    },
];
