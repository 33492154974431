import { DELETED_USER } from './constants';
import { transformPhotos } from '../api/transforms';
import { RESERVATION_PROP_TYPES } from '../../properties/PropertyReportContainer/constants';

export const transformProperty = (property = {}) => {
    const {
        region = {},
        user = {},
        userId,
        photos,
        propertyCoordinates = {},
        amenities = [],
        rules = [],
        ...rest
    } = property;
    const { utilizationReportConfigs: utilReportOwnerConfigs = [] } = user;
    const { coordinates = [] } = propertyCoordinates;
    const [longitude, latitude] = coordinates;
    return {
        regionAbbr: region ? region.regionAbbr : '',
        propertyOwner: user ? user.email : `${DELETED_USER} (#${userId})`,
        photos: transformPhotos(photos),
        amenityIds: amenities.map(({ amenityId }) => amenityId),
        ruleIds: rules.map(({ ruleId }) => ruleId),
        country: region ? region.country : property.country,
        longitude,
        latitude,
        coordinates: latitude && longitude ? true : '',
        utilReportOwnerConfigs: utilReportOwnerConfigs.sort((a, b) => {
            return a.sendTime < b.sendTime ? -1 : 1;
        }),
        utilizationReportRecipients: '',
        ...rest,
    };
};

/**
 *
 * @param properties
 * @return {string[]} e.g. "123 Some Drive, Raleigh, NC" or "456 Hay Place"
 */
export const transformOwnedPropertiesForSelect = (properties = []) => {
    return (
        (properties?.length &&
            properties.map(
                ({
                    propertyId,
                    propertyName,
                    propertyAddress,
                    propertyCity,
                    region: { regionAbbr } = {},
                    timezone,
                }) => ({
                    timezone,
                    value: propertyId,
                    label: `${propertyName} - ${propertyAddress}${(propertyCity &&
                        regionAbbr &&
                        `, ${propertyCity}, ${regionAbbr}`) ||
                        ''}`,
                }),
            )) ||
        []
    );
};

export const transformReportReservations = (reservations = []) =>
    (reservations || []).map((reservation) =>
        Object.keys(RESERVATION_PROP_TYPES).reduce(
            (carry, key) => ({
                ...carry,
                [key]: (reservation && reservation[key]) || '',
            }),
            {},
        ),
    );
