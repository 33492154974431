const styles = (theme) => ({
    inputRoot: {
        lineHeight: 1.5,
        color: theme.palette.secondary.main,
        width: 125,
        padding: 5,
        height: 54,
    },
    small: {
        lineHeight: 1,
        color: theme.palette.secondary.main,
        width: 125,
        padding: 0,
        height: 26,
        backgroundColor: theme.palette.primary.contrastText,
        fontSize: 12,
    },
    clockIcon: {
        color: theme.palette.secondary.main,
        padding: 0,
        marginTop: 2,
    },
    smallClockIcon: {
        color: theme.palette.secondary.main,
        width: '15px !important',
        height: '15px !important',
    },
});

export default styles;
