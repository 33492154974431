const styles = (theme) => ({
    buttonGridContainer: {
        backgroundColor: theme.palette.primary.main,
        padding: '0 10px',
    },
    button: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.custom.successBlue,
        borderRadius: 3,
        height: 30,
        width: 120,
        fontSize: 10,
        margin: '15px 5px',
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
        },
    },
});

export default styles;
