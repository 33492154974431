const styles = (theme) => ({
    adminButton: {
        fontWeight: 'bold',
        letterSpacing: 1,
        '&:hover': {
            opacity: theme.custom.buttonHoverOpacity,
        },
    },
    smallButton: {
        minWidth: 115,
        fontSize: 10,
        paddingTop: 6,
    },
    largeButton: {
        minWidth: 225,
        minHeight: 55,
        fontSize: 12,
    },
    positive: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    negative: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.custom.red,
        '&:hover': {
            backgroundColor: theme.custom.red,
        },
    },
    edit: {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.custom.editGreen,
        '&:hover': {
            backgroundColor: theme.custom.editGreen,
        },
    },
});

export default styles;
