export const PREFIX = 'userGroups/';

export const FETCH_USER_GROUPS_REQUESTED = `${PREFIX}FETCH_USER_GROUPS_REQUESTED`;
export const FETCH_USER_GROUPS_SUCCEEDED = `${PREFIX}FETCH_USER_GROUPS_SUCCEEDED`;
export const FETCH_USER_GROUPS_FAILED = `${PREFIX}FETCH_USER_GROUPS_FAILED`;

export function fetchUserGroupsRequested() {
    return {
        type: FETCH_USER_GROUPS_REQUESTED,
    };
}

export function fetchUserGroupsSucceeded(data) {
    return {
        type: FETCH_USER_GROUPS_SUCCEEDED,
        data,
    };
}

export function fetchUserGroupsFailed() {
    return {
        type: FETCH_USER_GROUPS_FAILED,
    };
}
