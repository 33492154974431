import { DateTime } from 'luxon';

/**
 * Format used to create instances of DateTime when transforming
 * data from the API layer, such as the startTime and endTime on a DockTime or a PropertyTime.
 * @type {string}
 */
const formatWithSeconds = 'HH:mm:ss';
const formatNoSeconds = 'HH:mm';

/**
 * Function that can be used to transform API Data
 * of a DockTime or PropertyTime so that it can be consumed by a the UI component
 * MultipleWeekdayTimeRangePicker.
 * @param times
 * @returns {{start: {time: DateTime, day}, end: {time: DateTime, day}, key}[]}
 */
export const transformTimes = (times = []) => {
    return times.map((dockTime) => {
        const {
            timeId,
            startDay,
            startTime,
            endDay,
            endTime,
            start,
            end,
            key,
        } = dockTime;
        return {
            key: key || timeId,
            start: start || {
                day: startDay,
                time: DateTime.fromFormat(
                    startTime,
                    startTime.length > 7 ? formatWithSeconds : formatNoSeconds,
                ),
            },
            end: end || {
                day: endDay,
                time: DateTime.fromFormat(
                    endTime,
                    endTime.length > 7 ? formatWithSeconds : formatNoSeconds,
                ),
            },
        };
    });
};

/**
 * Function that can be used to transform API data such as
 * PropertyPhoto or DockPhoto to be consumable by the UI component
 * named Photos.
 * @param photos
 * @returns {{photoUrl, key}[]}
 */
export const transformPhotos = (photos = []) => {
    return photos.map((dockPhoto) => {
        const { photoId, photoUrl, uploadId, ...rest } = dockPhoto;
        return {
            key: photoId,
            photoUrl,
            uploadId,
            ...rest,
        };
    });
};
