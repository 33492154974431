import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';

import styles from './styles';
import DashboardPropTypes from '../../../propTypes/dashboard';
import CompassIconButton from '../../../common/CompassIconButton';
import AdminAvatar from '../../../common/AdminAvatar';

class DashboardPanelCard extends React.Component {
    render() {
        const { classes, imageUrl, text, subtext, rightContent } = this.props;
        return (
            <Grid
                container
                direction="row"
                className={classes.root}
                alignItems="center"
                wrap="nowrap"
            >
                <Grid item>
                    <AdminAvatar
                        className={classes.avatar}
                        src={imageUrl}
                        alt={text}
                    />
                </Grid>
                <Grid item container direction="column">
                    <Grid item>
                        <Typography variant="body1" className={classes.text}>
                            {text}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        className={classes.subtext}
                        component={Typography}
                    >
                        {subtext}
                    </Grid>
                </Grid>
                <Grid item className={classes.subtext} component={Typography}>
                    {rightContent && rightContent.latitude ? (
                        <CompassIconButton coordinates={rightContent} />
                    ) : (
                        rightContent
                    )}
                </Grid>
            </Grid>
        );
    }
}

DashboardPanelCard.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // Url of the avatar image to be rendered
    imageUrl: PropTypes.string,

    // Main text of the Card
    text: PropTypes.string,

    // Text to be displayed underneath the main text
    subtext: PropTypes.string,

    // Content to be displayed on the right side of the card,
    rightContent: DashboardPropTypes.rightContent,
};

export default withStyles(styles)(DashboardPanelCard);
