// Import actions
import {
    FETCH_USER_GROUPS_SUCCEEDED,
    FETCH_USER_GROUPS_FAILED,
    FETCH_USER_GROUPS_REQUESTED,
} from './actions';
import { LOGOUT } from '../auth/actions';

// Import initial state
import { userGroupsInitialState } from './initialState';

export default function(state = userGroupsInitialState, action = {}) {
    const { type } = action;
    switch (type) {
        case FETCH_USER_GROUPS_REQUESTED:
            return { ...state, loading: true };
        case FETCH_USER_GROUPS_SUCCEEDED: {
            const { data } = action;
            return { ...state, loading: false, data };
        }
        case FETCH_USER_GROUPS_FAILED:
            return { ...state, loading: false };
        case LOGOUT:
            return userGroupsInitialState;
        default:
            return state;
    }
}
