export default () => ({
    headCell: {},
    bodyCell: {},
    sortableWrapper: {},
    sortArrow: {
        transition:
            'opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        opacity: 0,
    },
    sortArrowActive: {
        opacity: 1,
    },
    sortArrowAsc: {
        transform: 'rotate(-90deg)',
    },
    sortArrowDesc: {
        transform: 'rotate(90deg)',
    },
});
