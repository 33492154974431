const styles = (theme) => ({
    buttonContainer: {},
    container: {
        marginBottom: 20,
    },
    labelWrapper: {
        flex: 1,
        margin: 'auto',
    },
    label: {
        fontWeight: 'bold',
        textTransform: 'capitalize',
        fontSize: 16,
    },
    buttonWrapper: {
        flex: 1,
        margin: 'auto',
        padding: '10',
        justifyContent: 'flex-end',
    },
    divider: {
        marginTop: 18,
        width: '100%',
    },
});

export default styles;
