import { matchPath } from 'react-router';

/**
 * A function that does nothing.
 */
export function noop() {}

/**
 * Returns true if the path given matches any of the
 * paths in the paths array. (Not exact and not strict.)
 * @param path
 * @param paths
 * @returns {boolean}
 */
export const matchesPaths = (path, paths = []) => {
    return paths.reduce((accumulator = false, testPath) => {
        return accumulator || matchPath(testPath, { path }) !== null;
    }, false);
};

/**
 * Safely determine if an object has a key.
 *
 * @see https://eslint.org/docs/rules/no-prototype-builtins
 * @see Object.prototype.hasOwnProperty
 *
 * @param {{}} obj
 * @param {string} key
 */
export function has(obj, key) {
    return Object.prototype.hasOwnProperty.call(obj, key);
}

/**
 * If value is an object and contains the specified property, that will be returned.
 * Otherwise the default value will be returned. By default the default value is the value
 * itself.
 *
 * @param {*} value
 * @param {string} prop
 * @param {*} def
 * @return {string}
 */
export const extractStringProperty = (value, prop, def = value) => {
    return value && typeof value === 'object' && has(value, prop)
        ? `${value[prop]}`
        : `${def}`;
};

/**
 * If value is an object and contains the specified property, and that property is an array,
 * it will be returned. Otherwise the default value will be returned which is an empty array
 * by default.
 *
 * @param {*} value
 * @param {string} prop
 * @param {*} def
 * @return {*[]}
 */
export const extractArrayProperty = (value, prop, def = []) => {
    return value &&
        typeof value === 'object' &&
        has(value, prop) &&
        Array.isArray(value[prop])
        ? value[prop]
        : def;
};

/**
 * Capitalize the 1st letter of the given text.
 *
 * @param {string} text - The text to capitalize.
 * @returns {string} The text that the 1st letter was capitalized.
 */
export function capitalizeFirstLetter(text) {
    return text[0].toUpperCase() + text.slice(1);
}
