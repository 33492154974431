const styles = (theme) => ({
    largeInput: {
        minWidth: 350,
        color: theme.palette.secondary.main,
        padding: 0,
    },
    smallInput: {},
    largeInputStyle: {
        padding: 20,
        backgroundColor: theme.custom.grey,
        minHeight: 30,
        textIndent: 0,
        marginBottom: 0,
    },
    smallInputStyle: {
        backgroundColor: theme.palette.primary.contrastText,
        width: 110,
        height: 28,
        padding: 4,
        textIndent: 4,
        borderRadius: 3,
    },
    disabled: {
        opacity: 0.5,
    },
    helperTextError: {
        marginTop: 4,
        fontWeight: 'bold',
    },
    inputNotchedOutline: {
        borderWidth: 0,
    },
});

export default styles;
