const styles = (theme) => ({
    footerContainer: {
        backgroundColor: theme.palette.secondary.contrastText,
        height: 50,
        [theme.breakpoints.down('md')]: {
            height: 35,
        },
    },
    footerText: {
        color: theme.palette.primary.contrastText,
        letterSpacing: 3,
        opacity: 0.75,
        textTransform: 'uppercase',
    },
});

export default styles;
