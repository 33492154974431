import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton, withStyles } from '@material-ui/core';
import Compass from './compass.svg';

const styles = () => ({
    root: {
        height: 24,
    },
});

class CompassIconButton extends Component {
    render() {
        const { classes, coordinates, zoomLevel, ...props } = this.props;
        const hrefProp = coordinates
            ? {
                  href: `https://google.com/maps/place/?q=${
                      coordinates.latitude
                  },${coordinates.longitude}&z=${zoomLevel}`,
                  target: '_blank',
              }
            : {};
        return (
            <IconButton {...hrefProp} {...props}>
                <img src={Compass} alt="Compass" className={classes.root} />
            </IconButton>
        );
    }
}

CompassIconButton.propTypes = {
    // From withStyles we expect to get classes.
    classes: PropTypes.object.isRequired,

    // If Coordinates are provided, we'll add an href property to google maps.
    coordinates: PropTypes.shape({
        latitude: PropTypes.number.isRequired,
        longitude: PropTypes.number.isRequired,
    }),

    // The zoom level can be overridden but will be provided a default value otherwise (see below)
    zoomLevel: PropTypes.number,
};

CompassIconButton.defaultProps = {
    coordinates: null,

    // Default zoom level for an href for map links will be the "buildings" level
    zoomLevel: 20,
};

export default withStyles(styles, { name: 'RigParkCompassIconButton' })(
    CompassIconButton,
);
