export const SET_PROPERTY_STATUS_ACTIVE = 'setStatusActive';
export const PROPERTY_STATUS_ACTIVE = 'active';
export const SET_PROPERTY_STATUS_INACTIVE = 'setStatusInactive';
export const PROPERTY_STATUS_INACTIVE = 'inactive';
export const DELETE_PROPERTIES = 'deleteProperties';

const ACTION_LABELS = {
    [SET_PROPERTY_STATUS_ACTIVE]: 'Approve',
    [SET_PROPERTY_STATUS_INACTIVE]: 'Remove Approval',
    [DELETE_PROPERTIES]: 'Delete Properties',
};

export const ACTIONS = [
    {
        value: SET_PROPERTY_STATUS_ACTIVE,
        label: ACTION_LABELS[SET_PROPERTY_STATUS_ACTIVE],
    },
    {
        value: SET_PROPERTY_STATUS_INACTIVE,
        label: ACTION_LABELS[SET_PROPERTY_STATUS_INACTIVE],
    },
    {
        value: DELETE_PROPERTIES,
        label: ACTION_LABELS[DELETE_PROPERTIES],
    },
];
