import React from 'react';
import PropTypes from 'prop-types';
import { identical, uniqWith } from 'ramda';
import FormMessages from '../../common/FormMessages';

const ListErrors = ({ errors }) => {
    if (errors && errors.length > 0) {
        const messages = uniqWith(identical, errors).map((error) => ({
            key: error,
            message: error,
            type: 'error',
        }));
        return <FormMessages messages={messages} />;
    }
    return null;
};

ListErrors.propTypes = {
    errors: PropTypes.array,
};

ListErrors.defaultProps = {
    errors: [],
};

export default ListErrors;
