import reservationsInitialState, { initialFiltersState } from './initialState';
import { LOGOUT } from '../auth/actions';
import {
    FETCH_RESERVATIONS_LIST_PAGE_FAILED,
    FETCH_RESERVATIONS_LIST_PAGE_REQUESTED,
    FETCH_RESERVATIONS_LIST_PAGE_SUCCEEDED,
    UPDATE_RESERVATIONS_LIST_FILTERS,
    CLEAR_RESERVATIONS_LIST_FILTERS,
    FILTERS_VALIDATION_FAILED,
    FETCH_RESERVATION_TYPES,
    FETCH_PROPERTIES_LIST_FOR_FILTERS_REQUESTED,
    FETCH_PROPERTIES_LIST_FOR_FILTERS_FAILED,
    FETCH_PROPERTIES_LIST_FOR_FILTERS_SUCCEEDED,
    FETCH_USERS_LIST_FOR_FILTERS_REQUESTED,
    FETCH_USERS_LIST_FOR_FILTERS_FAILED,
    FETCH_USERS_LIST_FOR_FILTERS_SUCCEEDED,
    UPDATE_RESERVATION_SUCCEEDED,
    UPDATE_RESERVATION_FAILED,
    REMOVE_FORM_MESSAGES,
    BOOK_RESERVATION_SEARCH_USER_REQUESTED,
    BOOK_RESERVATION_SEARCH_USER_SUCCEEDED,
    BOOK_RESERVATION_SEARCH_USER_FAILED,
    BOOK_RESERVATION_GET_USER_PAYMENT_METHODS_REQUESTED,
    BOOK_RESERVATION_GET_USER_PAYMENT_METHODS_REQUESTED_FAILED,
    BOOK_RESERVATION_GET_USER_PAYMENT_METHODS_REQUESTED_SUCCEEDED,
    CLEAR_BOOKING_RESERVATION_STATE,
    BOOK_RESERVATION_REQUESTED,
    BOOK_RESERVATION_SUCCEEDED,
    BOOK_RESERVATION_FAILED,
    BOOK_RESERVATION_GET_PROPERTY_INFO_REQUESTED,
    BOOK_RESERVATION_GET_PROPERTY_INFO_FAILED,
    BOOK_RESERVATION_GET_PROPERTY_INFO_SUCCEEDED,
} from './actions';
import { createSliceUpdater } from '../utils';
import { removeMessage } from '../common/reducerUtils';

const createMessage = (message, key, error = false) => ({
    key,
    message,
    type: error ? 'error' : 'success',
});
const doneReservation = (state, data = {}) => ({
    ...state,
    ...data,
    reservationLoading: false,
});

export default function(state = reservationsInitialState, action = {}) {
    const { type } = action;

    const updateSlice = createSliceUpdater(state);

    switch (type) {
        // Fetch reservations list
        case FETCH_RESERVATIONS_LIST_PAGE_REQUESTED:
            return updateSlice('list', { loading: true });
        case FETCH_RESERVATIONS_LIST_PAGE_SUCCEEDED:
            return updateSlice('list', {
                loading: false,
                page: {
                    ...action.data,
                    filters: action.filters,
                    order: action.order,
                },
            });
        case FETCH_RESERVATIONS_LIST_PAGE_FAILED:
            return updateSlice('list', {
                loading: false,
                errors: action.errors,
            });

        // Fetch Reservation Detail
        case FETCH_RESERVATION_TYPES.requested:
            return { ...state, reservationLoading: true };
        case FETCH_RESERVATION_TYPES.succeeded: {
            const { reservation = {} } = action;
            return doneReservation(state, { reservation });
        }
        case UPDATE_RESERVATION_SUCCEEDED: {
            const { reservation = {} } = action;
            return {
                ...state,
                ...reservation,
                messages: [
                    createMessage(
                        'Reservation Updated!',
                        'update_reservation_succeeded',
                        false,
                    ),
                ],
            };
        }
        case UPDATE_RESERVATION_FAILED: {
            return doneReservation(state, {
                messages: [
                    createMessage(
                        action.error.message,
                        'update_reservation_failed',
                        true,
                    ),
                ],
            });
        }
        case REMOVE_FORM_MESSAGES: {
            return {
                ...state,
                messages: removeMessage(state.messages, action.messageKey),
            };
        }
        case FETCH_RESERVATION_TYPES.failed: {
            return {
                ...doneReservation(state, {
                    messages: [
                        createMessage(
                            'Failed To Fetch this Reservation',
                            'fetch_reservation_failed',
                            true,
                        ),
                    ],
                }),
                reservationLoading: false,
            };
        }

        // Reservations list filter actions
        case UPDATE_RESERVATIONS_LIST_FILTERS: {
            const { filters } = action;
            return updateSlice('list', {
                page: {
                    ...state.list.page,
                    filters,
                    filterErrors: [],
                },
            });
        }
        case CLEAR_RESERVATIONS_LIST_FILTERS:
            return updateSlice('list', {
                page: {
                    ...state.list.page,
                    filters: initialFiltersState,
                    filterErrors: [],
                },
            });
        case FILTERS_VALIDATION_FAILED: {
            const { filterErrors, filters } = action;
            return updateSlice('list', {
                page: {
                    ...state.list.page,
                    filters,
                    filterErrors: filterErrors.map((filterError) => {
                        return {
                            type: 'error',
                            key: filterError,
                            message: filterError,
                        };
                    }),
                },
            });
        }

        case FETCH_PROPERTIES_LIST_FOR_FILTERS_REQUESTED: {
            return {
                ...state,
                propertiesListLoading: true,
                propertiesListErrors: [],
            };
        }
        case FETCH_PROPERTIES_LIST_FOR_FILTERS_FAILED: {
            const { errors } = action;
            return {
                ...state,
                propertiesListLoading: false,
                propertiesListErrors: errors,
            };
        }
        case FETCH_PROPERTIES_LIST_FOR_FILTERS_SUCCEEDED: {
            const { propertiesList = [] } = action;
            return {
                ...state,
                propertiesListLoading: false,
                propertiesList,
            };
        }

        case FETCH_USERS_LIST_FOR_FILTERS_REQUESTED: {
            return {
                ...state,
                usersListLoading: true,
                usersListErrors: [],
            };
        }
        case FETCH_USERS_LIST_FOR_FILTERS_FAILED: {
            const { errors } = action;
            return {
                ...state,
                usersListLoading: false,
                usersListErrors: errors,
            };
        }
        case FETCH_USERS_LIST_FOR_FILTERS_SUCCEEDED: {
            const { usersList = [] } = action;
            return {
                ...state,
                usersListLoading: false,
                usersList,
            };
        }

        // User search for reservations
        case BOOK_RESERVATION_SEARCH_USER_REQUESTED: {
            return {
                ...state,
                userSearchResults: [],
                userSearchLoading: true,
            };
        }
        case BOOK_RESERVATION_SEARCH_USER_SUCCEEDED: {
            const { results } = action;
            return {
                ...state,
                userSearchResults: results,
                userSearchLoading: false,
            };
        }
        case BOOK_RESERVATION_SEARCH_USER_FAILED: {
            return state;
        }

        // Payment method search for reservations
        case BOOK_RESERVATION_GET_USER_PAYMENT_METHODS_REQUESTED: {
            return {
                ...state,
                userPaymentMethods: [],
                userPaymentMethodsLoading: true,
            };
        }
        case BOOK_RESERVATION_GET_USER_PAYMENT_METHODS_REQUESTED_SUCCEEDED: {
            const { paymentMethods } = action;
            return {
                ...state,
                userPaymentMethods: paymentMethods.cards || [],
                userPaymentMethodsLoading: false,
            };
        }
        case BOOK_RESERVATION_GET_USER_PAYMENT_METHODS_REQUESTED_FAILED: {
            return {
                ...state,
                userPaymentMethodsLoading: false,
            };
        }

        // Property/dock pricing search for reservations
        case BOOK_RESERVATION_GET_PROPERTY_INFO_REQUESTED: {
            return {
                ...state,
                propertyInfoLoading: true,
                property: {},
            };
        }
        case BOOK_RESERVATION_GET_PROPERTY_INFO_FAILED: {
            return { ...state, propertyInfoLoading: false };
        }
        case BOOK_RESERVATION_GET_PROPERTY_INFO_SUCCEEDED: {
            const { property } = action;
            return { ...state, propertyInfoLoading: false, property };
        }

        case CLEAR_BOOKING_RESERVATION_STATE: {
            return {
                ...state,
                reservationLoading: false,
                reservation: {},
                messages: [],
                userSearchResults: [],
                userSearchLoading: false,
                userPaymentMethods: [],
                userPaymentMethodsLoading: false,
            };
        }

        case BOOK_RESERVATION_REQUESTED: {
            return {
                ...state,
                reservationInProgress: true,
            };
        }
        case BOOK_RESERVATION_SUCCEEDED: {
            return {
                ...state,
                reservationInProgress: false,
                messages: [
                    createMessage(
                        'You have successfully created this reservation.',
                        'create_reservation_succeeded',
                        false,
                    ),
                ],
            };
        }
        case BOOK_RESERVATION_FAILED: {
            return {
                ...state,
                reservationInProgress: false,
                messages: [
                    createMessage(
                        'Failed to book this reservation. Please check your dock times and payment methods.',
                        'create_reservation_failed',
                        true,
                    ),
                ],
            };
        }

        // Clear on logout
        case LOGOUT:
            return reservationsInitialState;

        default:
            return state;
    }
}
