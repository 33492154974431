export default (theme) => ({
    cardContainer: {
        backgroundColor: theme.palette.primary.contrastText,
        padding: 60,
        width: 825,
        margin: 'auto',
        justifyContent: 'space-around',
    },
    errorContainer: {
        width: 825,
        margin: 'auto',
    },
    labelWrapper: {
        marginBottom: 15,
        minWidth: 150,
    },
    label: {
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        letterSpacing: 4,
        alignSelf: 'center',
        marginLeft: 'auto',
        flex: 1,
    },
    field: {
        flex: 3,
    },
    cardInput: {
        backgroundColor: '#EEEEEE',
        textAlign: 'center',
        fontWeight: 'bold',
        marginBottom: 15,
        minHeight: 30,
        padding: 20,
    },
});
