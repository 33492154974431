import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
    CircularProgress,
    Grid,
    Tooltip,
    Typography,
    withStyles,
} from '@material-ui/core';
import { AddCircleOutline, Delete, Info } from '@material-ui/icons';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import styles from './styles';
import ActionableListRow from '../../common/ActionableListRow';
import AdminButton, {
    BUTTON_TYPE_EDIT,
    BUTTON_TYPE_NEGATIVE,
    BUTTON_TYPE_POSITIVE,
} from '../../common/AdminButton';
import AdminSelect from '../../common/AdminSelect';
import ConfirmDialog from '../../common/ConfirmDialog';
import DetailForm from '../../common/DetailForm';
import Photos from '../../common/Photos';
import TextField from '../../common/TextField';
import MultilineTextField from '../../common/MultilineTextField';
import { formatDate } from '../../utils/dates';
import {
    FIELDS_TO_RESET_UPON_COUNTRY_CHANGE,
    PROPERTY_APPROVAL,
} from './constants';
import { formMessagesPropTypes } from '../../common/FormMessages';
import {
    DELETED_USER,
    USER_NOT_FOUND,
} from '../../stores/properties/constants';
import CoordinatesInput from '../CoordinatesInput';
import { ALL_TIMEZONES, TIMEZONES } from '../../constants/timezone';
import { PHONE_MASKS, PHONE_REG_EXP } from '../../constants/phoneNumber';
import AdminCheckboxes from '../../common/AdminCheckboxes';
import { without } from 'ramda';
import {
    COUNTRY_ABBR_UNITED_STATES,
    COUNTRY_NAMES_AND_ABBREVIATIONS,
} from '../../constants/country';
import {
    MAX_LENGTH_CITY,
    MAX_LENGTH_ZIPCODE,
    MAX_LENGTH_PROPERTY_NAME,
} from '../../validation/forms';
import { TIMES_OF_DAY } from '../../constants/timesOfDay';
import { DateTime } from 'luxon';

class PropertyForm extends Component {
    state = {
        timezones: [],
        phoneMask: '',
        deletingDock: {
            showConfirmDialog: false,
            dockId: '',
            dockName: '',
        },
        photos: this.props.formState.photos,
    };

    componentDidMount() {
        const {
            formState: { country: { countryAbbr } = {} } = {},
        } = this.props;
        this.setState({
            phoneMask:
                PHONE_MASKS[countryAbbr] ||
                PHONE_MASKS[COUNTRY_ABBR_UNITED_STATES],
            timezones: TIMEZONES[countryAbbr] || ALL_TIMEZONES,
        });
    }

    onCheckboxChange = (setValue, fieldName, currentValues, event) => {
        const { name } = event.target;
        if (!isNaN(name) && setValue) {
            const nameInt = parseInt(name);
            const newValues = currentValues.includes(nameInt)
                ? without([nameInt], currentValues)
                : [...currentValues, nameInt];
            setValue(fieldName, newValues);
        }
    };

    onCountryChange = (setFieldValue, event) => {
        const { value: countryAbbr } = event.target;
        const countryName = COUNTRY_NAMES_AND_ABBREVIATIONS.find(
            (country) => countryAbbr === country.value,
        ).label;
        setFieldValue('country', { countryName, countryAbbr });
        // Clear out the fields affected by country, forcing them to be changed since the country changed.
        FIELDS_TO_RESET_UPON_COUNTRY_CHANGE.forEach((fieldName) => {
            setFieldValue(fieldName, '');
        });
        const { regions, fetchRegionsForCountry } = this.props;
        if (!regions[countryAbbr] || regions[countryAbbr].length === 0) {
            fetchRegionsForCountry(countryAbbr);
        }
        this.setState({
            timezones: TIMEZONES[countryAbbr] || ALL_TIMEZONES,
            phoneMask: PHONE_MASKS[countryAbbr],
        });
    };

    onCoordinateChange = (setFieldValue, currentValues = {}, event) => {
        const { name, value } = event.target;
        setFieldValue(name, value);
        const hasBothValues =
            currentValues.latitude && currentValues.longitude ? true : '';
        setFieldValue('coordinates', hasBothValues);
    };

    onPhotoChange = (event, setFieldValue) => {
        const files = Array.from(event.target.files);
        const fileURLs = files.map((file) => {
            return {
                photoUrl: URL.createObjectURL(file),
                key: Date.now(),
                file,
            };
        });
        const photos = this.state.photos.concat(fileURLs);
        this.setState({ photos });
        setFieldValue('photos', photos);
    };

    onPhotoClear = (key, setFieldValue) => {
        const photos = this.state.photos.filter((photo) => photo.key !== key);
        this.setState({ photos });
        setFieldValue('photos', photos);
    };

    toggleConfirmationForDeleteDock = (dockName, dockId) => {
        const { showConfirmDialog } = this.state.deletingDock;
        this.setState({
            deletingDock: {
                showConfirmDialog: !showConfirmDialog,
                dockName: dockName || '',
                dockId: dockId || '',
            },
        });
    };

    onDeleteDock = () => {
        const { onDeleteDock, formState: { propertyId } = {} } = this.props;
        const { dockId, dockName } = this.state.deletingDock;
        onDeleteDock && onDeleteDock(propertyId, dockId, dockName);
        this.setState({
            deletingProperty: {
                showConfirmDialog: false,
                dockName: '',
                dockId: '',
            },
        });
    };

    renderUtilizationReportField = (
        {
            name,
            values: {
                propertyName,
                utilReportOwnerConfigs,
                utilizationReportConfigs,
                utilizationReportRecipients,
            },
        },
        { setFieldValue, errors },
    ) => {
        const { classes } = this.props;
        const { utilizationReportRecipients: error } = errors;
        return (
            <Grid container item direction="column">
                <Grid container item alignItems="center">
                    <AdminButton
                        className={classes.reportButton}
                        buttonType={BUTTON_TYPE_POSITIVE}
                        size="large"
                        disabled={
                            this.props.sendingReport || this.props.reportSent
                        }
                        onClick={() =>
                            this.props.onSendReport(utilizationReportRecipients)
                        }
                    >
                        {this.props.sendingReport ? (
                            <CircularProgress size={20} color="primary" />
                        ) : this.props.reportSent ? (
                            'Report Sent!'
                        ) : (
                            'Send Report'
                        )}
                    </AdminButton>
                    {this.props.reportError && (
                        <Typography className={classes.error}>
                            {this.props.reportError}
                        </Typography>
                    )}
                </Grid>
                <Grid
                    container
                    item
                    alignItems="center"
                    className={classes.reportLabelContainer}
                >
                    <Typography>
                        Additional Property Report Recipients
                    </Typography>
                    <Tooltip
                        title={`The report for ${propertyName} will be sent to the Property Owner and these emails`}
                        classes={{ tooltip: classes.tooltip }}
                    >
                        <Info fontSize="small" />
                    </Tooltip>
                </Grid>
                <Grid item className={classes.smallTopMargin}>
                    <TextField
                        classes={{
                            root: classes.reportRecipientsRoot,
                        }}
                        multiline
                        rows={3}
                        variant="outlined"
                        select={false}
                        placeholder="Enter comma-separated list of emails"
                        value={utilizationReportRecipients}
                        onChange={(event) => {
                            setFieldValue(
                                'utilizationReportRecipients',
                                event.target.value,
                            );
                        }}
                        error={!!error}
                        helperText={error}
                        FormHelperTextProps={{
                            classes: {
                                error: classes.helperTextError,
                            },
                        }}
                    />
                </Grid>
                {utilReportOwnerConfigs.length > 0 && (
                    <Grid
                        container
                        item
                        alignItems="center"
                        className={classes.reportLabelContainer}
                    >
                        <Typography>
                            Property Owner&apos;s Report Schedule
                        </Typography>
                        <Tooltip
                            title="A report for ALL of this owner's properties is sent at these times"
                            classes={{ tooltip: classes.tooltip }}
                        >
                            <Info fontSize="small" />
                        </Tooltip>
                    </Grid>
                )}
                {utilReportOwnerConfigs.map((reportConfig, index) => {
                    const { sendTime, timezone } = reportConfig;
                    return (
                        <Grid
                            item
                            key={`owner-report-config-${index}`}
                            component={Typography}
                            color="secondary"
                        >
                            Every day at{' '}
                            {DateTime.fromISO(sendTime).toLocaleString(
                                DateTime.TIME_SIMPLE,
                            )}{' '}
                            ({timezone})
                        </Grid>
                    );
                })}
                <Grid item className={classes.reportConfig}>
                    <AdminButton
                        size="small"
                        buttonType={BUTTON_TYPE_EDIT}
                        onClick={() => {
                            const { value: sendTime } = TIMES_OF_DAY[0];
                            utilizationReportConfigs.push({
                                sendTime,
                                timezone: Intl.DateTimeFormat().resolvedOptions()
                                    .timeZone,
                            });
                            setFieldValue(name, utilizationReportConfigs);
                        }}
                    >
                        Add Property Report Schedule
                    </AdminButton>
                </Grid>
                {utilizationReportConfigs.map((reportConfig, index) => {
                    return (
                        <Grid
                            container
                            item
                            key={`property-report-config-${index}`}
                            className={classes.reportConfig}
                            alignItems="center"
                        >
                            <Typography
                                color="secondary"
                                className={classes.reportConfigPrefix}
                            >
                                Every day at
                            </Typography>
                            <AdminSelect
                                FormControlProps={{
                                    className: classes.reportConfigTime,
                                }}
                                label="Time of day"
                                options={TIMES_OF_DAY}
                                value={reportConfig.sendTime}
                                onChange={(event) => {
                                    utilizationReportConfigs[index] = {
                                        ...reportConfig,
                                        sendTime: event.target.value,
                                    };
                                    setFieldValue(
                                        name,
                                        utilizationReportConfigs,
                                    );
                                }}
                            />
                            <AdminSelect
                                FormControlProps={{
                                    className: classes.reportConfigTimezone,
                                }}
                                SelectDisplayProps={{
                                    className:
                                        classes.reportConfigTimezoneSelect,
                                }}
                                label="Time zone"
                                options={ALL_TIMEZONES}
                                value={reportConfig.timezone}
                                onChange={(event) => {
                                    utilizationReportConfigs[index] = {
                                        ...reportConfig,
                                        timezone: event.target.value,
                                    };
                                    setFieldValue(
                                        name,
                                        utilizationReportConfigs,
                                    );
                                }}
                            />
                            <AdminButton
                                size="small"
                                buttonType={BUTTON_TYPE_NEGATIVE}
                                className={classes.reportConfigDeleteButton}
                                onClick={() => {
                                    setFieldValue(name, [
                                        ...utilizationReportConfigs.slice(
                                            0,
                                            index,
                                        ),
                                        ...utilizationReportConfigs.slice(
                                            index + 1,
                                        ),
                                    ]);
                                }}
                            >
                                <Delete />
                            </AdminButton>
                        </Grid>
                    );
                })}
            </Grid>
        );
    };

    _getFormFields = () => {
        const {
            classes,
            amenities,
            rules,
            regions,
            areRegionsLoading,
            formState: { propertyId },
        } = this.props;
        const { timezones, phoneMask } = this.state;
        return [
            {
                label: 'Approval',
                name: 'propertyActive',
                validation: Yup.boolean().required('* Required'),
                render: (fieldProps) => (
                    <AdminSelect
                        type="large"
                        options={PROPERTY_APPROVAL}
                        {...fieldProps}
                    />
                ),
            },
            {
                name: 'propertyOwner',
                label: 'Owner',
                render: ({ value }) => (
                    <Typography
                        inline
                        color={
                            value &&
                            (value.indexOf(DELETED_USER) > -1 ||
                                value.indexOf(USER_NOT_FOUND) > -1)
                                ? 'error'
                                : 'secondary'
                        }
                        className={classes.ownerValue}
                    >
                        {value}
                    </Typography>
                ),
            },
            {
                label: 'Name',
                name: 'propertyName',
                validation: Yup.string()
                    .ensure()
                    .max(MAX_LENGTH_PROPERTY_NAME)
                    .required(),
                render: (fieldProps) => (
                    <TextField fullWidth required label="" {...fieldProps} />
                ),
            },
            {
                label: 'Description',
                name: 'propertyDescription',
                validation: Yup.string().nullable(),
                render: (fieldProps) => (
                    <MultilineTextField fullWidth label="" {...fieldProps} />
                ),
            },
            {
                label: 'Address',
                name: 'propertyAddress',
                validation: Yup.string()
                    .ensure()
                    .required(),
                render: (fieldProps) => (
                    <TextField fullWidth required label="" {...fieldProps} />
                ),
            },
            {
                label: '',
                name: 'propertyAddressMore',
                render: (fieldProps) => (
                    <TextField fullWidth required label="" {...fieldProps} />
                ),
            },
            {
                label: 'City',
                name: 'propertyCity',
                validation: Yup.string()
                    .ensure()
                    .max(MAX_LENGTH_CITY)
                    .required(),
                render: (fieldProps) => (
                    <TextField fullWidth required label="" {...fieldProps} />
                ),
            },
            {
                label: 'State',
                name: 'regionAbbr',
                validation: Yup.mixed().required(),
                render: (fieldProps) => {
                    const {
                        values: { country: { countryAbbr } = {} } = {},
                    } = fieldProps;
                    return (
                        <AdminSelect
                            type="large"
                            className={classes.stateSelect}
                            loading={areRegionsLoading}
                            options={
                                regions[
                                    countryAbbr || COUNTRY_ABBR_UNITED_STATES
                                ]
                            }
                            {...fieldProps}
                        />
                    );
                },
            },
            {
                label: 'Zip',
                name: 'propertyZipcode',
                validation: Yup.string()
                    .ensure()
                    .max(MAX_LENGTH_ZIPCODE)
                    .required(),
                render: (fieldProps) => (
                    <TextField fullWidth required label="" {...fieldProps} />
                ),
            },
            {
                name: 'countryAbbr',
                label: 'Country',
                render: (
                    { values: { country: { countryAbbr = '' } = {} } },
                    { setFieldValue },
                ) => {
                    return (
                        <AdminSelect
                            className={classes.countrySelect}
                            options={COUNTRY_NAMES_AND_ABBREVIATIONS}
                            value={countryAbbr || COUNTRY_ABBR_UNITED_STATES}
                            onChange={(event) =>
                                this.onCountryChange(setFieldValue, event)
                            }
                        />
                    );
                },
            },
            {
                name: 'coordinates',
                label: 'Location',
                validation: Yup.boolean().required(),
                render: (
                    { values: { latitude, longitude }, error },
                    { setFieldValue },
                ) => {
                    return (
                        <CoordinatesInput
                            latitude={latitude}
                            longitude={longitude}
                            error={error}
                            onChange={(event) =>
                                this.onCoordinateChange(
                                    setFieldValue,
                                    { latitude, longitude },
                                    event,
                                )
                            }
                        />
                    );
                },
            },
            {
                name: 'timezone',
                label: 'Timezone',
                validation: Yup.mixed().required(),
                render: (fieldProps) => (
                    <AdminSelect
                        className={classes.timezoneSelect}
                        options={timezones}
                        {...fieldProps}
                    />
                ),
            },
            {
                label: 'Phone',
                name: 'propertyPhoneNumber',
                validation: Yup.string()
                    .nullable()
                    .matches(PHONE_REG_EXP, 'Phone Number is not valid.', {
                        excludeEmptyString: true,
                    }),
                render: (fieldProps) => {
                    return (
                        <InputMask mask={phoneMask} {...fieldProps}>
                            {() => (
                                <TextField fullWidth required {...fieldProps} />
                            )}
                        </InputMask>
                    );
                },
            },
            {
                label: 'Photos',
                name: 'photos',
                render: (fieldProps, { setFieldValue }) => (
                    <Photos
                        {...fieldProps} // Field props has to go first so we can override onChange with onPhotoChange.
                        onChange={(event) =>
                            this.onPhotoChange(event, setFieldValue)
                        }
                        onPhotoClear={(key) =>
                            this.onPhotoClear(key, setFieldValue)
                        }
                    />
                ),
            },
            ...(propertyId
                ? [
                      {
                          label: 'Created',
                          name: 'createdAt',
                          render: ({ value, name }) => {
                              return (
                                  <Typography
                                      inline
                                      color="primary"
                                      name={name}
                                  >
                                      {value && formatDate(value)}
                                  </Typography>
                              );
                          },
                      },
                  ]
                : []),
            {
                label: 'Amenities',
                name: 'amenityIds',
                render: ({ values: { amenityIds } }, { setFieldValue }) => (
                    <AdminCheckboxes
                        checkboxes={amenities}
                        checked={amenityIds}
                        CheckboxProps={{
                            onChange: (event) =>
                                this.onCheckboxChange(
                                    setFieldValue,
                                    'amenityIds',
                                    amenityIds,
                                    event,
                                ),
                        }}
                    />
                ),
            },
            {
                label: 'Rules',
                name: 'ruleIds',
                render: ({ values: { ruleIds } }, { setFieldValue }) => (
                    <AdminCheckboxes
                        checkboxes={rules}
                        checked={ruleIds}
                        CheckboxProps={{
                            onChange: (event) =>
                                this.onCheckboxChange(
                                    setFieldValue,
                                    'ruleIds',
                                    ruleIds,
                                    event,
                                ),
                        }}
                    />
                ),
            },
            ...(propertyId
                ? [
                      {
                          name: 'utilizationReportRecipients',
                          validation: Yup.string()
                              .ensure()
                              .max(
                                  1000,
                                  'List of email recipients is too long.',
                              ),
                      },
                      {
                          name: 'utilizationReportConfigs',
                          label: 'Utilization Report',
                          labelWrapperClass: classes.topLabel,
                          render: this.renderUtilizationReportField,
                      },
                      {
                          name: 'docks',
                          label: 'Spots',
                          labelWrapperClass: classes.topLabel,
                          render: () => {
                              const { docks } = this.props.formState;
                              return (
                                  Array.isArray(docks) &&
                                  docks.map(
                                      ({ dockName, dockId, propertyId }) => {
                                          return (
                                              <ActionableListRow
                                                  key={dockId}
                                                  label={dockName}
                                                  PositiveButtonProps={{
                                                      to: `/properties/${propertyId}/dock/${dockId}`,
                                                      component: Link,
                                                  }}
                                                  onNegativeAction={() =>
                                                      this.toggleConfirmationForDeleteDock(
                                                          dockName,
                                                          dockId,
                                                      )
                                                  }
                                              />
                                          );
                                      },
                                  )
                              );
                          },
                      },
                      {
                          name: 'addDock',
                          label: '',
                          render: ({ values: { propertyId } }) => {
                              return (
                                  <AdminButton
                                      buttonType={BUTTON_TYPE_EDIT}
                                      size="large"
                                      fullWidth
                                      component={Link}
                                      to={`/properties/${propertyId}/dock`}
                                  >
                                      <AddCircleOutline
                                          className={classes.addButtonIcon}
                                      />
                                      Add Spot
                                  </AdminButton>
                              );
                          },
                      },
                  ]
                : []),
        ];
    };

    render() {
        const {
            formState,
            loading,
            onSubmit,
            onDelete,
            messages,
            removeFormMessage,
        } = this.props;
        const { propertyId, propertyName } = formState;
        const { deletingDock } = this.state;
        const fields = this._getFormFields();
        return (
            <Fragment>
                <DetailForm
                    initFormState={formState}
                    fields={fields}
                    deleteButtonText="Delete Property"
                    showDelete={!!propertyId}
                    submitButtonText={
                        propertyId ? 'Update Property' : 'Add Property'
                    }
                    onSubmit={onSubmit}
                    onDelete={onDelete}
                    messages={messages}
                    loading={loading}
                    confirmDialogTitle="Action Confirmation"
                    confirmDialogText={`You are attempting to delete ${propertyName ||
                        'a property'} from your database`}
                    confirmDialogSubText="This action will permanently delete this property from your RigPark database."
                    removeFormMessage={removeFormMessage}
                    FormProps={{ validateOnChange: !!propertyId }}
                />
                <ConfirmDialog
                    open={deletingDock.showConfirmDialog}
                    onConfirm={this.onDeleteDock}
                    onCancel={this.toggleConfirmationForDeleteDock}
                    title="Confirm Property Delete"
                    text={`You are attempting to delete ${deletingDock.dockName}`}
                    subText="This action will permanently delete this spot from your RigPark database."
                />
            </Fragment>
        );
    }
}

PropertyForm.propTypes = {
    classes: PropTypes.object.isRequired,

    // Function that will be called to send a utilization report
    onSendReport: PropTypes.func.isRequired,
    sendingReport: PropTypes.bool,
    reportSent: PropTypes.bool,
    reportError: PropTypes.string,

    // Function that will be called when the form is submitted.
    onSubmit: PropTypes.func.isRequired,

    // Function that will be called when the delete button is pressed.
    onDelete: PropTypes.func.isRequired,

    // Function that will be called when the Delete Dock button is pressed.
    onDeleteDock: PropTypes.func.isRequired,

    // Function called when Edit Dock button is pressed.
    onEditDock: PropTypes.func.isRequired,

    // Function called when the country changes if the regions for that country are not yet loaded.
    fetchRegionsForCountry: PropTypes.func.isRequired,

    loading: PropTypes.bool,

    // So you can supply an initial state of the form, if any.
    formState: PropTypes.shape({
        propertyId: PropTypes.number,
        propertyName: PropTypes.string,
        propertyDescription: PropTypes.string,
        propertyOwner: PropTypes.string,
        propertyAddress: PropTypes.string,
        propertyAddress2: PropTypes.string,
        propertyState: PropTypes.string,
        propertyZip: PropTypes.number,
        propertyWidth: PropTypes.number,
        propertyHeight: PropTypes.number,
        photos: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
                photoUrl: PropTypes.string,
                uploadId: PropTypes.number,
            }),
        ),
        createdAt: PropTypes.string,
        docks: PropTypes.array,
    }),

    // Messages to be displayed on the form, if any.
    messages: formMessagesPropTypes.messages,
    removeFormMessage: PropTypes.func.isRequired,

    // Amenities for use in the form.
    amenities: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            name: PropTypes.number.isRequired,
        }),
    ).isRequired,

    // Rules for use in the form.
    rules: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            name: PropTypes.number.isRequired,
        }),
    ).isRequired,

    // Regions aka states for the state select in the form.
    regions: PropTypes.object.isRequired,
    areRegionsLoading: PropTypes.bool.isRequired,
};

PropertyForm.defaultProps = {
    formState: { photos: [] },
    loading: false,
    sendingReport: false,
    reportSent: false,
    reportError: '',
};

export default withStyles(styles)(PropertyForm);
