export const regionsInitialState = {
    data: {},
    loading: false,
};

/*
 * The data object above will be an object
 * with properties that represent country abbreviations,
 * and the value of the property being an array of the country's regions.
 * data = {
 *          [countryAbbr]: [{ regionAbbr, regionName, regionId }],
 *        };
 *
 * Example for the value of data
 * data = {
 *          US: [
 *                  { regionName: 'North Carolina', regionAbbr: 'NC', regionId: 1 },
 *                  { regionName: 'West Virginia', regionAbbr: 'WV', regionId: 2 },
 *                  ...
 *               ]
 *          };
 */
