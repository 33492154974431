import { PROPERTY_ID, PROPERTY_NAME } from './constants';
import { ORDER_BY_DIR_DESC } from '../../constants/api';

export const initialFiltersState = {
    docks: {
        min: null,
        max: null,
    },
    reservations: {
        min: null,
        max: null,
    },
    checked: [],
    start: {},
    end: {},
    selectedStates: [],
    selectedCountryAbbr: '',
};

export default {
    // Properties list loadings state and data
    list: {
        loading: false,
        page: {
            filters: initialFiltersState,
            filterErrors: [],
            order: [[PROPERTY_ID, ORDER_BY_DIR_DESC]],
        },
        errors: [],
    },

    ownerList: {
        loading: false,
        page: {
            filters: {
                search: '',
            },
            filterErrors: [],
            order: [[PROPERTY_NAME, ORDER_BY_DIR_DESC]],
        },
    },

    // Property detail
    propertyLoading: false,
    creating: false,
    photoUpdateInProgress: false,
    dockDeleteInProgress: false,
    property: {},
    messages: [],

    // Property owner report
    loadingReport: false,
    report: {},

    // Utilization report
    sendingReport: false,
    reportSent: false,
    reportFailed: '',

    // For tracking propertyIds that have pending API changes in progress.
    loadingUpdate: [],

    // New property owner info
    ownerLoading: false,
    owner: {},

    // For multi delete properties errors
    multiDeleteErrors: [],
};
