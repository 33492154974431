import { all, put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    DASHBOARD_LOAD_REQUESTED,
    FETCH_USERS_REQUESTED,
    fetchUsersRequested,
    fetchUsersSucceeded,
    fetchUsersFailed,
    FETCH_PROPERTIES_REQUESTED,
    fetchPropertiesRequested,
    fetchPropertiesSucceeded,
    fetchPropertiesFailed,
    FETCH_NEW_USERS_COUNT_REQUESTED,
    fetchNewUsersCountRequested,
    fetchNewUsersCountFailed,
    fetchNewUsersCountSucceeded,
    FETCH_NEW_PROPERTIES_COUNT_REQUESTED,
    fetchNewPropertiesCountRequested,
    fetchNewPropertiesCountFailed,
    fetchNewPropertiesCountSucceeded,
    fetchReservationsFailed,
    fetchReservationsSucceeded,
    fetchNewReservationsCountFailed,
    fetchNewReservationsCountSucceeded,
    FETCH_RESERVATIONS_REQUESTED,
    FETCH_NEW_RESERVATIONS_COUNT_REQUESTED,
    fetchReservationsRequested,
    fetchNewReservationsCountRequested,
} from './actions';
import {
    getStartOfTodayTimestamp,
    getEndOfTodayTimestamp,
} from '../../utils/dates';
import { makeRequestWithAuth } from '../api/sagas';
import { MAX_ITEMS_DASHBOARD } from '../../dashboard/constants';
import { USER_ID } from '../users/constants';
import { ORDER_BY_DIR_DESC } from '../../constants/api';
import { PROPERTY_ID } from '../properties/constants';
import { RESERVATION_ID } from '../reservations/constants';

export function* loadDashboard() {
    yield put(fetchUsersRequested());
    yield put(fetchNewUsersCountRequested());
    yield put(fetchPropertiesRequested());
    yield put(fetchNewPropertiesCountRequested());
    yield put(fetchReservationsRequested());
    yield put(fetchNewReservationsCountRequested());
}

export function* fetchDashboardUsers() {
    yield call(makeRequestWithAuth, {
        path: '/user',
        method: 'GET',
        query: {
            page: 1,
            perPage: MAX_ITEMS_DASHBOARD,
            order: [[USER_ID, ORDER_BY_DIR_DESC]],
        },
        actions: {
            succeeded: ({ data: users = [], total = 0 } = {}) => {
                return fetchUsersSucceeded(users, total);
            },
            failed: () => fetchUsersFailed(),
        },
    });
}

export function* fetchDashboardNewUsersCount() {
    yield call(makeRequestWithAuth, {
        path: '/user',
        method: 'GET',
        query: {
            created: [getStartOfTodayTimestamp(), getEndOfTodayTimestamp()],
        },
        actions: {
            succeeded: ({ total = 0 }) => fetchNewUsersCountSucceeded(total),
            failed: () => fetchNewUsersCountFailed(),
        },
    });
}

export function* fetchDashboardProperties() {
    yield call(makeRequestWithAuth, {
        path: '/property',
        method: 'GET',
        query: {
            page: 1,
            perPage: MAX_ITEMS_DASHBOARD,
            order: [[PROPERTY_ID, ORDER_BY_DIR_DESC]],
        },
        actions: {
            succeeded: ({ data: properties = [], total = 0 } = {}) => {
                return fetchPropertiesSucceeded(properties, total);
            },
            failed: () => fetchPropertiesFailed(),
        },
    });
}

export function* fetchDashboardNewPropertiesCount() {
    yield call(makeRequestWithAuth, {
        path: '/property',
        method: 'GET',
        query: {
            createdAt: [getStartOfTodayTimestamp(), getEndOfTodayTimestamp()],
        },
        actions: {
            succeeded: ({ total = 0 }) =>
                fetchNewPropertiesCountSucceeded(total),
            failed: () => fetchNewPropertiesCountFailed(),
        },
    });
}

export function* fetchDashboardReservations() {
    yield call(makeRequestWithAuth, {
        path: '/reservation/upcoming',
        method: 'GET',
        query: {
            page: 1,
            perPage: MAX_ITEMS_DASHBOARD,
            order: [[RESERVATION_ID, ORDER_BY_DIR_DESC]],
        },
        actions: {
            succeeded: ({ data: reservations = [], total = 0 } = {}) => {
                return fetchReservationsSucceeded(reservations, total);
            },
            failed: () => fetchReservationsFailed(),
        },
    });
}

export function* fetchDashboardNewReservationsCount() {
    yield call(makeRequestWithAuth, {
        path: '/reservation/upcoming',
        method: 'GET',
        query: {
            reservedAt: [getStartOfTodayTimestamp(), getEndOfTodayTimestamp()],
        },
        actions: {
            succeeded: ({ total = 0 }) =>
                fetchNewReservationsCountSucceeded(total),
            failed: () => fetchNewReservationsCountFailed(),
        },
    });
}

export default function*() {
    yield all([
        takeEvery(DASHBOARD_LOAD_REQUESTED, loadDashboard),
        takeLatest(FETCH_USERS_REQUESTED, fetchDashboardUsers),
        takeLatest(FETCH_PROPERTIES_REQUESTED, fetchDashboardProperties),
        takeLatest(
            FETCH_NEW_USERS_COUNT_REQUESTED,
            fetchDashboardNewUsersCount,
        ),
        takeLatest(
            FETCH_NEW_PROPERTIES_COUNT_REQUESTED,
            fetchDashboardNewPropertiesCount,
        ),
        takeLatest(FETCH_RESERVATIONS_REQUESTED, fetchDashboardReservations),
        takeLatest(
            FETCH_NEW_RESERVATIONS_COUNT_REQUESTED,
            fetchDashboardNewReservationsCount,
        ),
    ]);
}
