import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import AdminContainer from '../../common/AdminContainer';
import VehicleForm from '../VehicleForm';
import {
    getVehicleDetails,
    isUserDetailLoading,
} from '../../stores/users/selectors';
import { FETCH_USER_ACTIONS } from '../../stores/users/apiActions';
import {
    removeFormMessage,
    updateVehiclePhotosRequested,
} from '../../stores/vehicles/actions';
import {
    areVehicleUpdatesInProgress,
    getMessages,
} from '../../stores/vehicles/selectors';
import { formMessagesPropTypes } from '../../common/FormMessages';

class VehicleDetailsContainer extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        const { vehicle, userId, vehicleId, fetchUser } = this.props;
        if (!vehicle || vehicleId !== vehicle.vehicleId) {
            fetchUser(userId);
        }
    }

    renderForm = () => {
        const {
            vehicle = {},
            loading,
            onSubmit,
            vehicleId,
            messages,
            removeFormMessage,
        } = this.props;

        return loading ? (
            <CircularProgress />
        ) : (
            <VehicleForm
                formState={{ ...vehicle }}
                onSubmit={onSubmit}
                loading={loading}
                vehicleId={vehicleId}
                messages={messages}
                removeFormMessage={removeFormMessage}
            />
        );
    };

    render() {
        const { path, userId, ...rest } = this.props;

        return (
            <AdminContainer
                path={path}
                innerHeader
                innerHeaderProps={{
                    title: 'Vehicle',
                    backText: '< Back to User',
                    backTo: `/users/${userId}`,
                }}
                {...rest}
            >
                {this.renderForm()}
            </AdminContainer>
        );
    }
}

VehicleDetailsContainer.propTypes = {
    // The path which should be passed down from the router.
    path: PropTypes.string.isRequired,

    // The ID of the vehicle in the view, a route parameter.
    vehicleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    // The ID of the user who owns the vehicle being viewed, a route parameter.
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    // Indication of whether or not the vehicle data is loading.
    loading: PropTypes.bool,

    // The vehicle in the view
    vehicle: PropTypes.object,

    // Messages to be displayed on the form, if any.
    messages: formMessagesPropTypes.messages,

    removeFormMessage: PropTypes.func.isRequired,

    // Fetch user function
    fetchUser: PropTypes.func.isRequired,

    // Submit function
    onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = function(state) {
    return {
        loading:
            isUserDetailLoading(state) || areVehicleUpdatesInProgress(state),
        vehicle: getVehicleDetails(state),
        messages: getMessages(state),
    };
};

const mapDispatchToProps = function(dispatch) {
    return {
        fetchUser: (userId) => dispatch(FETCH_USER_ACTIONS.requested(userId)),
        onSubmit: (vehicleId, photos) =>
            dispatch(updateVehiclePhotosRequested(vehicleId, photos)),
        removeFormMessage: (messageKey) =>
            dispatch(removeFormMessage(messageKey)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    VehicleDetailsContainer,
);
