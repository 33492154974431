export default (theme) => ({
    labelWrapper: {
        marginBottom: 15,
        minWidth: 150,
    },
    label: {
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        letterSpacing: 4,
        alignSelf: 'center',
        marginLeft: 'auto',
        flex: 1,
    },
    field: {
        flex: 3,
    },
});
