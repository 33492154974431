import {
    INITIALIZATION_STATE_LOADING,
    INITIALIZATION_STATE_SUCCEEDED,
    USER_GROUP_ADMIN,
    USER_GROUP_OWNER,
} from './constants';

/**
 * Selector to determine if a user is considered as logged in.
 * @param state
 * @returns {boolean}
 */
export const isUserLoggedIn = (state) => {
    return state.auth.validUser;
};

/**
 * Get the current firebase user stored in the auth store.
 *
 * @param state
 * @returns {null}
 */
export const getCurrentUser = (state) => state.auth.firebaseUser;

/**
 * Get the current logged in user's userId.
 * @param state
 * @returns {*}
 */
export const getCurrentUserId = (state) => state.auth.userId;

export const isUserAdmin = (state) => state.auth.groupName === USER_GROUP_ADMIN;
export const isUserOwner = (state) => state.auth.groupName === USER_GROUP_OWNER;

/**
 * Returns true if the app is considered as initialized.
 *
 * @param state
 * @returns {boolean}
 */
export const isInitialized = (state) =>
    state.auth.initializationState === INITIALIZATION_STATE_SUCCEEDED;

/**
 * Returns true if the app is still initializing.
 * @param state
 * @returns {boolean}
 */
export const isInitializing = (state) =>
    state.auth.initializationState === INITIALIZATION_STATE_LOADING;
