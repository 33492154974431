export default (theme) => ({
    paginationContainer: {
        minHeight: 100,
    },
    pagination: {
        listStyle: 'none',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 50,
        textAlign: 'center',
        padding: 0,
    },
    item: {
        width: 35,
        minWidth: 0,
        height: 'auto',
        minHeight: 0,
        fontSize: 18,
        letterSpacing: 0,
        fontWeight: 'normal',
        opacity: 0.3,
    },
    activeItem: {
        fontWeight: 'bold',
        opacity: 1,
    },
    pageSizeSelectRoot: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: 3,
        height: 25,
    },
    pageSizeSelectIcon: {
        color: theme.palette.secondary.main,
        fontSize: 16,
    },
    pageSizeSelectDisplay: {
        padding: 5,
        color: theme.palette.secondary.main,
        fontSize: theme.custom.paginationFontSize,
        height: 25,
        width: 46,
    },
    paginationText: {
        fontSize: theme.custom.paginationFontSize,
    },
    prevNextArrows: {
        marginTop: 4,
    },
    prevArrow: {
        marginLeft: 25,
    },
});
