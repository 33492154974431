/**
 * Transforms the amenities retrieved from the API into objects
 * with name and label to be used with AdminCheckboxes in the property
 * details form that is used for creating and updating properties.
 * @param amenities
 * @returns {{name, label}[]}
 */
export const transformAmenitiesForCheckboxesInForm = (amenities = []) => {
    return amenities.map((amenity) => {
        const { amenityId, amenityName } = amenity;
        return {
            label: amenityName,
            name: amenityId,
        };
    });
};
