import {
    createApiPageRequestActionCreators,
    makeActionCreator,
} from '../../utils/api';
import { TYPES_PREFIX } from './constants';

export const USERS_LIST_VIEW_LOAD_REQUESTED = `${TYPES_PREFIX}USERS_LIST_VIEW_LOAD_REQUESTED`;
export const USER_DETAIL_VIEW_LOAD_REQUESTED = `${TYPES_PREFIX}USER_DETAIL_VIEW_LOAD_REQUESTED`;

export const FETCH_USERS_LIST_PAGE_REQUESTED = `${TYPES_PREFIX}FETCH_USERS_LIST_PAGE_REQUESTED`;
export const FETCH_USERS_LIST_PAGE_SUCCEEDED = `${TYPES_PREFIX}FETCH_USERS_LIST_PAGE_SUCCEEDED`;
export const FETCH_USERS_LIST_PAGE_FAILED = `${TYPES_PREFIX}FETCH_USERS_LIST_PAGE_FAILED`;

export const FETCH_USER_PAYMENT_METHODS_REQUESTED = `${TYPES_PREFIX}/FETCH_USER_PAYMENT_METHODS_REQUESTED`;
export const FETCH_USER_PAYMENT_METHODS_SUCCEEDED = `${TYPES_PREFIX}FETCH_USER_PAYMENT_METHODS_SUCCEEDED`;
export const FETCH_USER_PAYMENT_METHODS_FAILED = `${TYPES_PREFIX}FETCH_USER_PAYMENT_METHODS_FAILED`;

export const SEND_USER_REPORT_REQUESTED = `${TYPES_PREFIX}SEND_USER_REPORT_REQUESTED`;
export const SEND_USER_REPORT_SUCCEEDED = `${TYPES_PREFIX}SEND_USER_REPORT_SUCCEEDED`;
export const SEND_USER_REPORT_FAILED = `${TYPES_PREFIX}SEND_USER_REPORT_FAILED`;

export const SET_FORM_MESSAGES = `${TYPES_PREFIX}SET_FORM_MESSAGES`;
export const CLEAR_FORM_MESSAGES = `${TYPES_PREFIX}CLEAR_FORM_MESSAGES`;

export const CLEANUP_DETAILED_STATE = `${TYPES_PREFIX}CLEANUP_DETAILED_STATE`;

export const UPDATE_USERS_LIST_FILTERS = `${TYPES_PREFIX}UPDATE_USERS_LIST_FILTERS`;
export const CLEAR_USERS_LIST_FILTERS = `${TYPES_PREFIX}CLEAR_USERS_LIST_FILTERS`;
export const FILTERS_VALIDATION_FAILED = `${TYPES_PREFIX}FILTERS_VALIDATION_FAILED`;

export const UPDATE_FILTER_BY_VEHICLE_FILTERS = `${TYPES_PREFIX}UPDATE_FILTER_BY_VEHICLE_FILTERS`;
export const CLEAR_FILTER_BY_VEHICLE_FILTERS = `${TYPES_PREFIX}CLEAR_FILTER_BY_VEHICLE_FILTERS`;

export const REMOVE_FORM_MESSAGE = `${TYPES_PREFIX}REMOVE_FORM_MESSAGE`;

export const fetchUserPaymentMethodsRequested = (userId) => ({
    type: FETCH_USER_PAYMENT_METHODS_REQUESTED,
    userId,
});

export const fetchUserPaymentMethodsSucceeded = (paymentMethods) => ({
    type: FETCH_USER_PAYMENT_METHODS_SUCCEEDED,
    paymentMethods,
});

export const fetchUserPaymentMethodsFailed = () => ({
    type: FETCH_USER_PAYMENT_METHODS_FAILED,
});

export const usersListViewLoadRequested = () => ({
    type: USERS_LIST_VIEW_LOAD_REQUESTED,
});

export const userDetailViewLoadRequested = () => ({
    type: USER_DETAIL_VIEW_LOAD_REQUESTED,
});

export const {
    requested: fetchUsersListPageRequested,
    succeeded: fetchUsersListPageSucceeded,
    failed: fetchUsersListPageFailed,
} = createApiPageRequestActionCreators({
    requested: FETCH_USERS_LIST_PAGE_REQUESTED,
    succeeded: FETCH_USERS_LIST_PAGE_SUCCEEDED,
    failed: FETCH_USERS_LIST_PAGE_FAILED,
});

export const sendUserReportRequested = (userId, recipients) => ({
    type: SEND_USER_REPORT_REQUESTED,
    userId,
    recipients,
});
export const sendUserReportSucceeded = () => ({
    type: SEND_USER_REPORT_SUCCEEDED,
});
export const sendUserReportFailed = (error) => ({
    type: SEND_USER_REPORT_FAILED,
    error,
});

export const setFormMessages = makeActionCreator(SET_FORM_MESSAGES, 'messages');
export const clearFormMessages = makeActionCreator(CLEAR_FORM_MESSAGES);
export const cleanupDetailedUserState = makeActionCreator(
    CLEANUP_DETAILED_STATE,
);

export const updateUsersListFilters = (filters) => {
    return {
        type: UPDATE_USERS_LIST_FILTERS,
        filters,
    };
};

export const updateFilterByVehicleFilters = (vehicleFilters) => {
    return {
        type: UPDATE_FILTER_BY_VEHICLE_FILTERS,
        vehicleFilters,
    };
};

export const clearUsersListFilters = () => {
    return {
        type: CLEAR_USERS_LIST_FILTERS,
    };
};

export const filtersValidationFailed = (filterErrors, filters) => {
    return {
        type: FILTERS_VALIDATION_FAILED,
        filterErrors,
        filters,
    };
};

export const removeFormMessage = (messageKey) => {
    return {
        type: REMOVE_FORM_MESSAGE,
        messageKey,
    };
};
