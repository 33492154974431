import { useEffect, useState } from 'react';

export const useMobileThreshold = (threshold) => {
    const checkDesktopViewport = () => window.innerWidth < threshold;
    const [isMobile, setIsMobile] = useState(checkDesktopViewport());
    const updateMedia = () => setIsMobile(checkDesktopViewport());

    useEffect(() => {
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    });

    return isMobile;
};
