import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Grid, Typography, withStyles } from '@material-ui/core';
import MessagePaper from '../MessagePaper';
import styles from './styles';
import { Clear as CloseIcon } from '@material-ui/icons';
import { noop } from '../../utils';

export const TYPE_ERROR = 'error';
export const TYPE_SUCCESS = 'success';
export const TYPE_INFO = 'info';

class FormMessages extends Component {
    state = { closed: [] };

    onClose = (key) => {
        this.setState({ closed: [...this.state.closed, key] });
        const { onClose } = this.props;
        if (onClose) {
            onClose(key);
        }
    };

    render() {
        const { classes, messages } = this.props;
        const { closed } = this.state;
        return (
            <Grid container direction="column">
                {!messages
                    ? null
                    : messages.map((messageItem) => {
                          const { key, message, type } = messageItem;
                          return closed.includes(key) ? null : (
                              <MessagePaper
                                  key={key}
                                  type={type}
                                  withGutter={messages.length > 1}
                              >
                                  <Grid
                                      item
                                      container
                                      direction="row"
                                      key={key}
                                      justify="center"
                                      alignItems="center"
                                  >
                                      <Grid
                                          item
                                          className={classes.messageGridItem}
                                      >
                                          <Typography
                                              className={classes.messageText}
                                          >
                                              {message}
                                          </Typography>
                                      </Grid>
                                      <Grid item>
                                          <IconButton
                                              className={
                                                  classes.closeIconButton
                                              }
                                              onClick={() => this.onClose(key)}
                                          >
                                              <CloseIcon
                                                  className={classes.closeIcon}
                                              />
                                          </IconButton>
                                      </Grid>
                                  </Grid>
                              </MessagePaper>
                          );
                      })}
            </Grid>
        );
    }
}

export const formMessagesPropTypes = {
    // From withStyles we expect to get classes.
    classes: PropTypes.object.isRequired,

    // Messages to be displayed must each include a key, the message and its types.
    messages: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            message: PropTypes.string.isRequired,
            type: PropTypes.oneOf([TYPE_ERROR, TYPE_INFO, TYPE_SUCCESS])
                .isRequired,
        }),
    ).isRequired,

    onClose: PropTypes.func,
};

FormMessages.propTypes = formMessagesPropTypes;
FormMessages.defaultProps = {
    onClose: noop,
};

export default withStyles(styles)(FormMessages);
