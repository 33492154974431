import { Grid, Typography, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AdminAvatar from '../AdminAvatar';
import styles from './styles';

class SearchItem extends Component {
    render() {
        const {
            classes,
            imageUrl,
            resultHeader,
            containerClass,
            avatarClass,
        } = this.props;
        return (
            <Grid
                container
                item
                direction="row"
                className={clsx(classes.container, containerClass)}
            >
                <Grid container item className={avatarClass}>
                    <AdminAvatar src={imageUrl} />
                </Grid>
                <Typography className={classes.resultHeader}>
                    {resultHeader}
                </Typography>
            </Grid>
        );
    }
}

SearchItem.propTypes = {
    // From withStyles
    classes: PropTypes.object,

    // Result image
    imageUrl: PropTypes.string,

    // The text to display
    resultHeader: PropTypes.string,

    // Extra container styles
    containerClass: PropTypes.string,

    // Extra avatar styles
    avatarClass: PropTypes.string,
};

export default withStyles(styles)(SearchItem);
