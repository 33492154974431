/* eslint-disable no-undefined */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'ramda';
import { withStyles, Typography, Grid } from '@material-ui/core';
import styles from './styles';
import UserVehiclePhoto from './UserVehiclePhoto';
import DetailForm from '../../common/DetailForm';
import { formMessagesPropTypes } from '../../common/FormMessages';
import ReadOnlyTextField from './ReadOnlyTextField';
import AdminSelect from '../../common/AdminSelect';
import * as Yup from 'yup';
import {
    CREATE_RESERVATION_TITLE,
    START,
    END,
    MONTH,
    DAY,
    YEAR,
    TIME,
    UPDATE_RESERVATION_TITLE,
} from '../ReservationsListContainer/constants';
import AdminButton, { BUTTON_TYPE_EDIT } from '../../common/AdminButton';
import ReservationUserSearch from '../ReservationUserSearch';
import { transformUserVehiclesForBookingReservation } from '../../stores/users/transforms';
import SearchResultHeader from '../../common/SearchResultHeader';
import FilterDatePicker from '../../common/FilterDatePicker';
import { DateTime } from 'luxon';
import {
    RATE_TYPES,
    RATE_TYPE_DAILY,
    RATE_TYPE_HOURLY,
    RATE_TYPE_MONTHLY,
} from '../../docks/DockForm/constants';
import AdminCheckboxes from '../../common/AdminCheckboxes';
import { getReservationTotal } from '../../utils/reservations';
import TextField from '../../common/TextField';

class ReservationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photoUrl: props.existingReservation.photoUrl,
            photoFile: null,
            propertyId: props.existingReservation.propertyId,
            dockId: props.existingReservation.dockId,

            // booking reservations
            searchUser: false,
            selectedUser: {},
            userVehicles: [],
            start: {
                month: DateTime.local().get('month'),
                year: DateTime.local().get('year'),
            },
            end: {
                month: DateTime.local().get('month'),
                year: DateTime.local().get('year'),
            },
            selectedRateType: '',
        };
    }

    onSubmit = (data) => {
        this.props.onSubmit && this.props.onSubmit(data);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            properties,
            existingReservation,
            fetchUserPaymentMethods,
        } = this.props;
        // Received property data, now we can set the filtered docks
        if (
            prevProps.properties.length !== properties.length &&
            existingReservation.propertyId
        ) {
            this.findAndSetDocks(properties, existingReservation.propertyId);
        }

        if (
            prevState.selectedUser.id !== this.state.selectedUser.id &&
            this.state.selectedUser.id
        ) {
            fetchUserPaymentMethods(this.state.selectedUser.id);
        }
    }

    componentDidMount() {
        const { properties, existingReservation } = this.props;

        if (properties.length > 0 && existingReservation.propertyId) {
            this.findAndSetDocks(properties, existingReservation.propertyId);
        }
    }

    findAndSetDocks(properties, propertyId) {
        this.setState({
            docks: properties.find((property) => property.value === propertyId)
                .docks,
        });
    }

    toggleUserSearch = () => {
        this.setState({ searchUser: !this.state.searchUser });
    };

    userSelected = (user) => {
        this.setState({ ...this.state, selectedUser: user, searchUser: false });
    };

    getUserVehicleOptions = () => {
        return transformUserVehiclesForBookingReservation(
            this.state.selectedUser,
        );
    };

    handlePropertyChange = (e, setFieldValue) => {
        const { propertyId: currentPropertyId } = this.state;
        const { fetchPropertyDockInfo } = this.props;
        const propertyId = e.target.value;
        const docks = this.props.properties.find(
            (property) => property.value === propertyId,
        ).docks;
        if (propertyId && propertyId !== currentPropertyId) {
            fetchPropertyDockInfo(propertyId);
        }
        setFieldValue('propertyId', propertyId);
        this.setState({
            ...this.state,
            propertyId,
            dockId: null,
            docks,
        });
    };

    handleDockSelect = (e, setFieldValue) => {
        const dockId = e?.target?.value;
        setFieldValue('dockId', dockId);
        this.setState({ dockId });
    };

    onDatePickerChange = (event, startOrEnd, field) => {
        const currentFieldState =
            startOrEnd === START ? this.state.start : this.state.end;
        const newVal =
            event.target && event.target.value ? event.target.value : event;
        this.setState({
            [startOrEnd]: {
                ...currentFieldState,
                [field]: newVal,
            },
        });
    };

    onRateTypeChanged = (event, setFieldValue) => {
        const rateType = event.target.name;
        const now = DateTime.local();
        const day = now.get('day');
        const month = now.get('month');
        const year = now.get('year');
        if (rateType === RATE_TYPE_DAILY) {
            this.setState({
                selectedRateType: rateType,
                start: { time: now, day, month, year },
                end: {
                    time: now,
                    day: now.plus({ day: 1 }).get('day'),
                    month,
                    year,
                },
            });
        } else if (rateType === RATE_TYPE_HOURLY) {
            this.setState({
                selectedRateType: rateType,
                start: { time: now, day, month, year },
                end: { time: now.plus({ minutes: 240 }), day, month, year },
            });
        } else if (rateType === RATE_TYPE_MONTHLY) {
            this.setState({
                selectedRateType: rateType,
                start: {
                    time: now.startOf('day'),
                    day: now.startOf('month').get('day'),
                    month,
                    year,
                },
                end: {
                    time: now.endOf('day'),
                    day: now.endOf('month').get('day'),
                    month,
                    year,
                },
            });
        }
        setFieldValue('rateType', rateType);
    };

    _getFormFields = () => {
        const {
            existingReservation,
            classes,
            properties,
            userPaymentMethods,
            isCreatingReservation,
            propertyDockInfo,
        } = this.props;
        const {
            docks,
            selectedUser,
            selectedRateType,
            start: {
                month: startMonth,
                time: startTime,
                day: startDay,
                year: startYear,
            },
            end: { month: endMonth, time: endTime, day: endDay, year: endYear },
        } = this.state;
        const dateAndTimeStart =
            selectedRateType === RATE_TYPE_HOURLY ||
            selectedRateType === RATE_TYPE_DAILY;
        const dateAndTimeEnd = selectedRateType === RATE_TYPE_HOURLY;
        const userVehicleOptions = transformUserVehiclesForBookingReservation(
            this.state.selectedUser,
        );
        const filteredDockOptions = propertyDockInfo
            .filter((d) => {
                switch (selectedRateType) {
                    case RATE_TYPE_HOURLY:
                        return (
                            d.hourlyRate !== '' &&
                            d.hourlyRate !== null &&
                            d.hourlyRate !== undefined
                        );
                    case RATE_TYPE_DAILY:
                        return (
                            d.dailyRate !== '' &&
                            d.dailyRate !== null &&
                            d.dailyRate !== undefined
                        );
                    case RATE_TYPE_MONTHLY:
                        return (
                            d.monthlyRate !== '' &&
                            d.monthlyRate !== null &&
                            d.monthlyRate !== undefined
                        );
                }
            })
            .map((d) => {
                return { value: d.dockId, label: d.dockName };
            });
        return [
            {
                name: 'photoUrl',
                label: !isCreatingReservation ? 'Vehicle Photo' : '',
                render: (fieldProps) => {
                    return !isCreatingReservation ? (
                        <UserVehiclePhoto
                            {...fieldProps}
                            value={existingReservation.photoUrl}
                        />
                    ) : null;
                },
            },
            {
                name: 'reservationId',
                label: 'Reservation ID',
                render: (fieldProps) => {
                    return (
                        <Grid container direction="row" wrap="nowrap">
                            <Grid item>
                                <Typography
                                    color="primary"
                                    className={classes.reservationId}
                                >
                                    {isCreatingReservation
                                        ? 'TBD'
                                        : fieldProps.value}
                                </Typography>
                            </Grid>
                            <Grid item container direction="row" spacing={16}>
                                <Grid item>
                                    <Typography
                                        color="primary"
                                        className={classes.label}
                                    >
                                        {isCreatingReservation ? '' : 'Created'}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography color="primary">
                                        {' '}
                                        {isCreatingReservation
                                            ? ''
                                            : fieldProps.values.reservedAt}{' '}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                },
            },
            {
                name: 'userId',
                label: 'User',
                render: (fieldProps, { setFieldValue }) => {
                    const { value } = fieldProps;
                    if (!value && selectedUser.id) {
                        setFieldValue('userId', selectedUser.id);
                    }
                    const {
                        id: creatingReservationSelectedUserId,
                        imageUrl: creatingReservationUserImage,
                        resultHeader,
                    } = selectedUser;
                    const {
                        photoUrl: viewingReservationUserImage,
                    } = this.props.existingReservation;
                    const imageUrl = creatingReservationSelectedUserId
                        ? creatingReservationUserImage
                        : viewingReservationUserImage;
                    const showHeader =
                        !isCreatingReservation ||
                        (isCreatingReservation &&
                            creatingReservationSelectedUserId);
                    return (
                        <Grid container>
                            {showHeader && (
                                <SearchResultHeader
                                    imageUrl={imageUrl}
                                    resultHeader={
                                        resultHeader ||
                                        existingReservation.userName
                                    }
                                    avatarClass={classes.avatar}
                                />
                            )}
                            {isCreatingReservation && (
                                <AdminButton
                                    buttonType={BUTTON_TYPE_EDIT}
                                    size="small"
                                    onClick={this.toggleUserSearch}
                                    className={classes.chooseUserButton}
                                >
                                    {creatingReservationSelectedUserId
                                        ? 'Change User'
                                        : 'Choose User'}
                                </AdminButton>
                            )}
                        </Grid>
                    );
                },
            },
            {
                name: 'vehicle',
                label: userVehicleOptions.length > 0 ? 'Vehicle' : '',
                hidden: userVehicleOptions.length === 0,
                validation:
                    userVehicleOptions.length > 0
                        ? Yup.number().required('Vehicle Required')
                        : null,
                render: (fieldProps, { setFieldValue }) => {
                    return isCreatingReservation ? (
                        <AdminSelect
                            classes={{ root: classes.textField }}
                            type="large"
                            options={userVehicleOptions}
                            {...fieldProps}
                            onChange={(e) =>
                                setFieldValue('vehicle', e.target.value)
                            }
                            disabled={!selectedUser.id}
                        />
                    ) : (
                        <ReadOnlyTextField {...fieldProps} />
                    );
                },
            },
            {
                name: 'vehiclePlate',
                label: !isCreatingReservation
                    ? 'License Plate #'
                    : userVehicleOptions.length === 0
                    ? 'Tractor #'
                    : '',
                hidden: userVehicleOptions.length > 0,
                validation:
                    userVehicleOptions.length === 0
                        ? Yup.string().required('Tractor Number Required')
                        : null,
                render: (fieldProps) => {
                    return !isCreatingReservation ? (
                        <ReadOnlyTextField {...fieldProps} />
                    ) : (
                        <TextField
                            {...fieldProps}
                            style={{ width: 500 }}
                            inputProps={{ maxLength: 12 }}
                        />
                    );
                },
            },
            {
                name: 'rateType',
                label: isCreatingReservation ? 'Rate Type' : '',
                hidden: !isCreatingReservation,
                validation: Yup.string().required('Rate Type Required'),
                render: (fieldProps, { setFieldValue }) => {
                    const { error } = fieldProps;
                    return isCreatingReservation ? (
                        <AdminCheckboxes
                            checkboxes={RATE_TYPES}
                            checked={[selectedRateType]}
                            error={error}
                            CheckboxProps={{
                                onChange: (event) =>
                                    this.onRateTypeChanged(
                                        event,
                                        setFieldValue,
                                    ),
                            }}
                        />
                    ) : null;
                },
            },
            {
                name: 'propertyId',
                label: 'Property',
                validation: Yup.number().required('Property Required'),
                render: (fieldProps, { setFieldValue }) => {
                    return (
                        <AdminSelect
                            classes={{ root: classes.textField }}
                            type="large"
                            options={properties}
                            {...fieldProps}
                            onChange={(e) =>
                                this.handlePropertyChange(e, setFieldValue)
                            }
                        />
                    );
                },
            },
            {
                name: 'dockId',
                label: 'Dock',
                validation: Yup.number().required('Dock Required'),
                render: (fieldProps, { setFieldValue }) => {
                    return (
                        <AdminSelect
                            classes={{ root: classes.textField }}
                            type="large"
                            options={
                                isCreatingReservation
                                    ? filteredDockOptions
                                    : docks
                            }
                            {...fieldProps}
                            onChange={(e) =>
                                this.handleDockSelect(e, setFieldValue)
                            }
                        />
                    );
                },
            },
            {
                name: 'duration',
                label: 'Duration',
                validation: isCreatingReservation
                    ? Yup.object().shape({
                          start: Yup.date()
                              .min(
                                  selectedRateType === RATE_TYPE_MONTHLY
                                      ? DateTime.local()
                                            .startOf('month')
                                            .toJSDate()
                                      : DateTime.local()
                                            .startOf('minute')
                                            .toJSDate(),
                                  'Start Time must be later than the current date/time.',
                              )
                              .required('Start Time Required'),
                          end: Yup.date()
                              .min(
                                  Yup.ref('start'),
                                  'End Time must be after Start Time',
                              )
                              .required('End Time Required'),
                      })
                    : null,
                render: (fieldProps, { setFieldValue }) => {
                    const { values: { start, end } = {}, error } = fieldProps;
                    return (
                        <Grid
                            container
                            direction="row"
                            wrap="nowrap"
                            className={classes.datePickerWrapper}
                        >
                            <Grid container direction="column" item>
                                <Grid item>
                                    <Typography color="secondary">
                                        Start Time
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {!isCreatingReservation ? (
                                        <ReadOnlyTextField
                                            value={start}
                                            classes={{
                                                disabled:
                                                    classes.shortTextField,
                                            }}
                                        />
                                    ) : (
                                        <FilterDatePicker
                                            containerClass={
                                                classes.datePickerLeft
                                            }
                                            dateAndTime={dateAndTimeStart}
                                            onMonthChange={(event) =>
                                                this.onDatePickerChange(
                                                    event,
                                                    START,
                                                    MONTH,
                                                )
                                            }
                                            onDayChange={(event) =>
                                                this.onDatePickerChange(
                                                    event,
                                                    START,
                                                    DAY,
                                                )
                                            }
                                            onTimeChange={(event) =>
                                                this.onDatePickerChange(
                                                    event,
                                                    START,
                                                    TIME,
                                                )
                                            }
                                            onYearChange={(event) =>
                                                this.onDatePickerChange(
                                                    event,
                                                    START,
                                                    YEAR,
                                                )
                                            }
                                            fieldValueCallback={(value) => {
                                                setFieldValue(
                                                    'duration.start',
                                                    value,
                                                );
                                            }}
                                            month={startMonth}
                                            day={startDay}
                                            time={startTime}
                                            year={startYear}
                                            error={error?.start}
                                            monthEnabled={
                                                selectedRateType !==
                                                RATE_TYPE_MONTHLY
                                            }
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                container
                                direction="column"
                                alignItems="flex-end"
                            >
                                <Grid item>
                                    <Typography color="secondary">
                                        End Time
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {!isCreatingReservation ? (
                                        <ReadOnlyTextField
                                            value={end}
                                            classes={{
                                                disabled:
                                                    classes.shortTextField,
                                            }}
                                        />
                                    ) : (
                                        <FilterDatePicker
                                            containerClass={
                                                classes.datePickerRight
                                            }
                                            dateAndTime={dateAndTimeEnd}
                                            onMonthChange={(event) =>
                                                this.onDatePickerChange(
                                                    event,
                                                    END,
                                                    MONTH,
                                                )
                                            }
                                            onDayChange={(event) =>
                                                this.onDatePickerChange(
                                                    event,
                                                    END,
                                                    DAY,
                                                )
                                            }
                                            onTimeChange={(event) =>
                                                this.onDatePickerChange(
                                                    event,
                                                    END,
                                                    TIME,
                                                )
                                            }
                                            onYearChange={(event) =>
                                                this.onDatePickerChange(
                                                    event,
                                                    END,
                                                    YEAR,
                                                )
                                            }
                                            fieldValueCallback={(value) => {
                                                setFieldValue(
                                                    'duration.end',
                                                    value,
                                                );
                                            }}
                                            month={endMonth}
                                            day={endDay}
                                            time={endTime}
                                            year={endYear}
                                            error={error?.end}
                                            monthEnabled={
                                                selectedRateType !==
                                                RATE_TYPE_MONTHLY
                                            }
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                },
            },
            {
                name: 'cost',
                label: 'Cost',
                render: (fieldProps) => {
                    const { selectedRateType, start, end, dockId } = this.state;
                    const dockInfo = propertyDockInfo.find(
                        (d) => d.dockId === dockId,
                    );
                    const rate =
                        selectedRateType === RATE_TYPE_HOURLY
                            ? dockInfo?.hourlyRate
                            : selectedRateType === RATE_TYPE_DAILY
                            ? dockInfo?.dailyRate
                            : dockInfo?.monthlyRate;
                    const total = getReservationTotal(
                        rate,
                        start,
                        end,
                        selectedRateType,
                    );
                    const value = isCreatingReservation
                        ? total
                        : fieldProps?.value;
                    return (
                        <ReadOnlyTextField
                            classes={{ disabled: classes.cost }}
                            value={value}
                        />
                    );
                },
            },
            {
                name: 'paymentId',
                label: isCreatingReservation ? 'Payment' : '',
                hidden: !isCreatingReservation,
                validation: isCreatingReservation
                    ? Yup.string().required('Payment Method Required')
                    : null,
                render: (fieldProps, { setFieldValue }) => {
                    return (
                        <AdminSelect
                            classes={{ root: classes.textField }}
                            type="large"
                            options={userPaymentMethods}
                            {...fieldProps}
                            onChange={(e) =>
                                setFieldValue('paymentId', e.target.value)
                            }
                        />
                    );
                },
            },
        ];
    };

    render() {
        const {
            existingReservation,
            loading,
            messages,
            removeFormMessage,
            isCreatingReservation,
            reservationInProgress,
        } = this.props;

        const fields = this._getFormFields();
        return !this.state.searchUser ? (
            <DetailForm
                initFormState={
                    !isCreatingReservation
                        ? omit(
                              ['photoUrl', 'UserReservationPhotoId'],
                              existingReservation,
                          )
                        : {}
                }
                onSubmit={this.onSubmit}
                loading={loading}
                formSubmitInProgress={reservationInProgress}
                fields={fields}
                messages={messages}
                showDelete={false}
                showConfirm={true}
                submitButtonText={
                    isCreatingReservation
                        ? CREATE_RESERVATION_TITLE
                        : UPDATE_RESERVATION_TITLE
                }
                removeFormMessage={removeFormMessage}
            />
        ) : (
            <ReservationUserSearch
                onCancel={this.toggleUserSearch}
                onSelect={this.userSelected}
            />
        );
    }
}

ReservationForm.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // Function that will be called when the form is submitted.
    onSubmit: PropTypes.func.isRequired,

    // Bool that determines this form is loading
    loading: PropTypes.bool,

    // So you can supply an initial state of the form, if any.
    existingReservation: PropTypes.shape({
        photoUrl: PropTypes.string, // expects url to pull user avatar
        reservationName: PropTypes.string,
        duration: PropTypes.object,
        reservationId: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        propertyId: PropTypes.number,
        dockId: PropTypes.number,
        userName: PropTypes.string,
        vehicle: PropTypes.string,
        vehiclePlate: PropTypes.string,
        cost: PropTypes.string,
        paymentId: PropTypes.string,
    }),

    // Available property options
    properties: PropTypes.array,

    // Messages to be displayed on the form, if any.
    messages: formMessagesPropTypes.messages,
    removeFormMessage: PropTypes.func.isRequired,

    // If creating new reservation
    isCreatingReservation: PropTypes.bool.isRequired,

    // For searching payment methods
    fetchUserPaymentMethods: PropTypes.func,

    // User payment methods for booking
    userPaymentMethods: PropTypes.array,

    // A request to the API is in flight for making a reservation
    reservationInProgress: PropTypes.bool,

    // For fetching property/dock pricing info
    fetchPropertyDockInfo: PropTypes.func.isRequired,

    // The property docks and their pricing information
    propertyDockInfo: PropTypes.array,
};

ReservationForm.defaultProps = {
    existingReservation: {},
    loading: false,
    messages: [],
    propertyDockInfo: [],
};

export default withStyles(styles)(ReservationForm);
