export const SET_STATUS_ACTIVE = 'setStatusActive';
export const SET_STATUS_INACTIVE = 'setStatusInactive';
export const SET_USER_TYPE_ADMIN = 'setUserTypeAdmin';
export const SET_USER_TYPE_TRUCKER = 'setUserTypeTrucker';
export const SET_USER_TYPE_OWNER = 'setUserTypeOwner';

const ACTION_LABELS = {
    [SET_STATUS_ACTIVE]: 'Set Active',
    [SET_STATUS_INACTIVE]: 'Set Inactive',
    [SET_USER_TYPE_ADMIN]: 'Set as Admin(s)',
    [SET_USER_TYPE_TRUCKER]: 'Set as Trucker(s)',
    [SET_USER_TYPE_OWNER]: 'Set as Owner(s)',
};

export const ACTIONS = [
    { value: SET_STATUS_ACTIVE, label: ACTION_LABELS[SET_STATUS_ACTIVE] },
    { value: SET_STATUS_INACTIVE, label: ACTION_LABELS[SET_STATUS_INACTIVE] },
    { value: SET_USER_TYPE_ADMIN, label: ACTION_LABELS[SET_USER_TYPE_ADMIN] },
    {
        value: SET_USER_TYPE_TRUCKER,
        label: ACTION_LABELS[SET_USER_TYPE_TRUCKER],
    },
    { value: SET_USER_TYPE_OWNER, label: ACTION_LABELS[SET_USER_TYPE_OWNER] },
];
