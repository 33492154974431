const styles = (theme) => ({
    container: {
        backgroundColor: theme.palette.primary.contrastText,
        padding: 10,
        width: 825,
        margin: 'auto',
        // removes padding from form messages
        '&:first-child': {
            paddingTop: 30,
        },
        '&:last-child': {
            padding: 0,
        },
    },
    gridItem: {
        marginTop: 10,
        marginBottom: 10,
    },
    label: {
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        letterSpacing: 4,
        marginLeft: 'auto',
    },
    labelWrapper: {
        margin: 'auto 0 auto auto',
        textAlign: 'right',
        minWidth: 150,
        flex: 1,
    },
    actionButton: {
        marginBottom: 40,
        marginTop: 40,
    },
    actionButtonContainer: {
        paddingTop: 40,
        marginLeft: 75,
    },
    componentWrapper: {
        flex: 4,
        marginRight: 60,
        marginLeft: 40,
    },
    loadingContainer: {
        backgroundColor: theme.palette.primary.contrastText,
        padding: 50,
        maxWidth: theme.custom.containerMaxWidth,
    },
    fieldWrapper: {},
});

export default styles;
