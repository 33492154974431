import { all, call, takeEvery, put } from 'redux-saga/effects';
import { doUploadWithAuth, fetchWithAuth } from '../api/sagas';
import { getErrorsFromPossibleAPIErrorResponse } from '../../utils/api';
import {
    updateVehiclePhotosSucceeded,
    updateVehiclePhotosFailed,
    UPDATE_VEHICLE_PHOTOS_REQUESTED,
    FETCH_VEHICLE_MAKES_REQUESTED,
    FETCH_VEHICLE_MODELS_REQUESTED,
    getVehicleMakesSucceeded,
    getVehicleModelsSucceeded,
    getVehicleModelsFailed,
    getVehicleMakesFailed,
} from './actions';

export function* updateUserVehiclePhotos({ photos, vehicleId }) {
    try {
        // Pick out the files that already were uploaded to the vehicle by their uploadId being present.
        const photosForSync = photos.filter((photo) => photo.uploadId);
        const photoUploadIds = photosForSync.map(({ uploadId }) => uploadId);

        // Sync the photos based on uploadIds, i.e. this will delete any photos that were removed.
        const syncResponse = yield call(
            fetchWithAuth,
            `vehicle/${vehicleId}/photo/upload`,
            {
                method: 'PUT',
                body: { uploadIds: photoUploadIds },
            },
        );

        // Pick out the new photos which will have a file prop holding the photo file
        const newPhotos = photos.filter((photo) => photo.file);

        // If there are any new photos, upload them
        if (newPhotos && newPhotos.length > 0) {
            const formData = new FormData();
            newPhotos.forEach(({ file }) => {
                formData.append('photo', file);
            });
            const uploadResponse = yield call(
                doUploadWithAuth,
                `vehicle/${vehicleId}/photo/upload`,
                { method: 'POST', body: formData },
            );
            yield put(updateVehiclePhotosSucceeded(vehicleId, uploadResponse));
        } else {
            yield put(updateVehiclePhotosSucceeded(vehicleId, syncResponse));
        }
    } catch (error) {
        const errors = yield call(getErrorsFromPossibleAPIErrorResponse, error);
        yield put(
            updateVehiclePhotosFailed([
                'Upload of new Vehicle Photo(s) has failed.',
                ...errors,
            ]),
        );
    }
}

export function* fetchVehicleMakes() {
    try {
        const response = yield call(fetchWithAuth, `/make`, { method: 'GET' });
        yield put(getVehicleMakesSucceeded(response));
    } catch (error) {
        yield put(getVehicleMakesFailed());
    }
}

export function* fetchVehicleModels() {
    try {
        const response = yield call(fetchWithAuth, `/model`, { method: 'GET' });
        yield put(getVehicleModelsSucceeded(response));
    } catch (error) {
        yield put(getVehicleModelsFailed());
    }
}

export default function*() {
    yield all([
        takeEvery(UPDATE_VEHICLE_PHOTOS_REQUESTED, updateUserVehiclePhotos),
        takeEvery(FETCH_VEHICLE_MAKES_REQUESTED, fetchVehicleMakes),
        takeEvery(FETCH_VEHICLE_MODELS_REQUESTED, fetchVehicleModels),
    ]);
}
