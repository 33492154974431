import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    CircularProgress,
    Grid,
    TextField,
    withStyles,
} from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import styles from './styles';

class SearchBar extends Component {
    onSubmit = (event) => {
        event.preventDefault();
        this.props.onSubmit();
    };

    render() {
        const { classes, loading, containerClass, ...restProps } = this.props;
        return (
            <Grid
                container
                component="form"
                onSubmit={this.onSubmit}
                className={containerClass}
            >
                <Grid item className={classes.flex}>
                    <TextField
                        disabled={loading}
                        name="search"
                        variant="outlined"
                        label="Search..."
                        fullWidth
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel,
                                shrink: classes.shrinkInputLabel,
                            },
                        }}
                        InputProps={{
                            classes: {
                                notchedOutline: classes.inputNotchedOutline,
                                input: classes.inputStyle,
                            },
                        }}
                        {...restProps}
                    />
                </Grid>
                <Grid item>
                    <Button
                        variant="text"
                        type="submit"
                        className={classes.submitButton}
                    >
                        {loading ? (
                            <CircularProgress
                                className={classes.progress}
                                size={30}
                            />
                        ) : (
                            <ArrowForward className={classes.arrowIcon} />
                        )}
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

SearchBar.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // Function that will be called when the form is submitted.
    onSubmit: PropTypes.func,

    // Is the search currently submitting
    loading: PropTypes.bool,

    // Optional class for container
    containerClass: PropTypes.string,
};

SearchBar.defaultProps = {
    loading: false,
    value: '',
};

export default withStyles(styles)(SearchBar);
