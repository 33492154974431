import { createSelector } from 'reselect';
import { transformRulesForCheckboxesInForm } from './transforms';

export const getRules = (state) => state.rules.data;

export const getRulesForCheckboxesInForm = createSelector(
    (state) => getRules(state),
    (rules) => {
        return transformRulesForCheckboxesInForm(rules);
    },
);
