import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, withStyles } from '@material-ui/core';
import {
    ChevronLeft as LeftIcon,
    ChevronRight as RightIcon,
} from '@material-ui/icons';
import RCPagination from 'rc-pagination';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import classNames from 'classnames';
import styles from './styles';
import PageSizeSelect from './PageSizeSelect';
import PaginationText from './PaginationText';

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_SIZES = [DEFAULT_PAGE_SIZE, 15, 25, 50, 100, 250, 500];

const withPaginationStyles = (name) => withStyles(styles, { name });

/**
 * Function called to render the display of the total.
 *
 * @param total
 * @param range
 * @returns {*}
 */
const showTotal = (total, range) => (
    <PaginationText>
        {`Showing ${range[0]} - ${range[1]} of ${total}`}
    </PaginationText>
);

const PaginationItem = withPaginationStyles('PaginationItem')(
    ({ active, number, type, element, classes }) => {
        if (type === 'page') {
            return (
                <Button
                    color="secondary"
                    variant="text"
                    size="small"
                    className={classNames([
                        classes.item,
                        active ? classes.activeItem : null,
                    ])}
                >
                    {number}
                </Button>
            );
        } else if (type === 'prev') {
            return (
                <LeftIcon
                    color="secondary"
                    fontSize="small"
                    className={classNames([
                        classes.prevNextArrows,
                        classes.prevArrow,
                    ])}
                />
            );
        } else if (type === 'next') {
            return (
                <RightIcon
                    color="secondary"
                    fontSize="small"
                    className={classes.prevNextArrows}
                />
            );
        } else {
            return element;
        }
    },
);

const paginationItemRender = (current) => {
    /* eslint-disable-next-line */
    return (number, type, element) => (
        <PaginationItem
            active={current && current === number}
            number={number}
            type={type}
            element={element}
        />
    );
};

class Pagination extends Component {
    /**
     * Take the Select onChange event and call onSizeChange with the value (the new page size)
     * @param event
     */
    onSizeChange = (event) => {
        this.props.onSizeChange(event.target.value);
    };

    render() {
        const {
            classes,
            total,
            pageSizeOptions,
            onPageChange,
            page,
            pageSize,
            ...restProps
        } = this.props;
        const current =
            page || restProps.current || restProps.defaultCurrent || null;
        return (
            <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
                spacing={8}
                className={classes.paginationContainer}
            >
                {pageSizeOptions.length > 0 && (
                    <Grid item>
                        <PageSizeSelect
                            value={pageSize}
                            options={pageSizeOptions}
                            onChange={this.onSizeChange}
                        />
                    </Grid>
                )}
                <Grid item>
                    <RCPagination
                        className={classes.pagination}
                        locale={localeInfo}
                        itemRender={paginationItemRender(current)}
                        hideOnSinglePage
                        current={page}
                        pageSize={pageSize}
                        total={total}
                        showTotal={showTotal}
                        onChange={onPageChange}
                        {...restProps}
                    />
                </Grid>
            </Grid>
        );
    }
}

Pagination.propTypes = {
    // The amount to be displayed perPage by default
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
    // The amount to be displayed perPage by default
    pageSize: PropTypes.number,
    // The current page number being displayed
    page: PropTypes.number,
    // The total number of items
    total: PropTypes.number.isRequired,
    // Function that is called when the page size changes with the new page size.
    onSizeChange: PropTypes.func.isRequired,
    // Function that is called when the page being viewed changes with the new page number.
    onPageChange: PropTypes.func.isRequired,
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,
};

Pagination.defaultProps = {
    // The default options for page size
    pageSizeOptions: DEFAULT_PAGE_SIZES,
    // Default to page size default constant which is defined above.
    pageSize: DEFAULT_PAGE_SIZE,
    // Default to page 1.
    page: 1,
};

export default withPaginationStyles('Pagination')(Pagination);
