import React from 'react';
import ReactDOM from 'react-dom';
import objectFitImages from 'object-fit-images';
// Any global CSS imported here should also be imported in `../.storybook/config.js`
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// used to ensure IE compat on object-fit styles
objectFitImages();

ReactDOM.render(<App />, document.getElementById('root'));

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
