import exteriorBackground from './exterior-background.png';
import iPhone from './iPhone.png';
import logo from './rigpark-logo-vertical-color-high-res.png';

const styles = (theme) => ({
    container: {
        width: 'auto',
        height: 'auto',
        minHeight: '100vh',
        backgroundSize: 'cover',
        [theme.breakpoints.up('md')]: {
            minWidth: 1000,
        },
    },
    interiorContainer: {
        backgroundColor: theme.custom.grey,
    },
    exteriorContainer: {
        backgroundImage: `url(${exteriorBackground}), url(${logo})`,
        backgroundSize: 'auto 80%, auto 120px',
        backgroundRepeat: 'no-repeat, no-repeat',
        backgroundPosition: 'top center, left 50% bottom 80px',
        [theme.breakpoints.up('md')]: {
            backgroundImage: `url(${iPhone}), url(${exteriorBackground}), url(${logo})`,
            backgroundSize: 'auto 700px, auto 900px, 250px auto',
            backgroundRepeat: 'no-repeat, no-repeat, no-repeat',
            backgroundPosition:
                'right -70px top 190px, top right, left 50% bottom 120px',
            minHeight: 1080,
            height: '100vh',
        },
        [theme.breakpoints.up('lg')]: {
            backgroundSize: 'auto 700px, auto 1100px, 250px auto',
            backgroundPosition:
                'right -70px top 190px, center top -160px, left 50% bottom 120px',
        },
        [theme.breakpoints.up('xl')]: {
            backgroundSize: '45% auto, 100% auto, 250px auto',
            backgroundPosition:
                'right 50px top 150px, center top -200px, left 50% top 93%',
        },
    },
    innerContainer: {
        padding: `30px ${theme.custom.containerPadding}px`,
        flexGrow: 1,
        flexShrink: 1,
        display: 'block',
        margin: '0 auto',
        maxWidth: theme.custom.containerMaxWidth,
    },
    exteriorInnerContainer: {
        [theme.breakpoints.up('md')]: {
            padding:
                `140px ${470 + theme.custom.exteriorContainerPadding}px ` +
                `0 ${theme.custom.exteriorContainerPadding}px`,
        },
    },
    exteriorHeaderGridItem: {
        backgroundColor: 'transparent',
    },
    interiorHeaderGridItem: {
        backgroundColor: theme.palette.primary.contrastText,
    },
});
export default styles;
