// Import actions
import {
    FETCH_RULES_REQUESTED,
    FETCH_RULES_SUCCEEDED,
    FETCH_RULES_FAILED,
} from './apiActions';
import { LOGOUT } from '../auth/actions';

// Import initial state
import { rulesInitialState } from './initialState';

export default function(state = rulesInitialState, action = {}) {
    const { type } = action;
    switch (type) {
        case FETCH_RULES_REQUESTED:
            return { loading: true, errors: [] };
        case FETCH_RULES_SUCCEEDED:
            return { loading: false, data: action.data };
        case FETCH_RULES_FAILED:
            return { loading: false, error: action.error };
        case LOGOUT:
            return rulesInitialState;
        default:
            return state;
    }
}
