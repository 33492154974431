import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from '../../utils';

export const COMPONENT_NAME = 'SearchDraftManager';

class SearchDraftManager extends Component {
    static propTypes = {
        children: PropTypes.func.isRequired,
        search: PropTypes.string,
        onSearchSubmit: PropTypes.func,
    };

    static defaultProps = {
        search: '',
        onSearchSubmit: noop,
    };

    state = {
        search: this.props.search,
        prevSearch: this.props.search,
    };

    static getDerivedStateFromProps(props, state) {
        if (props.search !== state.prevSearch) {
            return {
                search: props.search,
                prevSearch: props.search,
            };
        }
        return null;
    }

    onSearchSubmit = () => {
        this.props.onSearchSubmit(this.state.search);
    };

    onSearchClear = () => {
        this.props.onSearchSubmit('');
    };

    onSearchChange = (search) => this.setState({ search });

    render() {
        const { children: renderTable } = this.props;
        const { search } = this.state;
        if (
            process.env.NODE_ENV === 'development' &&
            (typeof renderTable !== 'function' ||
                React.isValidElement(renderTable))
        ) {
            /* eslint-disable-next-line */
            console.warn(
                `Unexpected non-function children in ${COMPONENT_NAME}:`,
                renderTable,
            );
        }
        return renderTable({
            searchable: true,
            search,
            onSearchSubmit: this.onSearchSubmit,
            onSearchClear: this.onSearchClear,
            onSearchChange: this.onSearchChange,
        });
    }
}

export default SearchDraftManager;
