import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    CircularProgress,
    Grid,
    Typography,
    Divider,
    withStyles,
    Table,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    LinearProgress,
} from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import AdminContainer from '../../common/AdminContainer';
import { formMessagesPropTypes } from '../../common/FormMessages';
import {
    removeFormMessage,
    fetchOwnerPropertiesRequested,
    fetchPropertyReportRequested,
} from '../../stores/properties/actions';
import {
    getOwnedPropertiesForSelect,
    getPropertyReportReservations,
    isOwnedPropertiesListLoading,
    isPropertyDetailLoading,
    isPropertyReportLoading,
} from '../../stores/properties/selectors';
import Select from 'react-select';
import styles from './styles';
import { isUserAdmin } from '../../stores/auth/selectors';
import { useMobileThreshold } from '../../utils/hooks';
import {
    RESERVATION_PROP_ID,
    RESERVATION_PROP_LABELS,
    RESERVATION_PROP_TYPES,
} from './constants';
import clsx from 'clsx';
import { useDebounce } from 'use-debounce';
import muiTheme from '../../theme';

const abbreviatedNumber = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 1,
    notation: 'compact',
    compactDisplay: 'short',
});

const PropertyDetailsContainer = (props) => {
    const {
        classes,
        path,
        isAdmin,
        loading,
        properties,
        loadingReport,
        reservations,
        onLoad,
        onLoadReport,
        ...rest
    } = props;

    const [selectedProperty, setSelectedProperty] = useState(null);
    const [search, setSearch] = useState('');
    const isMobile = useMobileThreshold(850);
    const [debouncedSearch] = useDebounce(search, isMobile ? 600 : 400);

    const renderTable = useCallback(
        () => (
            <Table>
                <TableHead>
                    <TableRow>
                        {Object.values(RESERVATION_PROP_LABELS).map(
                            (label, index) => (
                                <TableCell
                                    key={`th-${index}`}
                                    className={classes.tableHead}
                                >
                                    {label}
                                </TableCell>
                            ),
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {reservations.map(
                        ({
                            [RESERVATION_PROP_ID]: reservationId,
                            ...reservation
                        }) => (
                            <TableRow key={`reservation-${reservationId}`}>
                                {Object.keys(RESERVATION_PROP_LABELS).map(
                                    (key) => (
                                        <TableCell
                                            key={`reservation-${reservationId}-${key}`}
                                        >
                                            {reservation[key]}
                                        </TableCell>
                                    ),
                                )}
                            </TableRow>
                        ),
                    )}
                </TableBody>
            </Table>
        ),
        [classes, reservations],
    );

    const renderMobileTable = useCallback(
        () => (
            <Grid container direction="column">
                {reservations.map(
                    (
                        {
                            [RESERVATION_PROP_ID]: reservationId,
                            ...reservation
                        },
                        index,
                    ) => (
                        <Grid
                            container
                            item
                            direction="column"
                            key={`reservation-${reservationId}`}
                        >
                            {Object.entries(RESERVATION_PROP_LABELS).map(
                                ([key, label], subIndex) => (
                                    <Grid
                                        container
                                        item
                                        key={`reservation-${reservationId}-${key}`}
                                        className={clsx(
                                            classes.mobileRow,
                                            (subIndex + 1) % 2 &&
                                                classes.mobileRowHighlight,
                                        )}
                                    >
                                        <Grid
                                            item
                                            className={classes.mobileRowLabel}
                                            component={Typography}
                                        >
                                            {label}
                                        </Grid>
                                        <Grid
                                            item
                                            className={classes.mobileRowValue}
                                            component={Typography}
                                        >
                                            {reservation[key]}
                                        </Grid>
                                    </Grid>
                                ),
                            )}
                            {index < reservations.length - 1 && (
                                <Divider
                                    className={classes.mobileBottomDivider}
                                />
                            )}
                        </Grid>
                    ),
                )}
            </Grid>
        ),
        [classes, reservations],
    );

    const renderReport = useCallback(() => {
        if (!reservations?.length) {
            return <Typography>No active reservations!</Typography>;
        }
        const { timezone } = selectedProperty || {};
        return (
            <>
                <Typography variant="h5" className={classes.reservationsHeader}>
                    {abbreviatedNumber.format(reservations.length)}{' '}
                    {`Active Reservation${
                        reservations.length !== 1 ? 's' : ''
                    }`}
                </Typography>
                <Typography className={classes.timezone}>
                    Time zone: {timezone}
                </Typography>
                {isMobile ? renderMobileTable() : renderTable()}
            </>
        );
    }, [
        classes,
        selectedProperty,
        reservations,
        isMobile,
        renderMobileTable,
        renderTable,
    ]);

    useEffect(() => {
        onLoad();
    }, [onLoad]);

    useEffect(() => {
        if (debouncedSearch.length > 2) {
            onLoad({ filters: { search: debouncedSearch } });
        }
    }, [onLoad, debouncedSearch]);

    return (
        <AdminContainer
            path={path}
            isAdmin={isAdmin}
            innerHeader
            innerHeaderProps={{
                title: 'Property Report',
            }}
            {...rest}
        >
            <Grid container item direction="column">
                <Grid
                    item
                    component={Select}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary: muiTheme.palette.secondary.main,
                        },
                    })}
                    isLoading={loading}
                    isOptionDisabled={() => loading}
                    className={classes.select}
                    inputValue={search}
                    onInputChange={setSearch}
                    placeholder="Select a property..."
                    options={properties}
                    value={selectedProperty}
                    onChange={(property) => {
                        setSelectedProperty(property);
                        onLoadReport(property.value);
                    }}
                />
                {loadingReport ? (
                    <Grid
                        item
                        color="secondary"
                        size={30}
                        {...(isMobile
                            ? {
                                  component: CircularProgress,
                                  className: classes.mobileCircularProgress,
                              }
                            : {
                                  component: LinearProgress,
                                  className: classes.topLinearProgress,
                              })}
                    />
                ) : (
                    renderReport()
                )}
            </Grid>
        </AdminContainer>
    );
};

PropertyDetailsContainer.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // The path which should be passed down from the router.
    path: PropTypes.string.isRequired,

    // Is the logged-in user an admin
    isAdmin: PropTypes.bool,

    // Connected functions from mapping dispatch to props
    onLoad: PropTypes.func.isRequired,
    onLoadReport: PropTypes.func.isRequired,

    // Messages to be displayed on the form, if any.
    messages: formMessagesPropTypes.messages,

    // Indication of whether or not the property data is loading.
    loading: PropTypes.bool,

    // Paginated object of owned properties
    properties: PropTypes.array,

    // Whether the report is loading for the selected property
    loadingReport: PropTypes.bool,

    // Active reservations in the Property report
    reservations: PropTypes.arrayOf(PropTypes.shape(RESERVATION_PROP_TYPES)),
};

PropertyDetailsContainer.defaultProps = {
    isAdmin: false,
    loading: false,
    properties: [],
    loadingReport: false,
    reservations: [],
};

const mapStateToProps = function(state) {
    return {
        loading:
            isPropertyDetailLoading(state) ||
            isOwnedPropertiesListLoading(state),
        loadingReport: isPropertyReportLoading(state),
        isAdmin: isUserAdmin(state),
        properties: getOwnedPropertiesForSelect(state),
        reservations: getPropertyReportReservations(state),
        messages: state.properties.messages,
    };
};

const mapDispatchToProps = function(dispatch) {
    return {
        onLoad: (context = {}) =>
            dispatch(fetchOwnerPropertiesRequested(context)),
        onLoadReport: (propertyId) =>
            dispatch(fetchPropertyReportRequested(propertyId)),
        removeFormMessage: (messageKey) =>
            dispatch(removeFormMessage(messageKey)),
    };
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(PropertyDetailsContainer);
