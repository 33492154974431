import {
    FETCH_USERS_REQUESTED,
    FETCH_USERS_SUCCEEDED,
    FETCH_USERS_FAILED,
    FETCH_NEW_USERS_COUNT_REQUESTED,
    FETCH_NEW_USERS_COUNT_FAILED,
    FETCH_NEW_USERS_COUNT_SUCCEEDED,
    FETCH_PROPERTIES_REQUESTED,
    FETCH_PROPERTIES_SUCCEEDED,
    FETCH_PROPERTIES_FAILED,
    FETCH_NEW_PROPERTIES_COUNT_REQUESTED,
    FETCH_NEW_PROPERTIES_COUNT_FAILED,
    FETCH_NEW_PROPERTIES_COUNT_SUCCEEDED,
    FETCH_RESERVATIONS_REQUESTED,
    FETCH_RESERVATIONS_SUCCEEDED,
    FETCH_RESERVATIONS_FAILED,
    FETCH_NEW_RESERVATIONS_COUNT_REQUESTED,
    FETCH_NEW_RESERVATIONS_COUNT_SUCCEEDED,
    FETCH_NEW_RESERVATIONS_COUNT_FAILED,
} from './actions';
import dashboardInitialState from './initialState';
import { LOGOUT } from '../auth/actions';
import { createSliceUpdater } from '../utils';

export default function(state = dashboardInitialState, action = {}) {
    const { type } = action;

    const updateSlice = createSliceUpdater(state);

    switch (type) {
        // Fetch users
        case FETCH_USERS_REQUESTED:
            return updateSlice('users', { loading: true });
        case FETCH_USERS_SUCCEEDED:
            return updateSlice('users', {
                loading: false,
                data: action.users,
                total: action.total,
            });
        case FETCH_USERS_FAILED:
            return updateSlice('users', { loading: false });

        // Fetch new users count
        case FETCH_NEW_USERS_COUNT_REQUESTED:
            return updateSlice('newUsers', { loading: true });
        case FETCH_NEW_USERS_COUNT_SUCCEEDED:
            return updateSlice('newUsers', {
                loading: false,
                count: action.count,
            });
        case FETCH_NEW_USERS_COUNT_FAILED:
            return updateSlice('newUsers', { loading: false });

        // Fetch users
        case FETCH_PROPERTIES_REQUESTED:
            return updateSlice('properties', { loading: true });
        case FETCH_PROPERTIES_SUCCEEDED:
            return updateSlice('properties', {
                loading: false,
                data: action.properties,
                total: action.total,
            });
        case FETCH_PROPERTIES_FAILED:
            return updateSlice('properties', { loading: false });

        // Fetch new properties count
        case FETCH_NEW_PROPERTIES_COUNT_REQUESTED:
            return updateSlice('newProperties', { loading: true });
        case FETCH_NEW_PROPERTIES_COUNT_SUCCEEDED:
            return updateSlice('newProperties', {
                loading: false,
                count: action.count,
            });
        case FETCH_NEW_PROPERTIES_COUNT_FAILED:
            return updateSlice('newProperties', { loading: false });

        // Fetch reservations
        case FETCH_RESERVATIONS_REQUESTED:
            return updateSlice('reservations', { loading: true });
        case FETCH_RESERVATIONS_SUCCEEDED:
            return updateSlice('reservations', {
                loading: false,
                data: action.reservations,
                total: action.total,
            });
        case FETCH_RESERVATIONS_FAILED:
            return updateSlice('reservations', { loading: false });

        // Fetch new reservations count
        case FETCH_NEW_RESERVATIONS_COUNT_REQUESTED:
            return updateSlice('newReservations', { loading: true });
        case FETCH_NEW_RESERVATIONS_COUNT_SUCCEEDED:
            return updateSlice('newReservations', {
                loading: false,
                count: action.count,
            });
        case FETCH_NEW_RESERVATIONS_COUNT_FAILED:
            return updateSlice('newReservations', { loading: false });

        // Clear on logout
        case LOGOUT:
            return dashboardInitialState;

        default:
            return state;
    }
}
