import { eventChannel } from 'redux-saga';
import {
    call,
    delay,
    put,
    race,
    select,
    take,
    takeLatest,
} from 'redux-saga/effects';
import firebase from '../../firebase/config';
import {
    initializationSucceeded,
    initializationFailed,
    firebaseAuthStateChanged,
    loginFailed,
    checkRequested,
    checkSucceeded,
    LOGIN_REQUESTED,
    LOGOUT,
    FIREBASE_AUTH_STATE_CHANGED,
} from './actions';
import { AUTHORIZED_GROUPS } from './constants';
import { fetchWithAuth } from '../api/sagas';
import { getCurrentUser, isInitialized } from './selectors';
import { fetchUserGroupsRequested } from '../userGroups/actions';
import { fetchRegionsRequested } from '../regions/actions';

export function createFirebaseAuthEventChannel() {
    return eventChannel((emitter) => {
        // `onAuthStateChanged` returns an unsub function for us so we can just return that!
        return firebase.auth().onAuthStateChanged((user) => {
            // We never need to emit END since this event stream never ends technically
            emitter({ user });
        });
    });
}

export function* firebaseAuthListenerSaga(firebase) {
    const firebaseAuthEventChannel = yield call(
        createFirebaseAuthEventChannel,
        firebase,
    );
    while (true) {
        const { user: userOrNull = null } = yield take(
            firebaseAuthEventChannel,
        );
        yield put(firebaseAuthStateChanged(userOrNull));
        const initialized = yield select(isInitialized);
        if (!initialized) {
            yield put(initializationSucceeded());
        }
        if (userOrNull) {
            try {
                yield call(doCheck);
            } catch (error) {
                yield put(
                    initializationFailed(
                        'Failed to reach the servers. Please try again later.',
                    ),
                );
            }
        }
    }
}

export function* init() {
    yield race({
        waitForAutoLogin: take(FIREBASE_AUTH_STATE_CHANGED),
        autoLoginTimeout: delay(3000),
    });
    // At this point either 3 seconds elapsed or the firebase auth state changed so we can hide the init screen
    yield put(initializationSucceeded());
}

function* login({ email, password }) {
    try {
        const auth = firebase.auth();
        yield call([auth, auth.signInWithEmailAndPassword], email, password);
    } catch (error) {
        // Login failed!
        const { code, message } = error;
        const errors = code.includes('user')
            ? { email: message }
            : { password: message };
        yield put(loginFailed(errors));
    }
}

function* doCheck() {
    try {
        yield put(checkRequested());
        const { groupName, userId } = yield call(fetchWithAuth, 'auth/me');
        // Make sure they are in the Admin group.
        if (!AUTHORIZED_GROUPS.includes(groupName)) {
            yield put(
                loginFailed({
                    email:
                        'User must be a RigPark Administrator or Property Owner.',
                }),
            );
            yield call(doLogout);
        } else {
            // Make sure their email is also verified
            const { emailVerified } = yield select(getCurrentUser);
            if (emailVerified) {
                // They are an Admin and their email is verified, so we'll flip the isValidUser in the auth store.
                yield put(checkSucceeded(userId, groupName));
                yield put(fetchUserGroupsRequested());
                yield put(fetchRegionsRequested());
            } else {
                yield put(
                    loginFailed({ email: 'Email has not been verified.' }),
                );
                yield call(doLogout);
            }
        }
    } catch (error) {
        /*
         * If we have an error thrown it means that we probably got a 401 Unauthorized from the /auth/me route
         * Which means there is no user with the firebase UID in the DB.
         */
        yield put(loginFailed({ email: 'User is not a valid RigPark user.' }));
        yield call(doLogout);
    }
}

function* doLogout() {
    const auth = firebase.auth();
    yield call([auth, auth.signOut]);
}

export function* listenLoginAndLogoutRequested() {
    yield takeLatest(LOGIN_REQUESTED, login);
    yield takeLatest(LOGOUT, doLogout);
}
