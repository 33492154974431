import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import AdminSelect from '../AdminSelect';
import styles from './styles';
import DatePropTypes from '../../propTypes/dates';
import { weekdayOptions } from '../../utils/dates';

class WeekdayPicker extends Component {
    render() {
        const { classes, value, ...props } = this.props;
        return (
            <AdminSelect
                className={classes.root}
                value={value}
                options={weekdayOptions}
                {...props}
            />
        );
    }
}

WeekdayPicker.propTypes = {
    // From withStyles we expect to get classes.
    classes: PropTypes.object.isRequired,

    // Value must be a weekday in short format.
    value: DatePropTypes.weekday,
};

WeekdayPicker.defaultProps = {
    // Default value is Monday's index + 1 , which is 1 because Monday is always at index 0.
    value: 1,
};

export default withStyles(styles)(WeekdayPicker);
