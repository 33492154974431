import {
    CLEANUP_DETAILED_STATE,
    CLEAR_FORM_MESSAGES,
    CLEAR_USERS_LIST_FILTERS,
    FETCH_USERS_LIST_PAGE_FAILED,
    FETCH_USERS_LIST_PAGE_REQUESTED,
    FETCH_USERS_LIST_PAGE_SUCCEEDED,
    FETCH_USER_PAYMENT_METHODS_FAILED,
    FETCH_USER_PAYMENT_METHODS_REQUESTED,
    FETCH_USER_PAYMENT_METHODS_SUCCEEDED,
    FILTERS_VALIDATION_FAILED,
    REMOVE_FORM_MESSAGE,
    SEND_USER_REPORT_FAILED,
    SEND_USER_REPORT_REQUESTED,
    SEND_USER_REPORT_SUCCEEDED,
    SET_FORM_MESSAGES,
    UPDATE_FILTER_BY_VEHICLE_FILTERS,
    UPDATE_USERS_LIST_FILTERS,
} from './actions';
import {
    CREATE_USER_FAILED,
    CREATE_USER_REQUESTED,
    CREATE_USER_SUCCEEDED,
    UPDATE_USER_REQUESTED,
    UPDATE_USER_SUCCEEDED,
    UPDATE_USER_FAILED,
    FETCH_USER_TYPES,
    UPDATE_USER_PHOTO_FAILED,
    UPDATE_USER_PHOTO_REQUESTED,
    UPDATE_USER_PHOTO_SUCCEEDED,
    UPDATE_USERS_STATUS_FAILED,
    UPDATE_USERS_STATUS_REQUESTED,
    UPDATE_USERS_STATUS_SUCCEEDED,
    UPDATE_USERS_TYPE_FAILED,
    UPDATE_USERS_TYPE_REQUESTED,
    UPDATE_USERS_TYPE_SUCCEEDED,
    DELETE_PROPERTY_FAILED,
    DELETE_PROPERTY_REQUESTED,
    DELETE_PROPERTY_SUCCEEDED,
} from './apiActions';
import usersInitialState, { initialFiltersState } from './initialState';
import { LOGOUT } from '../auth/actions';
import { CLEANUP_DETAILED_STATE as CLEANUP_DETAILED_PROPERTY_STATE } from '../properties/actions';
import {
    setLoadingUpdate,
    createMessagesFromErrors,
    removeMessage,
} from '../common/reducerUtils';
import {
    FETCH_VEHICLE_MAKES_FAILED,
    FETCH_VEHICLE_MODELS_FAILED,
    UPDATE_VEHICLE_PHOTOS_SUCCEEDED,
} from '../vehicles/actions';

/**
 * Transition the user detail state to be loading.
 */
const loadingUser = (state) => ({
    ...state,
    loading: true,
    reportSent: false,
});

/**
 * Transition the user detail state to be done, merging in additional data into the state.
 */
const doneUser = (state, data = {}) => ({
    ...state,
    loading: false,
    ...data,
});

/**
 * Transform the list state in some way.
 */
const updateListState = (state, data) => ({
    ...state,
    list: {
        ...state.list,
        ...data,
    },
});

export default function(state = usersInitialState, action = {}) {
    const { type, userIds } = action;
    switch (type) {
        // Fetch users list
        case FETCH_USERS_LIST_PAGE_REQUESTED:
            return updateListState(state, { loading: true });
        case FETCH_USERS_LIST_PAGE_SUCCEEDED:
            return updateListState(state, {
                loading: false,
                page: {
                    ...action.data,
                    filters: action.filters,
                    order: action.order,
                },
            });
        case FETCH_USERS_LIST_PAGE_FAILED:
            return updateListState(state, {
                loading: false,
                errors: action.errors,
            });

        case SEND_USER_REPORT_REQUESTED:
            return {
                ...state,
                sendingReport: true,
                reportSent: false,
                reportFailed: '',
            };
        case SEND_USER_REPORT_SUCCEEDED:
            return {
                ...state,
                sendingReport: false,
                reportSent: true,
            };
        case SEND_USER_REPORT_FAILED: {
            const { error } = action;
            return {
                ...state,
                sendingReport: false,
                reportFailed: error,
            };
        }

        // Update user detail
        case UPDATE_USER_REQUESTED:
            return loadingUser(state);
        case UPDATE_USER_FAILED: {
            const { user, errors } = action;
            return doneUser(state, {
                user,
                messages: createMessagesFromErrors(errors),
            });
        }
        case UPDATE_USER_SUCCEEDED: {
            const { user } = action;
            const messages = [
                {
                    key: 'updated_user_succeeded',
                    message: 'You have successfully updated the user',
                    type: 'success',
                },
            ];
            return doneUser(state, { user, messages });
        }

        // Fetch user detail
        case FETCH_USER_TYPES.requested:
            return loadingUser(state);
        case FETCH_USER_TYPES.failed: {
            const messages = [
                {
                    key: 'fetch_user_failed',
                    message: 'Failed to fetch this user',
                    type: 'error',
                },
            ];
            return doneUser(state, { messages });
        }
        case FETCH_USER_TYPES.succeeded: {
            const { user } = action;
            return doneUser(state, { user });
        }

        // Form messages
        case SET_FORM_MESSAGES: {
            const { messages } = action;
            return { ...state, messages };
        }
        case CLEAR_FORM_MESSAGES:
            return doneUser(state, { messages: [] });
        // User detail
        case CLEANUP_DETAILED_STATE:
            return doneUser(state, { messages: [], user: {} });
        // Properties actions
        case CLEANUP_DETAILED_PROPERTY_STATE: {
            return doneUser(state, { user: {}, messages: [] });
        }

        // Photo update actions
        case UPDATE_USER_PHOTO_REQUESTED:
            return { ...state, photoUpdateInProgress: true };
        case UPDATE_USER_PHOTO_SUCCEEDED: {
            const { user } = action;
            return { ...state, user, photoUpdateInProgress: false };
        }
        case UPDATE_USER_PHOTO_FAILED: {
            const { errors } = action;
            return {
                ...state,
                photoUpdateInProgress: false,
                messages: [
                    ...state.messages,
                    ...createMessagesFromErrors(errors),
                ],
            };
        }

        // Status update actions
        case UPDATE_USERS_STATUS_REQUESTED:
            return setLoadingUpdate(state, userIds);
        case UPDATE_USERS_STATUS_SUCCEEDED:
            return setLoadingUpdate(state, userIds, false);
        case UPDATE_USERS_STATUS_FAILED:
            return setLoadingUpdate(
                updateListState(state, { errors: action.errors }),
                userIds,
                false,
            );

        // Type update actions
        case UPDATE_USERS_TYPE_REQUESTED:
            return setLoadingUpdate(state, userIds);
        case UPDATE_USERS_TYPE_SUCCEEDED:
            return setLoadingUpdate(state, userIds, false);
        case UPDATE_USERS_TYPE_FAILED:
            return setLoadingUpdate(
                updateListState(state, { errors: action.errors }),
                userIds,
                false,
            );

        // Users list filter actions
        case UPDATE_USERS_LIST_FILTERS: {
            const { filters } = action;
            return updateListState(state, {
                page: { ...state.list.page, filters, filterErrors: [] },
            });
        }
        case CLEAR_USERS_LIST_FILTERS: {
            return updateListState(state, {
                errors: [],
                page: {
                    ...state.list.page,
                    filters: initialFiltersState,
                    filterErrors: [],
                },
            });
        }
        case UPDATE_FILTER_BY_VEHICLE_FILTERS: {
            const { vehicleFilters } = action;
            return updateListState(state, {
                page: {
                    ...state.list.page,
                    filters: {
                        ...state.list.page.filters,
                        vehicleFilters: {
                            ...state.list.page.filters.vehicleFilters,
                            ...vehicleFilters,
                        },
                    },
                },
            });
        }
        case FETCH_VEHICLE_MAKES_FAILED: {
            return updateListState(state, {
                errors: state.list.errors.concat([
                    'Failed to fetch vehicle makes for filters',
                ]),
            });
        }
        case FETCH_VEHICLE_MODELS_FAILED: {
            return updateListState(state, {
                errors: state.list.errors.concat([
                    'Failed to fetch vehicle models for filters',
                ]),
            });
        }
        case FILTERS_VALIDATION_FAILED: {
            const { filterErrors, filters } = action;
            return updateListState(state, {
                page: {
                    ...state.list.page,
                    filters,
                    filterErrors: filterErrors.map((filterError) => {
                        return {
                            type: 'error',
                            key: filterError,
                            message: filterError,
                        };
                    }),
                },
            });
        }

        case CREATE_USER_REQUESTED:
            return { ...state, creating: true };
        case CREATE_USER_SUCCEEDED: {
            const { user } = action;
            return { ...state, creating: false, user };
        }
        case CREATE_USER_FAILED: {
            const { user, errors } = action;
            return {
                ...state,
                creating: false,
                user,
                messages: createMessagesFromErrors(errors),
            };
        }

        case DELETE_PROPERTY_REQUESTED:
            return { ...state, propertyDeleteInProgress: true };
        case DELETE_PROPERTY_SUCCEEDED: {
            const { propertyId, propertyName } = action;
            return {
                ...state,
                propertyDeleteInProgress: false,
                messages: [
                    {
                        message: `Successfully deleted ${propertyName}`,
                        key: propertyId,
                        type: 'success',
                    },
                ],
            };
        }
        case DELETE_PROPERTY_FAILED: {
            const { propertyId, propertyName } = action;
            return {
                ...state,
                propertyDeleteInProgress: false,
                messages: [
                    {
                        message: `Failed to delete ${propertyName}`,
                        key: propertyId,
                        type: 'error',
                    },
                ],
            };
        }

        case REMOVE_FORM_MESSAGE:
            return {
                ...state,
                messages: removeMessage(state.messages, action.messageKey),
            };

        case UPDATE_VEHICLE_PHOTOS_SUCCEEDED: {
            const { vehicleId, updatedPhotos } = action;
            const {
                user,
                user: { vehicles = [] },
            } = state;
            return {
                ...state,
                user: {
                    ...user,
                    vehicles: vehicles.map((vehicle) => {
                        if (`${vehicleId}` === `${vehicle.vehicleId}`) {
                            vehicle.photos = updatedPhotos;
                        }
                        return vehicle;
                    }),
                },
            };
        }

        case FETCH_USER_PAYMENT_METHODS_REQUESTED: {
            return {
                ...state,
                paymentMethodFetchInProgress: true,
            };
        }

        case FETCH_USER_PAYMENT_METHODS_SUCCEEDED: {
            const { paymentMethods } = action;
            return {
                ...state,
                paymentMethodFetchInProgress: false,
                user: {
                    ...state.user,
                    paymentMethods: paymentMethods.cards || [],
                },
            };
        }

        case FETCH_USER_PAYMENT_METHODS_FAILED: {
            return {
                ...state,
                messages: [
                    {
                        message: `Failed to load user payment methods`,
                        key: 'fetch_payment_methods_failed',
                        type: 'info',
                    },
                ],
            };
        }

        // Clear on logout
        case LOGOUT:
            return usersInitialState;

        default:
            return state;
    }
}
