import { DateTime, Interval } from 'luxon';
import { transformPhotos, transformTimes } from '../api/transforms';
import {
    RATE_TYPE_DAILY,
    RATE_TYPE_HOURLY,
    RATE_TYPE_MONTHLY,
} from '../../docks/DockForm/constants';

/**
 * Transforms blackoutTimes received from the API to be luxon Intervals
 * so they can be consumed by the CalendarPicker component.
 * @param blackoutTimes
 * @returns {Interval[]}
 */
export const transformBlackoutTimes = (blackoutTimes = []) => {
    return blackoutTimes.map((blackoutTime) => {
        const { startTime, endTime } = blackoutTime;
        if (blackoutTime instanceof Interval) {
            // If its already an interval we can just return it.
            return blackoutTime;
        } else {
            return Interval.fromDateTimes(
                DateTime.fromISO(startTime),
                DateTime.fromISO(endTime),
            );
        }
    });
};

/**
 * Transforms the blackoutTimes from the format they are stored in
 * on the form to a format that can be accepted by the API server,
 * i.e. timestamps for startTime and endTime.
 *
 * @param blackoutTimes
 * @returns {*}
 */
export const transformBlackoutTimesToTimestamps = (blackoutTimes) => {
    return blackoutTimes.map((blackoutTime) => {
        if (blackoutTime instanceof Interval) {
            const { start, end } = blackoutTime;
            return {
                startTime: start.toFormat('X'),
                endTime: end.toFormat('X'),
            };
        } else {
            return blackoutTime;
        }
    });
};

/**
 * Transforms the dock times from the format used in the
 * form to a format that is accepted by the API server.
 *
 * @param dockTimes
 * @returns {*}
 */
export const transformDockTimesForAPI = (dockTimes) => {
    return dockTimes.map((dockTime) => {
        const { key, start, end } = dockTime;
        return {
            timeId: key,
            startDay: start.day,
            startTime: start.time.toFormat('HH:mm'),
            endDay: end.day,
            endTime: end.time.toFormat('HH:mm'),
        };
    });
};

/**
 * Transforms the API data received for a dock so it can be consumed
 * by the DockForm component.
 * @param dock
 * @returns {{blackoutTimes: Interval[],
 *            dockTimes: {start: {time: DateTime, day}, end: {time: DateTime, day}, key}[],
 *            photos: {photoUrl, key}[]}}
 */
export const transformDock = (dock = {}) => {
    const { blackoutTimes, dockTimes, photos, ...rest } = dock;
    return {
        ...rest,
        blackoutTimes: transformBlackoutTimes(blackoutTimes),
        dockTimes: transformTimes(dockTimes),
        photos: transformPhotos(photos),
        selectedRateTypes: getSelectedRateTypesFromExistingDock(dock),
    };
};

export const getSelectedRateTypesFromExistingDock = (dock) => {
    const selectedRateTypes = [];
    if (dock) {
        const { hourlyRate, dailyRate, monthlyRate } = dock;
        if (hourlyRate) {
            selectedRateTypes.push(RATE_TYPE_HOURLY);
        }
        if (dailyRate) {
            selectedRateTypes.push(RATE_TYPE_DAILY);
        }
        if (monthlyRate) {
            selectedRateTypes.push(RATE_TYPE_MONTHLY);
        }
    }
    return selectedRateTypes;
};
