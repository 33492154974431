import { createSelector } from 'reselect';
import {
    createApiPageContextSelector,
    createApiPageDataSelector,
} from '../../utils/api';
import { createTablePageDataSelector } from '../../tables/utils';
import { formatDate } from '../../utils/dates';
import { DURATION_DATE_FORMAT } from './constants';
import { getPathname } from '../router/selectors';

export const isReservationsListLoading = (state) =>
    state.reservations.list.loading;
export const getReservationsListPage = (state) => state.reservations.list.page;
export const getReservationsListPageTransformed = createSelector(
    getReservationsListPage,
    (reservationsPage) => {
        reservationsPage.data =
            reservationsPage.data && reservationsPage.data.length
                ? reservationsPage.data.map((reservation) => {
                      const { startTime, endTime } = reservation;
                      return {
                          ...reservation,
                          duration: `${formatDate(
                              startTime,
                              DURATION_DATE_FORMAT,
                          )} \n ${formatDate(endTime, DURATION_DATE_FORMAT)}`,
                          photoUrl:
                              reservation.photo && reservation.photo.uploadUrl
                                  ? reservation.photo.uploadUrl
                                  : '',
                      };
                  })
                : [];
        return reservationsPage;
    },
);

export const getReservationsListApiPageData = createApiPageDataSelector(
    getReservationsListPageTransformed,
);
export const getReservationsListTablePageData = createTablePageDataSelector(
    getReservationsListApiPageData,
    isReservationsListLoading,
);
export const getReservationsListPageContext = createApiPageContextSelector(
    getReservationsListPage,
);
export const getReservationsListErrors = (state) =>
    state.reservations.list.errors;

export const getReservationMessages = (state) => state.reservations.messages;

export const getReservationsState = (state) => state.reservations || {};

export const arePropertiesAndDocksForFiltersLoading = createSelector(
    getReservationsState,
    (reservations) => reservations.propertiesListLoading,
);
export const getPropertiesAndDocksForFilters = createSelector(
    getReservationsState,
    (reservations) => {
        return reservations.propertiesList && reservations.propertiesList.length
            ? reservations.propertiesList.map((property) => {
                  const {
                      docks = [],
                      propertyName = '',
                      propertyId = '',
                  } = property;
                  return {
                      label: `${propertyName} (${propertyId})`,
                      value: propertyId,
                      docks: docks.map((dock) => {
                          const { dockId, dockName } = dock;
                          return {
                              label: `${dockName} (${dockId})`,
                              value: dockId,
                          };
                      }),
                  };
              })
            : [];
    },
);

export const getDocksForFilters = createSelector(
    getPropertiesAndDocksForFilters,
    (properties) => {
        return properties.reduce((accumulatedDocks, property) => {
            return [...accumulatedDocks, ...property.docks];
        }, []);
    },
);

export const areUsersForFiltersLoading = createSelector(
    getReservationsState,
    (reservations) => reservations.usersListLoading,
);

export const getUsersForFilters = createSelector(
    getReservationsState,
    (reservations) => {
        const { usersList = [] } = reservations;
        return usersList.map(({ displayName, userId }) => {
            return {
                label: `${displayName} (${userId})`,
                value: userId,
            };
        });
    },
);

export const isReservationDetailLoading = createSelector(
    getReservationsState,
    (reservations) => reservations.reservationLoading,
);

export const getReservationDetails = createSelector(
    getReservationsState,
    (reservations) => {
        const { reservation = {} } = reservations;
        return {
            ...reservation,
            cost: `$${reservation.cost || ''}`,
            start: formatDate(
                reservation.startTime || '',
                DURATION_DATE_FORMAT,
            ),
            end: formatDate(reservation.endTime || '', DURATION_DATE_FORMAT),
            reservedAt: formatDate(reservation.reservedAt || ''),
            photoUrl:
                reservation.photo && reservation.photo.uploadUrl
                    ? reservation.photo.uploadUrl
                    : '',
            vehicle: `${reservation.vehicleId} - ${reservation.vehicleName ||
                ''}`,
        };
    },
);

export const isCreatingReservation = createSelector(
    getReservationsState,
    (reservations) => {
        const { reservation = {} } = reservations;
        return !reservation.reservationId;
    },
);

export const isReservationUserSearchLoading = createSelector(
    getReservationsState,
    ({ userSearchLoading }) => userSearchLoading,
);

export const getReservationUserSearchResults = createSelector(
    getReservationsState,
    ({ userSearchResults = [] }) =>
        userSearchResults.map((user = {}) => {
            const userAddress = [
                `${user?.userAddress || ''}`,
                `${user?.userAddressMore || ''}`,
                `${user?.userCity || ''} ${user?.region?.regionName ||
                    ''} ${user?.userZipCode || ''}`,
            ];
            return {
                id: user?.userId,
                imageUrl: user?.photo ? user?.photo?.uploadUrl : '',
                resultHeader: user?.displayName,
                vehicles: user?.vehicles,
                labels: [
                    { label: 'Email', value: [user?.email] },
                    { label: 'Address', value: userAddress },
                    { label: 'Phone', value: [user?.userPhoneNumber] },
                    { label: 'Type', value: [user?.group?.groupName] },
                    {
                        label: 'Status',
                        value: user?.userActive ? ['Active'] : ['Disabled'],
                    },
                ],
            };
        }),
);

export const getReservationUserPaymentMethods = createSelector(
    getReservationsState,
    ({ userPaymentMethods = [] }) =>
        userPaymentMethods.map((paymentMethod) => {
            const {
                id,
                brand,
                last4,
                expMonth,
                expYear,
                isDefault,
            } = paymentMethod;
            return {
                label: `${brand} ending in ${last4} - Exp: ${expMonth}/${expYear} ${
                    isDefault ? '[Default]' : ''
                } `,
                value: id,
            };
        }),
);

export const getReservationIdInPath = createSelector(getPathname, (path) => {
    try {
        if (!path) {
            return '';
        }
        const reservationId = path.replace('/reservations/', '');
        if (isNaN(reservationId)) {
            return '';
        }
        return reservationId;
    } catch (error) {
        return '';
    }
});

export const getIsReservationInProgress = createSelector(
    getReservationsState,
    (reservations) => reservations.reservationInProgress,
);

export const getPropertyDockInfo = createSelector(
    getReservationsState,
    (reservations) => reservations.property?.docks,
);
