import { createSelector } from 'reselect';

export const getVehiclesState = (state) => state.vehicles || {};

export const areVehicleUpdatesInProgress = createSelector(
    getVehiclesState,
    (vehicles) => vehicles.updateInProgress,
);

export const getMessages = createSelector(
    getVehiclesState,
    (vehicles) => vehicles.messages || [],
);

export const getVehicleMakes = createSelector(
    getVehiclesState,
    (vehicles) =>
        vehicles.vehicleMakes.map((make) => {
            return {
                value: make.makeId,
                label: make.makeName,
            };
        }) || [],
);

export const getVehicleModels = createSelector(
    getVehiclesState,
    (vehicles) =>
        vehicles.vehicleModels.map((model) => {
            return {
                value: model.modelId,
                label: model.modelName,
                makeId: model.makeId,
            };
        }) || [],
);
