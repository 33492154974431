import React from 'react';
import PropTypes from 'prop-types';

import { AppBar, Tab, Tabs, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

import styles from './styles';
import { matchesPaths } from '../../../utils';

import ExternalIcon from '../../ExternalIcon';

const AdminAppBar = ({ classes, menuItems, path }) => {
    const tabsValue = menuItems.reduce((matchedRoute, { route = null }) => {
        return route && matchesPaths(route, [path]) ? route : matchedRoute;
    }, '');
    return (
        <AppBar
            position="static"
            color="default"
            className={classes.appBar}
            elevation={0}
        >
            <Tabs
                value={tabsValue}
                textColor="primary"
                TabIndicatorProps={{ className: classes.tabIndicator }}
                classes={{ scrollable: classes.tabsScrollable }}
                variant="scrollable"
                scrollButtons="on"
            >
                {menuItems.map((menuItem) => {
                    const {
                        label,
                        route = '',
                        href = '',
                        icon = '',
                    } = menuItem;
                    const tabComponentProps = route
                        ? { to: route, component: Link }
                        : {
                              href,
                              component: 'a',
                              target: '_blank',
                              icon: icon || <ExternalIcon />,
                          };
                    return route || href ? (
                        <Tab
                            label={label}
                            key={label}
                            value={route}
                            icon={icon}
                            classes={{
                                root: classes.appBarTab,
                                label: classes.appBarTabLabel,
                                labelContainer: classes.appBarTabLabelContainer,
                                wrapper: classes.appBarTabWrapper,
                            }}
                            {...tabComponentProps}
                        />
                    ) : null;
                })}
            </Tabs>
        </AppBar>
    );
};

AdminAppBar.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // An array of labels for routes/urls to render with optional icons
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            route: PropTypes.string,
            href: PropTypes.string,
            icon: PropTypes.object,
        }),
    ),

    // The current path of the react router
    path: PropTypes.string.isRequired,
};

export default withStyles(styles)(AdminAppBar);
