export const END = 'end';
export const START = 'start';

export const MONTH = 'month';
export const DAY = 'day';
export const YEAR = 'year';
export const TIME = 'time';

export const APPLY = 'apply';
export const CLEAR = 'clear';

export const DOCKS = 'docks';
export const RESERVATIONS = 'reservations';

export const MIN = 'min';
export const MAX = 'max';

export const START_MONTH_REQUIRED_ERROR_MESSAGE = 'Start Month required.';
export const START_DAY_REQUIRED_ERROR_MESSAGE = 'Start Day required.';
export const START_YEAR_REQUIRED_ERROR_MESSSGE = 'Start Year required.';

export const END_MONTH_REQUIRED_ERROR_MESSAGE = 'End Month required.';
export const END_DAY_REQUIRED_ERROR_MESSAGE = 'End Day required.';
export const END_YEAR_REQUIRED_ERROR_MESSAGE = 'End Year required.';

export const ACTIVE_MONTH_REQUIRED_ERROR_MESSAGE = 'Active Month required.';
export const ACTIVE_DAY_REQUIRED_ERROR_MESSAGE = 'Active Day required.';
export const ACTIVE_YEAR_REQUIRED_ERROR_MESSAGE = 'Active Year required.';
export const ACTIVE_TIME_REQUIRED_ERROR_MESSAGE = 'Active Time required.';

export const UPDATE_RESERVATION_TITLE = 'Update Reservation';
export const CREATE_RESERVATION_TITLE = 'Create Reservation';
export const CREATE_RESERVATION_CONFIRMATION =
    'Do you wish to create this reservation?';
export const UPDATE_RESERVATION_CONFIRMATION =
    'Do you wish to update this reservation?';
