const styles = (theme) => ({
    appBar: {
        backgroundColor: 'inherit !important',
        margin: '0 auto',
        maxWidth: '100vw',
    },
    appBarTab: {
        minWidth: 0,
        padding: '0px ' + theme.custom.headerPadding * 2 + 'px',
        [theme.breakpoints.down('md')]: {
            padding: '0px ' + theme.custom.headerPadding + 'px',
        },
        [theme.breakpoints.up('sm')]: {
            '&:last-child': {
                paddingRight: 0,
            },
        },
    },
    appBarTabWrapper: {
        flexDirection: 'row-reverse',
    },
    appBarTabLabel: {
        fontSize: 14,
        fontWeight: 'bold',
        letterSpacing: 2,
        color: theme.palette.primary.main,
        [theme.breakpoints.down('md')]: {
            fontSize: 12,
        },
    },
    appBarTabLabelContainer: {
        padding: '0px ' + theme.custom.headerPadding + 'px',
    },
    tabsScrollable: {
        [theme.breakpoints.up('sm')]: {
            marginRight: -6,
            marginLeft: -6,
        },
    },
    tabIndicator: {
        backgroundColor: theme.palette.secondary.contrastText,
        height: 6,
    },
});

export default styles;
