import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styles from './styles';
import PropTypes from './propTypes';

const AdminInnerHeader = (props) => {
    const { classes, title, backText, backTo, rightContent } = props;
    return (
        <Grid
            item
            container
            direction="row"
            alignItems="center"
            justify="center"
            className={classes.root}
        >
            <Grid
                item
                container
                direction="row"
                alignItems="center"
                className={classes.content}
            >
                <Grid item className={classes.titleContainer}>
                    <Typography variant="subtitle1" className={classes.title}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item className={classes.linkContainer}>
                    {backTo ? (
                        <Link to={backTo} className={classes.link}>
                            <Typography className={classes.backText}>
                                {backText}
                            </Typography>
                        </Link>
                    ) : null}
                </Grid>
                {rightContent ? <Grid item>{rightContent}</Grid> : null}
            </Grid>
        </Grid>
    );
};

AdminInnerHeader.propTypes = PropTypes;

AdminInnerHeader.defaultProps = {
    backText: 'Go Back',
    backTo: null,
    rightContent: null,
};

export default withStyles(styles)(AdminInnerHeader);
export { PropTypes };
