import {
    FETCH_REGIONS_REQUESTED,
    FETCH_REGIONS_SUCCEEDED,
    FETCH_REGIONS_FAILED,
    FETCH_REGIONS_FINISHED,
} from './actions';
import { LOGOUT } from '../auth/actions';

import { regionsInitialState } from './initialState';

export default function(state = regionsInitialState, action = {}) {
    const { type, countryAbbr, data } = action;
    switch (type) {
        case FETCH_REGIONS_REQUESTED:
            return { ...state, loading: true };
        case FETCH_REGIONS_SUCCEEDED:
            return {
                ...state,
                loading: false,
                data: {
                    ...state.data,
                    [countryAbbr]: data,
                },
            };
        case FETCH_REGIONS_FINISHED: // Intentional fall through
        case FETCH_REGIONS_FAILED:
            return { ...state, loading: false };
        case LOGOUT:
            return regionsInitialState;
        default:
            return state;
    }
}
