import {
    put,
    select,
    takeEvery,
    all,
    takeLatest,
    call,
} from '@redux-saga/core/effects';
import { getReservationsListPageContext } from '../reservations/selectors';
import {
    CLEAR_RESERVATIONS_LIST_FILTERS,
    FETCH_PROPERTIES_LIST_FOR_FILTERS_REQUESTED,
    FETCH_RESERVATION_ACTIONS,
    FETCH_RESERVATION_TYPES,
    FETCH_RESERVATIONS_LIST_PAGE_REQUESTED,
    FETCH_USERS_LIST_FOR_FILTERS_REQUESTED,
    fetchPropertiesListForFiltersFailed,
    fetchPropertiesListForFiltersRequested,
    fetchPropertiesListForFiltersSucceeded,
    fetchReservationsListPageFailed,
    fetchReservationsListPageRequested,
    fetchReservationsListPageSucceeded,
    fetchUsersListForFiltersFailed,
    fetchUsersListForFiltersRequested,
    fetchUsersListForFiltersSucceeded,
    RESERVATIONS_LIST_VIEW_LOAD_REQUESTED,
    UPDATE_RESERVATION_REQUESTED,
    updateReservationFailed,
    updateReservationSucceeded,
    clearReservationLoadingState,
    bookReservationGetUserPaymentMethodsSucceeded,
    bookReservationGetUserPaymentMethodsFailed,
    BOOK_RESERVATION_GET_USER_PAYMENT_METHODS_REQUESTED,
    BOOK_RESERVATION_REQUESTED,
    bookReservationFailed,
    bookReservationSucceeded,
    BOOK_RESERVATION_GET_PROPERTY_INFO_REQUESTED,
    bookReservationGetPropertyInfoSucceeded,
    bookReservationGetPropertyInfoFailed,
} from '../reservations/actions';
import {
    createPaginatedRequestSaga,
    fetchWithAuth,
    makeRequestWithAuth,
} from '../api/sagas';
import { isEmpty } from 'ramda';
import {
    getDateTime,
    getEndOfDateTimestamp,
    getMonthDayAndYearFromObject,
    getStartOfDateTimestamp,
    hasDay,
    hasMonth,
    hasYear,
} from '../../utils/dates';
import { DateTime } from 'luxon';
import { getPathname } from '../router/selectors';

export function* loadReservationsListView() {
    const currentContext = yield select(getReservationsListPageContext);
    yield put(fetchPropertiesListForFiltersRequested());
    yield put(fetchUsersListForFiltersRequested());
    yield put(fetchReservationsListPageRequested(currentContext));
}

const fetchReservationsListPage = createPaginatedRequestSaga({
    path: '/reservation/upcoming',
    actions: {
        succeeded: fetchReservationsListPageSucceeded,
        failed: fetchReservationsListPageFailed,
    },
    applyFilters: (query, filters) => {
        const {
            search,
            checked,
            start,
            end,
            active,
            selectedPropertyId,
            selectedDockIds,
            selectedUserIds,
        } = filters;
        const queryFilters = {};

        if (checked && checked.length) {
            const cancelled = checked.includes('Cancelled');
            const notCancelled = checked.includes('Not Cancelled');
            if (cancelled !== notCancelled) {
                if (cancelled) {
                    queryFilters.cancelled = true;
                } else if (notCancelled) {
                    queryFilters.cancelled = false;
                }
            }
        }

        if (
            !isEmpty(start) &&
            hasMonth(start) &&
            hasDay(start) &&
            hasYear(start)
        ) {
            const dateTime = DateTime.fromObject(
                getMonthDayAndYearFromObject(start),
            );
            const { time } = start;
            if (time && time instanceof DateTime) {
                const { hour, minute, second, millisecond } = time;
                const newDateTime = dateTime.set({
                    hour,
                    minute,
                    second,
                    millisecond,
                });
                queryFilters.startTime = Math.round(
                    newDateTime.toMillis() / 1000,
                );
            } else {
                queryFilters.startTime = getStartOfDateTimestamp(dateTime);
            }
        }

        if (!isEmpty(end) && hasMonth(end) && hasDay(end) && hasYear(end)) {
            const dateTime = DateTime.fromObject(
                getMonthDayAndYearFromObject(end),
            );
            const { time } = end;
            if (time) {
                const { hour, minute, second, millisecond } = time;
                const newDateTime = dateTime.set({
                    hour,
                    minute,
                    second,
                    millisecond,
                });
                queryFilters.endTime = Math.round(
                    newDateTime.toMillis() / 1000,
                );
            } else {
                queryFilters.endTime = getEndOfDateTimestamp(dateTime);
            }
        }

        if (
            !isEmpty(active) &&
            hasMonth(active) &&
            hasDay(active) &&
            hasYear(active) &&
            active.time
        ) {
            const dateTime = DateTime.fromObject(
                getMonthDayAndYearFromObject(active),
            );
            const { time } = active;
            if (time) {
                const { hour, minute, second, millisecond } = time;
                const newDateTime = dateTime.set({
                    hour,
                    minute,
                    second,
                    millisecond,
                });
                queryFilters.activeTime = Math.round(
                    newDateTime.toMillis() / 1000,
                );
            }
        }

        if (selectedPropertyId) {
            queryFilters.propertyId = selectedPropertyId;
        }

        if (selectedDockIds && selectedDockIds.length) {
            queryFilters.dockIds = selectedDockIds;
        }

        if (selectedUserIds && selectedUserIds.length) {
            queryFilters.userIds = selectedUserIds;
        }
        return {
            ...(query || {}),
            search: search || '',
            ...queryFilters,
        };
    },
});

export function* fetchPropertiesListForFilters() {
    try {
        const response = yield call(fetchWithAuth, '/property/listPaid');
        yield put(fetchPropertiesListForFiltersSucceeded(response));
    } catch (error) {
        yield put(
            fetchPropertiesListForFiltersFailed(
                'Failed to fetch Properties list for filters.',
            ),
        );
    }
}

export function* fetchUsersListForFilters() {
    try {
        const response = yield call(fetchWithAuth, '/user/listTruckers');
        yield put(fetchUsersListForFiltersSucceeded(response));
    } catch (error) {
        yield put(
            fetchUsersListForFiltersFailed(
                'Failed to fetch Users list for filters.',
            ),
        );
    }
}

export function* fetchReservation() {
    const path = yield select(getPathname);
    const reservationId = path.replace('/reservations/', '');
    if (!isNaN(reservationId)) {
        yield call(makeRequestWithAuth, {
            actions: FETCH_RESERVATION_ACTIONS,
            path: `/reservation/${reservationId}`,
            method: 'GET',
        });
    } else {
        yield put(clearReservationLoadingState());
    }
}

export function* updateReservation(data) {
    try {
        const path = yield select(getPathname);
        const reservationId = path.replace('/reservations/', '');
        const response = yield call(
            fetchWithAuth,
            `/reservation/${reservationId}`,
            {
                method: 'PUT',
                body: {
                    ...data.data,
                },
            },
        );
        yield put(updateReservationSucceeded(response));
    } catch (error) {
        yield put(updateReservationFailed(error.json));
    }
}

export function* createReservation({ data, history }) {
    try {
        const {
            dockId,
            vehicle,
            rateType,
            duration: { start: { c: startObject }, end: { c: endObject } } = {},
            paymentId,
            userId,
            vehiclePlate,
        } = data;
        const {
            day: startDay,
            month: startMonth,
            year: startYear,
            hour: startHour,
            minute: startMinute,
        } = startObject;
        const {
            day: endDay,
            month: endMonth,
            year: endYear,
            hour: endHour,
            minute: endMinute,
        } = endObject;
        const startDateTime = getDateTime(
            startMinute,
            startHour,
            startDay,
            startMonth,
            startYear,
        );
        const endDateTime = getDateTime(
            endMinute,
            endHour,
            endDay,
            endMonth,
            endYear,
        );
        const startTime = startDateTime / 1000;
        const endTime = endDateTime / 1000;
        const bookedReservation = yield call(
            fetchWithAuth,
            `/dock/${dockId}/reservation`,
            {
                method: 'POST',
                body: {
                    vehicleId: vehicle,
                    startTime,
                    endTime,
                    source: paymentId,
                    userId,
                    rateType,
                    vehiclePlate,
                },
            },
        );
        yield put(bookReservationSucceeded());
        // Navigate to the reservation detail screen, this automatically fetches the correct data to display
        history.push(`/reservations/${bookedReservation.reservationId}`);
    } catch (error) {
        yield put(bookReservationFailed());
    }
}

export function* fetchPaymentMethodsForBookingReservation({ userId }) {
    try {
        const paymentMethods = yield call(fetchWithAuth, `/cards/${userId}`, {
            method: 'GET',
        });
        yield put(
            bookReservationGetUserPaymentMethodsSucceeded(paymentMethods),
        );
    } catch (error) {
        yield put(bookReservationGetUserPaymentMethodsFailed());
    }
}

export function* fetchPropertyForBookingReservation({ propertyId }) {
    try {
        const response = yield call(fetchWithAuth, `property/${propertyId}`);
        if (!response || response.error) {
            yield put(bookReservationGetPropertyInfoFailed());
        } else {
            yield put(bookReservationGetPropertyInfoSucceeded(response));
        }
    } catch (error) {
        yield put(bookReservationGetPropertyInfoFailed());
    }
}

export default function*() {
    yield all([
        takeEvery(
            RESERVATIONS_LIST_VIEW_LOAD_REQUESTED,
            loadReservationsListView,
        ),
        takeLatest(CLEAR_RESERVATIONS_LIST_FILTERS, loadReservationsListView),
        takeLatest(
            FETCH_RESERVATIONS_LIST_PAGE_REQUESTED,
            fetchReservationsListPage,
        ),
        takeLatest(
            FETCH_PROPERTIES_LIST_FOR_FILTERS_REQUESTED,
            fetchPropertiesListForFilters,
        ),
        takeLatest(
            FETCH_USERS_LIST_FOR_FILTERS_REQUESTED,
            fetchUsersListForFilters,
        ),
        takeLatest(
            BOOK_RESERVATION_GET_PROPERTY_INFO_REQUESTED,
            fetchPropertyForBookingReservation,
        ),
        takeLatest(FETCH_RESERVATION_TYPES.requested, fetchReservation),
        takeLatest(UPDATE_RESERVATION_REQUESTED, updateReservation),
        takeLatest(BOOK_RESERVATION_REQUESTED, createReservation),
        takeLatest(
            BOOK_RESERVATION_GET_USER_PAYMENT_METHODS_REQUESTED,
            fetchPaymentMethodsForBookingReservation,
        ),
    ]);
}
