import {
    createActionsAndTypesFromTypeDef,
    defineRequestActions,
    getRequestActionsForType,
    getRequestTypes,
} from '../../utils/api';
import {
    DELETE_DOCK,
    FETCH_DOCK,
    PREFIX,
    SET_FORM_MESSAGES,
} from '../docks/actions';
import { TYPES_PREFIX } from '../properties/constants';

export const ADD_DOCK_PHOTOS_REQUESTED = `${TYPES_PREFIX}addDockPhotosRequested`;
export const ADD_DOCK_PHOTOS_SUCCEEDED = `${TYPES_PREFIX}addDockPhotosSucceeded`;
export const ADD_DOCK_PHOTOS_FAILED = `${TYPES_PREFIX}addDockPhotosFailed`;

export const CREATE_DOCK_REQUESTED = `${TYPES_PREFIX}CREATE_DOCK_REQUESTED`;
export const CREATE_DOCK_SUCCEEDED = `${TYPES_PREFIX}CREATE_DOCK_SUCCEEDED`;
export const CREATE_DOCK_FAILED = `${TYPES_PREFIX}CREATE_DOCK_FAILED`;

export const UPDATE_DOCK_REQUESTED = `${TYPES_PREFIX}UPDATE_DOCK_REQUESTED`;
export const UPDATE_DOCK_SUCCEEDED = `${TYPES_PREFIX}UPDATE_DOCK_SUCCEEDED`;
export const UPDATE_DOCK_FAILED = `${TYPES_PREFIX}UPDATE_DOCK_FAILED`;

const typesDefs = {
    ...defineRequestActions(FETCH_DOCK, {
        requestedParams: ['dockId'],
        succeededParams: ['dock'],
        failedParams: ['errors'],
    }),
    ...defineRequestActions(DELETE_DOCK, {
        requestedParams: ['dockId'],
        failedParams: ['errors'],
    }),
    [SET_FORM_MESSAGES]: ['messages'],
};

const { types, actions } = createActionsAndTypesFromTypeDef(typesDefs, PREFIX);

export const FETCH_DOCK_TYPES = getRequestTypes(types, FETCH_DOCK);
export const FETCH_DOCK_ACTIONS = getRequestActionsForType(actions, FETCH_DOCK);

export const DELETE_DOCK_TYPES = getRequestTypes(types, DELETE_DOCK);
export const DELETE_DOCK_ACTIONS = getRequestActionsForType(
    actions,
    DELETE_DOCK,
);

export const dockTypes = types;
export const dockActions = actions;

export const addDockPhotosRequested = () => {
    return {
        type: ADD_DOCK_PHOTOS_REQUESTED,
    };
};

export const addDockPhotosSucceeded = (photos) => {
    return {
        type: ADD_DOCK_PHOTOS_SUCCEEDED,
        photos,
    };
};

export const addDockPhotosFailed = (errors) => {
    return {
        type: ADD_DOCK_PHOTOS_FAILED,
        errors,
    };
};

export const createDockRequested = (propertyId, dock) => {
    return {
        type: CREATE_DOCK_REQUESTED,
        propertyId,
        dock,
    };
};

export const createDockSucceeded = (dock) => {
    return {
        type: CREATE_DOCK_SUCCEEDED,
        dock,
    };
};

export const createDockFailed = (errors, dock) => {
    return {
        type: CREATE_DOCK_FAILED,
        errors,
        dock,
    };
};

export const updateDockRequested = (dock) => {
    return {
        type: UPDATE_DOCK_REQUESTED,
        dock,
    };
};

export const updateDockSucceeded = (dock) => {
    return {
        type: UPDATE_DOCK_SUCCEEDED,
        dock,
    };
};

export const updateDockFailed = (errors, dock) => {
    return {
        type: UPDATE_DOCK_FAILED,
        errors,
        dock,
    };
};
