import {
    createActionsAndTypesFromTypeDef,
    getRequestActionsForType,
    defineRequestActions,
    getRequestTypes,
} from '../../utils/api';
import { TYPES_PREFIX } from './constants';
import { PREFIX } from './actions';

const FETCH_PROPERTY = 'fetchProperty';
const DELETE_PROPERTY = 'deleteProperty';

export const ADD_PROPERTY_PHOTOS_REQUESTED = `${TYPES_PREFIX}addPropertyPhotosRequested`;
export const ADD_PROPERTY_PHOTOS_SUCCEEDED = `${TYPES_PREFIX}addPropertyPhotosSucceeded`;
export const ADD_PROPERTY_PHOTOS_FAILED = `${TYPES_PREFIX}addPropertyPhotosFailed`;

export const DELETE_DOCK_REQUESTED = `${PREFIX}DELETE_DOCK`;
export const DELETE_DOCK_SUCCEEDED = `${PREFIX}DELETE_DOCK_SUCCEEDED`;
export const DELETE_DOCK_FAILED = `${PREFIX}DELETE_DOCK_FAILED`;

export const UPDATE_PROPERTIES_STATUS_REQUESTED = `${PREFIX}updatePropertiesStatusRequested`;
export const UPDATE_PROPERTIES_STATUS_SUCCEEDED = `${PREFIX}updatePropertiesStatusSucceded`;
export const UPDATE_PROPERTIES_STATUS_FAILED = `${PREFIX}updatePropertiesStatusFailed`;

export const FETCH_OWNER_FOR_NEW_PROPERTY_REQUESTED = `${PREFIX}FETCH_OWNER_FOR_NEW_PROPERTY_REQUESTED`;
export const FETCH_OWNER_FOR_NEW_PROPERTY_SUCCEEDED = `${PREFIX}FETCH_OWNER_FOR_NEW_PROPERTY_SUCCEEDED`;
export const FETCH_OWNER_FOR_NEW_PROPERTY_FAILED = `${PREFIX}FETCH_OWNER_FOR_NEW_PROPERTY_FAILED`;

export const CREATE_PROPERTY_REQUESTED = `${PREFIX}CREATE_PROPERTY_REQUESTED`;
export const CREATE_PROPERTY_SUCCEEDED = `${PREFIX}CREATE_PROPERTY_SUCCEEDED`;
export const CREATE_PROPERTY_FAILED = `${PREFIX}CREATE_PROPERTY_FAILED`;

export const UPDATE_PROPERTY_REQUESTED = `${PREFIX}UPDATE_PROPERTY_REQUESTED`;
export const UPDATE_PROPERTY_SUCCEEDED = `${PREFIX}UPDATE_PROPERTY_SUCCEEDED`;
export const UPDATE_PROPERTY_FAILED = `${PREFIX}UPDATE_PROPERTY_FAILED`;

export const DELETE_PROPERTIES_REQUESTED = `${PREFIX}DELETE_PROPERTIES_REQUESTED`;
export const DELETE_PROPERTIES_SUCCEEDED = `${PREFIX}DELETE_PROPERTIES_SUCCEEDED`;
export const DELETE_PROPERTIES_FAILED = `${PREFIX}DELETE_PROPERTIES_FAILED`;
export const CLEAR_DELETE_ERRORS = `${PREFIX}CLEAR_DELETE_ERRORS`;

const typesDefs = {
    ...defineRequestActions(FETCH_PROPERTY, {
        requestedParams: ['propertyId'],
        succeededParams: ['property'],
        failedParams: ['errors'],
    }),
    ...defineRequestActions(DELETE_PROPERTY, {
        requestedParams: ['propertyId'],
        failedParams: ['errors'],
    }),
};

const { types, actions } = createActionsAndTypesFromTypeDef(
    typesDefs,
    TYPES_PREFIX,
);

export const propertyTypes = types;
export const propertyActions = actions;

export const FETCH_PROPERTY_TYPES = getRequestTypes(types, FETCH_PROPERTY);
export const FETCH_PROPERTY_ACTIONS = getRequestActionsForType(
    actions,
    FETCH_PROPERTY,
);

export const DELETE_PROPERTY_TYPES = getRequestTypes(types, DELETE_PROPERTY);
export const DELETE_PROPERTY_ACTIONS = getRequestActionsForType(
    actions,
    DELETE_PROPERTY,
);

export const addPropertyPhotosRequested = () => {
    return {
        type: ADD_PROPERTY_PHOTOS_REQUESTED,
    };
};

export const addPropertyPhotosSucceeded = (photos) => {
    return {
        type: ADD_PROPERTY_PHOTOS_SUCCEEDED,
        photos,
    };
};

export const addPropertyPhotosFailed = (errors) => {
    return {
        type: ADD_PROPERTY_PHOTOS_FAILED,
        errors,
    };
};

export function deleteDockRequested(propertyId, dockId, dockName) {
    return {
        type: DELETE_DOCK_REQUESTED,
        propertyId,
        dockId,
        dockName,
    };
}

export function deleteDockSucceeded(propertyId, dockId, dockName) {
    return {
        type: DELETE_DOCK_SUCCEEDED,
        propertyId,
        dockId,
        dockName,
    };
}

export function deleteDockFailed(dockId, dockName) {
    return {
        type: DELETE_DOCK_FAILED,
        dockId,
        dockName,
    };
}

export const updatePropertiesStatusRequested = (propertyIds, status) => {
    return {
        type: UPDATE_PROPERTIES_STATUS_REQUESTED,
        propertyIds,
        status,
    };
};

export const updatePropertiesStatusSucceeded = (propertyIds) => {
    return {
        type: UPDATE_PROPERTIES_STATUS_SUCCEEDED,
        propertyIds,
    };
};

export const updatePropertiesStatusFailed = (propertyIds, errors) => {
    return {
        type: UPDATE_PROPERTIES_STATUS_FAILED,
        propertyIds,
        errors,
    };
};

export const fetchOwnerForNewPropertyRequested = (userId) => {
    return {
        type: FETCH_OWNER_FOR_NEW_PROPERTY_REQUESTED,
        userId,
    };
};

export const fetchOwnerForNewPropertySucceeded = (owner) => {
    return {
        type: FETCH_OWNER_FOR_NEW_PROPERTY_SUCCEEDED,
        owner,
    };
};

export const fetchOwnerForNewPropertyFailed = (error, userId) => {
    return {
        type: FETCH_OWNER_FOR_NEW_PROPERTY_FAILED,
        error,
        userId,
    };
};

export const createPropertyRequested = (property) => {
    return {
        type: CREATE_PROPERTY_REQUESTED,
        property,
    };
};

export const createPropertySucceeded = (property) => {
    return {
        type: CREATE_PROPERTY_SUCCEEDED,
        property,
    };
};

export const createPropertyFailed = (errors, property) => {
    return {
        type: CREATE_PROPERTY_FAILED,
        errors,
        property,
    };
};

export const updatePropertyRequested = (property) => {
    return {
        type: UPDATE_PROPERTY_REQUESTED,
        property,
    };
};

export const updatePropertySucceeded = (property) => {
    return {
        type: UPDATE_PROPERTY_SUCCEEDED,
        property,
    };
};

export const updatePropertyFailed = (errors, property) => {
    return {
        type: UPDATE_PROPERTY_FAILED,
        errors,
        property,
    };
};

export const deletePropertiesRequested = (propertyIds) => {
    return {
        type: DELETE_PROPERTIES_REQUESTED,
        propertyIds,
    };
};

export const deletePropertiesSucceeded = (errors = []) => {
    return {
        type: DELETE_PROPERTIES_SUCCEEDED,
        errors,
    };
};

export const deletePropertiesFailed = (errors) => {
    return {
        type: DELETE_PROPERTIES_FAILED,
        errors,
    };
};

export const clearDeleteErrors = () => {
    return {
        type: CLEAR_DELETE_ERRORS,
    };
};
