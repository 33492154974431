import { ORDER_BY_DIR_DESC } from '../../constants/api';

/**
 * The prefix used by redux actions in this store.
 *
 * @constant
 * @type {string}
 */
export const TYPES_PREFIX = 'users/';

// Field keys as the API understands them
export const USER_ID = 'userId';
export const USER_CREATED = 'created';
export const USER_ACTIVE = 'userActive';
export const USER_GROUP_NAME = 'groupName';

// Possible fields that the API will accept to be ordered by
export const USER_ORDER_BY_FIELDS = [
    USER_ID,
    USER_CREATED,
    USER_ACTIVE,
    USER_GROUP_NAME,
];

// The default order for initial context-less requests
export const DEFAULT_ORDER = [[USER_CREATED, ORDER_BY_DIR_DESC]];

// User group names/types
export const USER_TYPE_TRUCKER = 'Trucker';
export const USER_TYPE_OWNER = 'Owner';
export const USER_TYPE_ADMIN = 'Admin';

// Possible user statuses
export const USER_STATUS_ACTIVE = 'active';
export const USER_STATUS_INACTIVE = 'inactive';
export const USER_STATUSES = [
    { value: true, label: 'Active' },
    { value: false, label: 'Disabled' },
];

export const UPLOAD_CONTEXT_PARAM = 'uploadContext';
export const UPLOAD_CONTEXT_USER_PHOTO_VALUE = 'user photo';
export const UPLOAD_PUBLIC_PARAM = 'public';
export const UPLOAD_PUBLIC_PARAM_TRUE_VALUE = 'true';
export const UPLOAD_PARAM_UPLOAD = 'upload';
