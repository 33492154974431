import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';
import styles from './styles';

const DataTableLink = ({ classes, typographyClasses, ...restProps }) => (
    <Typography
        component="a"
        color="primary"
        classes={{ root: classes.root, ...typographyClasses }}
        {...restProps}
    />
);

DataTableLink.propTypes = {
    // Styles
    classes: PropTypes.object.isRequired,

    // Classes to apply on top of the Typography root element
    typographyClasses: PropTypes.object,
};

DataTableLink.defaultProps = {
    typographyClasses: {},
};

export default withStyles(styles)(DataTableLink);
