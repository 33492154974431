import PropTypes from 'prop-types';

/*
 * Content to be displayed on the right side of the Admin Panel cards,
 * either something valid to render directly
 * or an object with lat/long which will cause a CompassIconButton to be rendered.
 */
export const rightContent = PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
    }),
]);

export const dashboardItem = PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageUrl: PropTypes.string,
    text: PropTypes.string,
    subtext: PropTypes.string,
    rightContent: rightContent,
});

export const dashboardItems = PropTypes.arrayOf(dashboardItem);

export const DashboardPropTypes = {
    rightContent,
    dashboardItem,
    dashboardItems,
};

export default DashboardPropTypes;
