/**
 * Create a function that will transform the given state by shallow merging data into a slice of it.
 *
 * @param {{}} state
 * @return {function(slice: string, data: string): {}}
 */
export const createSliceUpdater = (state) => {
    /**
     * Shallow merges data into a slice of the state
     * @param {string} slice
     * @param {{}} data
     * @return {{}}
     */
    return (slice, data) => ({
        ...state,
        [slice]: {
            ...state[slice],
            ...data,
        },
    });
};
