import React from 'react';
import { clone, equals, has, isEmpty, pick } from 'ramda';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    withStyles,
    CircularProgress,
    Grid,
    Typography,
    Divider,
} from '@material-ui/core';
import styles from './styles';
import AdminContainer from '../../common/AdminContainer';
import UserForm from '../UserForm';
import {
    getFormMessages,
    getUsersForDetailView,
    isUserDetailLoading,
    isCreatingUser,
    isSendingUserReport,
    wasUserReportSent,
    userReportError,
} from '../../stores/users/selectors';
import { getCurrentUserId } from '../../stores/auth/selectors';
import {
    DELETE_USER_ACTIONS,
    createUserRequested,
    updateUserRequested,
    deletePropertyRequested,
} from '../../stores/users/apiActions';
import {
    cleanupDetailedUserState,
    fetchUserPaymentMethodsRequested,
    removeFormMessage,
    sendUserReportRequested,
    userDetailViewLoadRequested,
} from '../../stores/users/actions';
import { formMessagesPropTypes } from '../../common/FormMessages';
import { getUserGroups } from '../../stores/userGroups/selectors';
import { fetchRegionsRequested } from '../../stores/regions/actions';
import {
    areRegionsLoading,
    getRegionsForSelect,
} from '../../stores/regions/selectors';
import { DEFAULT_USER_ACTIVE, DEFAULT_USER_COUNTRY } from './constants';
import ConfirmDialog from '../../common/ConfirmDialog';

class UserDetailedContainer extends React.Component {
    state = {
        showUpdateConfirmation: false,
        tempUserData: {},
    };

    componentDidMount() {
        const { userId } = this.props;
        if (!isNaN(userId)) {
            // Make sure we're actually viewing a user instead of creating
            this.props.onLoad(userId);
        }
    }

    componentWillUnmount() {
        const { cleanupDetailedUserState } = this.props;
        cleanupDetailedUserState && cleanupDetailedUserState();
    }

    onSendReport = (recipients) => {
        const { userId, onSendReport } = this.props;
        onSendReport && onSendReport(userId, recipients);
    };

    onDeleteUser = () => {
        const { deleteUser, userId } = this.props;
        deleteUser && deleteUser(userId);
    };

    onUpdateUser = (data) => {
        const { user = {} } = this.props;
        const { propertiesWithUtilReportConfigs } = user;
        if (
            !this.state.showUpdateConfirmation &&
            Object.keys(propertiesWithUtilReportConfigs || {}).length &&
            !equals(
                data.utilizationReportConfigs,
                user.utilizationReportConfigs,
            )
        ) {
            this.setState({
                showUpdateConfirmation: true,
                tempUserData: data,
            });
        } else {
            const { updateUser } = this.props;
            updateUser && updateUser(data);
        }
    };

    resetUpdateConfirmationState = () => {
        this.setState({
            showUpdateConfirmation: false,
            tempUserData: {},
        });
    };

    renderForm = () => {
        const {
            user = {},
            loading,
            messages,
            userGroups,
            currentUserId,
            createUser,
            regions,
            areRegionsLoading,
            fetchRegionsForCountry,
            onDeleteProperty,
            isCreatingUser,
            removeFormMessage,
            sendingReport,
            reportSent,
            reportError,
        } = this.props;
        if (!has('userActive', user)) {
            user.userActive = DEFAULT_USER_ACTIVE;
        }
        if (!has('country', user) || isEmpty(user.country)) {
            user.country = DEFAULT_USER_COUNTRY;
        }
        if (has('userPhoneNumber', user) && user.userPhoneNumber.length > 10) {
            // Trim country code
            user.userPhoneNumber = user.userPhoneNumber.substring(
                user.userPhoneNumber.length - 10,
                user.userPhoneNumber.length,
            );
        }
        return loading ? (
            <CircularProgress />
        ) : (
            <>
                <UserForm
                    formState={clone(user)}
                    onSubmit={isCreatingUser ? createUser : this.onUpdateUser}
                    showDelete={!user.deletedAt}
                    onDelete={this.onDeleteUser}
                    onSendReport={this.onSendReport}
                    sendingReport={sendingReport}
                    reportSent={reportSent}
                    reportError={reportError}
                    loading={loading}
                    messages={messages}
                    userGroups={userGroups}
                    currentUserId={currentUserId}
                    regions={regions}
                    areRegionsLoading={areRegionsLoading}
                    fetchRegionsForCountry={fetchRegionsForCountry}
                    onDeleteProperty={onDeleteProperty}
                    isCreatingUser={isCreatingUser}
                    removeFormMessage={removeFormMessage}
                />
            </>
        );
    };

    render() {
        const { classes, user = {}, ...rest } = this.props;
        const { propertiesWithUtilReportConfigs = {} } = user;
        return (
            <AdminContainer
                innerHeader
                classes={pick(['innerContainer'], classes)}
                innerHeaderProps={{
                    title: 'Users',
                    backTo: '/users',
                    backText: '< Back to Users',
                }}
                {...rest}
            >
                {this.renderForm()}
                <ConfirmDialog
                    open={this.state.showUpdateConfirmation}
                    onClose={this.resetUpdateConfirmationState}
                    confirmLabel="Save Property"
                    onConfirm={() => {
                        this.onUpdateUser(this.state.tempUserData);
                        this.resetUpdateConfirmationState();
                    }}
                    cancelLabel="Save Property & Clear Property Scheduled Reports"
                    onCancel={() => {
                        this.onUpdateUser({
                            ...this.state.tempUserData,
                            clearPropertyUtilReportConfigs: true,
                        });
                        this.resetUpdateConfirmationState();
                    }}
                    title="Clear Property Report Schedules?"
                    text="There are properties with scheduled reports"
                    additionalContent={
                        <Grid container item direction="column">
                            <Divider
                                variant="middle"
                                className={classes.divider}
                            />
                            {Object.entries(
                                propertiesWithUtilReportConfigs,
                            ).map(([propertyName, configs]) => (
                                <Typography
                                    key={`property-${propertyName}-configs`}
                                    align="center"
                                    color="primary"
                                    variant="body2"
                                >
                                    <span style={{ fontWeight: 600 }}>
                                        {propertyName}
                                    </span>
                                    :&nbsp;
                                    {configs.length} scheduled time
                                    {configs.length === 1 ? '' : 's'}
                                </Typography>
                            ))}
                        </Grid>
                    }
                />
            </AdminContainer>
        );
    }
}

UserDetailedContainer.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // The ID of the user (a route param).
    userId: PropTypes.string.isRequired,

    // Connected functions from mapping dispatch to props
    onLoad: PropTypes.func.isRequired,
    onSendReport: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    createUser: PropTypes.func.isRequired,
    fetchRegionsForCountry: PropTypes.func.isRequired,
    cleanupDetailedUserState: PropTypes.func.isRequired,
    onDeleteProperty: PropTypes.func.isRequired,
    removeFormMessage: PropTypes.func.isRequired,

    // Messages to be displayed on the form, if any.
    messages: formMessagesPropTypes.messages,

    // Indicate whether property utilization report is being sent
    sendingReport: PropTypes.bool,
    reportSent: PropTypes.bool,
    reportError: PropTypes.string,

    // User groups for the user type select dropdown.
    userGroups: PropTypes.array.isRequired,

    // The current user's user id, from mapping redux state to props.
    currentUserId: PropTypes.number.isRequired,

    // Regions aka states for the state select in the user details form.
    regions: PropTypes.object.isRequired,
    areRegionsLoading: PropTypes.bool.isRequired,

    // Indication of whether or not the form is being used to create a new user.
    isCreatingUser: PropTypes.bool.isRequired,

    // Loading indicator
    loading: PropTypes.bool,

    user: PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
        regionAbbr: PropTypes.string.isRequired,
        userZipCode: PropTypes.string,
        country: PropTypes.string.isRequired,
    }),
};

const mapStateToProps = function(state) {
    return {
        user: getUsersForDetailView(state),
        messages: getFormMessages(state),
        loading: isUserDetailLoading(state),
        sendingReport: isSendingUserReport(state),
        reportSent: wasUserReportSent(state),
        reportError: userReportError(state),
        userGroups: getUserGroups(state),
        currentUserId: getCurrentUserId(state),
        regions: getRegionsForSelect(state),
        areRegionsLoading: areRegionsLoading(state),
        isCreatingUser: isCreatingUser(state),
    };
};

const mapDispatchToProps = function(dispatch) {
    return {
        onLoad: (userId) => {
            dispatch(userDetailViewLoadRequested());
            dispatch(fetchUserPaymentMethodsRequested(userId));
        },
        onSendReport: (userId, recipients) =>
            dispatch(sendUserReportRequested(userId, recipients)),
        deleteUser: (userId) => dispatch(DELETE_USER_ACTIONS.requested(userId)),
        updateUser: (user) => dispatch(updateUserRequested(user)),
        cleanupDetailedUserState: () => dispatch(cleanupDetailedUserState()),
        createUser: (user) => dispatch(createUserRequested(user)),
        fetchRegionsForCountry: (countryAbbr) =>
            dispatch(fetchRegionsRequested(countryAbbr)),
        onDeleteProperty: (userId, propertyId, propertyName) =>
            dispatch(deletePropertyRequested(userId, propertyId, propertyName)),
        removeFormMessage: (messageKey) =>
            dispatch(removeFormMessage(messageKey)),
    };
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(UserDetailedContainer);
