export const PREFIX = 'auth/';
export const INITIALIZATION_SUCCEEDED = `${PREFIX}INITIALIZATION_SUCCEEDED`;
export const INITIALIZATION_FAILED = `${PREFIX}INITIALIZATION_FAILED`;
export const FIREBASE_AUTH_STATE_CHANGED = `${PREFIX}FIREBASE_AUTH_STATE_CHANGED`;
export const LOGIN_REQUESTED = `${PREFIX}LOGIN_REQUESTED`;
export const LOGOUT = `${PREFIX}LOGOUT`;
export const LOGIN_FAILED = `${PREFIX}LOGIN_FAILED`;
export const CHECK_REQUESTED = `${PREFIX}CHECK_REQUESTED`;
export const CHECK_SUCCEEDED = `${PREFIX}CHECK_SUCCEEDED`;
export const CLEAR_ERROR = `${PREFIX}CLEAR_ERROR`;

export function initializationSucceeded() {
    return { type: INITIALIZATION_SUCCEEDED };
}

export function initializationFailed(error = null) {
    return {
        type: INITIALIZATION_FAILED,
        error,
    };
}

export function firebaseAuthStateChanged(firebaseUser) {
    return {
        type: FIREBASE_AUTH_STATE_CHANGED,
        firebaseUser,
    };
}

export function loginRequested(email, password) {
    return {
        type: LOGIN_REQUESTED,
        email,
        password,
    };
}

export function logout() {
    return { type: LOGOUT };
}

export function loginFailed(errors) {
    return {
        type: LOGIN_FAILED,
        errors,
    };
}

export function checkRequested() {
    return { type: CHECK_REQUESTED };
}

export function checkSucceeded(userId, groupName) {
    return {
        type: CHECK_SUCCEEDED,
        userId,
        groupName,
    };
}

export function clearError(fieldName) {
    return {
        type: CLEAR_ERROR,
        fieldName,
    };
}
