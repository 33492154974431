export const PREFIX = 'stripe/';

export const ADD_STRIPE_PAYMENT_METHOD_REQUESTED = `${PREFIX}ADD_STRIPE_PAYMENT_METHOD_REQUESTED`;
export const ADD_STRIPE_PAYMENT_METHOD_SUCCEEDED = `${PREFIX}ADD_STRIPE_PAYMENT_METHOD_SUCCEEDED`;
export const ADD_STRIPE_PAYMENT_METHOD_FAILED = `${PREFIX}ADD_STRIPE_PAYMENT_METHOD_FAILED`;

export const addStripePaymentMethodRequested = (token, history, userId) => ({
    type: ADD_STRIPE_PAYMENT_METHOD_REQUESTED,
    userId,
    token,
    history,
});

export const addStripePaymentMethodSucceeded = () => ({
    type: ADD_STRIPE_PAYMENT_METHOD_SUCCEEDED,
});

export const addStripePaymentMethodFailed = (error) => ({
    type: ADD_STRIPE_PAYMENT_METHOD_FAILED,
    error,
});
