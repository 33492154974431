import {
    createActionsAndTypesFromTypeDef,
    getRequestActionsForType,
    defineRequestActions,
    getRequestTypes,
} from '../../utils/api';
import { TYPES_PREFIX } from './constants';

const FETCH_USER = 'fetchUser';
const DELETE_USER = 'deleteUser';

export const UPDATE_USER_PHOTO_REQUESTED = `${TYPES_PREFIX}updateUserPhotoRequested`;
export const UPDATE_USER_PHOTO_SUCCEEDED = `${TYPES_PREFIX}updateUserPhotoSucceeded`;
export const UPDATE_USER_PHOTO_FAILED = `${TYPES_PREFIX}updateUserPhotoFailed`;

export const UPDATE_USERS_STATUS_REQUESTED = `${TYPES_PREFIX}updateUsersStatusRequested`;
export const UPDATE_USERS_STATUS_SUCCEEDED = `${TYPES_PREFIX}updateUsersStatusSucceded`;
export const UPDATE_USERS_STATUS_FAILED = `${TYPES_PREFIX}updateUsersStatusFailed`;

export const UPDATE_USERS_TYPE_REQUESTED = `${TYPES_PREFIX}updateUsersTypeRequested`;
export const UPDATE_USERS_TYPE_SUCCEEDED = `${TYPES_PREFIX}updateUsersTypeSucceded`;
export const UPDATE_USERS_TYPE_FAILED = `${TYPES_PREFIX}updateUsersTypeFailed`;

export const CREATE_USER_REQUESTED = `${TYPES_PREFIX}CREATE_USER_REQUESTED`;
export const CREATE_USER_SUCCEEDED = `${TYPES_PREFIX}CREATE_USER_SUCCEEDED`;
export const CREATE_USER_FAILED = `${TYPES_PREFIX}CREATE_USER_FAILED`;

export const UPDATE_USER_REQUESTED = `${TYPES_PREFIX}UPDATE_USER_REQUESTED`;
export const UPDATE_USER_SUCCEEDED = `${TYPES_PREFIX}UPDATE_USER_SUCCEEDED`;
export const UPDATE_USER_FAILED = `${TYPES_PREFIX}UPDATE_USER_FAILED`;

export const DELETE_PROPERTY_REQUESTED = `${TYPES_PREFIX}DELETE_PROPERTY`;
export const DELETE_PROPERTY_SUCCEEDED = `${TYPES_PREFIX}DELETE_PROPERTY_SUCCEEDED`;
export const DELETE_PROPERTY_FAILED = `${TYPES_PREFIX}DELETE_PROPERTY_FAILED`;

const typesDefs = {
    ...defineRequestActions(FETCH_USER, {
        requestedParams: ['userId'],
        succeededParams: ['user'],
        failedParams: ['errors'],
    }),
    ...defineRequestActions(DELETE_USER, {
        requestedParams: ['userId'],
        failedParams: ['errors'],
    }),
};

const { types, actions } = createActionsAndTypesFromTypeDef(
    typesDefs,
    TYPES_PREFIX,
);

export const userTypes = types;
export const userActions = actions;

export const FETCH_USER_TYPES = getRequestTypes(types, FETCH_USER);
export const FETCH_USER_ACTIONS = getRequestActionsForType(actions, FETCH_USER);

export const DELETE_USER_TYPES = getRequestTypes(types, DELETE_USER);
export const DELETE_USER_ACTIONS = getRequestActionsForType(
    actions,
    DELETE_USER,
);

export const updateUserPhotoRequested = () => {
    return {
        type: UPDATE_USER_PHOTO_REQUESTED,
    };
};

export const updateUserPhotoSucceeded = () => {
    return {
        type: UPDATE_USER_PHOTO_SUCCEEDED,
    };
};

export const updateUserPhotoFailed = (errors) => {
    return {
        type: UPDATE_USER_PHOTO_FAILED,
        errors,
    };
};

export const updateUsersStatusRequested = (userIds, status) => {
    return {
        type: UPDATE_USERS_STATUS_REQUESTED,
        userIds,
        status,
    };
};

export const updateUsersStatusSucceeded = (userIds) => {
    return {
        type: UPDATE_USERS_STATUS_SUCCEEDED,
        userIds,
    };
};

export const updateUsersStatusFailed = (userIds, errors) => {
    return {
        type: UPDATE_USERS_STATUS_FAILED,
        userIds,
        errors,
    };
};

export const updateUsersTypeRequested = (userIds, groupName) => {
    return {
        type: UPDATE_USERS_TYPE_REQUESTED,
        userIds,
        groupName,
    };
};

export const updateUsersTypeSucceeded = (userIds) => {
    return {
        type: UPDATE_USERS_TYPE_SUCCEEDED,
        userIds,
    };
};

export const updateUsersTypeFailed = (userIds, errors) => {
    return {
        type: UPDATE_USERS_TYPE_FAILED,
        errors,
        userIds,
    };
};

export const createUserRequested = (user) => {
    return {
        type: CREATE_USER_REQUESTED,
        user,
    };
};

export const createUserSucceeded = (user) => {
    return {
        type: CREATE_USER_SUCCEEDED,
        user,
    };
};

export const createUserFailed = (errors, user) => {
    return {
        type: CREATE_USER_FAILED,
        errors,
        user,
    };
};

export const updateUserRequested = (user) => {
    return {
        type: UPDATE_USER_REQUESTED,
        user,
    };
};

export const updateUserSucceeded = (user) => {
    return {
        type: UPDATE_USER_SUCCEEDED,
        user,
    };
};

export const updateUserFailed = (errors, user) => {
    return {
        type: UPDATE_USER_FAILED,
        errors,
        user,
    };
};

export const deletePropertyRequested = (userId, propertyId, propertyName) => {
    return {
        type: DELETE_PROPERTY_REQUESTED,
        userId,
        propertyId,
        propertyName,
    };
};

export const deletePropertySucceeded = () => {
    return {
        type: DELETE_PROPERTY_SUCCEEDED,
    };
};

export const deletePropertyFailed = (propertyId, propertyName) => {
    return {
        type: DELETE_PROPERTY_FAILED,
        propertyId,
        propertyName,
    };
};
