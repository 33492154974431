import { generateRoundImageStyles } from '../../utils/styles';

export default (theme) => ({
    root: {
        ...generateRoundImageStyles(40),
        overflow: 'hidden',
        objectFit: 'cover',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    },
});
