import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';
import RangeItem from './RangeItem';
import styles from './styles';
import DatePropTypes from '../../propTypes/dates';
import { noop } from '../../utils';

class WeekdayTimeRangePicker extends Component {
    render() {
        const {
            classes,
            start,
            end,
            onStartDayChange,
            onStartTimeChange,
            onEndDayChange,
            onEndTimeChange,
        } = this.props;
        return (
            <Grid container direction="row" wrap="nowrap" alignItems="center">
                <RangeItem
                    labelText="Start"
                    value={start}
                    onDayChange={onStartDayChange}
                    onTimeChange={onStartTimeChange}
                />
                <Grid item>
                    <ArrowRight className={classes.arrow} />
                </Grid>
                <RangeItem
                    labelText="End"
                    value={end}
                    onDayChange={onEndDayChange}
                    onTimeChange={onEndTimeChange}
                />
            </Grid>
        );
    }
}

WeekdayTimeRangePicker.propTypes = {
    // From withStyles we expect to get classes.
    classes: PropTypes.object.isRequired,

    // Value for start day and time, if any.
    start: DatePropTypes.weekdayAndTime,

    // Value for end day and time, if any.
    end: DatePropTypes.weekdayAndTime,

    // Function called when the start day/time changes.
    onStartDayChange: PropTypes.func,
    onStartTimeChange: PropTypes.func,

    // Function called when the end day/time changes.
    onEndDayChange: PropTypes.func,
    onEndTimeChange: PropTypes.func,
};

WeekdayTimeRangePicker.defaultProps = {
    start: {},
    end: {},
    onStartDayChange: noop,
    onStartTimeChange: noop,
    onEndDayChange: noop,
    onEndTimeChange: noop,
};

export default withStyles(styles)(WeekdayTimeRangePicker);
