import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Grid, Typography, withStyles } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import styles from './styles';

class AdminCheckbox extends Component {
    render() {
        const { classes, label, CheckboxProps } = this.props;
        return (
            <Grid container direction="row" spacing={16} alignItems="center">
                <Grid item>
                    <Checkbox
                        className={classes.checkbox}
                        icon=""
                        checkedIcon={<Check className={classes.checkIcon} />}
                        {...CheckboxProps}
                    />
                </Grid>
                <Grid item className={classes.labelGridItem}>
                    <Typography color="secondary">{label}</Typography>
                </Grid>
            </Grid>
        );
    }
}

AdminCheckbox.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // Properties that will be spread onto the checkbox component.
    CheckboxProps: PropTypes.object,

    // The label to be used with the checkbox, if any.
    label: PropTypes.node,
};

AdminCheckbox.defaultProps = {
    CheckboxProps: {},
    label: '',
};

export default withStyles(styles)(AdminCheckbox);
