import background from './background.png';

const styles = (theme) => ({
    container: {
        backgroundImage: `url(${background})`,
    },
    subtitle: {
        color: theme.palette.primary.contrastText,
    },
    panelGridItem: {
        flex: 1,
        '&:last-child': {
            paddingRight: 0,
        },
    },
    circularProgress: {
        color: theme.palette.primary.contrastText,
        display: 'block',
        margin: '50px auto 0 auto',
    },
});

export default styles;
