import PropTypes from 'prop-types';

export const loginFormErrors = PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
});

export const loginFormProps = PropTypes.shape({
    // Function that will be called when the form is submitted.
    onSubmit: PropTypes.func.isRequired,

    // Optionally override the default error text for email
    emailError: PropTypes.string,

    // Errors for the fields in the login form.
    errors: loginFormErrors,

    /*
     * Function to be called when the errors should be cleared out in the form fields,
     * i.e. for when they have changed since the error occurred.
     */
    onClearErrorRequested: PropTypes.func.isRequired,

    // Indicate whether the login is in progress or not, used to disable the form fields and the submit button.
    loginInProgress: PropTypes.bool.isRequired,
});

export const LoginFormPropTypes = {
    loginFormErrors,
    loginFormProps,
};

export default LoginFormPropTypes;
