/**
 * Transforms the rules retrieved from the API into objects
 * with name and label to be used with AdminCheckboxes in the property
 * details form that is used for creating and updating properties.
 * @param rules
 * @returns {{name, label}[]}
 */
export const transformRulesForCheckboxesInForm = (rules = []) => {
    return rules.map((rule) => {
        const { ruleId, ruleName } = rule;
        return {
            label: ruleName,
            name: ruleId,
        };
    });
};
