import {
    createApiPageRequestActionCreators,
    makeActionCreator,
} from '../../utils/api';

export const PREFIX = 'properties/';

export const PROPERTIES_LIST_VIEW_LOAD_REQUESTED = `${PREFIX}PROPERTIES_LIST_VIEW_LOAD_REQUESTED`;
export const PROPERTY_DETAIL_VIEW_LOAD_REQUESTED = `${PREFIX}PROPERTY_DETAIL_VIEW_LOAD_REQUESTED`;

export const FETCH_PROPERTIES_LIST_PAGE_REQUESTED = `${PREFIX}FETCH_PROPERTIES_LIST_PAGE_REQUESTED`;
export const FETCH_PROPERTIES_LIST_PAGE_SUCCEEDED = `${PREFIX}FETCH_PROPERTIES_LIST_PAGE_SUCCEEDED`;
export const FETCH_PROPERTIES_LIST_PAGE_FAILED = `${PREFIX}FETCH_PROPERTIES_LIST_PAGE_FAILED`;

export const FETCH_OWNER_PROPERTIES_REQUESTED = `${PREFIX}FETCH_OWNER_PROPERTIES_REQUESTED`;
export const FETCH_OWNER_PROPERTIES_SUCCEEDED = `${PREFIX}FETCH_OWNER_PROPERTIES_SUCCEEDED`;
export const FETCH_OWNER_PROPERTIES_FAILED = `${PREFIX}FETCH_OWNER_PROPERTIES_FAILED`;

export const FETCH_PROPERTY_REPORT_REQUESTED = `${PREFIX}FETCH_PROPERTY_REPORT_REQUESTED`;
export const FETCH_PROPERTY_REPORT_SUCCEEDED = `${PREFIX}FETCH_PROPERTY_REPORT_SUCCEEDED`;
export const FETCH_PROPERTY_REPORT_FAILED = `${PREFIX}FETCH_PROPERTY_REPORT_FAILED`;

export const SEND_PROPERTY_REPORT_REQUESTED = `${PREFIX}SEND_PROPERTY_REPORT_REQUESTED`;
export const SEND_PROPERTY_REPORT_SUCCEEDED = `${PREFIX}SEND_PROPERTY_REPORT_SUCCEEDED`;
export const SEND_PROPERTY_REPORT_FAILED = `${PREFIX}SEND_PROPERTY_REPORT_FAILED`;

export const EDIT_DOCK = `${PREFIX}EDIT_DOCK`;
export const CLEANUP_DETAILED_STATE = `${PREFIX}CLEANUP_DETAILED_STATE`;

export const UPDATE_PROPERTIES_LIST_FILTERS = `${PREFIX}UPDATE_PROPERTIES_LIST_FILTERS`;
export const CLEAR_PROPERTIES_LIST_FILTERS = `${PREFIX}CLEAR_PROPERTIES_LIST_FILTERS`;
export const FILTERS_VALIDATION_FAILED = `${PREFIX}FILTERS_VALIDATION_FAILED`;

export const REMOVE_FORM_MESSAGE = `${PREFIX}REMOVE_FORM_MESSAGE`;

export const propertiesListViewLoadRequested = () => ({
    type: PROPERTIES_LIST_VIEW_LOAD_REQUESTED,
});

export const propertyDetailViewLoadRequested = () => ({
    type: PROPERTY_DETAIL_VIEW_LOAD_REQUESTED,
});

export const {
    requested: fetchPropertiesListPageRequested,
    succeeded: fetchPropertiesListPageSucceeded,
    failed: fetchPropertiesListPageFailed,
} = createApiPageRequestActionCreators({
    requested: FETCH_PROPERTIES_LIST_PAGE_REQUESTED,
    succeeded: FETCH_PROPERTIES_LIST_PAGE_SUCCEEDED,
    failed: FETCH_PROPERTIES_LIST_PAGE_FAILED,
});

export const {
    requested: fetchOwnerPropertiesRequested,
    succeeded: fetchOwnerPropertiesSucceeded,
    failed: fetchOwnerPropertiesFailed,
} = createApiPageRequestActionCreators({
    requested: FETCH_OWNER_PROPERTIES_REQUESTED,
    succeeded: FETCH_OWNER_PROPERTIES_SUCCEEDED,
    failed: FETCH_OWNER_PROPERTIES_FAILED,
});

export const fetchPropertyReportRequested = (propertyId) => ({
    type: FETCH_PROPERTY_REPORT_REQUESTED,
    propertyId,
});
export const fetchPropertyReportSucceeded = (report) => ({
    type: FETCH_PROPERTY_REPORT_SUCCEEDED,
    report,
});
export const fetchPropertyReportFailed = (errors) => ({
    type: FETCH_PROPERTY_REPORT_FAILED,
    errors,
});

export const sendPropertyReportRequested = (propertyId, recipients) => ({
    type: SEND_PROPERTY_REPORT_REQUESTED,
    propertyId,
    recipients,
});
export const sendPropertyReportSucceeded = () => ({
    type: SEND_PROPERTY_REPORT_SUCCEEDED,
});
export const sendPropertyReportFailed = (error) => ({
    type: SEND_PROPERTY_REPORT_FAILED,
    error,
});

export function editDock(data) {
    const { dockId, propertyId } = data;
    return {
        type: EDIT_DOCK,
        dockId,
        propertyId,
    };
}

export const cleanupDetailedPropertyState = makeActionCreator(
    CLEANUP_DETAILED_STATE,
);

export const updatePropertiesListFilters = (filters) => {
    return {
        type: UPDATE_PROPERTIES_LIST_FILTERS,
        filters,
    };
};

export const clearPropertiesListFilters = () => {
    return {
        type: CLEAR_PROPERTIES_LIST_FILTERS,
    };
};

export const filtersValidationFailed = (filterErrors, filters) => {
    return {
        type: FILTERS_VALIDATION_FAILED,
        filterErrors,
        filters,
    };
};

export const removeFormMessage = (messageKey) => {
    return {
        type: REMOVE_FORM_MESSAGE,
        messageKey,
    };
};
