import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TimePicker as MUITimePicker } from 'material-ui-pickers';
import { withStyles } from '@material-ui/core';
import { AccessTime as ClockIcon } from '@material-ui/icons';

import styles from './styles';
import DatePropTypes from '../../propTypes/dates';

class TimePicker extends Component {
    render() {
        const { classes, value, small, ...props } = this.props;
        return (
            <MUITimePicker
                variant="filled"
                keyboard
                format="h:mma"
                value={value}
                keyboardIcon={<ClockIcon />}
                KeyboardButtonProps={{
                    className: small
                        ? classes.smallClockIcon
                        : classes.clockIcon,
                }}
                InputProps={{
                    disableUnderline: true,
                    className: small ? classes.small : classes.inputRoot,
                }}
                {...props}
            />
        );
    }
}

TimePicker.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // The value of the selected time.
    value: DatePropTypes.time,

    small: PropTypes.bool,
};

TimePicker.defaultProps = {
    small: false,
};

export default withStyles(styles)(TimePicker);
