import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, withStyles } from '@material-ui/core';
import styles from './styles';

const LoginSubmitButton = ({ classes, disabled, ...props }) => {
    return (
        <Button
            variant="contained"
            type="submit"
            classes={{
                root: classes.root,
                disabled: classes.disabled,
            }}
            disabled={disabled}
            {...props}
        >
            {disabled ? <CircularProgress size={32} /> : 'Submit'}
        </Button>
    );
};

LoginSubmitButton.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    /*
     * Indicate if the button is disabled or not.
     * If true a Circular Progress will be shown instead of the button text and it will be disabled.
     */
    disabled: PropTypes.bool,
};

LoginSubmitButton.defaultProps = {
    // Not disabled by default.
    disabled: false,
};

export default withStyles(styles)(LoginSubmitButton);
