import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import TablePropTypes from '../propTypes';
import RigParkDataTable from '../RigParkDataTable';
import DataTableColumn from '../DataTable/DataTableColumn';
import { SORT_ASC, SORT_DESC } from '../DataTable/constants';
import DataTableLink from '../DataTableLink';
import {
    ACTIONS as PROPERTY_ACTIONS,
    SET_PROPERTY_STATUS_ACTIVE,
} from '../../properties/constants';
import { noop } from '../../utils';
import styles from './styles';
import { formatDate } from '../../utils/dates';
import {
    DOCK_COUNT,
    PROPERTY_ACTIVE,
    PROPERTY_CREATED_AT,
    PROPERTY_ID,
    PROPERTY_NAME,
    RESERVATION_COUNT,
} from '../../stores/properties/constants';
import AdminAvatar from '../../common/AdminAvatar';
import ApproveButton from './ApproveButton';

/**
 * A function that returns a uniquely identifying key for a property object.
 *
 * @param {{propertyId: *}} property
 * @return {*}
 */
const getKeyForProperty = (property) => property.propertyId;

const AdminPropertiesTable = ({
    classes,
    tableData,
    tableContext,
    onFetchDataRequested,
    onClickApprovePropertyRow,
    multisort,
    ...rest
}) => {
    return (
        <RigParkDataTable
            tableData={tableData}
            tableContext={tableContext}
            getKey={getKeyForProperty}
            onFetchDataRequested={onFetchDataRequested}
            selectable
            searchable
            onActionSelect={noop}
            actions={PROPERTY_ACTIONS}
            initialActionValue={SET_PROPERTY_STATUS_ACTIVE}
            {...rest}
        >
            {({
                createOnSingleSort,
                createOnMultiSortToggle,
                createSortGetter,
            }) => {
                const createOnSort = multisort
                    ? createOnMultiSortToggle
                    : createOnSingleSort;
                return (
                    <Fragment>
                        <DataTableColumn
                            headCellContent="ID"
                            headCellClassName={classes.idHeadCell}
                            getSort={createSortGetter(PROPERTY_ID)}
                            onSort={createOnSort(PROPERTY_ID)}
                            initialSort={SORT_DESC}
                        >
                            {({ propertyId }) => (
                                <DataTableLink
                                    component={Link}
                                    to={`/properties/${propertyId}`}
                                >
                                    {propertyId}
                                </DataTableLink>
                            )}
                        </DataTableColumn>
                        <DataTableColumn
                            headCellClassName={classes.photoHeadCell}
                        >
                            {({
                                propertyId,
                                propertyName,
                                photos: [{ photoUrl = null } = {}] = [],
                            }) => {
                                return (
                                    <DataTableLink
                                        component={Link}
                                        to={`/properties/${propertyId}`}
                                    >
                                        <AdminAvatar
                                            src={photoUrl || null}
                                            alt={propertyName}
                                        />
                                    </DataTableLink>
                                );
                            }}
                        </DataTableColumn>
                        <DataTableColumn
                            headCellContent="Name"
                            headCellClassName={classes.nameHeadCell}
                            getSort={createSortGetter(PROPERTY_NAME)}
                            onSort={createOnSort(PROPERTY_NAME)}
                            initialSort={SORT_ASC}
                        >
                            {({ propertyId, propertyName }) => (
                                <DataTableLink
                                    component={Link}
                                    to={`/properties/${propertyId}`}
                                >
                                    {propertyName}
                                </DataTableLink>
                            )}
                        </DataTableColumn>
                        <DataTableColumn
                            headCellContent="# of Spots"
                            headCellClassName={classes.numDocksHeadCell}
                            getSort={createSortGetter(DOCK_COUNT)}
                            onSort={createOnSort(DOCK_COUNT)}
                            initialSort={SORT_DESC}
                        >
                            {({ dockCount = null }) => dockCount}
                        </DataTableColumn>
                        <DataTableColumn
                            headCellContent="# of Reservations"
                            headCellClassName={classes.numReservationsHeadCell}
                            getSort={createSortGetter(RESERVATION_COUNT)}
                            onSort={createOnSort(RESERVATION_COUNT)}
                            initialSort={SORT_DESC}
                        >
                            {({ reservationCount = null }) => reservationCount}
                        </DataTableColumn>
                        <DataTableColumn
                            headCellContent="Date Added"
                            headCellClassName={classes.dateAddedHeadCell}
                            getSort={createSortGetter(PROPERTY_CREATED_AT)}
                            onSort={createOnSort(PROPERTY_CREATED_AT)}
                            initialSort={SORT_DESC}
                        >
                            {({ createdAt }) => {
                                return createdAt ? formatDate(createdAt) : null;
                            }}
                        </DataTableColumn>
                        <DataTableColumn
                            headCellContent="Status"
                            headCellClassName={classes.statusHeadCell}
                            classes={{
                                sortableWrapper:
                                    classes.statusHeadCellSortableWrapper,
                            }}
                            className={classes.statusBodyCell}
                            getSort={createSortGetter(PROPERTY_ACTIVE)}
                            onSort={createOnSort(PROPERTY_ACTIVE)}
                            initialSort={SORT_ASC}
                        >
                            {({ propertyActive }) => {
                                return propertyActive
                                    ? 'Published'
                                    : 'Waiting for Approval';
                            }}
                        </DataTableColumn>
                        <DataTableColumn className={classes.approveButtonCell}>
                            {({ propertyActive, propertyId }) => {
                                return propertyActive ? null : (
                                    <ApproveButton
                                        onClick={() =>
                                            onClickApprovePropertyRow(
                                                propertyId,
                                            )
                                        }
                                    />
                                );
                            }}
                        </DataTableColumn>
                    </Fragment>
                );
            }}
        </RigParkDataTable>
    );
};

AdminPropertiesTable.propTypes = {
    classes: PropTypes.object.isRequired,
    tableData: TablePropTypes.tablePageData.isRequired,
    tableContext: TablePropTypes.tablePageContext.isRequired,
    onFetchDataRequested: PropTypes.func.isRequired,
    onClickApprovePropertyRow: PropTypes.func.isRequired,
    multisort: PropTypes.bool,
};

AdminPropertiesTable.defaultProps = {
    multisort: false,
};

export default withStyles(styles)(AdminPropertiesTable);
