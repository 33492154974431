export default {
    // Users dashboard panel list data
    users: {
        loading: false,
        data: [],
        total: 0,
    },

    // Users dashboard panel "new users" count
    newUsers: {
        loading: false,
        count: 0,
    },

    // Properties dashboard panel list data
    properties: {
        loading: false,
        data: [],
        total: 0,
    },

    // Properties dashboard panel "new properties" count
    newProperties: {
        loading: false,
        count: 0,
    },

    reservations: {
        loading: false,
        data: [],
        total: 0,
    },

    newReservations: {
        loading: false,
        count: 0,
    },
};
