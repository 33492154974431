import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Avatar } from '@material-ui/core';
import styles from './styles';

const AdminAvatar = ({ classes, ...restProps }) => {
    return <Avatar classes={{ root: classes.root }} {...restProps} />;
};

AdminAvatar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminAvatar);
