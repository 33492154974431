import { COUNTRY_NAMES_AND_ABBREVIATIONS } from '../../constants/country';

export const STATUS_ACTIVE = 'Active';
export const STATUS_INACTIVE = 'Inactive';
export const STATUS_FILTERS = [STATUS_ACTIVE, STATUS_INACTIVE];

export const SELECT_A_COUNTRY = 'Select a Country...';
export const SELECT_A_STATE = 'Select a State...';
export const SELECT_COUNTRY_FIRST = 'Select Country First...';

export const COUNTRY_FILTERS = [
    { label: SELECT_A_COUNTRY, value: SELECT_A_COUNTRY },
    ...COUNTRY_NAMES_AND_ABBREVIATIONS,
];
