import { call, put, takeEvery } from 'redux-saga/effects';
import {
    FETCH_AMENITIES_REQUESTED,
    fetchAmenitiesSucceeded,
    fetchAmenitiesFailed,
} from './apiActions';
import { fetchWithAuth } from '../api/sagas';

export function* fetchAmenities() {
    try {
        const data = yield call(fetchWithAuth, '/amenity');
        yield put(fetchAmenitiesSucceeded(data));
    } catch (error) {
        yield put(fetchAmenitiesFailed('Fetching available amenities failed.'));
    }
}

export default function*() {
    yield takeEvery(FETCH_AMENITIES_REQUESTED, fetchAmenities);
}
