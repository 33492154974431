import React, { Component } from 'react';
import AdminContainer from '../../common/AdminContainer';
import LoginForm from '../LoginForm';
import LoginFormPropTypes from '../../propTypes/loginForm';

class LoginPage extends Component {
    render() {
        const { LoginFormProps } = this.props;
        return (
            <AdminContainer exterior>
                <LoginForm {...LoginFormProps} />
            </AdminContainer>
        );
    }
}

LoginPage.propTypes = {
    // Props that will be passed to the login form.
    LoginFormProps: LoginFormPropTypes.loginFormProps,
};

export default LoginPage;
