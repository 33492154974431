import React from 'react';
import { withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AdminButton, { BUTTON_TYPE_EDIT } from './../common/AdminButton';
import { AddCircleOutline } from '@material-ui/icons';

const styles = {
    root: {
        minWidth: 280,
    },
    addCircleIcon: {
        marginRight: 25,
    },
};

const AddReservationButton = ({ classes, history, ...props }) => {
    return (
        <AdminButton
            buttonType={BUTTON_TYPE_EDIT}
            size="large"
            className={classes.root}
            component={Link}
            to={'/reservations/new'}
            {...props}
        >
            <AddCircleOutline className={classes.addCircleIcon} />
            Add Reservation
        </AdminButton>
    );
};

export default withStyles(styles)(AddReservationButton);
