export const USER_TYPE_TRUCKER = 'Trucker';
export const USER_TYPE_PROPERTY_OWNER = 'Owner';
export const USER_TYPE_FILTERS = [USER_TYPE_TRUCKER, USER_TYPE_PROPERTY_OWNER];
export const MODEL_ID = 'modelId';
export const MAKE_ID = 'makeId';
export const VEHICLE_PLATE = 'vehiclePlate';
export const VEHICLE_NAME = 'vehicleName';
export const LICENSE_PLATE_FILTER = {
    key: VEHICLE_PLATE,
    label: 'Vehicle Plate',
};
export const VEHICLE_NAME_FILTER = {
    key: VEHICLE_NAME,
    label: 'Vehicle Name',
};
export const VEHICLE_MODEL_FILTER = {
    key: MODEL_ID,
    label: 'Vehicle Model',
};
export const USER_VEHICLE_TEXT_FILTERS = [
    LICENSE_PLATE_FILTER,
    VEHICLE_NAME_FILTER,
];

export const USER_STATUS_ACTIVE = 'Active';
export const USER_STATUS_INACTIVE = 'Inactive';
export const USER_STATUS_PENDING_DELETE = 'Pending Deletion';
export const USER_STATUS_FILTERS = [
    USER_STATUS_ACTIVE,
    USER_STATUS_INACTIVE,
    USER_STATUS_PENDING_DELETE,
];

export const CHECKBOX_FILTERS = USER_TYPE_FILTERS.concat(USER_STATUS_FILTERS);
