import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'ramda';
import { withStyles } from '@material-ui/core';
import styles from './styles';
import UserVehiclePhoto from './UserVehiclePhoto';
import DetailForm from '../../common/DetailForm';
import { formMessagesPropTypes } from '../../common/FormMessages';
import ReadOnlyTextField from './ReadOnlyTextField';
import MakeAndModel from './MakeAndModel';
import Photos from '../../common/Photos';

class VehicleForm extends Component {
    state = {
        // User provided photo - NOT editable. 0 to 1 photos supported.
        photoUrl: this.props.formState.photoUrl,
        photoFile: null,

        // Admin photos - editable and 0 to many supported
        photos: this.props.formState.photos,
    };

    onSubmit = (vehicleId, photos) => {
        this.props.onSubmit && this.props.onSubmit(vehicleId, photos);
    };

    onPhotoChange = (event, setFieldValue) => {
        const files = Array.from(event.target.files);
        const fileURLs = files.map((file) => {
            return {
                photoUrl: URL.createObjectURL(file),
                key: Date.now(),
                file,
            };
        });
        const photos = this.state.photos.concat(fileURLs);
        this.setState({ photos });
        setFieldValue('photos', photos);
    };

    onPhotoClear = (key, setFieldValue) => {
        const photos = this.state.photos.filter((photo) => photo.key !== key);
        this.setState({ photos });
        setFieldValue('photos', photos);
    };

    _getFormFields = () => {
        const { formState } = this.props;

        return [
            {
                name: 'photoUrl',
                label: 'User Photo',
                render: (fieldProps) => (
                    <UserVehiclePhoto
                        {...fieldProps}
                        value={formState.photoUrl}
                    />
                ),
            },
            {
                name: 'vehicleName',
                label: 'Name',
                render: (fieldProps) => <ReadOnlyTextField {...fieldProps} />,
            },
            {
                name: 'makeAndModel',
                label: '',
                render: () => {
                    return (
                        <MakeAndModel
                            makeName={formState.makeName}
                            modelName={formState.modelName}
                        />
                    );
                },
            },
            {
                name: 'vehicleColor',
                label: 'Color',
                render: (fieldProps) => <ReadOnlyTextField {...fieldProps} />,
            },
            {
                name: 'vehiclePlate',
                label: 'License Plate #',
                render: (fieldProps) => <ReadOnlyTextField {...fieldProps} />,
            },
            {
                name: 'photos',
                label: 'Admin Photos',
                render: (fieldProps, { setFieldValue }) => (
                    <Photos
                        {...fieldProps} // Field props has to go first so we can override onChange with onPhotoChange.
                        onChange={(event) =>
                            this.onPhotoChange(event, setFieldValue)
                        }
                        onPhotoClear={(key) =>
                            this.onPhotoClear(key, setFieldValue)
                        }
                    />
                ),
            },
        ];
    };

    render() {
        const {
            formState,
            loading,
            messages,
            vehicleId,
            removeFormMessage,
        } = this.props;
        const { photos } = this.state;
        const fields = this._getFormFields();
        return (
            <DetailForm
                initFormState={omit(
                    ['photoUrl', 'UserVehiclePhotoId'],
                    formState,
                )}
                onSubmit={() => this.onSubmit(vehicleId, photos)}
                loading={loading}
                fields={fields}
                messages={messages}
                showDelete={false}
                submitButtonText="Submit Vehicle"
                removeFormMessage={removeFormMessage}
            />
        );
    }
}

VehicleForm.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // Function that will be called when the form is submitted.
    onSubmit: PropTypes.func.isRequired,

    // Bool that determines this form is loading
    loading: PropTypes.bool,

    // The ID Of the vehicle being viewed/edited
    vehicleId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    // So you can supply an initial state of the form, if any.
    formState: PropTypes.shape({
        photoUrl: PropTypes.string, // expects url to pull user avatar
        vehicleName: PropTypes.string,
        makeName: PropTypes.string,
        modelName: PropTypes.string,
        vehicleLength: PropTypes.number,
        vehicleHeight: PropTypes.number,
        vehicleColor: PropTypes.string,
        vehiclePlate: PropTypes.string,
        photos: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
                photoUrl: PropTypes.string,
                uploadId: PropTypes.number,
            }),
        ),
    }),

    // Messages to be displayed on the form, if any.
    messages: formMessagesPropTypes.messages,

    removeFormMessage: PropTypes.func.isRequired,
};

VehicleForm.defaultProps = {
    formState: {},
    loading: false,
    messages: [],
};

export default withStyles(styles)(VehicleForm);
