export default (theme) => ({
    root: {
        padding: '1em 1em',
        textAlign: 'center',
    },
    withGutter: {
        marginBottom: 10,
    },
    error: {
        backgroundColor: theme.custom.red,
        color: theme.palette.primary.contrastText,
    },
    success: {
        backgroundColor: theme.custom.successBlue,
        color: theme.palette.primary.contrastText,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
});
