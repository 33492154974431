const styles = (theme) => ({
    container: {
        backgroundColor: theme.custom.grey,
        marginBottom: 2,
        borderRadius: 3,
        flexWrap: 'nowrap',
        padding: 12,
    },
    selected: {
        backgroundColor: theme.custom.lightGreen,
    },
    labelContainer: {
        flexWrap: 'nowrap',
        flex: 1,
    },
    resultInfo: {
        maxHeight: 125,
        width: 'fit-content',
    },
    label: {
        textTransform: 'uppercase',
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
        letterSpacing: 1,
        fontSize: 10,
        width: 60,
        textAlign: 'right',
        margin: '0px 25px 10px 0px',
    },
    value: {
        fontSize: 12,
    },
    checkbox: {
        width: 'min-content',
        alignItems: 'flex-start',
        padding: '5px 0px 0px 0px',
    },
    header: {
        marginBottom: 15,
    },
    avatar: {
        width: 60,
        justifyContent: 'flex-end',
    },
});
export default styles;
