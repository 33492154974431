import { createSelector } from 'reselect';

/**
 * @typedef {RigParkApiPageData|{loading: boolean}} RigParkTablePageData
 */

/**
 * Create a selector that will create a table page data object (a combination of the loading state and the
 * api page data).
 *
 * @param {function(state: {}): RigParkApiPageData} getApiPageData
 * @param {function(state: {}): boolean} isLoading
 * @return {function(state: {}): RigParkApiPageData}
 */
export const createTablePageDataSelector = (getApiPageData, isLoading) => {
    return createSelector(
        getApiPageData,
        isLoading,
        (pageData, loading) => ({ ...pageData, loading }),
    );
};
