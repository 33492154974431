import { formatDate } from '../../utils/dates';
import { DURATION_DATE_FORMAT } from '../reservations/constants';

/**
 * Convert an array of properties from the API into data that's well formatted for the users dashboard panel
 *
 * @param {{}[]} properties
 * @return {{}[]}
 */
export function transformPropertiesDataForDashboard(properties) {
    return properties.map((property) => {
        const {
            propertyId,
            propertyName,
            propertyAddress,
            propertyCity,
            propertyZipcode,
            propertyCoordinates: {
                coordinates: [longitude, latitude],
            },
            photos,
        } = property;
        return {
            key: propertyId,
            text: propertyName,
            subtext: `${propertyAddress}, ${propertyCity}, ${propertyZipcode}`,
            rightContent: { latitude, longitude },
            imageUrl: photos && photos.length > 0 ? photos[0].photoUrl : '',
        };
    });
}

/**
 * Convert an array of users from the API into data that's well formatted for the users dashboard panel
 *
 * @param {{}[]} users
 * @return {{}[]}
 */
export function transformUsersDataForDashboard(users) {
    return users.map((user) => {
        const {
            userId,
            displayName,
            group: { groupName },
            created,
            photoURL,
        } = user;
        return {
            key: userId,
            text: displayName,
            subtext: groupName,
            rightContent: created && formatDate(created),
            imageUrl: photoURL,
        };
    });
}

/**
 * Convert an array of reservations from the API into data that's well formatted for the reservations dashboard panel
 *
 * @param {{}[]} reservations
 * @return {{}[]}
 */
export function transformReservationsDataForDashboard(reservations) {
    return reservations.map((reservation) => {
        const {
            reservationId,
            propertyName,
            propertyAddress,
            propertyCity,
            propertyZipcode,
            photo,
            startTime,
            endTime,
        } = reservation;
        return {
            key: reservationId,
            text: propertyName,
            subtext: `${propertyAddress}, ${propertyCity}, ${propertyZipcode}`,
            rightContent: `${formatDate(
                startTime,
                DURATION_DATE_FORMAT,
            )} ${formatDate(endTime, DURATION_DATE_FORMAT)}`,
            imageUrl: photo ? photo.uploadUrl : '',
        };
    });
}
