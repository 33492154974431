import {
    createActionsAndTypesFromTypeDef,
    createApiPageRequestActionCreators,
    defineRequestActions,
    getRequestActionsForType,
    getRequestTypes,
} from '../../utils/api';
import { TYPES_PREFIX } from '../properties/constants';

export const PREFIX = 'reservations/';

export const RESERVATIONS_LIST_VIEW_LOAD_REQUESTED = `${PREFIX}RESERVATIONS_LIST_VIEW_LOAD_REQUESTED`;
export const RESERVATION_DETAIL_VIEW_LOAD_REQUESTED = `${PREFIX}RESERVATION_DETAIL_VIEW_LOAD_REQUESTED`;

export const FETCH_RESERVATIONS_LIST_PAGE_REQUESTED = `${PREFIX}FETCH_RESERVATIONS_LIST_PAGE_REQUESTED`;
export const FETCH_RESERVATIONS_LIST_PAGE_SUCCEEDED = `${PREFIX}FETCH_RESERVATIONS_LIST_PAGE_SUCCEEDED`;
export const FETCH_RESERVATIONS_LIST_PAGE_FAILED = `${PREFIX}FETCH_RESERVATIONS_LIST_PAGE_FAILED`;

export const UPDATE_RESERVATIONS_LIST_FILTERS = `${PREFIX}UPDATE_RESERVATIONS_LIST_FILTERS`;
export const CLEAR_RESERVATIONS_LIST_FILTERS = `${PREFIX}CLEAR_RESERVATIONS_LIST_FILTERS`;
export const FILTERS_VALIDATION_FAILED = `${PREFIX}FILTERS_VALIDATION_FAILED`;

export const UPDATE_RESERVATION_REQUESTED = `${PREFIX}UPDATE_RESERVATION_REQUESTED`;
export const UPDATE_RESERVATION_SUCCEEDED = `${PREFIX}UPDATE_RESERVATION_SUCCEEDED`;
export const UPDATE_RESERVATION_FAILED = `${PREFIX}UPDATE_RESERVATION_FAILED`;
export const REMOVE_FORM_MESSAGES = `${PREFIX}REMOVE_FORM_MESSAGES`;

export const BOOK_RESERVATION_SEARCH_USER_REQUESTED = `${PREFIX}BOOK_RESERVATION_SEARCH_USER_REQUESTED`;
export const BOOK_RESERVATION_SEARCH_USER_SUCCEEDED = `${PREFIX}BOOK_RESERVATION_SEARCH_USER_SUCCEEDED`;
export const BOOK_RESERVATION_SEARCH_USER_FAILED = `${PREFIX}BOOK_RESERVATION_SEARCH_USER_FAILED`;

export const BOOK_RESERVATION_GET_USER_PAYMENT_METHODS_REQUESTED = `${PREFIX}BOOK_RESERVATION_GET_USER_PAYMENT_METHODS_REQUESTED`;
export const BOOK_RESERVATION_GET_USER_PAYMENT_METHODS_REQUESTED_SUCCEEDED =
    `${PREFIX}BOOK_RESERVATION_GET_USER_PAYMENT_METHODS_REQUESTED_SUCCEEDED`;
export const BOOK_RESERVATION_GET_USER_PAYMENT_METHODS_REQUESTED_FAILED =
    `${PREFIX}BOOK_RESERVATION_GET_USER_PAYMENT_METHODS_REQUESTED_FAILED`;

export const BOOK_RESERVATION_GET_PROPERTY_INFO_REQUESTED = `${PREFIX}BOOK_RESERVATION_GET_PROPERTY_INFO_REQUESTED`;
export const BOOK_RESERVATION_GET_PROPERTY_INFO_SUCCEEDED = `${PREFIX}BOOK_RESERVATION_GET_PROPERTY_INFO_SUCCEEDED`;
export const BOOK_RESERVATION_GET_PROPERTY_INFO_FAILED = `${PREFIX}BOOK_RESERVATION_GET_PROPERTY_INFO_FAILED`;

export const BOOK_RESERVATION_REQUESTED = `${PREFIX}BOOK_RESERVATION_REQUESTED`;
export const BOOK_RESERVATION_SUCCEEDED = `${PREFIX}BOOK_RESERVATION_SUCCEEDED`;
export const BOOK_RESERVATION_FAILED = `${PREFIX}BOOK_RESERVATION_FAILED`;

export const CLEAR_BOOKING_RESERVATION_STATE = `${PREFIX}CLEAR_BOOKING_RESERVATION_STATE`;

export const clearBookingReservationState = () => ({
    type: CLEAR_BOOKING_RESERVATION_STATE,
});

export const bookReservationUserSearchRequested = (search) => ({
    type: BOOK_RESERVATION_SEARCH_USER_REQUESTED,
    search: search,
});

export const bookReservationUserSearchFailed = () => ({
    type: BOOK_RESERVATION_SEARCH_USER_FAILED,
});

export const bookReservationUserSearchSucceeded = (results) => ({
    type: BOOK_RESERVATION_SEARCH_USER_SUCCEEDED,
    results,
});

export const bookReservationGetUserPaymentMethods = (userId) => ({
    type: BOOK_RESERVATION_GET_USER_PAYMENT_METHODS_REQUESTED,
    userId,
});

export const bookReservationGetUserPaymentMethodsFailed = () => ({
    type: BOOK_RESERVATION_GET_USER_PAYMENT_METHODS_REQUESTED_FAILED,
});

export const bookReservationGetUserPaymentMethodsSucceeded = (
    paymentMethods,
) => ({
    type: BOOK_RESERVATION_GET_USER_PAYMENT_METHODS_REQUESTED_SUCCEEDED,
    paymentMethods,
});

export const bookReservationGetPropertyInfoRequested = (propertyId) => ({
    type: BOOK_RESERVATION_GET_PROPERTY_INFO_REQUESTED,
    propertyId,
});

export const bookReservationGetPropertyInfoFailed = () => ({
    type: BOOK_RESERVATION_GET_PROPERTY_INFO_FAILED,
});

export const bookReservationGetPropertyInfoSucceeded = (property) => ({
    type: BOOK_RESERVATION_GET_PROPERTY_INFO_SUCCEEDED,
    property,
});

export const reservationsListViewLoadRequested = () => ({
    type: RESERVATIONS_LIST_VIEW_LOAD_REQUESTED,
});

export const reservationDetailViewLoadRequested = () => ({
    type: RESERVATION_DETAIL_VIEW_LOAD_REQUESTED,
});

export const {
    requested: fetchReservationsListPageRequested,
    succeeded: fetchReservationsListPageSucceeded,
    failed: fetchReservationsListPageFailed,
} = createApiPageRequestActionCreators({
    requested: FETCH_RESERVATIONS_LIST_PAGE_REQUESTED,
    succeeded: FETCH_RESERVATIONS_LIST_PAGE_SUCCEEDED,
    failed: FETCH_RESERVATIONS_LIST_PAGE_FAILED,
});

export const updateReservationsListFilters = (filters) => {
    return {
        type: UPDATE_RESERVATIONS_LIST_FILTERS,
        filters,
    };
};

export const clearReservationsListFilters = () => {
    return {
        type: CLEAR_RESERVATIONS_LIST_FILTERS,
    };
};

export const filtersValidationFailed = (filterErrors, filters) => {
    return {
        type: FILTERS_VALIDATION_FAILED,
        filterErrors,
        filters,
    };
};

const FETCH_RESERVATION = 'fetchReservation';
const typesDefs = {
    ...defineRequestActions(FETCH_RESERVATION, {
        requestedParams: ['reservationId'],
        succeededParams: ['reservation'],
        failedParams: ['errors'],
    }),
};
const { types, actions } = createActionsAndTypesFromTypeDef(
    typesDefs,
    TYPES_PREFIX,
);

export const reservationTypes = types;
export const reservationActions = actions;

export const FETCH_RESERVATION_TYPES = getRequestTypes(
    types,
    FETCH_RESERVATION,
);
export const FETCH_RESERVATION_ACTIONS = getRequestActionsForType(
    actions,
    FETCH_RESERVATION,
);

export const clearReservationLoadingState = () => ({
    type: FETCH_RESERVATION_TYPES.succeeded,
});

export const FETCH_PROPERTIES_LIST_FOR_FILTERS_REQUESTED = `${PREFIX}FETCH_PROPERTIES_LIST_FOR_FILTERS_REQUESTED `;
export const FETCH_PROPERTIES_LIST_FOR_FILTERS_SUCCEEDED = `${PREFIX}FETCH_PROPERTIES_LIST_FOR_FILTERS_SUCCEEDED `;
export const FETCH_PROPERTIES_LIST_FOR_FILTERS_FAILED = `${PREFIX}FETCH_PROPERTIES_LIST_FOR_FILTERS_FAILED `;

export const fetchPropertiesListForFiltersRequested = () => ({
    type: FETCH_PROPERTIES_LIST_FOR_FILTERS_REQUESTED,
});

export const fetchPropertiesListForFiltersSucceeded = (propertiesList) => ({
    type: FETCH_PROPERTIES_LIST_FOR_FILTERS_SUCCEEDED,
    propertiesList,
});

export const fetchPropertiesListForFiltersFailed = (errors = []) => ({
    type: FETCH_PROPERTIES_LIST_FOR_FILTERS_FAILED,
    errors,
});

export const FETCH_USERS_LIST_FOR_FILTERS_REQUESTED = `${PREFIX}FETCH_USERS_LIST_FOR_FILTERS_REQUESTED `;
export const FETCH_USERS_LIST_FOR_FILTERS_SUCCEEDED = `${PREFIX}FETCH_USERS_LIST_FOR_FILTERS_SUCCEEDED `;
export const FETCH_USERS_LIST_FOR_FILTERS_FAILED = `${PREFIX}FETCH_USERS_LIST_FOR_FILTERS_FAILED `;

export const fetchUsersListForFiltersRequested = () => ({
    type: FETCH_USERS_LIST_FOR_FILTERS_REQUESTED,
});

export const fetchUsersListForFiltersSucceeded = (usersList) => ({
    type: FETCH_USERS_LIST_FOR_FILTERS_SUCCEEDED,
    usersList,
});

export const fetchUsersListForFiltersFailed = (errors = []) => ({
    type: FETCH_USERS_LIST_FOR_FILTERS_FAILED,
    errors,
});

export const updateReservationRequested = (data) => ({
    type: UPDATE_RESERVATION_REQUESTED,
    data,
});

export const updateReservationSucceeded = (reservation) => ({
    type: UPDATE_RESERVATION_SUCCEEDED,
    reservation,
});

export const updateReservationFailed = (error = {}) => ({
    type: UPDATE_RESERVATION_FAILED,
    error,
});

export const removeFormMessages = (messageKey) => ({
    type: REMOVE_FORM_MESSAGES,
    messageKey,
});

export const bookReservationRequested = (data, history) => ({
    type: BOOK_RESERVATION_REQUESTED,
    data,
    history,
});

export const bookReservationFailed = () => ({
    type: BOOK_RESERVATION_FAILED,
});

export const bookReservationSucceeded = () => ({
    type: BOOK_RESERVATION_SUCCEEDED,
});
