import {
    COUNTRY_ABBR_CANADA,
    COUNTRY_ABBR_MEXICO,
    COUNTRY_ABBR_UNITED_STATES,
} from './country';

export const PHONE_MASK_US_CA = '999-999-9999';
export const PHONE_MASK_MX = '99 99 9999 9999';
export const PHONE_MASKS = {
    [COUNTRY_ABBR_UNITED_STATES]: PHONE_MASK_US_CA,
    [COUNTRY_ABBR_CANADA]: PHONE_MASK_US_CA,
    [COUNTRY_ABBR_MEXICO]: PHONE_MASK_MX,
};

// Just a regex to make sure the masks placeholder _ is not present.
export const PHONE_REG_EXP = /^[^_]+$/;
