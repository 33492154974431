import { createSelector } from 'reselect';
import { MAX_ITEMS_DASHBOARD } from '../../dashboard/constants';
import {
    transformPropertiesDataForDashboard,
    transformReservationsDataForDashboard,
    transformUsersDataForDashboard,
} from './transforms';

export const areDashboardUsersLoading = (state) =>
    state.dashboard.users.loading;
export const areDashboardPropertiesLoading = (state) =>
    state.dashboard.properties.loading;
export const areDashboardReservationsLoading = (state) =>
    state.dashboard.reservations.loading;

export const isDashboardNewUsersCountLoading = (state) =>
    state.dashboard.newUsers.loading;
export const isDashboardNewPropertiesCountLoading = (state) =>
    state.dashboard.newProperties.loading;
export const isDashboardNewReservationsCountLoading = (state) =>
    state.dashboard.newReservations.loading;

export const getUsersForDashboard = createSelector(
    (state) => state.dashboard.users.data,
    (users) =>
        transformUsersDataForDashboard(users.slice(0, MAX_ITEMS_DASHBOARD)),
);
export const getPropertiesForDashboard = createSelector(
    (state) => state.dashboard.properties.data,
    (properties) =>
        transformPropertiesDataForDashboard(
            properties.slice(0, MAX_ITEMS_DASHBOARD),
        ),
);
export const getReservationsForDashboard = createSelector(
    (state) => state.dashboard.reservations.data,
    (reservations) =>
        transformReservationsDataForDashboard(
            reservations.slice(0, MAX_ITEMS_DASHBOARD),
        ),
);

export const getNewUsersCountForDashboard = (state) =>
    state.dashboard.newUsers.count;
export const getNewPropertiesCountForDashboard = (state) =>
    state.dashboard.newProperties.count;
export const getNewReservationsCountForDashboard = (state) =>
    state.dashboard.newReservations.count;

export const getUsersTotalForDashboard = (state) => state.dashboard.users.total;
export const getPropertiesTotalForDashboard = (state) =>
    state.dashboard.properties.total;
export const getReservationsTotalForDashboard = (state) =>
    state.dashboard.reservations.total;
