import { generateTableColumnStyles } from '../../utils/styles';

export default (theme) => ({
    idHeadCell: theme.rigParkAdmin.tableHeadCells.id,
    photoHeadCell: theme.rigParkAdmin.tableHeadCells.photo,
    nameHeadCell: theme.rigParkAdmin.tableHeadCells.name,
    numDocksHeadCell: {
        ...generateTableColumnStyles(120),
        [theme.breakpoints.down('md')]: {
            ...generateTableColumnStyles(50),
            whiteSpace: 'normal',
        },
    },
    numReservationsHeadCell: {
        ...generateTableColumnStyles(150),
        [theme.breakpoints.down('md')]: {
            ...generateTableColumnStyles(65),
            whiteSpace: 'normal',
            wordWrap: 'break-word',
        },
    },
    dateAddedHeadCell: theme.rigParkAdmin.tableHeadCells.date,
    statusHeadCell: {
        ...theme.rigParkAdmin.tableHeadCells.status,
        textAlign: 'right',
        [theme.breakpoints.down('md')]: {
            ...generateTableColumnStyles(55),
        },
    },
    statusHeadCellSortableWrapper: {
        flexDirection: 'row-reverse',
    },

    statusBodyCell: {
        textAlign: 'right',
        [theme.breakpoints.down('md')]: {
            ...generateTableColumnStyles(55),
        },
    },
    approveButtonCell: {
        [theme.breakpoints.down('md')]: {
            paddingRight: '12px !important',
        },
    },
});
