import { createSelector } from 'reselect';
import { transformRegionsForSelect } from './transforms';

export const getRegions = (state) => state.regions.data;

export const areRegionsLoading = (state) => state.regions.loading;

export const getRegionsForSelect = createSelector(
    (state) => getRegions(state),
    (regions) => {
        const transformedRegions = {};
        Object.entries(regions).forEach(([countryAbbr, regions]) => {
            transformedRegions[countryAbbr] = transformRegionsForSelect(
                regions,
            );
        });
        return transformedRegions;
    },
);
