const styles = (theme) => ({
    innerContainer: {
        maxWidth: '60%',
    },
    divider: {
        margin: '8px 0 12px',
    },
});

export default styles;
