import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import WeekdayTimePicker from '../WeekdayTimePicker';
import RangeLabel from './RangeLabel';
import DatePropTypes from '../../propTypes/dates';

const RangeItem = ({ labelText, value, ...props }) => (
    <Grid item container direction="column">
        <Grid item>
            <RangeLabel>{labelText}</RangeLabel>
        </Grid>
        <Grid item>
            <WeekdayTimePicker value={value} {...props} />
        </Grid>
    </Grid>
);

RangeItem.propTypes = {
    // The text is label the range item with, if any.
    labelText: PropTypes.string,

    // Value for the day and time, if any.
    value: DatePropTypes.weekdayAndTime,
};

RangeItem.defaultProps = {
    label: '',
    value: {},
};

export default RangeItem;
