import React from 'react';
import PropTypes from 'prop-types';
import { TextField, withStyles } from '@material-ui/core';
import styles from './styles';

const LoginTextField = ({ classes, error, ...props }) => {
    return (
        <TextField
            variant="outlined"
            required
            InputLabelProps={{
                shrink: true,
                classes: {
                    root: classes.inputLabel,
                    shrink: classes.shrinkInputLabel,
                    disabled: classes.disabled,
                },
            }}
            InputProps={{
                classes: {
                    root: classes.input,
                    notchedOutline: classes.inputNotchedOutline,
                    input: classes.inputStyle,
                    disabled: classes.disabled,
                },
            }}
            error={!!error}
            helperText={error}
            FormHelperTextProps={{
                classes: {
                    error: classes.helperTextError,
                },
            }}
            {...props}
        />
    );
};

LoginTextField.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // Error text to be displayed, should be empty if there is no error.
    error: PropTypes.string,
};

LoginTextField.defaultProps = {
    // Blank message for no error by default.
    error: '',
};

export default withStyles(styles)(LoginTextField);
