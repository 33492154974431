import { ORDER_BY_DIR_DESC } from '../../constants/api';

export const PROPERTY_ID = 'propertyId';
export const PROPERTY_CREATED_AT = 'createdAt';
export const PROPERTY_ACTIVE = 'propertyActive';
export const PROPERTY_NAME = 'propertyName';
export const PROPERTY_ADDRESS = 'propertyAddress';
export const PROPERTY_CITY = 'propertyCity';
export const DOCK_COUNT = 'dockCount';
export const RESERVATION_COUNT = 'reservationCount';

export const PROPERTY_ORDER_BY_FIELDS = [
    PROPERTY_ID,
    PROPERTY_CREATED_AT,
    PROPERTY_ACTIVE,
    PROPERTY_NAME,
    PROPERTY_ADDRESS,
    PROPERTY_CITY,
    DOCK_COUNT,
    RESERVATION_COUNT,
];

export const TYPES_PREFIX = 'properties/';

export const DEFAULT_ORDER = [[PROPERTY_ID, ORDER_BY_DIR_DESC]];

// Value that will be used if the owner user is not found (i.e. they've been deleted!)
export const DELETED_USER = 'Deleted User';

// Value that will be used if the owner user in the new property creation is not found.
export const USER_NOT_FOUND = 'User Not Found';
