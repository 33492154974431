import PropTypes from 'prop-types';

export default {
    // Title to appear in the  left side of the header
    title: PropTypes.string.isRequired,

    // From withStyles we expect to get classes
    classes: PropTypes.object,

    // Clickable text to appear on the right side of the header
    backText: PropTypes.string,

    // Link to the page that is shown if backText is clicked
    backTo: PropTypes.string,

    // Optional content to be shown on the right side of the inner header.
    rightContent: PropTypes.node,
};
