import qs from 'qs';
import { createSelector } from 'reselect';
import { pick } from 'ramda';
import {
    DEFAULT_API_PAGE_CONTEXT,
    DEFAULT_API_PAGE_DATA,
} from '../../constants/api';
import APIErrorResponse from './APIErrorResponse';

export const REQUESTED = 'Requested';
export const REQUEST_SUCCEEDED = 'RequestSucceeded';
export const REQUEST_FAILED = 'RequestFailed';

/**
 * Create an APIErrorResponse object from a fetch Response.
 *
 * @param {Response} response
 * @return {Promise<APIErrorResponse>}
 */
export async function createAPIErrorResponse(response) {
    const errorResponse = new APIErrorResponse();
    await errorResponse.setJsonFromResponse(response);
    return errorResponse;
}

/**
 * Given a caught error, if it's an `APIErrorResponse`, attempt to extract its validation errors or other errors as an array of
 * error messages that occurred, falling back to an array containing only `genericErrorMessage` in all of the following
 * scenarios:
 *
 * -   `error` is not an `APIErrorResponse`
 * -   The `APIErrorResponse` was not JSON parse-able
 * -   The `APIErrorResponse`'s JSON body did not contain a top-level `message` and did not contain any `validationErrors` or
 *     it was empty.
 *
 * @see APIErrorResponse.getErrorMessages
 * @param {APIErrorResponse|*} error
 * @param {string|null} genericErrorMessage
 * @return {string[]}
 */
export async function getErrorsFromPossibleAPIErrorResponse(
    error,
    genericErrorMessage = null,
) {
    const fallback = genericErrorMessage ? [genericErrorMessage] : [];
    return error instanceof APIErrorResponse
        ? error.getErrorMessages(genericErrorMessage)
        : fallback;
}

/**
 * We send API errors to the console since the devtools Network tab still doesn't like to show error response bodies.
 * @param errorResponse
 */
export function logAPIErrorResponse(errorResponse) {
    // eslint-disable-next-line no-console
    console.error(
        'An API error occurred: ',
        errorResponse && errorResponse.json
            ? errorResponse.json
            : errorResponse,
    );
}

/**
 * Make a request to a URI using the fetch API.
 *
 * @param {string} path
 * @param {string=} method
 * @param {(string|null)=} token
 * @param {({}|null)=} query
 * @param {(*|null)=} body
 * @return {Promise<Response>}
 */
export async function requestWithAuth(
    path,
    method = 'GET',
    token = null,
    query = null,
    body = null,
) {
    // Get the API URI from the build-time environment variables, removing any trailing slash
    const apiUri = `${process.env.REACT_APP_API_URI}`.replace(/\/+$/, '');
    // Append the path to the apiUri with a slash, removing any leading slashes on the path
    let fullURI = `${apiUri}/${path.replace(/^\/+/, '')}`;
    if (query !== null) {
        fullURI += '?' + qs.stringify(query);
    }
    const headers = {
        Accept: 'application/json',
    };
    if (token !== null) {
        headers['Authorization'] = 'Bearer ' + token;
    }
    const options = { method, headers };
    if (body) {
        options.headers['Content-Type'] = 'application/json';
        options.body = JSON.stringify(body);
    }
    return fetch(fullURI, options);
}

/**
 *  Helper to generate a set of reduxsauce-compatible action type/creator definitions
 * (to be passed to `createActions`) for common API requests.
 *
 * Definition includes 3 actions:
 *
 *   - `${name}Requested`: An API request was made
 *   - `${name}RequestSucceeded`: An API request was successful. It'll have (by default) a "response" parameter
 *   - `${name}RequestFailed: An API request was unsuccessful. It'll have (by default) an "error" parameter
 *
 * @param {string} name The lower-camel-case name to prefix actions with
 * @param {object<string,string[]>?} The object containing requesterParams, succeededParams and failedParams as it's properties.
 *         -  {string[]?} requestedParams: The parameters to define for the "requested" action
 *         -  {string[]|?} succeededParams: The parameters to define for the "request succeeded" action
 *         -  {string[]?} failedParams: The parameters to define for the "request failed" action
 */
export const defineRequestActions = (
    name,
    {
        requestedParams = null,
        succeededParams = ['response'],
        failedParams = ['error', 'response'],
    },
) => ({
    [`${name}${REQUESTED}`]: requestedParams,
    [`${name}${REQUEST_SUCCEEDED}`]: succeededParams,
    [`${name}${REQUEST_FAILED}`]: failedParams,
});

/**
 * Helper to reduce boilerplate from creating actions
 * ie:
 *   export function removeTodo(id) {
 *    return {
 *      type: 'REMOVE_TODO',
 *      id
 *    }
 *  }
 *  can be replaced with:
 *      export const removeTodo = makeActionCreator(REMOVE_TODO, 'id')
 *
 * @param type
 * @param argNames
 * @returns {function(...[*]): {type: *}}
 */
export const makeActionCreator = (type, ...argNames) => {
    return function(...args) {
        const action = { type };
        argNames.forEach((arg, index) => {
            action[argNames[index]] = args[index];
        });
        return action;
    };
};

/**
 * Helper to generate the  prefix types and actions for each type given an definition object
 * The definition object should have it's keys as the action types and it's value should be an array of
 * arguments to be passed as arguments for that types action
 * ie:
 * const typesDefs = {
 *  'setFormMessages': ['messages'],
 *  };
 *  const result = createActionsAndTypesFromTypeDef(typeDefs, 'user')
 *  // result would then be the following object:
 *  {
 *  types: userSetFormMessages,
 *  actions: {
 *  userSetFormMessages: function setFormMessages(messages) {
 *    return {
 *      type: 'userSetFormMessages',
 *      messages
 *    }
 *  }
 * @param typeDefinition
 * @param prefix
 * @returns {{types: {}, actions: {}}}
 */
export const createActionsAndTypesFromTypeDef = (
    typeDefinition,
    prefix = '',
) => {
    const types = Object.keys(typeDefinition).reduce((types, typeKey) => {
        types[typeKey] = `${prefix}${typeKey}`;
        return types;
    }, {});
    const actions = Object.keys(types).reduce((actions, typeKey) => {
        actions[typeKey] = makeActionCreator(
            types[typeKey],
            typeDefinition[typeKey],
        );
        return actions;
    }, {});
    return { types, actions };
};

/**
 *
 * @param actions{object<string,fn>?}
 * @param actionType {string}
 * @returns {{requestedAction: {fn}, failedAction: {fn}, succeededAction: {fn}}}
 */
export const getRequestActionsForType = (actions, actionType) => ({
    requested: actions[`${actionType}${REQUESTED}`],
    failed: actions[`${actionType}${REQUEST_FAILED}`],
    succeeded: actions[`${actionType}${REQUEST_SUCCEEDED}`],
});
/**
 *
 * @param types{object<string,fn>?}
 * @param constant {string}
 * @returns {{requestedType: {fn}, failedType: {fn}, succeededType: {fn}}}
 */
export const getRequestTypes = (types, constant) => ({
    requested: types[`${constant}${REQUESTED}`],
    failed: types[`${constant}${REQUEST_FAILED}`],
    succeeded: types[`${constant}${REQUEST_SUCCEEDED}`],
});

/**
 * Create three action creator functions (requested, succeeded, and failed) for doing standard requests of paginated
 * API data.
 *
 * @param {{ requested: string, succeeded: string, failed: string }} actionTypes The action type constants.
 * @return {{
 *      requested: (function(context: RigParkApiPageContext=): {context: RigParkApiPageContext, type: string}),
 *      succeeded: (function(
 *          data: RigParkApiPageData,
 *          context: RigParkApiPageContext
 *      ): {data: RigParkApiPageData, context: RigParkApiPageContext, type: string})
 *      failed: (function(): {type: string}),
 * }}
 */
export const createApiPageRequestActionCreators = (actionTypes) => {
    const { requested, succeeded, failed } = actionTypes;
    return {
        requested: (context = {}) => {
            return {
                type: requested,
                context,
            };
        },
        succeeded: (data, context) => {
            return {
                type: succeeded,
                data,
                order: context && context.order ? context.order : [],
                filters: context && context.filters ? context.filters : {},
            };
        },
        failed: (errors = []) => {
            return {
                type: failed,
                errors,
            };
        },
    };
};

/**
 * Transform raw API page response into a well-formed API page data object.
 *
 * (raw page response) => (page data)
 *
 * @param {{currentPage?: number, perPage?: number, order?: string[][], filters?: {}}} rawPage
 * @return {RigParkApiPageData}
 */
export const transformPageToPageData = (rawPage) => ({
    ...DEFAULT_API_PAGE_DATA,
    ...pick(Object.keys(DEFAULT_API_PAGE_DATA), rawPage || {}),
});

/**
 * Transform raw API page response into a well-formed API page context object.
 *
 * (raw page response) => (context)
 *
 * @param {{currentPage?: number, perPage?: number, order?: string[][], filters?: {}}} rawPage
 * @return {{ pagination?: { page?: number, perPage?: number }, order?: string[][], filters?: {} }}
 */
export const transformPageToPageContext = (rawPage) => {
    const page = rawPage || {};
    return {
        ...DEFAULT_API_PAGE_CONTEXT,
        pagination: {
            ...DEFAULT_API_PAGE_CONTEXT.pagination,
            page: page.currentPage || DEFAULT_API_PAGE_CONTEXT.pagination.page,
            perPage:
                page.perPage || DEFAULT_API_PAGE_CONTEXT.pagination.perPage,
        },
        filters: page.filters || DEFAULT_API_PAGE_CONTEXT.filters,
        filterErrors:
            page.filterErrors || DEFAULT_API_PAGE_CONTEXT.filterErrors,
        order: page.order || DEFAULT_API_PAGE_CONTEXT.order,
    };
};

/**
 * Given a raw object representing an API page context in some form, coerce it into a well-formed
 * API page context object.
 *
 * (raw context) => (context)
 *
 * @param {{}=} rawContext
 * @return {RigParkApiPageContext}
 */
export const coercePageContext = (rawContext) => {
    const context = rawContext || {};
    return {
        ...DEFAULT_API_PAGE_CONTEXT,
        ...pick(Object.keys(DEFAULT_API_PAGE_CONTEXT), context),
        pagination: {
            ...DEFAULT_API_PAGE_CONTEXT.pagination,
            ...pick(
                Object.keys(DEFAULT_API_PAGE_CONTEXT.pagination),
                context.pagination || {},
            ),
        },
    };
};

/**
 * Create a selector that will generate a well-formed API page data object from a raw page in the state.
 *
 * @param {function(state): {}} getPage The selector to use to get the raw API page from the state
 * @return {function(state): RigParkApiPageData} A selector that, when given the store state, will return API page
 *      data for the selected page.
 */
export const createApiPageDataSelector = (getPage) => {
    return createSelector(
        getPage,
        transformPageToPageData,
    );
};

/**
 * Create a selector that will generate a well-formed API page context object from a raw page in the state.
 *
 * @param {function(state): {}} getPage The selector to use to get the raw API page from the state
 * @return {function(state): RigParkApiPageContext} A selector that, when given the store state, will return API
 *      page context for the selected page.
 */
export const createApiPageContextSelector = (getPage) => {
    return createSelector(
        getPage,
        transformPageToPageContext,
    );
};

/**
 * Perform an upload using the fetch API.
 *
 * @param uri
 * @param method
 * @param token
 * @param body
 * @returns {Promise<Response>}
 */
export async function uploadWithAuth(
    uri,
    method = 'PUT',
    token = null,
    body = null,
) {
    let fullURI = `${process.env.REACT_APP_API_URI}/${uri}`;
    const headers = {
        Accept: 'application/json',
    };
    if (token !== null) {
        headers['Authorization'] = 'Bearer ' + token;
    }
    const options = { method, headers };
    if (body) {
        options.body = body;
    }
    return fetch(fullURI, options);
}
