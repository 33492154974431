// The groupName of the Admin group, the value for a user's groupName must match this to pass the auth check.
export const USER_GROUP_ADMIN = 'Admin';
export const USER_GROUP_OWNER = 'Owner';
// The RigPark user groups (i.e. roles) that are allowed to access the admin panel
export const AUTHORIZED_GROUPS = [USER_GROUP_ADMIN, USER_GROUP_OWNER];

// Values used to indicate the state of the app's initialization.
export const INITIALIZATION_STATE_LOADING = 0;
export const INITIALIZATION_STATE_FAILED = -1;
export const INITIALIZATION_STATE_SUCCEEDED = 1;
