import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    CircularProgress,
    FilledInput,
    FormControl,
    FormHelperText,
    Grid,
    MenuItem,
    Select,
    withStyles,
} from '@material-ui/core';
import styles from './styles';
import { ExpandMore } from '@material-ui/icons';
import { has } from 'ramda';
import clsx from 'clsx';

export const SIZE_COMPACT = 'compact';
export const SIZE_LARGE = 'large';
export const SIZES = [SIZE_COMPACT, SIZE_LARGE];

class AdminSelect extends Component {
    render() {
        const {
            classes,
            type,
            options,
            value,
            loading,
            error,
            SelectDisplayProps = {},
            FormControlProps = {},
            ...props
        } = this.props;
        const selectDisplayProps = {
            ...SelectDisplayProps,
            className: clsx(
                type === SIZE_COMPACT
                    ? classes.compactDisplay
                    : classes.largeDisplay,
                SelectDisplayProps.className,
            ),
        };
        return loading ? (
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className={classes[`${type}Root`]}
            >
                <Grid item>
                    <CircularProgress
                        size={type === SIZE_LARGE ? 40 : 20}
                        color="secondary"
                    />
                </Grid>
            </Grid>
        ) : (
            <FormControl {...FormControlProps}>
                <Select
                    input={<FilledInput />}
                    IconComponent={ExpandMore}
                    classes={{
                        root: classNames([
                            classes.root,
                            classes[`${type}Root`],
                            error ? classes.error : null,
                        ]),
                        icon: classNames([
                            classes.icon,
                            type === SIZE_COMPACT ? classes.compactIcon : null,
                            props.disabled ? classes.disabledIcon : null,
                        ]),
                    }}
                    SelectDisplayProps={selectDisplayProps}
                    disableUnderline
                    value={value}
                    {...props}
                    error={!!error}
                >
                    {options &&
                        options.map((option) => (
                            <MenuItem
                                className={classNames([
                                    classes.menuItem,
                                    type === SIZE_COMPACT
                                        ? classes.compactMenuItem
                                        : null,
                                ])}
                                key={option.value || option}
                                value={
                                    has('value', option) ? option.value : option
                                }
                            >
                                {option.label || option}
                            </MenuItem>
                        ))}
                </Select>
                {error ? (
                    <FormHelperText error className={classes.errorText}>
                        {' '}
                        {error}{' '}
                    </FormHelperText>
                ) : null}
            </FormControl>
        );
    }
}

AdminSelect.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // The type of dropdown to render.
    type: PropTypes.oneOf(SIZES),

    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]),

    // The options that are selectable in the dropdown component.
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.shape({
                value: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                    PropTypes.bool,
                ]),
                label: PropTypes.node,
            }),
        ]),
    ),

    // Whether this select is in a loading state, if true a circular progress will be shown.
    loading: PropTypes.bool,

    error: PropTypes.string,

    SelectDisplayProps: PropTypes.object,

    FormControlProps: PropTypes.object,
};

AdminSelect.defaultProps = {
    // If no type is specified, default to large.
    type: SIZE_LARGE,

    loading: false,
};

export default withStyles(styles)(AdminSelect);
