/**
 * Returns the new state, adding or removing an array of IDs from the the state's
 * loadingUpdate parameter which tracks objects that have pending API updates.
 *
 * @param {{}} state
 * @param {int[]} ids
 * @param {boolean=true} loading
 * @return {{}} The new state
 */
export const setLoadingUpdate = (state, ids, loading = true) => ({
    ...state,
    loadingUpdate: loading
        ? [...new Set(state.loadingUpdate.concat(ids))]
        : state.loadingUpdate.filter((id) => !ids.includes(id)),
});

/**
 * Forms error messages from an array of errors.
 * @param errors
 * @returns {{message: *, type: string, key: *}[]}
 */
export const createMessagesFromErrors = (errors = []) => {
    return errors.map((error) => ({
        key: error,
        message: error,
        type: 'error',
    }));
};

/**
 * Removes a message from the array of messages by key.
 *
 * @param messages
 * @param keyToRemove
 * @returns {*}
 */
export const removeMessage = (messages, keyToRemove) => {
    return messages.filter((message) => keyToRemove !== message.key);
};
