import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { without, isEmpty } from 'ramda';
import { Typography, Grid } from '@material-ui/core';
import TablePropTypes from '../../tables/propTypes';
import AdminContainer from '../../common/AdminContainer';
import UsersTableFilters from '../../users/UsersTableFilters';
import {
    usersListViewLoadRequested,
    fetchUsersListPageRequested,
    updateUsersListFilters,
    clearUsersListFilters,
    filtersValidationFailed,
    updateFilterByVehicleFilters,
} from '../../stores/users/actions';
import {
    getUsersListPageContext,
    getUsersListErrors,
    getUsersListTablePageData,
} from '../../stores/users/selectors';
import AdminUsersDataTable from '../../tables/AdminUsersDataTable';
import AdminButton from '../../common/AdminButton';
import AddUserButton from './AddUserButton';
import ListErrors from '../../common/ListErrors';
import {
    updateUsersStatusRequested,
    updateUsersTypeRequested,
} from '../../stores/users/apiActions';
import {
    SET_STATUS_ACTIVE,
    SET_STATUS_INACTIVE,
    SET_USER_TYPE_ADMIN,
    SET_USER_TYPE_OWNER,
    SET_USER_TYPE_TRUCKER,
} from '../../users/constants';
import {
    USER_TYPE_ADMIN,
    USER_TYPE_OWNER,
    USER_TYPE_TRUCKER,
    USER_STATUS_ACTIVE,
    USER_STATUS_INACTIVE,
} from '../../stores/users/constants';
import { hasMonth, hasDay, hasYear } from '../../utils/dates';
import {
    getUserGroups,
    isUserGroupsLoading,
} from '../../stores/userGroups/selectors';
import {
    getVehicleMakes,
    getVehicleModels,
} from '../../stores/vehicles/selectors';
import {
    getVehicleMakesRequested,
    getVehicleModelsRequested,
} from '../../stores/vehicles/actions';

const getUserTypeForAction = (action) => {
    switch (action) {
        case SET_USER_TYPE_ADMIN:
            return USER_TYPE_ADMIN;
        case SET_USER_TYPE_OWNER:
            return USER_TYPE_OWNER;
        case SET_USER_TYPE_TRUCKER:
            return USER_TYPE_TRUCKER;
        default:
            return null;
    }
};

class UsersListContainer extends Component {
    static propTypes = {
        tableData: TablePropTypes.tablePageData.isRequired,
        tableContext: TablePropTypes.tablePageContext.isRequired,
        onLoad: PropTypes.func.isRequired,
        onPageRequested: PropTypes.func.isRequired,
        errors: PropTypes.arrayOf(PropTypes.string).isRequired,
        fetchVehicleMakes: PropTypes.func.isRequired,
        fetchVehicleModels: PropTypes.func.isRequired,
        onSetUsersStatusSelected: PropTypes.func.isRequired,
        onSetUsersTypeSelected: PropTypes.func.isRequired,
        loadingUpdate: PropTypes.array.isRequired,
        onUpdateFilters: PropTypes.func.isRequired,
        onVehicleFilterUpdate: PropTypes.func.isRequired,
        onClearFilters: PropTypes.func.isRequired,
        onFiltersValidationFailed: PropTypes.func.isRequired,
        userGroups: PropTypes.array.isRequired,
        isUserGroupsLoading: PropTypes.bool,
        vehicleModels: PropTypes.array,
        vehicleMakes: PropTypes.array,
    };
    static defaultProps = {
        isUserGroupsLoading: false,
        vehicleModels: [],
        vehicleMakes: [],
    };

    componentDidMount() {
        this.props.onLoad();

        if (isEmpty(this.props.vehicleMakes)) {
            this.props.fetchVehicleMakes();
        }
        if (isEmpty(this.props.vehicleModels)) {
            this.props.fetchVehicleModels();
        }
    }

    onActionSelect = (selectedIds, action) => {
        if (action === SET_STATUS_ACTIVE || action === SET_STATUS_INACTIVE) {
            const status =
                action === SET_STATUS_ACTIVE
                    ? USER_STATUS_ACTIVE
                    : USER_STATUS_INACTIVE;
            this.props.onSetUsersStatusSelected(selectedIds, status);
        } else if (
            action === SET_USER_TYPE_OWNER ||
            action === SET_USER_TYPE_TRUCKER ||
            action === SET_USER_TYPE_ADMIN
        ) {
            const userType = getUserTypeForAction(action);
            if (userType) {
                this.props.onSetUsersTypeSelected(selectedIds, userType);
            }
        }
    };

    renderFilters = () => {
        const { vehicleMakes, vehicleModels } = this.props;
        const {
            filters,
            filterErrors,
            filters: { vehicleFilters },
        } = this.props.tableContext;
        return (
            <UsersTableFilters
                checked={filters.checked}
                onCheckboxChange={this.onFilterCheckboxChange}
                vehicleFilters={vehicleFilters}
                vehicleMakes={vehicleMakes}
                vehicleModels={vehicleModels}
                onVehicleFilterChange={this.onVehicleFilterFieldChange}
                start={{
                    ...filters.start,
                    onMonthChange: (event) =>
                        this.onFilterDateChange(event, 'start', 'month'),
                    onDayChange: (event) =>
                        this.onFilterDateChange(event, 'start', 'day'),
                    onYearChange: (event) =>
                        this.onFilterDateChange(event, 'start', 'year'),
                }}
                end={{
                    ...filters.end,
                    onMonthChange: (event) =>
                        this.onFilterDateChange(event, 'end', 'month'),
                    onDayChange: (event) =>
                        this.onFilterDateChange(event, 'end', 'day'),
                    onYearChange: (event) =>
                        this.onFilterDateChange(event, 'end', 'year'),
                }}
                onClickApply={() => this.onClickFilterButtons('apply')}
                onClickClear={() => this.onClickFilterButtons('clear')}
                errors={filterErrors}
            />
        );
    };

    onFilterCheckboxChange = (event) => {
        const { filters } = this.props.tableContext;
        const checkboxValue = event.target.value;
        const oldChecked = filters.checked;
        const checked = oldChecked.includes(checkboxValue)
            ? without(checkboxValue, oldChecked)
            : [...oldChecked, checkboxValue];
        this.props.onUpdateFilters({ ...filters, checked });
    };

    onVehicleFilterFieldChange = (value, field) => {
        const { vehicleFilters } = this.props.tableContext;
        const filtersToSet = {
            ...vehicleFilters,
            [field]: value,
        };
        this.props.onVehicleFilterUpdate(filtersToSet);
    };

    renderEmpty = () => {
        return (
            <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
                spacing={16}
            >
                <Grid item>
                    <Typography color="primary">
                        There are no users to show that match the selected
                        filters.
                    </Typography>
                </Grid>
                <Grid item>
                    <AdminButton
                        size="medium"
                        buttonType="positive"
                        onClick={this.props.onClearFilters}
                    >
                        Clear Filters
                    </AdminButton>
                </Grid>
            </Grid>
        );
    };

    onFilterDateChange = (event, prop, monthDayYear) => {
        const { filters } = this.props.tableContext;
        const updates = {
            [prop]: {
                ...filters[prop],
                [monthDayYear]: event.target.value,
                [`${monthDayYear}Error`]: false,
            },
        };
        if (monthDayYear === 'month') {
            // If the month changes remove the day
            updates[prop] = without('day', updates[prop]);
        }
        this.props.onUpdateFilters({ ...filters, ...updates });
    };

    validateFilters = () => {
        const {
            filters,
            filters: { vehicleFilters },
        } = this.props.tableContext;
        const { start, end } = filters;
        const filterErrors = [];
        if (!isEmpty(start)) {
            if (!hasMonth(start)) {
                filterErrors.push('Start Month required.');
                start.monthError = true;
            }
            if (!hasDay(start)) {
                filterErrors.push('Start Day required.');
                start.dayError = true;
            }
            if (!hasYear(start)) {
                filterErrors.push('Start Year required.');
                start.yearError = true;
            }
        }
        if (!isEmpty(end)) {
            if (!hasMonth(end)) {
                filterErrors.push('End Month required.');
                end.monthError = true;
            }
            if (!hasDay(end)) {
                filterErrors.push('End Day required.');
                end.dayError = true;
            }
            if (!hasYear(end)) {
                filterErrors.push('End Year required.');
                end.yearError = true;
            }
        }
        if (
            !isEmpty(vehicleFilters.makeId) &&
            isEmpty(vehicleFilters.modelId.value)
        ) {
            filterErrors.push('Vehicle Model required.');
            vehicleFilters.modelId.error = true;
        }
        if (filterErrors.length > 0) {
            this.props.onFiltersValidationFailed(filterErrors, {
                ...filters,
                vehicleFilters,
                start,
                end,
            });
        }
        return filterErrors.length === 0;
    };

    onClickFilterButtons = (buttonName) => {
        if (buttonName === 'clear') {
            this.props.onClearFilters();
        } else {
            if (this.validateFilters()) {
                this.props.onLoad();
            }
        }
    };

    isRowSelectable = ({ userId }) => {
        return !this.props.loadingUpdate.includes(userId);
    };

    render() {
        const {
            tableData,
            tableContext,
            onLoad,
            onPageRequested,
            errors,
            isUserGroupsLoading,
            ...rest
        } = this.props;
        return (
            <AdminContainer
                innerHeader
                innerHeaderProps={{
                    title: 'Users',
                    rightContent: <AddUserButton />,
                }}
                {...rest}
            >
                <ListErrors errors={errors} />
                <AdminUsersDataTable
                    tableData={tableData}
                    tableContext={tableContext}
                    onFetchDataRequested={onPageRequested}
                    onActionSelect={this.onActionSelect}
                    isRowSelectable={this.isRowSelectable}
                    renderFilters={this.renderFilters()}
                    renderEmpty={this.renderEmpty}
                    actionsLoading={isUserGroupsLoading}
                />
            </AdminContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    tableData: getUsersListTablePageData(state),
    tableContext: getUsersListPageContext(state),
    errors: getUsersListErrors(state),
    loadingUpdate: state.users.loadingUpdate,
    userGroups: getUserGroups(state),
    isUserGroupsLoading: isUserGroupsLoading(state),
    vehicleModels: getVehicleModels(state),
    vehicleMakes: getVehicleMakes(state),
});

const mapDispatchToProps = (dispatch) => ({
    onLoad: () => dispatch(usersListViewLoadRequested()),
    onPageRequested: (context = {}) =>
        dispatch(fetchUsersListPageRequested(context)),
    onSetUsersStatusSelected: (userIds, status) =>
        dispatch(updateUsersStatusRequested(userIds, status)),
    onSetUsersTypeSelected: (userIds, groupId) =>
        dispatch(updateUsersTypeRequested(userIds, groupId)),
    onUpdateFilters: (filters) => dispatch(updateUsersListFilters(filters)),
    onVehicleFilterUpdate: (filters) =>
        dispatch(updateFilterByVehicleFilters(filters)),
    onClearFilters: () => dispatch(clearUsersListFilters()),
    onFiltersValidationFailed: (errors, filters) =>
        dispatch(filtersValidationFailed(errors, filters)),
    fetchVehicleMakes: () => dispatch(getVehicleMakesRequested()),
    fetchVehicleModels: () => dispatch(getVehicleModelsRequested()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersListContainer);
