const styles = (theme) => ({
    root: {
        color: theme.palette.secondary.main,
        borderRadius: 3,
    },
    largeRoot: {
        height: theme.rigParkAdmin.select.largeHeight,
        minWidth: theme.rigParkAdmin.select.largeMinWidth,
    },
    compactRoot: {
        border: `1px solid ${theme.palette.secondary.main}`,
        backgroundColor: theme.palette.primary.contrastText,
        height: theme.rigParkAdmin.select.compactHeight,
        minWidth: theme.rigParkAdmin.select.compactMinWidth,
    },
    icon: {
        color: theme.palette.secondary.main,
        marginRight: 7,
    },
    compactIcon: {
        fontSize: 16,
        marginRight: 2,
        marginTop: 4,
    },
    menuItem: {
        color: theme.palette.secondary.main,
    },
    compactMenuItem: {
        paddingBottom: 0,
    },
    largeDisplay: {
        padding: 20,
        height: theme.rigParkAdmin.select.largeHeight,
        minWidth: theme.rigParkAdmin.select.largeMinWidth,
        cursor: 'pointer',
    },
    compactDisplay: {
        padding: 2,
        color: theme.palette.secondary.main,
        fontSize: theme.custom.paginationFontSize,
        height: theme.rigParkAdmin.select.compactHeight - 2,
        minWidth: theme.rigParkAdmin.select.compactMinWidth,
        marginLeft: 2,
        cursor: 'pointer',
    },
    error: {
        border: `2px solid ${theme.custom.red}`,
    },
    errorText: {
        fontWeight: 'bold',
        marginLeft: 8,
        fontSize: 12,
    },
    disabledIcon: {
        display: 'none',
    },
});

export default styles;
