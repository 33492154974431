export const END = 'end';
export const START = 'start';
export const ACTIVE = 'active';

export const MONTH = 'month';
export const DAY = 'day';
export const YEAR = 'year';

export const APPLY = 'apply';
export const CLEAR = 'clear';

export const DOCKS = 'docks';
export const RESERVATIONS = 'reservations';

export const MIN = 'min';
export const MAX = 'max';

export const START_MONTH_REQUIRED_ERROR_MESSAGE = 'Start Month required.';
export const START_DAY_REQUIRED_ERROR_MESSAGE = 'Start Day required.';
export const START_YEAR_REQUIRED_ERROR_MESSSGE = 'Start Year required.';

export const END_MONTH_REQUIRED_ERROR_MESSAGE = 'End Month required.';
export const END_DAY_REQUIRED_ERROR_MESSAGE = 'End Day required.';
export const END_YEAR_REQUIRED_ERROR_MESSAGE = 'End Year required.';
