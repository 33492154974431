import React from 'react';
import { Grid, Avatar, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';

const UserReservationPhoto = ({
    value,
    classes,
    containerClass,
    ...inputProps
}) => {
    return (
        <Grid container spacing={16} className={containerClass}>
            <Grid item>
                <Avatar
                    src={value} // TODO should we have a default photo here or just a grey thing?
                    className={classes.avatar}
                />
                <input hidden type="file" accept="image/*" {...inputProps} />
            </Grid>
        </Grid>
    );
};

UserReservationPhoto.propTypes = {
    value: PropTypes.string,
    classes: PropTypes.object.isRequired,
    inputProps: PropTypes.object,
    containerClass: PropTypes.string,
};

export default withStyles(styles)(UserReservationPhoto);
