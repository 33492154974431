import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import AdminSelect, { SIZE_COMPACT } from '../AdminSelect';
import styles from './styles';
import DatePropTypes from '../../propTypes/dates';
import { yearOptions } from '../../utils/dates';

class YearPicker extends Component {
    render() {
        const { classes, value, ...props } = this.props;
        return (
            <AdminSelect
                type={SIZE_COMPACT}
                className={classes.root}
                value={value}
                options={yearOptions}
                {...props}
            />
        );
    }
}

YearPicker.propTypes = {
    // From withStyles we expect to get classes.
    classes: PropTypes.object.isRequired,

    // Value must be a valid year or 'none'.
    value: DatePropTypes.year,
};

YearPicker.defaultProps = {
    value: 'none',
};

export default withStyles(styles)(YearPicker);
