const styles = (theme) => ({
    componentWrapper: {
        flex: 4,
        marginRight: 60,
        marginLeft: 40,
        minWidth: 575,
    },
    labelWrapper: {
        margin: 'auto 0 auto auto',
        textAlign: 'right',
        minWidth: 175,
        flex: 1,
    },
});

export default styles;
