const styles = (theme) => ({
    messageGridItem: {
        flex: 1,
    },
    messageText: {
        color: theme.palette.primary.contrastText,
        fontSize: 16,
        fontWeight: 'normal',
        letterSpacing: 1,
    },
    closeIconButton: {
        color: theme.custom.red,
        backgroundColor: theme.palette.primary.contrastText,
        padding: 4,
        marginRight: 25,
        marginLeft: 25,
    },
    closeIcon: {
        fontSize: 14,
    },
});

export default styles;
