import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import TablePropTypes from '../propTypes';
import RigParkDataTable from '../RigParkDataTable';
import DataTableColumn from '../DataTable/DataTableColumn';
import { SORT_ASC, SORT_DESC } from '../DataTable/constants';
import DataTableLink from '../DataTableLink';
import { noop } from '../../utils';
import styles from './styles';
import {
    DOCK_ID,
    PROPERTY_NAME,
    RESERVATION_ID,
    START_TIME,
    USER_NAME,
    VEHICLE_NAME,
    DOCK_NAME,
} from '../../stores/reservations/constants';
import AdminAvatar from '../../common/AdminAvatar';

/**
 * A function that returns a uniquely identifying key for a reservation object.
 *
 * @param {{reservationId: *}} reservation
 * @return {*}
 */
const getKeyForReservation = (reservation) => reservation.reservationId;

const ReservationsTable = ({
    classes,
    tableData,
    tableContext,
    onFetchDataRequested,
    multisort,
    ...rest
}) => {
    return (
        <RigParkDataTable
            tableData={tableData}
            tableContext={tableContext}
            getKey={getKeyForReservation}
            onFetchDataRequested={onFetchDataRequested}
            searchable
            onActionSelect={noop}
            selectable={false}
            {...rest}
        >
            {({
                createOnSingleSort,
                createOnMultiSortToggle,
                createSortGetter,
            }) => {
                const createOnSort = multisort
                    ? createOnMultiSortToggle
                    : createOnSingleSort;
                return (
                    <Fragment>
                        <DataTableColumn
                            headCellClassName={classes.photoHeadCell}
                        >
                            {({
                                vehicleName,
                                userId,
                                vehicleId,
                                photoUrl = null,
                            }) => {
                                return (
                                    <DataTableLink
                                        component={Link}
                                        to={`/user/${userId}/vehicles/${vehicleId}`}
                                    >
                                        <AdminAvatar
                                            src={photoUrl}
                                            alt={vehicleName}
                                        />
                                    </DataTableLink>
                                );
                            }}
                        </DataTableColumn>
                        <DataTableColumn
                            headCellContent="Property"
                            headCellClassName={classes.propertyNameHeadCell}
                            getSort={createSortGetter(PROPERTY_NAME)}
                            onSort={createOnSort(PROPERTY_NAME)}
                            initialSort={SORT_ASC}
                        >
                            {({ propertyId, propertyName }) => (
                                <DataTableLink
                                    component={Link}
                                    to={`/properties/${propertyId}`}
                                >
                                    {propertyName}
                                </DataTableLink>
                            )}
                        </DataTableColumn>
                        <DataTableColumn
                            headCellContent="Dock ID"
                            headCellClassName={classes.idHeadCell}
                            getSort={createSortGetter(DOCK_ID)}
                            onSort={createOnSort(DOCK_ID)}
                            initialSort={SORT_DESC}
                        >
                            {({ dockId, propertyId }) => {
                                return (
                                    <DataTableLink
                                        component={Link}
                                        to={`/properties/${propertyId}/dock/${dockId}`}
                                        classes={{
                                            root: classes.centeredColumn,
                                        }}
                                    >
                                        {dockId}
                                    </DataTableLink>
                                );
                            }}
                        </DataTableColumn>
                        <DataTableColumn
                            headCellContent="Reservation ID"
                            headCellClassName={classes.reservationIdHeadCell}
                            getSort={createSortGetter(RESERVATION_ID)}
                            onSort={createOnSort(RESERVATION_ID)}
                            initialSort={SORT_DESC}
                        >
                            {({ reservationId }) => {
                                return (
                                    <DataTableLink
                                        component={Link}
                                        to={`/reservations/${reservationId}`}
                                        classes={{
                                            root: classes.centeredColumn,
                                        }}
                                    >
                                        {reservationId}
                                    </DataTableLink>
                                );
                            }}
                        </DataTableColumn>
                        <DataTableColumn
                            headCellContent="User"
                            headCellClassName={classes.userNameHeadCell}
                            getSort={createSortGetter(USER_NAME)}
                            onSort={createOnSort(USER_NAME)}
                            initialSort={SORT_ASC}
                        >
                            {({ userId, userName }) => (
                                <DataTableLink
                                    component={Link}
                                    to={`/users/${userId}`}
                                >
                                    {userName}
                                </DataTableLink>
                            )}
                        </DataTableColumn>
                        <DataTableColumn
                            headCellContent="License Plate #"
                            headCellClassName={classes.plateHeadCell}
                            getSort={createSortGetter(VEHICLE_NAME)}
                            onSort={createOnSort(VEHICLE_NAME)}
                            initialSort={SORT_ASC}
                        >
                            {({ userId, vehicleId, vehiclePlate }) => (
                                <DataTableLink
                                    component={Link}
                                    to={`/users/${userId}/vehicles/${vehicleId}`}
                                    classes={{
                                        root: classes.centeredColumn,
                                    }}
                                >
                                    {vehiclePlate}
                                </DataTableLink>
                            )}
                        </DataTableColumn>
                        <DataTableColumn
                            headCellContent="Spot Name"
                            headCellClassName={classes.propertyNameHeadCell}
                            getSort={createSortGetter(DOCK_NAME)}
                            onSort={createOnSort(DOCK_NAME)}
                            initialSort={SORT_DESC}
                        >
                            {({ propertyId, dockId, dockName }) => (
                                <DataTableLink
                                    component={Link}
                                    to={`/properties/${propertyId}/dock/${dockId}`}
                                    classes={{
                                        root: classes.centeredColumn,
                                    }}
                                >
                                    {dockName}
                                </DataTableLink>
                            )}
                        </DataTableColumn>
                        <DataTableColumn
                            headCellContent="Duration"
                            headCellClassName={classes.durationHeadCell}
                            getSort={createSortGetter(START_TIME)}
                            onSort={createOnSort(START_TIME)}
                            initialSort={SORT_DESC}
                        >
                            {({ duration }) => {
                                return duration;
                            }}
                        </DataTableColumn>
                        <DataTableColumn
                            headCellContent="Cost"
                            headCellClassName={classes.costHeadCell}
                        >
                            {({ cost }) => {
                                return `$${cost}`;
                            }}
                        </DataTableColumn>
                    </Fragment>
                );
            }}
        </RigParkDataTable>
    );
};

ReservationsTable.propTypes = {
    classes: PropTypes.object.isRequired,
    tableData: TablePropTypes.tablePageData.isRequired,
    tableContext: TablePropTypes.tablePageContext.isRequired,
    onFetchDataRequested: PropTypes.func.isRequired,
    multisort: PropTypes.bool,
};

ReservationsTable.defaultProps = {
    multisort: false,
};

export default withStyles(styles)(ReservationsTable);
