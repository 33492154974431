import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';

import styles from './styles';

import AdminAppBar from './AdminAppBar';
import Logo from '../Logo';
import LogoutButton from './LogoutButton';
import classNames from 'classnames';

const Header = (props) => {
    const {
        classes,
        exterior,
        isAdmin,
        onLogoutRequested,
        menuItems,
        path,
    } = props;
    return (
        <Grid
            container
            direction="row"
            className={classNames([
                classes.container,
                exterior
                    ? classes.exteriorContainer
                    : classes.interiorContainer,
            ])}
        >
            <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                wrap="nowrap"
                className={classes.innerContainer}
            >
                <Grid item>
                    <Logo white={exterior} />
                </Grid>
                {!exterior && (
                    <Grid item className={classes.menuAndLogoutGridItem}>
                        <Grid
                            container
                            direction="column"
                            alignItems="flex-end"
                        >
                            <Grid item>
                                <LogoutButton
                                    onLogoutRequested={onLogoutRequested}
                                />
                            </Grid>
                            {isAdmin && (
                                <Grid item>
                                    <AdminAppBar
                                        menuItems={menuItems}
                                        path={path}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

Header.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    /*
     * When exterior is set to true, it will cause the background to be transparent,
     * hide logout button, use white logo and won't render menu items.
     * Defaults to false.
     */
    exterior: PropTypes.bool,

    // THe logged-in user is an Admin
    isAdmin: PropTypes.bool,

    // Function that will be called when the logout button is clicked
    onLogoutRequested: PropTypes.func,

    // An array of labels for routes/urls to render with optional icons
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            route: PropTypes.string,
            href: PropTypes.string,
            icon: PropTypes.object,
        }),
    ),

    // The current path of the react router
    path: PropTypes.string.isRequired,
};

Header.defaultProps = {
    exterior: false,
    isAdmin: false,
    menuItems: [],
    path: '/',
};

export default withStyles(styles)(Header);
