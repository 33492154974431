import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles';

// Material-UI Imports
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    withStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class AccordionMenu extends Component {
    _handleChange = (...args) => {
        return this.props.onChange && this.props.onChange(...args);
    };

    render() {
        const { label, children, classes, container, ...props } = this.props;

        const panelStyles = container
            ? classes.defaultAccordion
            : classes.defaultChildAccordion;
        const iconStyles = container
            ? classes.defaultIcon
            : classes.defaultChildIcon;
        const labelStyles = container
            ? classes.defaultLabel
            : classes.defaultChildLabel;

        return (
            <ExpansionPanel
                classes={{ root: classes.root, expanded: classes.expanded }}
                {...props}
                onChange={(event, expanded) =>
                    this._handleChange(label, event, expanded)
                }
            >
                <ExpansionPanelSummary
                    className={classNames([panelStyles, classes.panel])}
                    expandIcon={<ExpandMoreIcon className={iconStyles} />}
                >
                    <Typography className={labelStyles}>{label}</Typography>
                </ExpansionPanelSummary>
                {children}
            </ExpansionPanel>
        );
    }
}

AccordionMenu.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    /*
     * Can be anything that needs to be inside the Accordion.
     * i.e. checkboxes, buttons, pickers, or even another <AccordionMenu />
     */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),

    // Text on the Accordion
    label: PropTypes.string.isRequired,

    // Styles prop to use parent's accordion style or child accordion style
    container: PropTypes.bool,

    // Indicate if the accordion is disabled or not.
    disabled: PropTypes.bool,

    // Boolean passed in that controls expanded state of expansion panel
    expanded: PropTypes.bool,

    // Handler for when a panel is expanded
    onChange: PropTypes.func,
};

AccordionMenu.defaultProps = {
    // Not disabled by default.
    disabled: false,

    // false by default.
    container: false,
};

export default withStyles(styles)(AccordionMenu);
