const styles = (theme) => {
    return {
        topLinearProgress: {
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            height: 3,
        },
        mobileCircularProgress: {
            alignSelf: 'center',
        },
        select: {
            width: 540,
            marginBottom: 12,
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
        selectControl: {
            borderColor: theme.palette.secondary.main,
        },
        reservationsHeader: {
            color: theme.palette.secondary.main,
            fontWeight: 600,
            marginLeft: 8,
            [theme.breakpoints.down(850)]: {
                marginBottom: 4,
                marginLeft: 0,
            },
        },
        timezone: {
            marginLeft: 8,
            [theme.breakpoints.down(850)]: {
                marginBottom: 18,
                marginLeft: 0,
            },
        },
        tableHead: {
            color: theme.palette.secondary.main,
            fontWeight: 600,
            fontSize: 14,
        },
        mobileRow: {
            padding: 5,
        },
        mobileRowHighlight: {
            backgroundColor: theme.custom.lightGray,
            borderRadius: 4,
        },
        mobileRowLabel: {
            width: '40%',
            color: theme.palette.secondary.main,
            fontWeight: 600,
        },
        mobileRowValue: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        mobileBottomDivider: {
            height: 2,
            margin: '12px 0',
            marginLeft: -4,
            width: 'calc(100% + 8px)',
        },
    };
};

export default styles;
