import { RESERVATION_ID } from './constants';
import { ORDER_BY_DIR_DESC } from '../../constants/api';

export const initialFiltersState = {
    checked: [],
    start: {},
    end: {},
    active: {},
    selectedPropertyId: null,
    selectedDockIds: [],
    selectedUserIds: [],
};

export default {
    // Reservations list loadings state and data
    list: {
        loading: false,
        page: {
            filters: initialFiltersState,
            filterErrors: [],
            order: [[RESERVATION_ID, ORDER_BY_DIR_DESC]],
        },
        errors: [],
    },

    // Reservation detail
    reservationLoading: false,
    reservation: {},
    messages: [],

    // For Reservations table filters

    propertiesListLoading: false,
    propertiesList: [],
    propertiesListErrors: [],

    usersListLoading: false,
    usersList: [],
    usersListErrors: [],

    // For booking new reservations
    userSearchResults: [],
    userSearchLoading: false,
    userPaymentMethods: [],
    userPaymentMethodsLoading: false,
    reservationInProgress: false,
    propertyInfoLoading: false,
    property: {},
};
