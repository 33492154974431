import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/remote-config';
import {
    MAX_HOURLY_RATE_DEFAULT,
    MAX_DAILY_RATE,
    MAX_MONTHLY_RATE,
} from '../validation/forms';
import {
    REMOTE_CONFIG_NAME_FORMAT_DOCK_NAME_DESC,
    REMOTE_CONFIG_NAME_FORMAT_DOCK_NAME_REGEX,
    REMOTE_CONFIG_NAME_MAX_DAILY_RATE,
    REMOTE_CONFIG_NAME_MAX_HOURLY_RATE,
    REMOTE_CONFIG_NAME_MAX_MONTHLY_RATE,
    REMOTE_CONFIG_NAME_VALIDATE_DOCK_NAME,
} from './constants';

export const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
    databaseURL: `https://${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
const app = firebase.initializeApp(config);
const remoteConfig = firebase.remoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 300000;
remoteConfig.defaultConfig = {
    [REMOTE_CONFIG_NAME_VALIDATE_DOCK_NAME]: false,
    [REMOTE_CONFIG_NAME_FORMAT_DOCK_NAME_REGEX]: '',
    [REMOTE_CONFIG_NAME_FORMAT_DOCK_NAME_DESC]:
        'Please enter a valid dock name',
    [REMOTE_CONFIG_NAME_MAX_HOURLY_RATE]: MAX_HOURLY_RATE_DEFAULT,
    [REMOTE_CONFIG_NAME_MAX_DAILY_RATE]: MAX_DAILY_RATE,
    [REMOTE_CONFIG_NAME_MAX_MONTHLY_RATE]: MAX_MONTHLY_RATE,
};
remoteConfig.fetchAndActivate().then(() => {
    // console.log('[Fetch Remote Config] Success');
});
export default firebase;
