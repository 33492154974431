import { generateRoundImageStyles } from '../../../utils/styles';

const styles = (theme) => ({
    root: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 30,
        paddingRight: 30,
        backgroundColor: theme.custom.grey,
        height: 85,
        maxHeight: 85,
    },
    avatar: {
        margin: 'auto',
        marginRight: 25,
        ...generateRoundImageStyles(52),
    },
    text: {
        fontSize: 14,
        fontWeight: 'bold',
        color: theme.custom.darkBlue,
    },
    subtext: {
        fontSize: 10,
        color: theme.custom.darkBlue,
    },
});

export default styles;
