import PropTypes from 'prop-types';
import { apiPageDataShape, apiPageContextShape } from '../propTypes/api';

export const tablePageData = PropTypes.shape({
    // Extends the API page data.
    ...apiPageDataShape,
    // Indicates whether the table data is loading.
    loading: PropTypes.bool.isRequired,
});

// Table page contexts are (currently) equivalent to API page contexts
export const tablePageContext = PropTypes.shape(apiPageContextShape);

export const TablesPropTypes = {
    tablePageData,
    tablePageContext,
};

export default TablesPropTypes;
