const styles = (theme) => ({
    accordionMenu: {
        margin: '16px 0',
        [theme.breakpoints.down('md')]: {
            width: theme.custom.actionsAndFiltersWidthForMediumAndDown,
        },
    },
});

export default styles;
