import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoginFormPropTypes from '../../propTypes/loginForm';
import classNames from 'classnames';
import { Formik } from 'formik';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import LoginTextField from '../LoginTextField';
import LoginSubmitButton from '../LoginSubmitButton';
import { noop } from '../../utils';

class LoginForm extends Component {
    render() {
        const {
            classes,
            onSubmit,
            emailError,
            errors: propsErrors,
            loginInProgress,
            onClearErrorRequested,
        } = this.props;
        return (
            <Grid container direction="column">
                <Grid item>
                    <Grid container item direction="column">
                        <Grid item>
                            <Typography
                                variant="subtitle1"
                                className={classNames([
                                    classes.text,
                                    classes.welcomeText,
                                ])}
                            >
                                Welcome to RigPark!
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="subtitle1"
                                className={classNames([
                                    classes.text,
                                    classes.descriptionText,
                                ])}
                            >
                                For all your big rig parking needs in one.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        onSubmit={(values) => {
                            onSubmit(values.email, values.password);
                        }}
                        validateOnChange={false}
                        validate={(values) => {
                            const errors = {};
                            if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                    values.email,
                                )
                            ) {
                                errors.email = emailError;
                            }
                            return errors;
                        }}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleSubmit,
                            handleBlur,
                        }) => (
                            <Grid
                                container
                                item
                                direction="column"
                                component="form"
                                onSubmit={handleSubmit}
                            >
                                <Grid item className={classes.formGridItem}>
                                    <Typography
                                        variant="subtitle1"
                                        className={classNames([
                                            classes.text,
                                            classes.loginText,
                                        ])}
                                    >
                                        Administrator Login
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <LoginTextField
                                        label="Email Address"
                                        placeholder="Email Address"
                                        type="text"
                                        name="email"
                                        value={values.email}
                                        className={classes.formGridItem}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onClick={() =>
                                            onClearErrorRequested('email')
                                        }
                                        error={propsErrors.email}
                                        disabled={loginInProgress}
                                    />
                                </Grid>
                                <Grid item>
                                    <LoginTextField
                                        label="Password"
                                        placeholder="Password"
                                        type="password"
                                        name="password"
                                        value={values.password}
                                        className={classes.formGridItem}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        onClick={() =>
                                            onClearErrorRequested('password')
                                        }
                                        error={propsErrors.password}
                                        disabled={loginInProgress}
                                    />
                                </Grid>
                                <Grid item className={classes.formGridItem}>
                                    <LoginSubmitButton
                                        disabled={loginInProgress}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        );
    }
}

LoginForm.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // Function that will be called when the form is submitted.
    onSubmit: PropTypes.func.isRequired,

    // Optionally override the default error text for email
    emailError: PropTypes.string,

    // Errors for the fields in the login form.
    errors: LoginFormPropTypes.loginFormErrors,

    /*
     * Function to be called when the errors should be cleared out in the form fields,
     * i.e. for when they have changed since the error occurred.
     */
    onClearErrorRequested: PropTypes.func.isRequired,

    // Indicate whether the login is in progress or not, used to disable the form fields and the submit button.
    loginInProgress: PropTypes.bool.isRequired,
};

LoginForm.defaultProps = {
    // Basic default error text for email
    emailError: 'Invalid email address.',

    // Default initial errors for email and password fields are empty strings.
    errors: {
        email: '',
        password: '',
    },

    // Default noop so no extra action is taken on change of fields.
    onClearErrorRequested: noop,
};

export default withStyles(styles)(LoginForm);
