const styles = (theme) => ({
    label: {
        textAlign: 'right',
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        letterSpacing: 4,
        marginRight: 40,
    },
    container: {
        marginLeft: -112,
    },
    model: {
        marginRight: -65,
    },
    inputRoot: {
        width: 180,
        height: 'auto',
        color: theme.palette.secondary.main,
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: `${theme.custom.grey} !important`,
    },
    input: {
        padding: '20px 0',
        marginBottom: 0,
        color: theme.palette.secondary.main,
        backgroundColor: theme.custom.grey,
    },
});

export default styles;
