import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Grid, Typography, withStyles } from '@material-ui/core';
import styles from './styles';

class FilterCheckbox extends Component {
    render() {
        const { classes, ...checkboxProps } = this.props;
        return (
            <Grid
                item
                container
                direction="row"
                alignItems="center"
                spacing={8}
                className={classes.checkboxGridItem}
            >
                <Grid item>
                    <Checkbox className={classes.checkbox} {...checkboxProps} />
                </Grid>
                <Grid item>
                    <Typography className={classes.label}>
                        {checkboxProps.label || checkboxProps.value}
                    </Typography>
                </Grid>
            </Grid>
        );
    }
}

FilterCheckbox.propTypes = {
    // From withStyles we expect to get classes.
    classes: PropTypes.object.isRequired,

    // Optional label to be provided, if not provided the displayed label will be the value.
    label: PropTypes.string,

    // All other expected props are all/any for spreading to the MUI Checkbox component.
};

export default withStyles(styles)(FilterCheckbox);
