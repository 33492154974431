import { call, put, takeEvery } from 'redux-saga/effects';
import {
    FETCH_USER_GROUPS_REQUESTED,
    fetchUserGroupsSucceeded,
    fetchUserGroupsFailed,
} from './actions';
import { fetchWithAuth } from '../api/sagas';

export function* doFetchUserGroups() {
    try {
        const response = yield call(fetchWithAuth, '/userGroup');
        yield put(fetchUserGroupsSucceeded(response));
    } catch {
        yield put(fetchUserGroupsFailed());
    }
}

export default function*() {
    yield takeEvery(FETCH_USER_GROUPS_REQUESTED, doFetchUserGroups);
}
