const styles = () => ({
    photo: {
        height: 85,
        paddingBottom: 10,
        margin: 2,
    },
    button: {
        margin: 4,
    },
});

export default styles;
