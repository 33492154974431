import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
    CircularProgress,
    Grid,
    Typography,
    withStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import styles from './styles';
import AdminContainer from '../../common/AdminContainer';
import DashboardPropTypes from '../../propTypes/dashboard';
import AdminDashboardPanel from '../AdminDashboardPanel';
import { logout } from '../../stores/auth/actions';
import { dashboardLoadRequested } from '../../stores/dashboard/actions';
import {
    areDashboardPropertiesLoading,
    areDashboardReservationsLoading,
    areDashboardUsersLoading,
    getNewPropertiesCountForDashboard,
    getNewReservationsCountForDashboard,
    getNewUsersCountForDashboard,
    getPropertiesForDashboard,
    getPropertiesTotalForDashboard,
    getReservationsForDashboard,
    getReservationsTotalForDashboard,
    getUsersForDashboard,
    getUsersTotalForDashboard,
    isDashboardNewPropertiesCountLoading,
    isDashboardNewReservationsCountLoading,
    isDashboardNewUsersCountLoading,
} from '../../stores/dashboard/selectors';

class Dashboard extends Component {
    componentDidMount() {
        this.props.onLoad();
    }

    render() {
        const {
            classes,
            usersLoading,
            users,
            usersCount,
            newUsersCount,
            newUsersCountLoading,
            propertiesLoading,
            properties,
            propertiesCount,
            newPropertiesCount,
            newPropertiesCountLoading,
            reservationsLoading,
            reservations,
            reservationsCount,
            newReservationsCount,
            newReservationsCountLoading,
            path,
            onLogoutRequested,
        } = this.props;
        return (
            <AdminContainer
                path={path}
                onLogoutRequested={onLogoutRequested}
                classes={{ interiorContainer: classes.container }}
            >
                <Grid container direction="column">
                    <Grid item>
                        <Typography
                            variant="subtitle1"
                            className={classes.subtitle}
                        >
                            Welcome to the RigPark Dashboard.
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="space-around"
                        spacing={32}
                        wrap="nowrap"
                    >
                        <Grid item className={classes.panelGridItem}>
                            {usersLoading ? (
                                <CircularProgress
                                    className={classes.circularProgress}
                                />
                            ) : (
                                <AdminDashboardPanel
                                    items={users}
                                    title="Users"
                                    count={usersCount}
                                    statusText={
                                        !newUsersCountLoading &&
                                        !isNaN(newUsersCount)
                                            ? `${newUsersCount} New Today`
                                            : ''
                                    }
                                    AdminButtonProps={{
                                        children: 'See All Users',
                                        component: Link,
                                        to: '/users',
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid item className={classes.panelGridItem}>
                            {propertiesLoading ? (
                                <CircularProgress
                                    className={classes.circularProgress}
                                />
                            ) : (
                                <AdminDashboardPanel
                                    items={properties}
                                    title="Properties"
                                    count={propertiesCount}
                                    statusText={
                                        !newPropertiesCountLoading &&
                                        !isNaN(newPropertiesCount)
                                            ? `${newPropertiesCount} New Today`
                                            : ''
                                    }
                                    AdminButtonProps={{
                                        children: 'See All Properties',
                                        component: Link,
                                        to: '/properties',
                                    }}
                                />
                            )}
                        </Grid>
                        <Grid item className={classes.panelGridItem}>
                            {reservationsLoading ? (
                                <CircularProgress
                                    className={classes.circularProgress}
                                />
                            ) : (
                                <AdminDashboardPanel
                                    items={reservations}
                                    title="Reservations"
                                    count={reservationsCount}
                                    statusText={
                                        !newReservationsCountLoading &&
                                        !isNaN(newReservationsCount)
                                            ? `${newReservationsCount} New Today`
                                            : ''
                                    }
                                    AdminButtonProps={{
                                        children: 'See All Reservations',
                                        component: Link,
                                        to: '/reservations',
                                    }}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </AdminContainer>
        );
    }
}

Dashboard.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // The path which should be passed down from the router.
    path: PropTypes.string.isRequired,

    // Users and their counts for the first panel.
    users: DashboardPropTypes.dashboardItems.isRequired,
    usersCount: PropTypes.number.isRequired,
    newUsersCount: PropTypes.number.isRequired,

    // Properties and their counts for the second panel.
    properties: DashboardPropTypes.dashboardItems.isRequired,
    propertiesCount: PropTypes.number.isRequired,
    newPropertiesCount: PropTypes.number.isRequired,

    reservations: DashboardPropTypes.dashboardItems.isRequired,
    reservationsCount: PropTypes.number.isRequired,
    newReservationsCount: PropTypes.number.isRequired,

    // The follow properties are used to indicate whether or not the data for the panels is loading.
    usersLoading: PropTypes.bool,
    propertiesLoading: PropTypes.bool,
    reservationsLoading: PropTypes.bool,
    newUsersCountLoading: PropTypes.bool.isRequired,
    newPropertiesCountLoading: PropTypes.bool.isRequired,
    newReservationsCountLoading: PropTypes.bool.isRequired,

    onLoad: PropTypes.func.isRequired,
    onLogoutRequested: PropTypes.func.isRequired,
};

const mapStateToProps = function(state) {
    return {
        // Data from redux store for the users panel
        newUsersCount: getNewUsersCountForDashboard(state),
        newUsersCountLoading: isDashboardNewUsersCountLoading(state),
        users: getUsersForDashboard(state),
        usersCount: getUsersTotalForDashboard(state),
        usersLoading: areDashboardUsersLoading(state),

        // Data from the redux store for the properties panel
        newPropertiesCount: getNewPropertiesCountForDashboard(state),
        newPropertiesCountLoading: isDashboardNewPropertiesCountLoading(state),
        properties: getPropertiesForDashboard(state),
        propertiesCount: getPropertiesTotalForDashboard(state),
        propertiesLoading: areDashboardPropertiesLoading(state),

        // Data from the redux store for the reservations panel
        newReservationsCount: getNewReservationsCountForDashboard(state),
        newReservationsCountLoading: isDashboardNewReservationsCountLoading(
            state,
        ),
        reservations: getReservationsForDashboard(state),
        reservationsCount: getReservationsTotalForDashboard(state),
        reservationsLoading: areDashboardReservationsLoading(state),
    };
};

const mapDispatchToProps = function(dispatch) {
    return {
        onLoad: () => dispatch(dashboardLoadRequested()),
        onLogoutRequested: () => dispatch(logout()),
    };
};

export default compose(
    withStyles(styles),
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(Dashboard);
