const srOnly = {
    border: '0 !important',
    clip: 'rect(1px, 1px, 1px, 1px) !important',
    clipPath: 'inset(50%) !important',
    height: '1px !important',
    margin: '-1px !important',
    overflow: 'hidden !important',
    padding: '0 !important',
    position: 'absolute !important',
    width: '1px !important',
    whiteSpace: 'nowrap !important',
};

export default () => ({
    root: {},
    meta: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'stretch',
    },
    label: {},
    input: {},
    filters: {},
    filter: {},
    filterLabel: {
        ...srOnly,
    },
    filterInput: {},
    searchToggleButton: {},
    searchToggleButtonHide: {},
    searchToggleButtonShow: {},
    searchToggleIcon: {},
    searchToggleIconHide: {},
    searchToggleIconShow: {},
    searchContainer: {},
    search: {},
    searchControl: {},
    searchLabel: {
        ...srOnly,
    },
    searchInput: {},
    actions: {},
    actionsLabel: {
        ...srOnly,
    },
    actionsInput: {},
    table: {},
    tableHead: {},
    tableHeadRow: {},
    tableBody: {},
    tableBodyRow: {},
    tableBodyWithData: {},
    tableBodyWithDataRow: {},
    tableBodyLoading: {},
    tableBodyLoadingRow: {},
    tableLoadingCell: {
        textAlign: 'center',
        paddingTop: 12,
        paddingBottom: 12,
    },
    tableBodyEmpty: {},
    tableBodyEmptyRow: {},
    tableEmptyCell: {},
    tableFooter: {},
    tableFooterRow: {},
    tableFooterCell: {},
    checkboxBodyCell: {},
    checkboxHeadCell: {},
});
