// Firebase Remote config name for indicating whether or not the dock name should be validated with the remote config for regex.
export const REMOTE_CONFIG_NAME_VALIDATE_DOCK_NAME = 'validateDockName';

/*
 * Firebase remote config name for indicating the regex to use when validating dock names,
 * if they are being validated at all. (See above config).
 */
export const REMOTE_CONFIG_NAME_FORMAT_DOCK_NAME_REGEX = 'formatDockNameRegex';

/*
 * Firebase remote config name for the config to specify a user friendly message to use when the regex constraint is violated.
 * (Again only if dock name validation is turned on.)
 */
export const REMOTE_CONFIG_NAME_FORMAT_DOCK_NAME_DESC = 'formatDockNameDesc';

// Firebase remote config name for controlling the maximum value to validate for a dock's hourly rate.
export const REMOTE_CONFIG_NAME_MAX_HOURLY_RATE = 'maxHourlyRate';

// Firebase remote config name for controlling the maximum value to validate for a dock's daily rate.
export const REMOTE_CONFIG_NAME_MAX_DAILY_RATE = 'maxDailyRate';

// Firebase remote config name for controlling the maximum value to validate for a dock's monthly rate.
export const REMOTE_CONFIG_NAME_MAX_MONTHLY_RATE = 'maxMonthlyRate';
