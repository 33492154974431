import {
    INITIALIZATION_SUCCEEDED,
    INITIALIZATION_FAILED,
    FIREBASE_AUTH_STATE_CHANGED,
    LOGIN_REQUESTED,
    LOGIN_FAILED,
    CHECK_SUCCEEDED,
    CLEAR_ERROR,
    LOGOUT,
    CHECK_REQUESTED,
} from './actions';
import { authInitialState } from './initialState';
import {
    INITIALIZATION_STATE_FAILED,
    INITIALIZATION_STATE_SUCCEEDED,
} from './constants';

export default function(state = authInitialState, action = {}) {
    const { type, firebaseUser, errors, fieldName } = action;
    switch (type) {
        case FIREBASE_AUTH_STATE_CHANGED:
            return {
                ...state,
                firebaseUser,
                loginInProgress: false,
            };
        case INITIALIZATION_SUCCEEDED:
            return {
                ...state,
                initializationState: INITIALIZATION_STATE_SUCCEEDED,
            };
        case INITIALIZATION_FAILED:
            return {
                ...state,
                initializationState: INITIALIZATION_STATE_FAILED,
                initializationErrors: state.initializationErrors.concat(
                    action.error || [],
                ),
            };
        case LOGIN_REQUESTED:
            return {
                ...state,
                loginInProgress: true,
            };
        case LOGIN_FAILED:
            return {
                ...state,
                errors,
                loginInProgress: false,
                checkInProgress: false,
            };
        case CHECK_REQUESTED:
            return {
                ...state,
                checkInProgress: true,
            };
        case CHECK_SUCCEEDED: {
            const { userId, groupName } = action;
            return {
                ...state,
                validUser: true,
                loginInProgress: false,
                checkInProgress: false,
                userId,
                groupName,
            };
        }
        case CLEAR_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [fieldName]: '',
                },
            };
        case LOGOUT:
            return authInitialState;
        default:
            return state;
    }
}
