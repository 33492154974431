import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import styles from './styles';
import AccordionMenu from '../../common/AccordionMenu';
import { COUNTRY_FILTERS, STATUS_FILTERS } from './constants';
import DatePropTypes from '../../propTypes/dates';
import FilterDatePicker from '../../common/FilterDatePicker';
import FilterMenuButtons from '../../common/FilterMenuButtons';
import FilterCheckbox from '../../common/FilterCheckbox';
import AdminMultipleSelect from '../../common/AdminMultipleSelect';
import TextField, { SIZE_SMALL } from '../../common/TextField';
import { noop } from '../../utils';
import AdminSelect from '../../common/AdminSelect';
import FormMessages, { formMessagesPropTypes } from '../../common/FormMessages';
import { COUNTRY_ABBR_UNITED_STATES } from '../../constants/country';

class PropertiesTableFilters extends Component {
    renderRange = ({ min, onMinChange, max, onMaxChange }) => {
        const { classes } = this.props;
        return (
            <Grid
                container
                direction="row"
                className={classes.panel}
                wrap="nowrap"
                justify="center"
                alignItems="center"
            >
                <Grid item container direction="column">
                    <Grid item>
                        <Typography className={classes.filterLabel}>
                            Min
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            size={SIZE_SMALL}
                            type="number"
                            value={min || ''}
                            onChange={onMinChange}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <KeyboardArrowRight className={classes.rangeArrow} />
                </Grid>
                <Grid item container direction="column">
                    <Grid item>
                        <Typography className={classes.filterLabel}>
                            Max
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            size={SIZE_SMALL}
                            type="number"
                            value={max || ''}
                            onChange={onMaxChange}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    renderCheckBoxes = (options) => {
        const { classes, checked, onCheckboxChange } = this.props;
        return (
            <Grid container direction="column" className={classes.panel}>
                {options.map((option) => {
                    return (
                        <Grid
                            item
                            container
                            key={option}
                            direction="row"
                            alignItems="center"
                            spacing={8}
                            className={classes.checkboxGridItem}
                        >
                            <Grid item>
                                <FilterCheckbox
                                    checked={checked.includes(option)}
                                    value={option}
                                    onChange={onCheckboxChange}
                                />
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        );
    };

    renderDatePickers = (label) => {
        const { classes, [label.toLowerCase()]: datePickerProps } = this.props;
        return (
            <Grid container direction="column" className={classes.panel}>
                <Grid item>
                    <Typography className={classes.filterLabel}>
                        {label}
                    </Typography>
                </Grid>
                <Grid item>
                    <FilterDatePicker {...datePickerProps} />
                </Grid>
            </Grid>
        );
    };

    render() {
        const {
            classes,
            onClickApply,
            onClickClear,
            StateSelectProps,
            CountrySelectProps,
            docks,
            reservations,
            errors,
        } = this.props;
        return (
            <AccordionMenu
                container
                label="Filters"
                className={classes.container}
            >
                <AccordionMenu label="# of Spots">
                    {this.renderRange(docks)}
                </AccordionMenu>
                <AccordionMenu label="# of Bookings">
                    {this.renderRange(reservations)}
                </AccordionMenu>
                <AccordionMenu label="Date Added">
                    {this.renderDatePickers('Start')}
                    {this.renderDatePickers('End')}
                </AccordionMenu>
                <AccordionMenu label="Status">
                    {this.renderCheckBoxes(STATUS_FILTERS)}
                </AccordionMenu>
                <AccordionMenu label="State">
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        className={classes.panel}
                    >
                        <Grid item container direction="column">
                            <Grid item>
                                <Typography
                                    className={classNames([
                                        classes.filterLabel,
                                        classes.stateAndCountryLabels,
                                    ])}
                                >
                                    Country
                                </Typography>
                            </Grid>
                            <Grid item container justify="center">
                                <Grid item>
                                    <AdminSelect
                                        type="compact"
                                        className={
                                            classes.stateAndCountrySelect
                                        }
                                        {...CountrySelectProps}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column">
                            <Grid item>
                                <Typography
                                    className={classNames([
                                        classes.filterLabel,
                                        classes.stateAndCountryLabels,
                                    ])}
                                >
                                    State(s)
                                </Typography>
                            </Grid>
                            <Grid item>
                                <AdminMultipleSelect
                                    type="compact"
                                    className={classes.stateAndCountrySelect}
                                    {...StateSelectProps}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionMenu>
                <FilterMenuButtons
                    onClickApply={onClickApply}
                    onClickClear={onClickClear}
                />
                {errors && errors.length > 0 && (
                    <Grid
                        container
                        direction="column"
                        className={classes.errorMessages}
                    >
                        <Grid item>
                            <FormMessages messages={errors} />
                        </Grid>
                    </Grid>
                )}
            </AccordionMenu>
        );
    }
}

PropertiesTableFilters.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // Range for # of docks filter
    docks: PropTypes.shape({
        min: PropTypes.number,
        onMinChange: PropTypes.func,
        max: PropTypes.number,
        onMaxChange: PropTypes.func,
    }),

    // Range for # of bookings filter
    reservations: PropTypes.shape({
        min: PropTypes.number,
        onMinChange: PropTypes.func,
        max: PropTypes.number,
        onMaxChange: PropTypes.func,
    }),

    /*
     * Checked is a property that should be an array which contains the name of any checkboxes that are checked.
     * If the name of the checkbox is not in this array then its considered unchecked.
     */
    checked: PropTypes.arrayOf(PropTypes.oneOf(STATUS_FILTERS)),

    // Function to be called when a checkbox is checked/unchecked.
    onCheckboxChange: PropTypes.func,

    // Properties that we will pass to the date pickers.
    start: DatePropTypes.filterDate,
    end: DatePropTypes.filterDate,

    // Functions to be called when the buttons are clicked.
    onClickApply: PropTypes.func,
    onClickClear: PropTypes.func,

    // Props to spread onto the AdminMultipleSelect for state selection.
    StateSelectProps: PropTypes.object,

    // Properties for the country select.
    CountrySelectProps: PropTypes.object,

    errors: formMessagesPropTypes.messages,
};

PropertiesTableFilters.defaultProps = {
    docks: {
        min: null,
        onMinChange: noop,
        max: null,
        onMaxChange: noop,
    },
    reservations: {
        min: null,
        onMinChange: noop,
        max: null,
        onMaxChange: noop,
    },
    checked: [],
    start: {},
    end: {},
    StateSelectProps: {
        options: [],
        selectedOptions: [],
        handleDelete: noop,
        handleSelectedOption: noop,
        loading: false,
    },
    CountrySelectProps: {
        value: COUNTRY_ABBR_UNITED_STATES,
        options: COUNTRY_FILTERS,
    },
    onCheckboxChange: noop,
    onClickApply: noop,
    onClickClear: noop,
    errors: [],
};

export default withStyles(styles)(PropertiesTableFilters);
