import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { omit } from 'ramda';
import {
    CircularProgress,
    Grid,
    Tooltip,
    Typography,
    withStyles,
} from '@material-ui/core';
import { AddCircleOutline, Delete, Info } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import styles from './styles';
import PhotoUpload from './PhotoUpload';
import TextField from '../../common/TextField';
import AdminSelect from '../../common/AdminSelect';
import DetailForm from '../../common/DetailForm';
import ConfirmDialog from '../../common/ConfirmDialog';
import { USER_STATUSES, USER_TYPE_TRUCKER } from '../../stores/users/constants';
import { formMessagesPropTypes } from '../../common/FormMessages';
import { PHONE_REG_EXP, PHONE_MASKS } from '../../constants/phoneNumber';
import { COUNTRY_ABBR_UNITED_STATES } from '../../constants/country';
import ActionableListRow from '../../common/ActionableListRow';
import AdminButton, {
    BUTTON_TYPE_EDIT,
    BUTTON_TYPE_NEGATIVE,
    BUTTON_TYPE_POSITIVE,
} from '../../common/AdminButton';
import { MAX_LENGTH_CITY, MAX_LENGTH_ZIPCODE } from '../../validation/forms';
import { USER_TYPE_PROPERTY_OWNER } from '../UsersTableFilters/constants';
import Pagination from '../../tables/Pagination';
import { PROPERTIES_PER_PAGE } from './constants';
import { noop } from '../../utils';
import { TIMES_OF_DAY } from '../../constants/timesOfDay';
import { ALL_TIMEZONES } from '../../constants/timezone';
import ExternalIcon, { COLOR_WHITE } from '../../common/ExternalIcon';

class UserForm extends Component {
    state = {
        photoURL: this.props.formState.photoURL,
        photoFile: null,
        phoneMask: '',
        deletingProperty: {
            showConfirmDialog: false,
            propertyName: null,
            propertyId: null,
        },
        selectedGroupId: this.props.formState.groupId,
        propertiesPage: 1,
    };

    componentDidMount() {
        const {
            formState: { country: { countryAbbr } = {} } = {},
        } = this.props;
        this.setState({
            phoneMask:
                PHONE_MASKS[countryAbbr] ||
                PHONE_MASKS[COUNTRY_ABBR_UNITED_STATES],
        });
    }

    onDelete = () => {
        this.props.onDelete && this.props.onDelete();
    };

    onSubmit = (data) => {
        this.props.onSubmit && this.props.onSubmit(data);
    };

    onPhotoChange = (event, setFieldValue) => {
        const files = event.target.files;
        if (files.length === 1) {
            // User photos only support one photo and we only want to do the following when one photo has been selected.
            const photoFile = files[0];
            this.setState({
                photoURL: URL.createObjectURL(photoFile),
                photoFile,
            });
            setFieldValue('photoFile', photoFile);
        }
    };

    getUserGroupName = () => {
        const { userGroups } = this.props;
        const foundGroup = userGroups.find(
            ({ groupId: currentGroupId }) =>
                currentGroupId === this.state.selectedGroupId,
        );
        return foundGroup ? foundGroup.groupName : '';
    };

    changedGroupName = () => {
        const currentGroupName = this.getUserGroupName();
        const { userGroups } = this.props;
        const savedGroup = userGroups.find(
            ({ groupId: currentGroupId }) =>
                currentGroupId === this.props.formState.groupId,
        );
        return savedGroup?.groupName !== currentGroupName;
    };

    isUserPropertyOwner = () => {
        return this.getUserGroupName() === USER_TYPE_PROPERTY_OWNER;
    };

    isUserTrucker = () => {
        return this.getUserGroupName() === USER_TYPE_TRUCKER;
    };

    handlePropertiesPageChange = (page) => {
        this.setState({ propertiesPage: page });
    };

    renderUtilizationReportField = (
        {
            name,
            values: {
                displayName,
                utilizationReportConfigs,
                utilizationReportRecipients,
            },
        },
        { setFieldValue, errors },
    ) => {
        const { classes } = this.props;
        const { utilizationReportRecipients: error } = errors;
        return (
            <Grid container item direction="column">
                <Grid container item alignItems="center">
                    <AdminButton
                        className={classes.reportButton}
                        buttonType={BUTTON_TYPE_POSITIVE}
                        size="large"
                        disabled={
                            this.props.sendingReport || this.props.reportSent
                        }
                        onClick={() =>
                            this.props.onSendReport(utilizationReportRecipients)
                        }
                    >
                        {this.props.sendingReport ? (
                            <CircularProgress size={20} color="primary" />
                        ) : this.props.reportSent ? (
                            'Report Sent!'
                        ) : (
                            'Send Report'
                        )}
                    </AdminButton>
                    {this.props.reportError && (
                        <Typography className={classes.error}>
                            {this.props.reportError}
                        </Typography>
                    )}
                </Grid>
                <Grid
                    container
                    item
                    alignItems="center"
                    className={classes.reportLabelContainer}
                >
                    <Typography>
                        Additional Property Report Recipients
                    </Typography>
                    <Tooltip
                        title={`The report for ${displayName}'s properties will be sent to him/her and these emails`}
                        classes={{ tooltip: classes.tooltip }}
                    >
                        <Info fontSize="small" />
                    </Tooltip>
                </Grid>
                <Grid item className={classes.smallTopMargin}>
                    <TextField
                        classes={{
                            root: classes.reportRecipientsRoot,
                        }}
                        multiline
                        rows={3}
                        variant="outlined"
                        select={false}
                        placeholder="Enter comma-separated list of emails"
                        value={utilizationReportRecipients}
                        onChange={(event) => {
                            setFieldValue(
                                'utilizationReportRecipients',
                                event.target.value,
                            );
                        }}
                        error={!!error}
                        helperText={error}
                        FormHelperTextProps={{
                            classes: {
                                error: classes.helperTextError,
                            },
                        }}
                    />
                </Grid>
                <Grid item className={classes.reportConfig}>
                    <AdminButton
                        size="small"
                        buttonType={BUTTON_TYPE_EDIT}
                        onClick={() => {
                            const { value: sendTime } = TIMES_OF_DAY[0];
                            utilizationReportConfigs.push({
                                sendTime,
                                timezone: Intl.DateTimeFormat().resolvedOptions()
                                    .timeZone,
                            });
                            setFieldValue(name, utilizationReportConfigs);
                        }}
                    >
                        Add Report Schedule
                    </AdminButton>
                </Grid>
                {utilizationReportConfigs.map((reportConfig, index) => {
                    return (
                        <Grid
                            container
                            item
                            key={`report-config-${index}`}
                            className={classes.reportConfig}
                            alignItems="center"
                        >
                            <Typography
                                color="secondary"
                                className={classes.reportConfigPrefix}
                            >
                                Every day at
                            </Typography>
                            <AdminSelect
                                FormControlProps={{
                                    className: classes.reportConfigTime,
                                }}
                                label="Time of day"
                                options={TIMES_OF_DAY}
                                value={reportConfig.sendTime}
                                onChange={(event) => {
                                    utilizationReportConfigs[index] = {
                                        ...reportConfig,
                                        sendTime: event.target.value,
                                    };
                                    setFieldValue(
                                        name,
                                        utilizationReportConfigs,
                                    );
                                }}
                            />
                            <AdminSelect
                                FormControlProps={{
                                    className: classes.reportConfigTimezone,
                                }}
                                SelectDisplayProps={{
                                    className:
                                        classes.reportConfigTimezoneSelect,
                                }}
                                label="Time zone"
                                options={ALL_TIMEZONES}
                                value={reportConfig.timezone}
                                onChange={(event) => {
                                    utilizationReportConfigs[index] = {
                                        ...reportConfig,
                                        timezone: event.target.value,
                                    };
                                    setFieldValue(
                                        name,
                                        utilizationReportConfigs,
                                    );
                                }}
                            />
                            <AdminButton
                                size="small"
                                buttonType={BUTTON_TYPE_NEGATIVE}
                                className={classes.reportConfigDeleteButton}
                                onClick={() => {
                                    setFieldValue(name, [
                                        ...utilizationReportConfigs.slice(
                                            0,
                                            index,
                                        ),
                                        ...utilizationReportConfigs.slice(
                                            index + 1,
                                        ),
                                    ]);
                                }}
                            >
                                <Delete />
                            </AdminButton>
                        </Grid>
                    );
                })}
            </Grid>
        );
    };

    _getFormFields = () => {
        const {
            classes,
            regions,
            areRegionsLoading,
            isCreatingUser,
            formState: {
                properties = [],
                vehicles = [],
                paymentMethods = [],
                deletedAt,
            } = {},
            showDelete,
        } = this.props;
        const { phoneMask } = this.state;

        const trucker = this.isUserTrucker();
        const propertyOwner = this.isUserPropertyOwner();

        return [
            {
                name: 'photoURL',
                label: 'Photo',
                render: (fieldProps, { setFieldValue }) => (
                    <PhotoUpload
                        {...fieldProps}
                        value={this.state}
                        onChange={(event) =>
                            this.onPhotoChange(event, setFieldValue)
                        }
                    />
                ),
            },
            {
                name: 'displayName',
                label: 'Name',
                validation: Yup.string().required(),
                render: (fieldProps) => <TextField required {...fieldProps} />,
            },
            {
                name: 'email',
                label: 'Email',
                validation: Yup.string()
                    .email()
                    .required(),
                render: (fieldProps) => <TextField required {...fieldProps} />,
            },
            ...(deletedAt && !showDelete
                ? [
                      {
                          name: 'customerId',
                          label: 'Stripe Customer',
                          render: ({ values: { customerId } }) => (
                              <Grid
                                  container
                                  item
                                  wrap="nowrap"
                                  alignItems="center"
                              >
                                  <AdminButton
                                      buttonType={BUTTON_TYPE_POSITIVE}
                                      className={classes.stripeCustomerButton}
                                      onClick={() =>
                                          window.open(
                                              `https://dashboard.stripe.com${
                                                  process.env.REACT_APP_ENV !==
                                                      'production' &&
                                                  process.env
                                                      .REACT_APP_VERCEL_ENV !==
                                                      'production'
                                                      ? '/test'
                                                      : ''
                                              }/customers/${customerId}`,
                                          )
                                      }
                                  >
                                      View&nbsp;
                                      <ExternalIcon color={COLOR_WHITE} />
                                  </AdminButton>
                                  <Typography color="primary">
                                      ID: <strong>{customerId}</strong>
                                  </Typography>
                              </Grid>
                          ),
                      },
                  ]
                : []),
            {
                name: 'userAddress',
                label: 'Address',
                validation: Yup.string().nullable(),
                render: (fieldProps) => <TextField fullWidth {...fieldProps} />,
            },
            {
                name: 'userAddressMore',
                label: '',
                render: (fieldProps) => <TextField fullWidth {...fieldProps} />,
            },
            {
                name: 'userCity',
                label: 'City',
                validation: Yup.string()
                    .nullable()
                    .max(MAX_LENGTH_CITY),
                render: (fieldProps) => <TextField label="" {...fieldProps} />,
            },
            {
                name: 'regionAbbr',
                label: 'State',
                validation: Yup.mixed().nullable(),
                render: (fieldProps) => {
                    const {
                        values: { country: { countryAbbr } = {} } = {},
                    } = fieldProps;
                    const options = Array.isArray(regions[countryAbbr])
                        ? ['', ...regions[countryAbbr]]
                        : [];
                    return (
                        <AdminSelect
                            type="large"
                            className={classes.stateSelect}
                            loading={areRegionsLoading}
                            options={options}
                            {...fieldProps}
                        />
                    );
                },
            },
            {
                label: 'Zip',
                name: 'userZipcode',
                validation: Yup.string()
                    .nullable()
                    .max(MAX_LENGTH_ZIPCODE),
                render: (fieldProps) => (
                    <TextField fullWidth label="" {...fieldProps} />
                ),
            },
            {
                label: 'Phone',
                name: 'userPhoneNumber',
                validation: Yup.string()
                    .ensure()
                    .matches(PHONE_REG_EXP, 'Phone Number is not valid.')
                    .required(),
                render: (fieldProps) => (
                    <InputMask mask={phoneMask} {...fieldProps}>
                        {() => <TextField fullWidth required {...fieldProps} />}
                    </InputMask>
                ),
            },
            {
                name: 'userActive',
                label: 'Status',
                render: (fieldProps) => {
                    const isCurrentUser = this.isCurrentUser();
                    return (
                        <Tooltip
                            title={
                                isCurrentUser
                                    ? 'You cannot disable yourself.'
                                    : ''
                            }
                            placement="right-start"
                        >
                            <AdminSelect
                                options={USER_STATUSES}
                                disabled={isCurrentUser}
                                {...fieldProps}
                            />
                        </Tooltip>
                    );
                },
            },
            {
                name: 'groupId',
                label: 'Type',
                validation: Yup.mixed().required(),
                render: (fieldProps) => {
                    const isCurrentUser = this.isCurrentUser();
                    const { onChange, ...restFieldProps } = fieldProps;
                    return (
                        <Tooltip
                            title={
                                isCurrentUser
                                    ? 'You cannot change your own Type.'
                                    : ''
                            }
                            placement="right-start"
                        >
                            <AdminSelect
                                options={this.props.userGroups.map((group) => {
                                    const { groupName, groupId } = group;
                                    return {
                                        label: groupName,
                                        value: groupId,
                                    };
                                })}
                                disabled={isCurrentUser}
                                onChange={(eventOrPath) => {
                                    this.setState({
                                        selectedGroupId:
                                            eventOrPath.target.value,
                                    });
                                    onChange && onChange(eventOrPath);
                                }}
                                {...restFieldProps}
                            />
                        </Tooltip>
                    );
                },
            },
            ...(!isCreatingUser
                ? [
                      {
                          name: 'createdAt',
                          label: 'Created',
                          render: ({ values: { createdAt } }) => (
                              <Typography inline color="primary">
                                  {createdAt}
                              </Typography>
                          ),
                      },
                  ]
                : []),
            ...(!isCreatingUser && propertyOwner && !this.changedGroupName()
                ? [
                      {
                          name: 'utilizationReportRecipients',
                          validation: Yup.string()
                              .ensure()
                              .max(
                                  1000,
                                  'List of email recipients is too long.',
                              ),
                      },
                      {
                          label: 'Utilization Report',
                          labelWrapperClass: classes.topLabel,
                          name: 'utilizationReportConfigs',
                          render: this.renderUtilizationReportField,
                      },
                      {
                          label: 'Property',
                          labelWrapperClass: classes.topLabel,
                          name: 'properties',
                          render: () => {
                              return (
                                  Array.isArray(properties) &&
                                  properties.length > 0 && (
                                      <>
                                          {properties
                                              .slice(
                                                  (this.state.propertiesPage -
                                                      1) *
                                                      PROPERTIES_PER_PAGE,
                                                  this.state.propertiesPage *
                                                      PROPERTIES_PER_PAGE,
                                              )
                                              .map(
                                                  ({
                                                      propertyId,
                                                      propertyName,
                                                  }) => {
                                                      return (
                                                          <ActionableListRow
                                                              key={propertyId}
                                                              label={
                                                                  propertyName
                                                              }
                                                              PositiveButtonProps={{
                                                                  to: `/properties/${propertyId}`,
                                                                  component: Link,
                                                              }}
                                                              onNegativeAction={() =>
                                                                  this.toggleConfirmationForDeleteProperty(
                                                                      propertyName,
                                                                      propertyId,
                                                                  )
                                                              }
                                                          />
                                                      );
                                                  },
                                              )}
                                          <Pagination
                                              total={properties.length}
                                              onSizeChange={noop}
                                              pageSizeOptions={[]}
                                              hideOnSinglePage={false}
                                              page={this.state.propertiesPage}
                                              onPageChange={
                                                  this
                                                      .handlePropertiesPageChange
                                              }
                                          />
                                      </>
                                  )
                              );
                          },
                      },
                      {
                          name: 'addProperty',
                          label: '',
                          render: ({ values: { userId } }) => (
                              <AdminButton
                                  buttonType={BUTTON_TYPE_EDIT}
                                  size="large"
                                  fullWidth
                                  component={Link}
                                  to={`/properties/new/${userId}`}
                              >
                                  <AddCircleOutline
                                      className={classes.addButtonIcon}
                                  />
                                  Add Property
                              </AdminButton>
                          ),
                      },
                  ]
                : []),
            ...(!isCreatingUser && trucker && !this.changedGroupName()
                ? [
                      {
                          label: 'Trucks',
                          name: 'vehicles',
                          render: ({ values: { userId } }) =>
                              vehicles.map(({ vehicleId, vehicleName }) => {
                                  return (
                                      <ActionableListRow
                                          key={vehicleId}
                                          label={vehicleName}
                                          positiveActionLabel="View"
                                          PositiveButtonProps={{
                                              to: `/users/${userId}/vehicles/${vehicleId}`,
                                              component: Link,
                                          }}
                                      />
                                  );
                              }),
                      },
                  ]
                : []),
            {
                name: 'addVehicle',
                label: '',
                render: ({ values: { userId } }) => {
                    return !isCreatingUser && trucker ? (
                        <AdminButton
                            buttonType={BUTTON_TYPE_EDIT}
                            size="large"
                            fullWidth
                            component={Link}
                            to={`/users/${userId}/vehicles/new`}
                        >
                            <AddCircleOutline
                                className={classes.addButtonIcon}
                            />
                            Add Truck
                        </AdminButton>
                    ) : null;
                },
            },
            {
                label: !isCreatingUser && trucker ? 'Payment Methods' : '',
                name: 'addCard',
                render: ({ values: { userId } }) => {
                    return !isCreatingUser && trucker
                        ? paymentMethods.map((paymentMethod) => {
                              const {
                                  brand,
                                  last4,
                                  default: isDefault,
                                  expMonth,
                                  expYear,
                              } = paymentMethod;
                              return (
                                  <ActionableListRow
                                      key={paymentMethod.id}
                                      label={`${brand} ending in ${last4} - Exp: ${expMonth}/${expYear} ${
                                          isDefault ? '[Default]' : ''
                                      } `}
                                      positiveActionLabel="View"
                                      PositiveButtonProps={{
                                          to: `/`,
                                          component: Link,
                                          disabled: true, // disable for now until we build ability to edit payment methods
                                      }}
                                  />
                              );
                          })
                        : null;
                },
            },
            {
                name: 'addPaymentMethod',
                label: !isCreatingUser ? 'Add Method' : '',
                render: ({ values: { userId } }) => {
                    return !isCreatingUser && trucker ? (
                        <AdminButton
                            buttonType={BUTTON_TYPE_EDIT}
                            size="large"
                            fullWidth
                            component={Link}
                            to={`${userId}/cards/new`}
                        >
                            <AddCircleOutline
                                className={classes.addButtonIcon}
                            />
                            Add Card
                        </AdminButton>
                    ) : null;
                },
            },
        ];
    };

    isCurrentUser = () => {
        const { currentUserId, formState: { userId } = {} } = this.props;
        return currentUserId === userId;
    };

    toggleConfirmationForDeleteProperty = (propertyName, propertyId) => {
        const { showConfirmDialog } = this.state.deletingProperty;
        this.setState({
            deletingProperty: {
                showConfirmDialog: !showConfirmDialog,
                propertyName: propertyName || '',
                propertyId: propertyId || '',
            },
        });
    };

    onDeleteProperty = () => {
        const { onDeleteProperty, formState: { userId } = {} } = this.props;
        const { propertyId, propertyName } = this.state.deletingProperty;
        onDeleteProperty && onDeleteProperty(userId, propertyId, propertyName);
        this.setState({
            deletingProperty: {
                showConfirmDialog: false,
                propertyName: '',
                propertyId: '',
            },
        });
    };

    render() {
        const {
            formState,
            loading,
            messages,
            isCreatingUser,
            removeFormMessage,
            showDelete,
        } = this.props;
        const { deletingProperty } = this.state;
        const fields = this._getFormFields();
        return (
            <Fragment>
                <DetailForm
                    initFormState={omit(
                        ['photoURL', 'photoUploadId', 'regionId'],
                        formState,
                    )}
                    onSubmit={this.onSubmit}
                    onDelete={this.onDelete}
                    loading={loading}
                    fields={fields}
                    messages={messages}
                    deleteButtonText="Delete User"
                    showDelete={!isCreatingUser && showDelete}
                    showConfirm={!formState.deletedAt}
                    submitButtonText={
                        isCreatingUser ? 'Add User' : 'Update User'
                    }
                    confimationTitle="Action Confirmation"
                    confirmDialogText={`You are attempting to delete ${formState.displayName} from your database`}
                    confirmDialogSubText="This action will permanently delete this user from your RigPark database."
                    removeFormMessage={removeFormMessage}
                    FormProps={{ validateOnChange: !isCreatingUser }}
                />
                <ConfirmDialog
                    open={deletingProperty.showConfirmDialog}
                    onConfirm={this.onDeleteProperty}
                    onCancel={this.toggleConfirmationForDeleteProperty}
                    title="Confirm Property Delete"
                    text={`You are attempting to delete ${deletingProperty.propertyName}`}
                    subText="This action will permanently delete this property from your RigPark database."
                />
            </Fragment>
        );
    }
}

UserForm.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // Function that will be called to send a utilization report
    onSendReport: PropTypes.func.isRequired,
    sendingReport: PropTypes.bool,
    reportSent: PropTypes.bool,
    reportError: PropTypes.string,

    // Function that will be called when the form is submitted.
    onSubmit: PropTypes.func.isRequired,

    showDelete: PropTypes.bool,
    // Function that will be called when the delete button is clicked and confirmed.
    onDelete: PropTypes.func.isRequired,

    // Function that is called when the delete property button is clicked and confirmed.
    onDeleteProperty: PropTypes.func.isRequired,

    // Bool that determines this form is loading
    loading: PropTypes.bool,

    // So you can supply an initial state of the form, if any.
    formState: PropTypes.shape({
        photoURL: PropTypes.string, // expects url to pull user avatar
        displayName: PropTypes.string,
        email: PropTypes.string,
        status: PropTypes.oneOf(USER_STATUSES.map((status) => status.value)),
        groupId: PropTypes.number,
        createdAt: PropTypes.string,
        userId: PropTypes.number,
        properties: PropTypes.arrayOf(
            PropTypes.shape({
                propertyId: PropTypes.number.isRequired,
                propertyName: PropTypes.string.isRequired,
            }),
        ),
        userActive: PropTypes.bool,
        country: PropTypes.shape({
            countryAbbr: PropTypes.string,
        }),
        userAddress: PropTypes.string,
        userAddressMore: PropTypes.string,
        regionAbbr: PropTypes.string,
        userCity: PropTypes.string,
        userPhoneNumber: PropTypes.string,
        userZipcode: PropTypes.string,
        utilizationReportConfigs: PropTypes.arrayOf(
            PropTypes.shape({
                sendTime: PropTypes.string.isRequired,
                timezone: PropTypes.string,
            }),
        ),
        vehicles: PropTypes.arrayOf(
            PropTypes.shape({
                vehicleId: PropTypes.number.isRequired,
                vehicleName: PropTypes.string.isRequired,
            }),
        ),
        deletedAt: PropTypes.string,
    }),

    // Messages to be displayed on the form, if any.
    messages: formMessagesPropTypes.messages,
    removeFormMessage: PropTypes.func.isRequired,

    // For the type select dropdown.
    userGroups: PropTypes.array.isRequired,

    // The current logged in user's id, for determining if the current user is the one being edited.
    currentUserId: PropTypes.number.isRequired,

    // Regions aka states for the state select in the form.
    regions: PropTypes.object.isRequired,
    areRegionsLoading: PropTypes.bool.isRequired,

    // Indication of whether or not the form is being used to create a new user.
    isCreatingUser: PropTypes.bool.isRequired,
};

UserForm.defaultProps = {
    formState: {},
    loading: false,
    showDelete: true,
    sendingReport: false,
    reportSent: false,
    reportError: '',
};

export default withStyles(styles)(UserForm);
