import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import AccordionMenu from '../../common/AccordionMenu';
import { isEmpty } from 'ramda';
import {
    CHECKBOX_FILTERS,
    USER_STATUS_FILTERS,
    USER_TYPE_FILTERS,
    USER_VEHICLE_TEXT_FILTERS,
    MODEL_ID,
    MAKE_ID,
} from './constants';
import DatePropTypes from '../../propTypes/dates';
import FilterDatePicker from '../../common/FilterDatePicker';
import FilterMenuButtons from '../../common/FilterMenuButtons';
import FilterCheckbox from '../../common/FilterCheckbox';
import { noop } from '../../utils';
import FormMessages, { formMessagesPropTypes } from '../../common/FormMessages';
import FormTextField from '../../common/TextField';
import AdminSelect from '../../common/AdminSelect';

class UsersTableFilters extends Component {
    onVehicleFilterFieldChange = (event, field) => {
        if (event.target && event.target.value) {
            if (field === MODEL_ID) {
                // Special case for model id since it could hold an error
                this.props.onVehicleFilterChange(
                    { value: event.target.value, error: false },
                    field,
                );
            } else {
                this.props.onVehicleFilterChange(event.target.value, field);
            }
            if (field === MAKE_ID) {
                // Clear model if a new make was selected
                this.props.onVehicleFilterChange(
                    { value: '', error: false },
                    MODEL_ID,
                );
            }
        } else {
            this.props.onVehicleFilterChange('', field);
        }
    };

    renderCheckBoxes = (options) => {
        const { classes, checked, onCheckboxChange } = this.props;
        return (
            <Grid container direction="column" className={classes.panel}>
                {options.map((option) => {
                    return (
                        <Grid
                            item
                            container
                            key={option}
                            direction="row"
                            alignItems="center"
                            spacing={8}
                            className={classes.checkboxGridItem}
                        >
                            <Grid item>
                                <FilterCheckbox
                                    checked={checked.includes(option)}
                                    value={option}
                                    onChange={onCheckboxChange}
                                />
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        );
    };

    renderVehicleTextFilters = (vehicleFilterObjects = []) => {
        const { classes, vehicleFilters } = this.props;
        return (
            <Grid container direction="column" className={classes.panel}>
                {vehicleFilterObjects.map((filter) => {
                    return (
                        <Grid
                            key={filter.key}
                            container
                            item
                            direction="column"
                            spacing={8}
                        >
                            <Grid item>
                                <Typography className={classes.dateLabel}>
                                    {filter.label}
                                </Typography>
                            </Grid>
                            <Grid item className={classes.filterField}>
                                <FormTextField
                                    classes={{
                                        smallInputStyle: classes.vehicleFilters,
                                    }}
                                    onChange={(event) =>
                                        this.onVehicleFilterFieldChange(
                                            event,
                                            filter.key,
                                        )
                                    }
                                    value={vehicleFilters[filter.key]}
                                    size="small"
                                />
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        );
    };

    renderVehicleDropdownFilters = () => {
        const {
            classes,
            vehicleFilters,
            vehicleMakes,
            vehicleModels,
        } = this.props;
        const makeId = vehicleFilters[MAKE_ID];
        const modelId = vehicleFilters[MODEL_ID].value;
        const modelError = vehicleFilters[MODEL_ID].error;
        const models = !isEmpty(makeId)
            ? vehicleModels.filter((model) => model.makeId === makeId)
            : [];
        return (
            <Grid container direction="column" className={classes.panel}>
                <Grid
                    key={MAKE_ID}
                    container
                    item
                    direction="column"
                    spacing={8}
                >
                    <Grid item>
                        <Typography className={classes.dateLabel}>
                            Vehicle Make
                        </Typography>
                    </Grid>
                    <Grid item className={classes.filterField}>
                        <AdminSelect
                            classes={{ root: classes.vehicleFilters }}
                            onChange={(event) =>
                                this.onVehicleFilterFieldChange(event, MAKE_ID)
                            }
                            type="compact"
                            options={vehicleMakes}
                            value={vehicleFilters[MAKE_ID]}
                        />
                    </Grid>
                </Grid>
                <Grid
                    key={MODEL_ID}
                    container
                    item
                    direction="column"
                    spacing={8}
                >
                    <Grid item>
                        <Typography className={classes.dateLabel}>
                            Vehicle Model
                        </Typography>
                    </Grid>
                    <Grid item className={classes.filterField}>
                        <AdminSelect
                            classes={{ root: classes.vehicleFilters }}
                            onChange={(event) =>
                                this.onVehicleFilterFieldChange(event, MODEL_ID)
                            }
                            type="compact"
                            options={models}
                            value={modelId}
                            error={modelError}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    renderDatePickers = (label) => {
        const { classes, [label.toLowerCase()]: datePickerProps } = this.props;
        return (
            <Grid container direction="column" className={classes.panel}>
                <Grid item>
                    <Typography className={classes.dateLabel}>
                        {label}
                    </Typography>
                </Grid>
                <Grid item>
                    <FilterDatePicker {...datePickerProps} />
                </Grid>
            </Grid>
        );
    };

    render() {
        const { classes, onClickApply, onClickClear, errors } = this.props;
        return (
            <AccordionMenu
                container
                label="Filters"
                className={classes.container}
            >
                <AccordionMenu label="Type">
                    {this.renderCheckBoxes(USER_TYPE_FILTERS)}
                </AccordionMenu>
                <AccordionMenu label="Registration Date">
                    {this.renderDatePickers('Start')}
                    {this.renderDatePickers('End')}
                </AccordionMenu>
                <AccordionMenu label="Status">
                    {this.renderCheckBoxes(USER_STATUS_FILTERS)}
                </AccordionMenu>
                <AccordionMenu label="Vehicle">
                    {this.renderVehicleTextFilters(USER_VEHICLE_TEXT_FILTERS)}
                    {this.renderVehicleDropdownFilters()}
                </AccordionMenu>
                <FilterMenuButtons
                    onClickApply={onClickApply}
                    onClickClear={onClickClear}
                />
                {errors && errors.length > 0 && (
                    <Grid
                        container
                        direction="column"
                        className={classes.errorMessages}
                    >
                        <Grid item>
                            <FormMessages messages={errors} />
                        </Grid>
                    </Grid>
                )}
            </AccordionMenu>
        );
    }
}

UsersTableFilters.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    /*
     * Checked is a property that should be an array which contains the name of any checkboxes that are checked.
     * If the name of the checkbox is not in this array then its considered unchecked.
     */
    checked: PropTypes.arrayOf(PropTypes.oneOf(CHECKBOX_FILTERS)),

    // Vehicle filter object holding filter values for vehicle fields.
    vehicleFilters: PropTypes.object,

    // Function to be called when a checkbox is checked/unchecked.
    onCheckboxChange: PropTypes.func,

    // Function to be called when a vehicle filter is changed.
    onVehicleFilterChange: PropTypes.func,

    // Array of vehicle make objects to be used as options for filtering.
    vehicleMakes: PropTypes.array.isRequired,

    // Array of vehicle model objects to be used as options for filtering.
    vehicleModels: PropTypes.array.isRequired,

    // Properties that we will pass to the date pickers.
    start: DatePropTypes.filterDate,
    end: DatePropTypes.filterDate,

    // Functions to be called when the buttons are clicked.
    onClickApply: PropTypes.func,
    onClickClear: PropTypes.func,

    errors: formMessagesPropTypes.messages,
};

UsersTableFilters.defaultProps = {
    checked: [],
    start: {},
    end: {},
    onCheckboxChange: noop,
    onClickApply: noop,
    onClickClear: noop,
    onVehicleFilterChange: noop,
    errors: [],
    vehicleFilters: {},
    vehicleMakes: [],
    vehicleModels: [],
};

export default withStyles(styles)(UsersTableFilters);
