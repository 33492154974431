import React from 'react';
import TextField from '../../common/TextField';
import { withStyles } from '@material-ui/core';
import styles from './styles';

const ReadOnlyTextField = ({ classes, ...props }) => {
    return (
        <TextField
            disabled
            classes={{ disabled: classes.textField }}
            {...props}
        />
    );
};

export default withStyles(styles)(ReadOnlyTextField);
