// The radius used for the top/bottom left of a start tile and top/bottom right of an end tile
const activeTileRadius = 50;

const styles = (theme) => ({
    calendar: {
        border: 'none',
        borderBottom: '1px solid #EEEEEE',
        width: 450,
    },
    activeTile: {
        // Need important to override native styling for the component and index.css
        backgroundColor: `${theme.palette.secondary.main} !important`,
        color: theme.palette.primary.contrastText,
        fontWeight: 'bold',
        fontSize: 16,
        lineHeight: 1,
    },
    activeTileStart: {
        borderTopLeftRadius: activeTileRadius,
        borderBottomLeftRadius: activeTileRadius,
    },
    activeTileEnd: {
        borderTopRightRadius: activeTileRadius,
        borderBottomRightRadius: activeTileRadius,
    },
    tile: {
        color: theme.palette.secondary.main,
        fontSize: 16,
        lineHeight: 1,
    },
    checkboxGrid: {
        height: 50,
    },
    checkboxLabel: {
        fontSize: 12,
        color: theme.palette.secondary.main,
        marginTop: 4,
    },
    checkbox: {
        color: theme.custom.grey,
        backgroundColor: theme.custom.grey,
        padding: 0,
        borderRadius: 0,
        height: 14,
        width: 14,
        marginLeft: 20,
    },
    removalInstructionText: {
        color: theme.custom.red,
        fontSize: 12,
    },
});

export default styles;
