import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import AdminContainer from '../../common/AdminContainer';
import ReservationForm from '../ReservationForm';
import {
    arePropertiesAndDocksForFiltersLoading,
    getIsReservationInProgress,
    getPropertiesAndDocksForFilters,
    getPropertyDockInfo,
    getReservationDetails,
    getReservationIdInPath,
    getReservationMessages,
    getReservationUserPaymentMethods,
    isCreatingReservation,
    isReservationDetailLoading,
} from '../../stores/reservations/selectors';
import {
    bookReservationGetPropertyInfoRequested,
    bookReservationGetUserPaymentMethods,
    bookReservationRequested,
    FETCH_RESERVATION_ACTIONS,
    removeFormMessages,
    reservationsListViewLoadRequested,
    updateReservationRequested,
} from '../../stores/reservations/actions';
import { noop } from '../../utils';
import { DateTime } from 'luxon';
import ConfirmDialog from '../../common/ConfirmDialog';
import {
    CREATE_RESERVATION_CONFIRMATION,
    CREATE_RESERVATION_TITLE,
    UPDATE_RESERVATION_CONFIRMATION,
    UPDATE_RESERVATION_TITLE,
} from '../ReservationsListContainer/constants';
import ReservationPropTypes from '../../propTypes/reservations';
class ReservationDetailsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showConfirmDialog: false,
            data: {},
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        const {
            fetchReservation,
            properties,
            fetchPropertyInformation,
        } = this.props;
        fetchReservation();

        /**
         * Properties may be empty if the user navigates directly to a specific
         * reservation. We detect that here and fire off a request to fetch property data.
         * This isn't necessary if the flow is 'Reservations screen' -> 'Specific reservation'
         * since the properties will have been loaded in the Reservation screen
         */
        if (properties.length === 0) {
            fetchPropertyInformation();
        }
    }

    componentDidUpdate(prevProps) {
        const { reservationId: prevReservationId } = prevProps;
        const {
            reservationId: currentReservationId,
            fetchReservation,
        } = this.props;

        // If the reservation id changed (after booking a new reservation), fetch it
        if (
            prevReservationId !== currentReservationId &&
            currentReservationId
        ) {
            fetchReservation();
        }
    }

    onSubmit = (data) => {
        this.setState({
            showConfirmDialog: true,
            data,
        });
    };

    onConfirmClick = () => {
        const {
            updateReservation,
            createReservation,
            isCreatingReservation,
            history,
        } = this.props;
        const { data } = this.state;
        if (isCreatingReservation) {
            createReservation(data, history);
        } else {
            const updateData = {
                startTime: DateTime.fromISO(data.startTime).valueOf() / 1000,
                endTime: DateTime.fromISO(data.endTime).valueOf() / 1000,
                dockId: data.dockId,
            };
            updateReservation(updateData);
        }
        this.onCancelClick();
    };

    onCancelClick = () => {
        this.setState({ showConfirmDialog: false, data: {} });
    };

    renderForm = () => {
        const {
            reservation = {},
            loading,
            properties,
            messages,
            removeMessages,
            userPaymentMethods,
            isCreatingReservation,
            fetchUserPaymentMethods,
            reservationInProgress,
            fetchPropertyDockInfo,
            propertyDockInfo,
        } = this.props;

        return loading ? (
            <CircularProgress />
        ) : (
            <ReservationForm
                existingReservation={{ ...reservation }}
                isCreatingReservation={isCreatingReservation} // form being filled out
                reservationInProgress={reservationInProgress} // request to api
                loading={loading}
                properties={properties}
                messages={messages}
                onSubmit={this.onSubmit}
                onDelete={noop}
                removeFormMessage={removeMessages}
                fetchUserPaymentMethods={fetchUserPaymentMethods}
                userPaymentMethods={userPaymentMethods}
                fetchPropertyDockInfo={fetchPropertyDockInfo}
                propertyDockInfo={propertyDockInfo}
            />
        );
    };

    render() {
        const { path, isCreatingReservation, ...rest } = this.props;
        return (
            <AdminContainer
                path={path}
                innerHeader
                innerHeaderProps={{
                    title: 'Reservation',
                    backText: '< Back to Reservations',
                    backTo: `/reservations`,
                }}
                {...rest}
            >
                <ConfirmDialog
                    open={this.state.showConfirmDialog}
                    onConfirm={this.onConfirmClick}
                    onCancel={this.onCancelClick}
                    title={
                        isCreatingReservation
                            ? CREATE_RESERVATION_TITLE
                            : UPDATE_RESERVATION_TITLE
                    }
                    text={
                        isCreatingReservation
                            ? CREATE_RESERVATION_CONFIRMATION
                            : UPDATE_RESERVATION_CONFIRMATION
                    }
                />
                {this.renderForm()}
            </AdminContainer>
        );
    }
}

ReservationDetailsContainer.propTypes = {
    // The path which should be passed down from the router.
    path: PropTypes.string.isRequired,

    // Indication of whether or not the reservation data is loading.
    loading: PropTypes.bool,

    // The reservation in the view
    reservation: PropTypes.object,

    // Messages to be displayed on the form, if any.
    messages: PropTypes.array,

    // Function to remove form messages
    removeMessages: PropTypes.func.isRequired,

    // Function for fetching reservation
    fetchReservation: PropTypes.func.isRequired,

    // Property list with ids and labels
    properties: ReservationPropTypes.properties,

    // Used to fetch "basic" property info (ids, labels)
    fetchPropertyInformation: PropTypes.func.isRequired,

    // Function to update reservation
    updateReservation: PropTypes.func.isRequired,

    // Function to create resevation
    createReservation: PropTypes.func.isRequired,

    // The status of the reservation booking
    bookingReservationStatus: PropTypes.object.isRequired,

    // Whether the form is being used to create
    isCreatingReservation: PropTypes.bool.isRequired,

    // Function used to fetch user payment methods once selected in the form
    fetchUserPaymentMethods: PropTypes.func.isRequired,

    // List of user payment methods used for booking reservations
    userPaymentMethods: PropTypes.array,

    history: PropTypes.object.isRequired,

    // Reservation id (if viewing existing reservation)
    reservationId: PropTypes.string,

    reservationInProgress: PropTypes.bool,

    // Function to fetch property dock information (for pricing)
    fetchPropertyDockInfo: PropTypes.func.isRequired,

    // The property docks and their pricing information
    propertyDockInfo: PropTypes.array,
};

const mapStateToProps = function(state) {
    return {
        loading: isReservationDetailLoading(state),
        reservationInProgress: getIsReservationInProgress(state),
        reservation: getReservationDetails(state),
        arePropertiesAndDocksLoading: arePropertiesAndDocksForFiltersLoading(
            state,
        ),
        properties: getPropertiesAndDocksForFilters(state),
        messages: getReservationMessages(state),
        isCreatingReservation: isCreatingReservation(state),
        userPaymentMethods: getReservationUserPaymentMethods(state),
        reservationId: getReservationIdInPath(state),
        propertyDockInfo: getPropertyDockInfo(state),
    };
};

const mapDispatchToProps = function(dispatch) {
    return {
        fetchReservation: () => dispatch(FETCH_RESERVATION_ACTIONS.requested()),
        fetchPropertyInformation: () =>
            dispatch(reservationsListViewLoadRequested()),
        updateReservation: (data) => dispatch(updateReservationRequested(data)),
        createReservation: (data, history) =>
            dispatch(bookReservationRequested(data, history)),
        removeMessages: (messageKey) =>
            dispatch(removeFormMessages(messageKey)),
        fetchUserPaymentMethods: (userId) =>
            dispatch(bookReservationGetUserPaymentMethods(userId)),
        fetchPropertyDockInfo: (propertyId) =>
            dispatch(bookReservationGetPropertyInfoRequested(propertyId)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    ReservationDetailsContainer,
);
