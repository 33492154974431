import { Grid, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from '../../TextField';
import styles from './styles';

const StripeCardField = ({
    classes,
    handleFieldChange,
    fieldName,
    fieldLabel,
}) => {
    return (
        <Grid item container className={classes.labelWrapper}>
            <Typography className={classes.label}>{fieldLabel}</Typography>
            <TextField
                className={classes.field}
                onChange={(e) => handleFieldChange(e, fieldName)}
            />
        </Grid>
    );
};

StripeCardField.propTypes = {
    classes: PropTypes.object.isRequired,
    handleFieldChange: PropTypes.func.isRequired,
    fieldName: PropTypes.string.isRequired,
    fieldLabel: PropTypes.string.isRequired,
};

StripeCardField.defaultProps = {};

export default withStyles(styles)(StripeCardField);
