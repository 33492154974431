import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import * as Yup from 'yup';
import { DateTime } from 'luxon';
import styles from './styles';
import AdminSelect from '../../common/AdminSelect';
import CalendarPicker from '../../common/CalendarPicker';
import DetailForm from '../../common/DetailForm';
import MultilineTextField from '../../common/MultilineTextField';
import MultipleWeekdayTimeRangePicker from '../../common/MultipleWeekdayTimeRangePicker';
import Photos from '../../common/Photos';
import TextField from '../../common/TextField';
import { formatDate } from '../../utils/dates';
import {
    PULLTHROUGH_OPTIONS,
    RATE_TYPE_DAILY,
    RATE_TYPE_HOURLY,
    RATE_TYPE_MONTHLY,
    RATE_TYPES,
} from './constants';
import { formMessagesPropTypes } from '../../common/FormMessages';
import { MAX_LENGTH_DOCK_NAME } from '../../validation/forms';
import AdminCheckboxes from '../../common/AdminCheckboxes';

import firebase from 'firebase/compat/app';
import 'firebase/compat/remote-config';
import {
    REMOTE_CONFIG_NAME_FORMAT_DOCK_NAME_DESC,
    REMOTE_CONFIG_NAME_FORMAT_DOCK_NAME_REGEX,
    REMOTE_CONFIG_NAME_MAX_DAILY_RATE,
    REMOTE_CONFIG_NAME_MAX_HOURLY_RATE,
    REMOTE_CONFIG_NAME_MAX_MONTHLY_RATE,
    REMOTE_CONFIG_NAME_VALIDATE_DOCK_NAME,
} from '../../firebase/constants';

const NEW_TIME_PREFIX = 'newTime';

class DockForm extends Component {
    state = {
        dockTimes: this.props.formState.dockTimes,
        photos: this.props.formState.photos,
        selectedRateTypes: this.props.isCreatingDock
            ? [RATE_TYPE_HOURLY]
            : this.props.formState.selectedRateTypes,
    };

    onDockTimeChange = (key, startOrEnd, dayOrTime, value, setFieldValue) => {
        const dockTimes = this.state.dockTimes.map((range) => {
            const { key: rangeKey } = range;
            if (key === rangeKey) {
                range[startOrEnd][dayOrTime] = value;
            }
            return range;
        });
        this.setState({ dockTimes });
        setFieldValue('dockTimes', dockTimes);
    };

    onAddDockTime = (setFieldValue) => {
        const dockTimes = [
            ...this.state.dockTimes,
            {
                key: `${NEW_TIME_PREFIX}_${Date.now()}`,
                start: {
                    day: 1,
                    time: DateTime.local(),
                },
                end: {
                    day: 1,
                    time: DateTime.local(),
                },
            },
        ];
        this.setState({ dockTimes });
        setFieldValue('dockTimes', dockTimes);
    };

    onRemoveDockTime = (key, setFieldValue) => {
        const dockTimes = this.state.dockTimes.filter(
            ({ key: rangeKey }) => rangeKey !== key,
        );
        this.setState({ dockTimes });
        setFieldValue('dockTimes', dockTimes);
    };

    onPhotoChange = (event, setFieldValue) => {
        const files = Array.from(event.target.files);
        const fileURLs = files.map((file) => {
            return {
                photoUrl: URL.createObjectURL(file),
                key: Date.now(),
                file,
            };
        });
        const photos = this.state.photos.concat(fileURLs);
        this.setState({ photos });
        setFieldValue('photos', photos);
    };

    onPhotoClear = (key, setFieldValue) => {
        const photos = this.state.photos.filter((photo) => photo.key !== key);
        this.setState({ photos });
        setFieldValue('photos', photos);
    };

    onRateTypeChanged = (event, setFieldValue) => {
        const { selectedRateTypes } = this.state;
        const { name: changedRateType } = event.target;
        if (selectedRateTypes.includes(changedRateType)) {
            const newSelectedRateTypes = selectedRateTypes.filter(
                (rateType) => rateType !== changedRateType,
            );
            this.setState({
                selectedRateTypes: newSelectedRateTypes,
            });
            setFieldValue(`${changedRateType}Rate`, null);
            setFieldValue('selectedRateTypes', newSelectedRateTypes);
        } else {
            const newSelectedRateTypes = [
                ...selectedRateTypes,
                changedRateType,
            ];
            this.setState({
                selectedRateTypes: newSelectedRateTypes,
            });
            setFieldValue('selectedRateTypes', newSelectedRateTypes);
        }
    };

    _getFormFields = () => {
        const { isCreatingDock } = this.props;
        const { selectedRateTypes } = this.state;
        const hourlyRateSelected = selectedRateTypes.includes(RATE_TYPE_HOURLY);
        const dailyRateSelected = selectedRateTypes.includes(RATE_TYPE_DAILY);
        const monthlyRateSelected = selectedRateTypes.includes(
            RATE_TYPE_MONTHLY,
        );
        const remoteConfig = firebase.remoteConfig();
        const validateDockName = remoteConfig.getValue(
            REMOTE_CONFIG_NAME_VALIDATE_DOCK_NAME,
        );
        const formatDockNameRegexString = remoteConfig.getValue(
            REMOTE_CONFIG_NAME_FORMAT_DOCK_NAME_REGEX,
        );
        const formatDockNameRegEx = formatDockNameRegexString
            ? RegExp(formatDockNameRegexString.asString())
            : null;
        const formatDockNameDesc = remoteConfig.getValue(
            REMOTE_CONFIG_NAME_FORMAT_DOCK_NAME_DESC,
        );
        const maxHourlyRate = remoteConfig.getValue(
            REMOTE_CONFIG_NAME_MAX_HOURLY_RATE,
        );
        const maxDailyRate = remoteConfig.getValue(
            REMOTE_CONFIG_NAME_MAX_DAILY_RATE,
        );
        const maxMonthlyRate = remoteConfig.getValue(
            REMOTE_CONFIG_NAME_MAX_MONTHLY_RATE,
        );
        return [
            {
                name: 'dockName',
                label: 'Name',
                validation: Yup.string()
                    .max(MAX_LENGTH_DOCK_NAME)
                    .required()
                    .matches(
                        formatDockNameRegEx && validateDockName.asBoolean()
                            ? formatDockNameRegEx
                            : /^.*$/,
                        formatDockNameDesc.asString(),
                    ),
                render: (fieldProps) => (
                    <TextField fullWidth required {...fieldProps} label="" />
                ),
            },
            {
                name: 'dockDescription',
                label: 'Description',
                validation: Yup.string().nullable(),
                render: (fieldProps) => (
                    <MultilineTextField fullWidth {...fieldProps} label="" />
                ),
            },
            {
                name: 'photos',
                label: 'Photos',
                render: (fieldProps, { setFieldValue }) => (
                    <Photos
                        {...fieldProps} // Field props has to go first so we can override onChange with onPhotoChange.
                        onChange={(event) =>
                            this.onPhotoChange(event, setFieldValue)
                        }
                        onPhotoClear={(key) =>
                            this.onPhotoClear(key, setFieldValue)
                        }
                    />
                ),
            },
            {
                name: 'selectedRateTypes',
                label: 'Booking Options',
                validation: Yup.array().required(
                    'At least one booking option must be selected.',
                ),
                render: (fieldProps, { setFieldValue }) => (
                    <AdminCheckboxes
                        {...fieldProps}
                        checkboxes={RATE_TYPES}
                        checked={selectedRateTypes}
                        CheckboxProps={{
                            onChange: (event) =>
                                this.onRateTypeChanged(event, setFieldValue),
                        }}
                        helperText="Select at least one booking option."
                    />
                ),
            },
            {
                name: 'hourlyRate',
                label: 'Hourly Rate',
                validation: Yup.number()
                    .min(0)
                    .max(maxHourlyRate.asNumber())
                    .nullable()
                    .required(() => hourlyRateSelected),
                render: (fieldProps, { setFieldValue }) => {
                    return (
                        <TextField
                            required={false}
                            type="number"
                            {...fieldProps}
                            label=""
                            disabled={!hourlyRateSelected}
                            onChange={(event) => {
                                const { onChange } = fieldProps;
                                onChange && onChange(event);
                                setFieldValue(
                                    'hourlyRate',
                                    `${event.target.value}`,
                                );
                            }}
                        />
                    );
                },
            },
            {
                name: 'dailyRate',
                label: 'Daily Rate',
                validation: Yup.number()
                    .min(0)
                    .max(maxDailyRate.asNumber())
                    .nullable()
                    .required(() => dailyRateSelected),
                render: (fieldProps, { setFieldValue }) => (
                    <TextField
                        required={false}
                        type="number"
                        {...fieldProps}
                        label=""
                        disabled={!dailyRateSelected}
                        onChange={(event) => {
                            const { onChange } = fieldProps;
                            onChange && onChange(event);
                            setFieldValue('dailyRate', `${event.target.value}`);
                        }}
                    />
                ),
            },
            {
                name: 'monthlyRate',
                label: 'Monthly Rate',
                validation: Yup.number()
                    .min(0)
                    .max(maxMonthlyRate.asNumber())
                    .nullable()
                    .required(() => monthlyRateSelected),
                render: (fieldProps, { setFieldValue }) => (
                    <TextField
                        required={false}
                        type="number"
                        {...fieldProps}
                        label=""
                        disabled={!monthlyRateSelected}
                        onChange={(event) => {
                            const { onChange } = fieldProps;
                            onChange && onChange(event);
                            setFieldValue(
                                'monthlyRate',
                                `${event.target.value}`,
                            );
                        }}
                    />
                ),
            },
            {
                name: 'dockPullthrough',
                label: 'Pullthrough',
                validation: Yup.boolean().required(),
                render: (fieldProps) => (
                    <AdminSelect
                        type="large"
                        options={PULLTHROUGH_OPTIONS}
                        {...fieldProps}
                    />
                ),
            },
            {
                name: 'createdAt',
                label: isCreatingDock ? '' : 'Created',
                render: ({ values: { createdAt }, ...rest }) => {
                    return isCreatingDock ? null : (
                        <Typography inline color="primary" {...rest}>
                            {createdAt && formatDate(createdAt)}
                        </Typography>
                    );
                },
            },
            {
                name: 'dockTimes',
                label: 'Availability',
                render: ({ name }, { setFieldValue }) => (
                    <MultipleWeekdayTimeRangePicker
                        onRangeChange={(key, startOrEnd, dayOrTime, value) =>
                            this.onDockTimeChange(
                                key,
                                startOrEnd,
                                dayOrTime,
                                value,
                                setFieldValue,
                            )
                        }
                        onAdd={() => this.onAddDockTime(setFieldValue)}
                        onRemove={(key) =>
                            this.onRemoveDockTime(key, setFieldValue)
                        }
                        ranges={this.state.dockTimes}
                        name={name}
                    />
                ),
            },
            {
                name: 'blackoutTimes',
                label: 'Blackout Dates',
                render: ({ name }, { setFieldValue }) => (
                    <CalendarPicker
                        initialState={{
                            ranges: this.props.formState.blackoutTimes,
                        }}
                        setFieldValue={setFieldValue}
                        fieldName="blackoutTimes"
                        name={name}
                    />
                ),
            },
        ];
    };

    render() {
        const {
            classes,
            formState,
            loading,
            onSubmit,
            onDelete,
            messages,
            removeFormMessage,
            isCreatingDock,
        } = this.props;
        return (
            <DetailForm
                initFormState={formState}
                classes={{
                    componentWrapper: classes.componentWrapper,
                    labelWrapper: classes.labelWrapper,
                }}
                fields={this._getFormFields()}
                deleteButtonText="Delete Spot"
                submitButtonText={isCreatingDock ? 'Add Spot' : 'Update Spot'}
                onSubmit={onSubmit}
                onDelete={onDelete}
                showDelete={!isCreatingDock}
                messages={messages}
                loading={loading}
                confirmDialogTitle="Action Confirmation"
                confirmDialogText={`You are attempting to delete ${formState.dockName ||
                    'a spot'} from your database`}
                confirmDialogSubText="This action will permanently delete this spot from your RigPark database."
                removeFormMessage={removeFormMessage}
            />
        );
    }
}

DockForm.propTypes = {
    classes: PropTypes.object.isRequired,

    // Function that will be called when the form is submitted.
    onSubmit: PropTypes.func.isRequired,

    // Function that will be called when the delete button is pressed.
    onDelete: PropTypes.func.isRequired,

    loading: PropTypes.bool,

    // So you can supply an initial state of the form, if any.
    formState: PropTypes.shape({
        dockName: PropTypes.string,
        dockDescription: PropTypes.string,
        dockLength: PropTypes.number,
        dockWidth: PropTypes.number,
        dockHeight: PropTypes.number,
        dockPhotos: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
                photoUrl: PropTypes.string,
                uploadId: PropTypes.number,
            }),
        ),
        hourlyRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        dailyRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        monthlyRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        createdAt: PropTypes.string,
        dockTimes: PropTypes.arrayOf(
            PropTypes.shape({
                timeId: PropTypes.number,
                startDay: PropTypes.number,
                startTime: PropTypes.string,
                endDay: PropTypes.number,
                endTime: PropTypes.string,
            }),
        ),
        blackoutTimes: PropTypes.arrayOf(
            PropTypes.shape({
                blackoutId: PropTypes.number,
                startTime: PropTypes.string,
                endTime: PropTypes.string,
            }),
        ),
        selectedRateTypes: PropTypes.arrayOf(PropTypes.string),
        photos: PropTypes.arrayOf(
            PropTypes.shape({
                photoUrl: PropTypes.string,
                key: PropTypes.object,
                file: PropTypes.object,
            }),
        ),
    }),
    messages: formMessagesPropTypes.messages,
    removeFormMessage: PropTypes.func.isRequired,

    // Indication of whether or not the form is being used to create a new dock.
    isCreatingDock: PropTypes.bool.isRequired,
};

DockForm.defaultProps = {
    formState: {},
    loading: false,
};

export default withStyles(styles)(DockForm);
