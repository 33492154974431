import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import AdminContainer from '../../common/AdminContainer';
import DockForm from '../DockForm';
import { formMessagesPropTypes } from '../../common/FormMessages';
import {
    createDockRequested,
    updateDockRequested,
    dockActions,
} from '../../stores/docks/apiActions';
import {
    cleanupDetailedDockState,
    removeFormMessage,
} from '../../stores/docks/actions';
import {
    getDockDetails,
    isDockDetailLoading,
    isCreatingDock,
} from '../../stores/docks/selectors';
import { DEFAULT_DOCK_TIMES } from '../DockForm/constants';

class DockDetailsContainer extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        const { dockId, fetchDock } = this.props;
        if (dockId) {
            fetchDock(dockId);
        }
    }

    componentWillUnmount() {
        const { cleanupDetailedDockState } = this.props;
        cleanupDetailedDockState();
    }

    onDeleteDock = () => {
        const { deleteDock, dockId } = this.props;
        deleteDock && deleteDock(dockId);
    };

    onUpdateDock = (data) => {
        const { updateDock } = this.props;
        updateDock && updateDock(data);
    };

    renderForm = () => {
        const {
            dock = {},
            loading,
            messages,
            createDock,
            propertyId,
            removeFormMessage,
            isCreatingDock,
        } = this.props;

        const formState = {
            ...dock,
        };

        if (isCreatingDock) {
            formState.dockTimes = DEFAULT_DOCK_TIMES;
        }
        return loading ? (
            <CircularProgress />
        ) : (
            <DockForm
                formState={formState}
                onSubmit={
                    isCreatingDock
                        ? (dock) => createDock(propertyId, dock)
                        : this.onUpdateDock
                }
                onDelete={this.onDeleteDock}
                loading={loading}
                messages={messages}
                isCreatingDock={isCreatingDock}
                removeFormMessage={removeFormMessage}
            />
        );
    };

    render() {
        const { path, propertyId, ...rest } = this.props;

        return (
            <AdminContainer
                path={path}
                innerHeader
                innerHeaderProps={{
                    title: 'Spots',
                    backText: '< Back to Property',
                    backTo: `/properties/${propertyId}`,
                }}
                {...rest}
            >
                {this.renderForm()}
            </AdminContainer>
        );
    }
}

DockDetailsContainer.propTypes = {
    // The path which should be passed down from the router.
    path: PropTypes.string.isRequired,

    // The ID of the dock in the view (if editing), a route parameter.
    dockId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    // The ID of the property (a route param) that the dock belongs to, used for the Back link in the inner header.
    propertyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,

    // Function to delete the dock
    onDelete: PropTypes.func,

    // Functions for adding and removing dates and pictures in the form
    onAddPhoto: PropTypes.func,
    onAddDockTime: PropTypes.func,
    onRemoveDockTime: PropTypes.func,
    onAddBlackoutTime: PropTypes.func,
    onRemoveBlackoutTime: PropTypes.func,

    // Connected functions from mapping dispatch to props
    fetchDock: PropTypes.func.isRequired,
    updateDock: PropTypes.func.isRequired,
    deleteDock: PropTypes.func.isRequired,
    cleanupDetailedDockState: PropTypes.func.isRequired,
    createDock: PropTypes.func.isRequired,
    removeFormMessage: PropTypes.func.isRequired,

    // Connected props from mapping redux state to props

    // Messages to be displayed ont he form, if any.
    messages: formMessagesPropTypes.messages,

    // Indication of whether or not the dock data is loading.
    loading: PropTypes.bool,

    // The dock in the view
    dock: PropTypes.object,

    // Indication of whether or not the form is being used to create a new dock.
    isCreatingDock: PropTypes.bool.isRequired,
};

const mapStateToProps = function(state) {
    return {
        loading: isDockDetailLoading(state),
        dock: getDockDetails(state),
        messages: state.docks.messages,
        isCreatingDock: isCreatingDock(state),
    };
};

const mapDispatchToProps = function(dispatch) {
    return {
        fetchDock: (dockId) => dispatch(dockActions.fetchDockRequested(dockId)),
        deleteDock: (dockId) =>
            dispatch(dockActions.deleteDockRequested(dockId)),
        updateDock: (dock) => dispatch(updateDockRequested(dock)),
        cleanupDetailedDockState: () => dispatch(cleanupDetailedDockState()),
        createDock: (propertyId, dock) =>
            dispatch(createDockRequested(propertyId, dock)),
        removeFormMessage: (messageKey) =>
            dispatch(removeFormMessage(messageKey)),
    };
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(DockDetailsContainer);
