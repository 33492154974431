import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import Copyright from '../Copyright';
import styles from './styles';

const Footer = ({ classes }) => {
    return (
        <Grid
            container
            className={classes.footerContainer}
            direction="row"
            justify="center"
            alignItems="center"
        >
            <Grid item>
                <Typography className={classes.footerText} variant="body2">
                    <Copyright name="RigPark" />
                </Typography>
            </Grid>
        </Grid>
    );
};
Footer.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
