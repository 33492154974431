const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.primary.main,
        borderRadius: 50,
        fontWeight: 'bold',
        fontSize: 16,
        textTransform: 'none',
        width: theme.spacing.unit * 24,
        '&$disabled': {
            backgroundColor: theme.palette.primary.contrastText,
            opacity: 0.75,
        },
    },
    disabled: {},
});

export default styles;
