import React from 'react';
import PropTypes from 'prop-types';
import TextField from '../TextField';

const DEFAULT_ROWS = 4;

const MultilineTextField = ({ ...props }) => {
    return <TextField multiline rows={props.rows || DEFAULT_ROWS} {...props} />;
};

MultilineTextField.propTypes = {
    // the minimum number of rows for the textfield
    rows: PropTypes.number,
    // Error text to be displayed, should be empty if there is no error.
    error: PropTypes.string,
};

export default MultilineTextField;
