import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import PaginationText from './PaginationText';
import AdminSelect, { SIZE_COMPACT } from '../../common/AdminSelect';

class PageSizeSelect extends Component {
    render() {
        const { value, options, ...props } = this.props;
        return (
            <Grid container direction="row" alignItems="center" spacing={8}>
                <Grid item>
                    <PaginationText>Display</PaginationText>
                </Grid>
                <Grid item>
                    <AdminSelect
                        type={SIZE_COMPACT}
                        value={value}
                        options={options}
                        {...props}
                    />
                </Grid>
                <Grid item>
                    <PaginationText>per page</PaginationText>
                </Grid>
            </Grid>
        );
    }
}

PageSizeSelect.propTypes = {
    // Current value that should be selected
    value: PropTypes.number.isRequired,

    // Valid values for selection
    options: PropTypes.arrayOf(PropTypes.number).isRequired,
};

PageSizeSelect.defaultProps = {
    // Default to an empty array to avoid error if nothing supplied
    options: [],
};

export default PageSizeSelect;
