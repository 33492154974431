import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import Photo from '../Photo';
import styles from './styles';
import AdminButton from '../AdminButton';
import { noop } from '../../utils';

class Photos extends Component {
    render() {
        const { classes, value: photos, onPhotoClear, onChange } = this.props;
        let uploadRef;
        return (
            <Grid
                item
                container
                direction="row"
                alignItems="flex-end"
                spacing={8}
            >
                {photos &&
                    photos.map(({ key, photoUrl }, index) => {
                        return (
                            <Grid
                                item
                                key={`photo-${index}-${key}`}
                                className={classes.photo}
                            >
                                <Photo
                                    classes={{ photo: classes.photo }}
                                    src={photoUrl}
                                    showClearButton
                                    key={key}
                                    onClear={() => onPhotoClear(key)}
                                />
                            </Grid>
                        );
                    })}
                <input
                    hidden
                    type="file"
                    multiple
                    onChange={onChange}
                    ref={(ref) => {
                        uploadRef = ref;
                    }}
                    accept="image/*"
                />
                <Grid item>
                    <AdminButton
                        buttonType="edit"
                        size="small"
                        className={classes.button}
                        onClick={() => uploadRef.click()}
                    >
                        Choose File
                    </AdminButton>
                </Grid>
            </Grid>
        );
    }
}

Photos.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    value: PropTypes.arrayOf(
        PropTypes.shape({
            photoUrl: PropTypes.string,
            key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    ),

    // Functions to control the added and removed photos.
    onChange: PropTypes.func,
    onPhotoClear: PropTypes.func,
};

Photos.defaultProps = {
    value: [],
    onChange: noop,
    onPhotoClear: noop,
};

export default withStyles(styles)(Photos);
