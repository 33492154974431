import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { has } from 'ramda';
import { withStyles } from '@material-ui/core';
import DataTable from '../DataTable';
import KeyedDataTableSelector from '../DataTable/KeyedDataTableSelector';
import SearchDraftManager from '../DataTable/SearchDraftManager';
import { SORT_ASC, SORT_DESC } from '../DataTable/constants';
import TablesPropTypes from '../propTypes';
import ApiPropTypes from '../../propTypes/api';
import { ORDER_BY_DIR_ASC, ORDER_BY_DIR_DESC } from '../../constants/api';
import { noop } from '../../utils';
import Pagination from '../Pagination';
import SearchBar from '../../common/SearchBar';
import ActionsMenu from '../../common/ActionsMenu';
import styles from './styles';

export const COMPONENT_NAME = 'RigParkDataTable';

class RigParkDataTable extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        tableData: TablesPropTypes.tablePageData.isRequired,
        tableContext: ApiPropTypes.apiPageContext.isRequired,
        getKey: PropTypes.func.isRequired,
        onFetchDataRequested: PropTypes.func.isRequired,
        onActionSelect: PropTypes.func,
        actions: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string,
                label: PropTypes.string,
            }),
        ),
        actionsLoading: PropTypes.bool,
        initialActionValue: PropTypes.string,
        isRowSelectable: PropTypes.func,
        children: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.arrayOf(PropTypes.node),
        ]).isRequired,
    };

    static defaultProps = {
        onAddWatchlistRequested: noop,
        onActionSelect: noop,
        actions: [],
        initialActionValue: '',
    };

    state = {
        actionsExpanded: false,
    };

    getSearch = () => {
        return this.props.tableContext.filters &&
            this.props.tableContext.filters.search
            ? this.props.tableContext.filters.search
            : '';
    };

    getFilterValue = ({ key }) => {
        if (
            this.props.tableContext.filters &&
            has(key, this.props.tableContext.filters)
        ) {
            return this.props.tableContext.filters[key] || '';
        }
        return '';
    };

    /**
     * Create a function that will call onFetchDataRequested with the current table context + the results of
     * the provided `update` function used to update a specific slice (`contextKey`) of it (i.e. `pagination` or
     * `filters` or `order`).
     *
     * The `update` function will be passed the current value of the slice as the first argument and then it will also
     * be forwarded the remaining arguments that the returned refetcher function is called with.
     *
     * By default, the pagination will be reset to the first page. Use `resetToFirstPage` to disable this behaviour.
     *
     * @param {string} contextKey
     * @param {function({}|*[], ...*): ({}|*[])} update
     * @param {boolean=true} resetToFirstPage
     * @return {function(*...): void}
     */
    createRefetcher = (contextKey, update, resetToFirstPage = true) => {
        return (...updateArgs) => {
            const { onFetchDataRequested, tableContext } = this.props;
            const updatedSlice = update(
                tableContext[contextKey],
                ...updateArgs,
            );
            onFetchDataRequested({
                ...tableContext,
                [contextKey]: updatedSlice,
                ...(resetToFirstPage
                    ? {
                          pagination: {
                              ...(contextKey === 'pagination'
                                  ? updatedSlice
                                  : tableContext.pagination),
                              page: 1,
                          },
                      }
                    : {}),
            });
        };
    };

    /**
     * Creates a function that will dispatch a fetch with a new order by cycling the sort of a given column through:
     *
     * asc -> desc -> unsorted -> (repeat)
     *
     * This will preserve the existing sort / order of all other columns. If the sort of this column is updated, it's updated
     * in place in the order (i.e. precedence doesn't change). If it's added to the sort it's added to the end (i.e.
     * lowest precedence).
     *
     * @param {string} orderByFieldKey
     * @return {function(*): void}
     */
    createOnMultiSortToggle = (orderByFieldKey) => {
        /**
         * Will cycle the sort direction of a column following: asc -> desc -> unsorted -> repeat
         *
         * This allows multi column sort.
         *
         * @param currentOrder
         * @return {{order: *[][]}}
         */
        const updateOrder = (currentOrder) => {
            const currentDir = currentOrder.reduce(
                (current, [fieldKey, orderByDir]) => {
                    return (
                        current ||
                        (fieldKey === orderByFieldKey ? orderByDir : false)
                    );
                },
                false,
            );

            if (!currentDir) {
                // It's not currently present in the order, add as asc
                return [...currentOrder, [orderByFieldKey, ORDER_BY_DIR_ASC]];
            } else if (currentDir === ORDER_BY_DIR_ASC) {
                // Is currently in the order as asc, replace with desc
                return currentOrder.map(([fieldKey, sortDir]) => [
                    fieldKey,
                    fieldKey === orderByFieldKey ? ORDER_BY_DIR_DESC : sortDir,
                ]);
            } else {
                // Is currently in the order as desc, remove from order
                return currentOrder.filter(
                    ([fieldKey]) => fieldKey !== orderByFieldKey,
                );
            }
        };
        return this.createRefetcher('order', updateOrder);
    };

    /**
     * Creates a function that will dispatch a fetch with a new order by cycling a single column through:
     *
     * asc -> desc -> unsorted -> (repeat)
     *
     * This replaces all other sorts (i.e. forces the sort to be by just this one column).
     *
     * @param {string} orderByFieldKey
     * @return {function(*): void}
     */
    createOnSingleSortToggle = (orderByFieldKey) => {
        return this.createRefetcher('order', (currentOrder) => {
            const currentDir = currentOrder.reduce(
                (current, [fieldKey, orderByDir]) => {
                    return (
                        current ||
                        (fieldKey === orderByFieldKey ? orderByDir : false)
                    );
                },
                false,
            );

            if (!currentDir) {
                return [[orderByFieldKey, ORDER_BY_DIR_ASC]];
            } else if (currentDir === ORDER_BY_DIR_ASC) {
                return [[orderByFieldKey, ORDER_BY_DIR_DESC]];
            }
            return [];
        });
    };

    /**
     * Create a refetcher function that will set the order by direction to a single column based on the provided
     * table sort direction.
     *
     * @param orderByFieldKey
     * @return {function(*): void}
     */
    createOnSingleSort = (orderByFieldKey) => {
        return this.createRefetcher('order', (currentOrder, nextSortDir) => {
            return nextSortDir
                ? [
                      [
                          orderByFieldKey,
                          nextSortDir === SORT_ASC
                              ? ORDER_BY_DIR_ASC
                              : ORDER_BY_DIR_DESC,
                      ],
                  ]
                : [];
        });
    };

    /**
     * Create a function that will return the current sort direction for a given column.
     *
     * @param orderByFieldKey
     * @return {function(): *}
     */
    createSortGetter = (orderByFieldKey) => () => {
        // If the specified orderByFieldKey is present in the ordering fields, return its sort direction, otherwise return false.
        return this.props.tableContext.order.reduce(
            (dir, [orderBy, orderByDir]) => {
                if (orderBy === orderByFieldKey) {
                    return orderByDir === ORDER_BY_DIR_ASC
                        ? SORT_ASC
                        : SORT_DESC;
                }
                return dir;
            },
            false,
        );
    };

    onFilterChange = this.createRefetcher(
        'filters',
        (currentFilters, value, { key }) => ({
            ...currentFilters,
            [key]: value,
        }),
    );

    onSearchSubmit = this.createRefetcher(
        'filters',
        (currentFilters, search) => ({
            ...currentFilters,
            search,
        }),
    );

    onPageChange = this.createRefetcher(
        'pagination',
        (currentPagination, page) => ({ ...currentPagination, page }),
        false,
    );

    onPerPageChange = this.createRefetcher(
        'pagination',
        (currentPagination, perPage) => ({ ...currentPagination, perPage }),
        true,
    );

    renderFooter = () => {
        const {
            tableData: { total },
            tableContext: {
                pagination: { page, perPage },
            },
        } = this.props;
        return (
            <Pagination
                pageSize={perPage}
                page={page}
                total={total}
                onSizeChange={this.onPerPageChange}
                onPageChange={this.onPageChange}
            />
        );
    };

    renderSearch = ({ search, loading, onSearchSubmit, onSearchChange }) => {
        return (
            <SearchBar
                loading={loading}
                onSubmit={onSearchSubmit}
                onChange={onSearchChange}
                value={search}
            />
        );
    };

    renderMeta = (
        {
            classes,
            searchable,
            searchAlwaysVisible,
            title,
            renderFilters,
            renderActions,
            renderSearchToggle,
            renderSearch,
        },
        tableState,
    ) => {
        const { searchVisible } = tableState;
        return (
            <div className={classes.meta}>
                {title}
                {searchable ? (
                    <div className={classes.searchContainer}>
                        {searchAlwaysVisible || searchVisible
                            ? renderSearch()
                            : null}
                        {searchAlwaysVisible ? null : renderSearchToggle()}
                    </div>
                ) : null}
                {renderActions()}
                {renderFilters()}
            </div>
        );
    };

    onActionsAccordionChange = (panel, event, expanded) => {
        this.setState({ actionsExpanded: expanded });
    };

    renderActions = ({ loading, onActionSelect }, { someRowsSelected }) => {
        const {
            classes,
            actions,
            initialActionValue,
            actionsLoading,
        } = this.props;
        const { actionsExpanded } = this.state;
        return actions && actions.length ? (
            <ActionsMenu
                actions={actions}
                initValue={initialActionValue}
                onClickApply={(action) => onActionSelect(action)}
                expanded={actionsExpanded || someRowsSelected}
                onAccordionChange={this.onActionsAccordionChange}
                classes={{
                    root: classes.accordionMenu,
                    expanded: classes.accordionMenu,
                }}
                disabled={loading || !someRowsSelected || actionsLoading}
            />
        ) : null;
    };

    render() {
        const {
            getKey,
            isRowSelectable,
            children: renderTable = noop,
            tableData: { loading, data },
            onActionSelect,
            classes,
            ...rest
        } = this.props;

        const keyedDataTableSelectorProps = {
            data,
            getKey,
            onActionSelect,
            isRowSelectable,
        };

        const searchDraftManagerProps = {
            search: this.getSearch(),
            onSearchSubmit: this.onSearchSubmit,
        };

        const dataTableProps = {
            renderFooter: this.renderFooter,
            renderSearch: this.renderSearch,
            renderActions: this.renderActions,
            renderMeta: this.renderMeta,
            getFilterValue: this.getFilterValue,
            onFilterChange: this.onFilterChange,
            searchAlwaysVisible: true,
            loading,
            isRowSelectable,
            ...rest,
        };

        if (
            process.env.NODE_ENV === 'development' &&
            (typeof renderTable !== 'function' ||
                React.isValidElement(renderTable))
        ) {
            /* eslint-disable-next-line */
            console.warn(
                `Unexpected non-function child of ${COMPONENT_NAME}:`,
                renderTable,
            );
        }

        const renderTableProps = {
            createRefetcher: this.createRefetcher,
            createOnMultiSortToggle: this.createOnMultiSortToggle,
            createOnSingleSort: this.createOnSingleSort,
            createOnSingleSortToggle: this.createOnSingleSortToggle,
            createSortGetter: this.createSortGetter,
        };

        return (
            <SearchDraftManager {...searchDraftManagerProps}>
                {(searchDraftManagerDataTableProps) => (
                    <KeyedDataTableSelector {...keyedDataTableSelectorProps}>
                        {(keyedDataTableProps) => (
                            <DataTable
                                {...keyedDataTableProps}
                                {...searchDraftManagerDataTableProps}
                                {...dataTableProps}
                            >
                                {renderTable(renderTableProps)}
                            </DataTable>
                        )}
                    </KeyedDataTableSelector>
                )}
            </SearchDraftManager>
        );
    }
}

export default withStyles(styles)(RigParkDataTable);
