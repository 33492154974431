import { USER_ID } from './constants';
import { ORDER_BY_DIR_DESC } from '../../constants/api';

export const initialVehicleFiltersState = {
    vehiclePlate: '',
    vehicleName: '',
    makeId: '',
    modelId: {
        value: '',
        error: false,
    },
};

export const initialFiltersState = {
    checked: [],
    start: {},
    end: {},
    vehicleFilters: initialVehicleFiltersState,
};

export default {
    // User detail form messages
    messages: [],

    // User detail loading states and data
    loading: false,
    creating: false,
    photoUpdateInProgress: false,
    propertyDeleteInProgress: false,
    paymentMethodFetchInProgress: false,
    user: {
        paymentMethods: [],
    },

    // Utilization report
    sendingReport: false,
    reportSent: false,
    reportFailed: '',

    // Users list loadings state and data
    list: {
        loading: false,
        page: {
            filters: initialFiltersState,
            filterErrors: [],
            order: [[USER_ID, ORDER_BY_DIR_DESC]],
        },
        errors: [],
    },

    // For tracking userIds that have pending API changes in progress.
    loadingUpdate: [],
};
