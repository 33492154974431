export const PREFIX = 'amenities/';

// Actions
export const FETCH_AMENITIES_REQUESTED = `${PREFIX}FETCH_AMENITIES_REQUESTED`;
export const FETCH_AMENITIES_SUCCEEDED = `${PREFIX}FETCH_AMENITIES_SUCCEEDED`;
export const FETCH_AMENITIES_FAILED = `${PREFIX}FETCH_AMENITIES_FAILED`;

// Functions for each action
export function fetchAmenitiesRequested(param) {
    return {
        type: FETCH_AMENITIES_REQUESTED,
        param,
    };
}

export function fetchAmenitiesSucceeded(data) {
    return {
        type: FETCH_AMENITIES_SUCCEEDED,
        data,
    };
}

export function fetchAmenitiesFailed(error) {
    return {
        type: FETCH_AMENITIES_FAILED,
        error,
    };
}
