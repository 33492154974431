import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import AccordionMenu from '../../common/AccordionMenu';
import DatePropTypes from '../../propTypes/dates';
import FilterDatePicker from '../../common/FilterDatePicker';
import FilterMenuButtons from '../../common/FilterMenuButtons';
import FilterCheckbox from '../../common/FilterCheckbox';
import AdminMultipleSelect from '../../common/AdminMultipleSelect';
import { noop } from '../../utils';
import AdminSelect from '../../common/AdminSelect';
import FormMessages, { formMessagesPropTypes } from '../../common/FormMessages';

class ReservationsTableFilters extends Component {
    renderCheckBoxes = (options) => {
        const { classes, checked, onCheckboxChange } = this.props;
        return (
            <Grid container direction="column" className={classes.panel}>
                {options.map((option) => {
                    return (
                        <Grid
                            item
                            container
                            key={option}
                            direction="row"
                            alignItems="center"
                            spacing={8}
                            className={classes.checkboxGridItem}
                        >
                            <Grid item>
                                <FilterCheckbox
                                    checked={checked.includes(option)}
                                    value={option}
                                    onChange={onCheckboxChange}
                                />
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        );
    };

    renderDatePickers = ({ label, name }) => {
        const { classes, [name]: datePickerProps } = this.props;
        return (
            <Grid container direction="column" className={classes.panel}>
                <Grid item>
                    <Typography className={classes.filterLabel}>
                        {label}
                    </Typography>
                </Grid>
                <Grid item>
                    <FilterDatePicker dateAndTime {...datePickerProps} />
                </Grid>
            </Grid>
        );
    };

    render() {
        const {
            classes,
            onClickApply,
            onClickClear,
            DockSelectProps,
            PropertySelectProps,
            UserSelectProps,
            errors,
        } = this.props;
        return (
            <AccordionMenu
                container
                label="Filters"
                className={classes.container}
            >
                <AccordionMenu label="Property">
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        className={classes.panel}
                    >
                        <Grid item container direction="column">
                            <Grid item>
                                <Typography className={classes.filterLabel}>
                                    Property
                                </Typography>
                            </Grid>
                            <Grid item container justify="center">
                                <Grid item>
                                    <AdminSelect
                                        type="compact"
                                        className={classes.select}
                                        {...PropertySelectProps}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container direction="column">
                            <Grid item>
                                <Typography className={classes.filterLabel}>
                                    Dock(s)
                                </Typography>
                            </Grid>
                            <Grid item>
                                <AdminMultipleSelect
                                    type="compact"
                                    className={classes.select}
                                    {...DockSelectProps}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionMenu>
                <AccordionMenu label="User">
                    <Grid
                        container
                        direction="column"
                        className={classes.panel}
                    >
                        <Grid item>
                            <Typography className={classes.filterLabel}>
                                User Name (User ID)
                            </Typography>
                        </Grid>
                        <Grid item container justify="center">
                            <Grid item>
                                <AdminMultipleSelect
                                    type="compact"
                                    className={classes.select}
                                    {...UserSelectProps}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionMenu>
                <AccordionMenu label="Date Range">
                    {this.renderDatePickers({
                        name: 'start',
                        label: 'Starts After',
                    })}
                    {this.renderDatePickers({
                        name: 'end',
                        label: 'Ends Before',
                    })}
                </AccordionMenu>
                <AccordionMenu label="Active Date">
                    {this.renderDatePickers({
                        name: 'active',
                        label: 'Active',
                    })}
                </AccordionMenu>
                <AccordionMenu label="Cancelled">
                    {this.renderCheckBoxes(['Not Cancelled', 'Cancelled'])}
                </AccordionMenu>
                <FilterMenuButtons
                    onClickApply={onClickApply}
                    onClickClear={onClickClear}
                />
                {errors && errors.length > 0 && (
                    <Grid
                        container
                        direction="column"
                        className={classes.errorMessages}
                    >
                        <Grid item>
                            <FormMessages messages={errors} />
                        </Grid>
                    </Grid>
                )}
            </AccordionMenu>
        );
    }
}

ReservationsTableFilters.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    /*
     * Checked is a property that should be an array which contains the name of any checkboxes that are checked.
     * If the name of the checkbox is not in this array then its considered unchecked.
     */
    checked: PropTypes.arrayOf(PropTypes.string),

    // Function to be called when a checkbox is checked/unchecked.
    onCheckboxChange: PropTypes.func,

    // Reservations that we will pass to the date pickers.
    start: DatePropTypes.filterDate,
    end: DatePropTypes.filterDate,

    // Functions to be called when the buttons are clicked.
    onClickApply: PropTypes.func,
    onClickClear: PropTypes.func,

    // Props to spread onto the AdminMultipleSelect for dock selection.
    DockSelectProps: PropTypes.object,

    // Properties for the property select.
    PropertySelectProps: PropTypes.object,

    // Props for the user select.
    UserSelectProps: PropTypes.object,

    errors: formMessagesPropTypes.messages,
};

ReservationsTableFilters.defaultProps = {
    checked: [],
    start: {},
    end: {},
    DockSelectProps: {
        options: [],
        selectedOptions: [],
        handleDelete: noop,
        handleSelectedOption: noop,
        loading: false,
    },
    PropertySelectProps: {
        options: [],
        selectedOptions: [],
        handleDelete: noop,
        handleSelectedOption: noop,
        loading: false,
    },
    UserSelectProps: {
        options: [],
        selectedOptions: [],
        handleDelete: noop,
        handleSelectedOption: noop,
        loading: false,
    },
    onCheckboxChange: noop,
    onClickApply: noop,
    onClickClear: noop,
    errors: [],
};

export default withStyles(styles)(ReservationsTableFilters);
