import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import {
    FETCH_DOCK_ACTIONS,
    FETCH_DOCK_TYPES,
    DELETE_DOCK_ACTIONS,
    DELETE_DOCK_TYPES,
    UPDATE_DOCK_REQUESTED,
    updateDockSucceeded,
    updateDockFailed,
    addDockPhotosRequested,
    addDockPhotosSucceeded,
    addDockPhotosFailed,
    createDockSucceeded,
    createDockFailed,
    CREATE_DOCK_REQUESTED,
} from './apiActions';
import {
    doUploadWithAuth,
    fetchWithAuth,
    makeRequestWithAuth,
} from '../api/sagas';
import { getPathname } from '../router/selectors';
import {
    transformBlackoutTimesToTimestamps,
    transformDockTimesForAPI,
} from './transforms';
import { getErrorsFromPossibleAPIErrorResponse } from '../../utils/api';

export function* fetchDock({ dockId }) {
    yield call(makeRequestWithAuth, {
        actions: FETCH_DOCK_ACTIONS,
        path: `dock/${dockId}`,
        method: 'GET',
    });
}

export function* createDock({ propertyId, dock }) {
    const { photos, blackoutTimes, dockTimes, ...dockData } = dock;
    try {
        dockData.blackoutTimes =
            blackoutTimes && blackoutTimes.length > 0
                ? transformBlackoutTimesToTimestamps(blackoutTimes)
                : [];
        dockData.dockTimes =
            dockTimes && dockTimes.length > 0
                ? transformDockTimesForAPI(dockTimes)
                : [];

        const dock = yield call(fetchWithAuth, `property/${propertyId}/dock`, {
            method: 'POST',
            body: dockData,
        });

        // Creation of the dock has succeeded so we can upload the photos now, too.
        if (photos && photos.length > 0) {
            yield put(addDockPhotosRequested());
            const formData = new FormData();
            photos.forEach(({ file }) => {
                formData.append('photo', file);
            });
            try {
                const uploadResponse = yield call(
                    doUploadWithAuth,
                    `dock/${dock.dockId}/photo/upload`,
                    { method: 'POST', body: formData },
                );
                yield put(addDockPhotosSucceeded(uploadResponse));
                dock.photos = uploadResponse;
            } catch (error) {
                const errors = yield call(
                    getErrorsFromPossibleAPIErrorResponse,
                    error,
                );
                yield put(
                    addDockPhotosFailed([
                        'Upload of new Dock Photo(s) failed.',
                        ...errors,
                    ]),
                );
            }
        }

        yield put(createDockSucceeded(dock));

        // Now we will redirect to the dock edit
        yield put(push(`/properties/${propertyId}/dock/${dock.dockId}`));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Creation of new dock has failed.',
        );
        yield put(createDockFailed(errors, dock));
    }
}

export function* updateDock({ dock }) {
    const { photos, blackoutTimes, dockTimes, ...dockData } = dock;

    const photosForSync = photos.filter((photo) => photo.uploadId);
    dockData.photoUploadIds = photosForSync.map(({ uploadId }) => uploadId);
    try {
        dockData.blackoutTimes =
            blackoutTimes && blackoutTimes.length > 0
                ? transformBlackoutTimesToTimestamps(blackoutTimes)
                : [];
        dockData.dockTimes =
            dockTimes && dockTimes.length > 0
                ? transformDockTimesForAPI(dockTimes)
                : [];
        const updatedDock = yield call(fetchWithAuth, `dock/${dock.dockId}`, {
            method: 'PUT',
            body: dockData,
        });
        // Pick out the new photos which will have a file prop holding the photo file
        const newPhotos = photos.filter((photo) => photo.file);

        // If there are any new photos, upload them as dock photos
        if (newPhotos && newPhotos.length > 0) {
            yield put(addDockPhotosRequested());
            const formData = new FormData();
            newPhotos.forEach(({ file }) => {
                formData.append('photo', file);
            });
            try {
                const uploadResponse = yield call(
                    doUploadWithAuth,
                    `dock/${dock.dockId}/photo/upload`,
                    { method: 'POST', body: formData },
                );
                yield put(addDockPhotosSucceeded(uploadResponse));
                updatedDock.photos = uploadResponse;
            } catch (error) {
                const errors = yield call(
                    getErrorsFromPossibleAPIErrorResponse,
                    error,
                );
                yield put(
                    addDockPhotosFailed([
                        'Upload of new Dock Photo(s) failed.',
                        ...errors,
                    ]),
                );
            }
        }
        yield put(updateDockSucceeded(updatedDock));
    } catch (error) {
        const errors = yield call(
            getErrorsFromPossibleAPIErrorResponse,
            error,
            'Update of dock has failed.',
        );
        yield put(updateDockFailed(errors, dock));
    }
}

export function* deleteDock({ dockId }) {
    yield call(makeRequestWithAuth, {
        actions: DELETE_DOCK_ACTIONS,
        path: `dock/${dockId}`,
        method: 'DELETE',
    });
}

export function* redirectToPropertyOrProperties() {
    const pathname = yield select(getPathname);
    const { params } = matchPath(pathname, { path: '/properties/:propertyId' });
    if (params && params.propertyId) {
        const { propertyId } = params;
        yield put(push(`/properties/${propertyId}`));
    } else {
        yield put(push(`/properties`));
    }
}

export function* listenFetchUpdateDeleteDock() {
    yield all([
        takeLatest(FETCH_DOCK_TYPES.requested, fetchDock),
        takeLatest(UPDATE_DOCK_REQUESTED, updateDock),
        takeLatest(DELETE_DOCK_TYPES.requested, deleteDock),
        takeLatest(DELETE_DOCK_TYPES.succeeded, redirectToPropertyOrProperties),
        takeLatest(CREATE_DOCK_REQUESTED, createDock),
    ]);
}
