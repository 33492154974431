import { all, call } from 'redux-saga/effects';
import dashboard from './dashboard/sagas';
import users from './users/sagas';
import properties from './properties/sagas';
import {
    firebaseAuthListenerSaga,
    init,
    listenLoginAndLogoutRequested,
} from './auth/sagas';
import { listenFetchUpdateDeleteDock } from './docks/sagas';
import userGroups from './userGroups/sagas';
import regions from './regions/sagas';
import amenities from './amenities/sagas';
import rules from './rules/sagas';
import reservations from './reservations/sagas';
import vehicles from './vehicles/sagas';
import stripe from './stripe/sagas';

/**
 *
 * @returns {IterableIterator<AllEffect<SimpleEffect<"CALL", CallEffectDescriptor>> | AllEffect<any>>}
 */
export default function* rootSaga() {
    yield all([
        call(firebaseAuthListenerSaga),
        call(init),
        call(userGroups),
        call(regions),
        call(listenLoginAndLogoutRequested),
        call(dashboard),
        call(users),
        call(properties),
        call(listenFetchUpdateDeleteDock),
        call(amenities),
        call(rules),
        call(reservations),
        call(vehicles),
        call(stripe),
    ]);
}
