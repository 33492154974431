const styles = (theme) => ({
    contentPaper: {
        maxWidth: 650,
    },
    content: {
        paddingLeft: 75,
        paddingRight: 75,
    },
    contentText: {
        color: theme.palette.primary.main,
    },
    text: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    moreText: {
        fontSize: 10,
    },
    title: {
        backgroundColor: theme.palette.secondary.contrastText,
        color: theme.palette.primary.contrastText,
        marginBottom: 20,
        fontSize: 24,
        fontWeight: 'bold',
    },
});

export default styles;
