import { INITIALIZATION_STATE_LOADING } from './constants';

export const authInitialState = {
    // Used to indicate whether or not the app has been initialized.
    initializationState: INITIALIZATION_STATE_LOADING,

    // If any errors occur in initialization they will go here.
    initializationErrors: [],

    // Indicate whether or not a login attempt is in progress.
    loginInProgress: false,

    // Indicate whether or not a post login check is in progress.
    checkInProgress: false,

    // Any errors from login for either field will be stored here.
    errors: {
        email: '',
        password: '',
    },

    /*
     * Flag is only marked true if the user is logged in and has passed all checks (
     * i.e. has verified their email in firebase, is in the pg db and is an admin)
     */
    validUser: false,

    // The current logged in user's userId.
    userId: null,

    firebaseUser: null,
};
