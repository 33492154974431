const styles = (theme) => ({
    label: {
        textAlign: 'right',
        textTransform: 'uppercase',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        letterSpacing: 4,
    },
    textField: {
        color: `${theme.palette.secondary.main}`,
        opacity: 1,
        width: 495,
        backgroundColor: theme.custom.grey,
    },
});

export default styles;
