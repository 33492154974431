import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import ReadOnlyTextField from '../ReadOnlyTextField';

const MakeAndModel = ({ classes, makeName, modelName }) => {
    return (
        <Grid
            container
            wrap="nowrap"
            className={classes.container}
            justify="center"
            alignItems="center"
        >
            <Grid container item justify="center" alignItems="center">
                <Typography className={classes.label}>Make</Typography>
                <ReadOnlyTextField
                    variant="filled"
                    InputProps={{
                        classes: {
                            root: classes.inputRoot,
                            input: classes.input,
                        },
                        disableUnderline: true,
                    }}
                    value={makeName}
                />
            </Grid>
            <Grid
                container
                item
                justify="center"
                alignItems="center"
                className={classes.model}
            >
                <Typography className={classes.label}>Model</Typography>
                <ReadOnlyTextField
                    variant="filled"
                    InputProps={{
                        classes: {
                            root: classes.inputRoot,
                            input: classes.input,
                        },
                        disableUnderline: true,
                    }}
                    value={modelName}
                />
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(MakeAndModel);
