import React from 'react';
import PropTypes from 'prop-types';
import { has } from 'ramda';
import { CircularProgress, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import AdminContainer from '../../common/AdminContainer';
import PropertyForm from '../PropertyForm';
import { formMessagesPropTypes } from '../../common/FormMessages';
import {
    cleanupDetailedPropertyState,
    editDock,
    removeFormMessage,
    propertyDetailViewLoadRequested,
    sendPropertyReportRequested,
} from '../../stores/properties/actions';
import {
    DELETE_PROPERTY_ACTIONS,
    deleteDockRequested,
    createPropertyRequested,
    updatePropertyRequested,
} from '../../stores/properties/apiActions';
import { fetchRegionsRequested } from '../../stores/regions/actions';
import {
    getOwnerEmail,
    getOwnerCountry,
    getPropertyDetails,
    isPropertyDetailLoading,
    isSendingPropertyReport,
    wasPropertyReportSent,
    propertyReportError,
} from '../../stores/properties/selectors';
import { getAmenitiesForCheckboxesInForm } from '../../stores/amenities/selectors';
import { getRulesForCheckboxesInForm } from '../../stores/rules/selectors';
import {
    areRegionsLoading,
    getRegionsForSelect,
} from '../../stores/regions/selectors';
import { DEFAULT_PROPERTY_ACTIVE, PROPERTY_ACTIVE_FIELD } from './constants';

const styles = () => ({
    innerContainer: {},
});

class PropertyDetailsContainer extends React.Component {
    componentDidMount() {
        this.props.onLoad();
    }

    componentWillUnmount() {
        const { cleanupDetailedPropertyState } = this.props;
        cleanupDetailedPropertyState && cleanupDetailedPropertyState();
    }

    onSendReport = (recipients) => {
        const { propertyId, onSendReport } = this.props;
        onSendReport && onSendReport(propertyId, recipients);
    };

    onDeleteProperty = () => {
        const { propertyId, deleteProperty } = this.props;
        deleteProperty && deleteProperty(propertyId);
    };

    onEditDock = (dockId) => {
        const { onEditDock, propertyId } = this.props;
        if (dockId) {
            onEditDock && onEditDock({ dockId, propertyId });
        }
    };

    onDeleteDock = (propertyId, dockId, dockName) => {
        const { onDeleteDock } = this.props;
        if (dockId) {
            onDeleteDock && onDeleteDock(propertyId, dockId, dockName);
        }
    };

    onUpdateProperty = (data) => {
        const { updateProperty } = this.props;
        updateProperty && updateProperty(data);
    };

    renderForm = () => {
        const {
            property,
            loading,
            messages,
            amenities,
            rules,
            fetchRegionsForCountry,
            regions,
            areRegionsLoading,
            userId,
            ownerEmail,
            ownerCountry,
            onCreateProperty,
            removeFormMessage,
            sendingReport,
            reportSent,
            reportError,
        } = this.props;
        if (userId) {
            property.propertyOwner = ownerEmail;
            property.userId = userId;
            property.country = ownerCountry;
        }
        if (!has(PROPERTY_ACTIVE_FIELD, property)) {
            property.propertyActive = DEFAULT_PROPERTY_ACTIVE;
        }
        return loading ? (
            <CircularProgress />
        ) : (
            <PropertyForm
                formState={property}
                onSendReport={this.onSendReport}
                onSubmit={userId ? onCreateProperty : this.onUpdateProperty}
                onDelete={this.onDeleteProperty}
                onEditDock={this.onEditDock}
                onDeleteDock={this.onDeleteDock}
                sendingReport={sendingReport}
                reportSent={reportSent}
                reportError={reportError}
                loading={loading}
                messages={messages}
                amenities={amenities}
                rules={rules}
                fetchRegionsForCountry={fetchRegionsForCountry}
                regions={regions}
                areRegionsLoading={areRegionsLoading}
                removeFormMessage={removeFormMessage}
            />
        );
    };

    render() {
        const { classes, path, ...rest } = this.props;

        return (
            <AdminContainer
                classes={classes}
                path={path}
                innerHeader
                innerHeaderProps={{
                    title: 'Property',
                    backText: '< Back to Properties',
                    backTo: `/properties`,
                }}
                {...rest}
            >
                {this.renderForm()}
            </AdminContainer>
        );
    }
}

PropertyDetailsContainer.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // The path which should be passed down from the router.
    path: PropTypes.string.isRequired,

    // The ID of the property (a route param), for when a property is being edited.
    propertyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    // The ID of a user, for when a new property is being created.
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    // The email and country of the user, for when a new property is being created.
    ownerEmail: PropTypes.string,
    ownerCountry: PropTypes.object,

    // Function to delete the property
    onDelete: PropTypes.func,

    // Function to edit the dock
    onEditDock: PropTypes.func,

    // Function to delete the dock
    onDeleteDock: PropTypes.func,

    // Functions for adding and removing dates and pictures in the form
    onAddPhoto: PropTypes.func,
    onAddSpot: PropTypes.func,

    // Connected functions from mapping dispatch to props
    onLoad: PropTypes.func.isRequired,
    onSendReport: PropTypes.func.isRequired,
    updateProperty: PropTypes.func.isRequired,
    deleteProperty: PropTypes.func.isRequired,
    cleanupDetailedPropertyState: PropTypes.func.isRequired,
    onCreateProperty: PropTypes.func.isRequired,
    removeFormMessage: PropTypes.func.isRequired,
    fetchRegionsForCountry: PropTypes.func.isRequired,

    // Messages to be displayed on the form, if any.
    messages: formMessagesPropTypes.messages,

    // Indicate whether property utilization report is being sent
    sendingReport: PropTypes.bool,
    reportSent: PropTypes.bool,
    reportError: PropTypes.string,

    // Indication of whether or not the property data is loading.
    loading: PropTypes.bool,

    // The property in the view
    property: PropTypes.object,

    // Amenities for use in the form.
    amenities: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            name: PropTypes.number.isRequired,
        }),
    ).isRequired,

    // Rules for use in the form.
    rules: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            name: PropTypes.number.isRequired,
        }),
    ).isRequired,

    // Regions aka states for the state select in the property details form.
    regions: PropTypes.object.isRequired,
    areRegionsLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = function(state) {
    return {
        loading: isPropertyDetailLoading(state),
        sendingReport: isSendingPropertyReport(state),
        reportSent: wasPropertyReportSent(state),
        reportError: propertyReportError(state),
        property: getPropertyDetails(state),
        messages: state.properties.messages,
        amenities: getAmenitiesForCheckboxesInForm(state),
        rules: getRulesForCheckboxesInForm(state),
        regions: getRegionsForSelect(state),
        areRegionsLoading: areRegionsLoading(state),
        ownerEmail: getOwnerEmail(state),
        ownerCountry: getOwnerCountry(state),
    };
};

const mapDispatchToProps = function(dispatch) {
    return {
        onLoad: () => dispatch(propertyDetailViewLoadRequested()),
        onSendReport: (propertyId, recipients) =>
            dispatch(sendPropertyReportRequested(propertyId, recipients)),
        onEditDock: ({ propertyId, dockId }) =>
            dispatch(editDock({ propertyId, dockId })),
        onDeleteDock: (propertyId, dockId, dockName) =>
            dispatch(deleteDockRequested(propertyId, dockId, dockName)),
        deleteProperty: (propertyId) =>
            dispatch(DELETE_PROPERTY_ACTIONS.requested(propertyId)),
        updateProperty: (property) =>
            dispatch(updatePropertyRequested(property)),
        cleanupDetailedPropertyState: () =>
            dispatch(cleanupDetailedPropertyState()),
        fetchRegionsForCountry: (countryAbbr) =>
            dispatch(fetchRegionsRequested(countryAbbr)),
        onCreateProperty: (property) =>
            dispatch(createPropertyRequested(property)),
        removeFormMessage: (messageKey) =>
            dispatch(removeFormMessage(messageKey)),
    };
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(PropertyDetailsContainer);
