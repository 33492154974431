import { makeActionCreator } from '../../utils/api';

export const PREFIX = 'docks/';

export const FETCH_DOCK = 'fetchDock';
export const DELETE_DOCK = 'deleteDock';
export const SET_FORM_MESSAGES = 'setFormMessages';
export const REMOVE_FORM_MESSAGE = `${PREFIX}REMOVE_FORM_MESSAGE`;
export const CLEANUP_DETAILED_STATE = `${PREFIX}CLEANUP_DETAILED_STATE`;

export const cleanupDetailedDockState = makeActionCreator(
    CLEANUP_DETAILED_STATE,
);

export const removeFormMessage = (messageKey) => {
    return {
        type: REMOVE_FORM_MESSAGE,
        messageKey,
    };
};
