import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { has } from 'ramda';

import {
    Grid,
    Typography,
    withStyles,
    CircularProgress,
} from '@material-ui/core';

import styles from './styles';
import { noop } from '../../utils';
import AdminButton from '../../common/AdminButton';
import ConfirmDialog from '../../common/ConfirmDialog';
import FormMessages, {
    formMessagesPropTypes,
    TYPE_ERROR,
} from '../../common/FormMessages';
import clsx from 'clsx';

const DEFAULT_CONFIRM_DIALOG_TEXT =
    'You are attempting to delete a record record from the RigPark database.';
const DEFAULT_CONFIRM_DIALOG_SUBTEXT =
    'This action will permanently delete this record from the RigPark database.';
const DEFAULT_CONFIRM_DIALOG_TITLE = 'Action Confirmation';

const DEFAULT_SUBMIT_BUTTON_TEXT = 'Update';
const DEFAULT_DELETE_BUTTON_TEXT = 'Delete';

/**
 * Helper to extract common used values from formik props.
 * Result of this helper is passed as the first argument to the `render` function of the field definition.
 * @see DetailForm.propTypes.fields
 * @param name
 * @param values
 * @param handleChange
 * @param errors
 * @returns {{name: <string>, onChange: <func>, value: *, values: <*[]>, error: <string>}}
 */
export const extractCommonFormikProps = ({
    name,
    values,
    handleChange,
    errors,
}) => {
    const value = values[name];
    return {
        values,
        value: value || typeof value === 'boolean' ? value : '',
        error: errors[name] || null,
        onChange: handleChange,
        name,
    };
};

class DetailForm extends Component {
    state = {
        // controls if the dialog for a destructive action is shown
        showConfirmationDialog: false,
    };

    onDelete = () => {
        const { onDelete } = this.props;
        onDelete && onDelete();
        this.toggleConfirmation();
    };

    toggleConfirmation = () => {
        this.setState({
            showConfirmationDialog: !this.state.showConfirmationDialog,
        });
    };
    onSubmit = (data) => {
        this.props.onSubmit && this.props.onSubmit(data);
    };

    onValidate = (values) => {
        return this.props.onValidate ? this.props.onValidate(values) : {};
    };

    /**
     * Helper that goes through the field definition for this form and adds all of the validation rules to the YUP validation schema
     * for this form
     * @param fields
     * @returns {*}
     */
    getValidationSchemaFromFieldDefinition = (fields) => {
        const schema = fields.reduce((schema, fieldDef) => {
            let validation = has('validation', fieldDef)
                ? fieldDef.validation
                : null;
            // verify that the validation value is set and a valid Yup schema
            if (validation && Yup.isSchema(validation)) {
                // the label is used for the error description rather that the field's name
                if (has('label', fieldDef)) {
                    validation = validation.label(fieldDef.label);
                }
                schema[fieldDef.name] = validation;
            }
            return schema;
        }, {});
        return Yup.object().shape(schema);
    };

    renderActionButtons(handleSubmit, isValid = false) {
        const {
            submitButtonText,
            deleteButtonText,
            showDelete,
            showConfirm,
            FormProps: { validateOnChange },
            formSubmitInProgress,
            classes,
            disableSubmit,
        } = this.props;
        return (
            <Grid
                container
                className={classes.actionButtonContainer}
                item
                spacing={24}
                justify="center"
                key="actionButtons"
            >
                {showConfirm && !disableSubmit ? (
                    <Grid item>
                        <AdminButton
                            buttonType="positive"
                            size="large"
                            disabled={
                                (validateOnChange ? !isValid : false) ||
                                formSubmitInProgress
                            }
                            className={classes.actionButton}
                            onClick={handleSubmit}
                        >
                            {formSubmitInProgress ? (
                                <CircularProgress />
                            ) : (
                                submitButtonText
                            )}
                        </AdminButton>
                    </Grid>
                ) : null}
                {showDelete ? (
                    <Grid item>
                        <AdminButton
                            buttonType="negative"
                            size="large"
                            className={classes.actionButton}
                            onClick={this.toggleConfirmation}
                        >
                            {deleteButtonText}
                        </AdminButton>
                    </Grid>
                ) : null}
            </Grid>
        );
    }

    /**
     * used to call the render prop for each field
     * render is passed 2 arguments:
     *  1. an object with only common props // @see: extractCommonFormikProps above
     *  2. an object containing formiks props merged with all properties passed into the fields definition
     * @param props
     * @returns {*}
     */
    renderFieldComponent = (props) => {
        const { render = noop } = props;
        return render(extractCommonFormikProps(props), props);
    };

    renderFormBody(formikProps) {
        const { fields, classes } = this.props;
        const renderedFields = fields.map((field) => {
            const { name, label, render, labelWrapperClass, hidden } = field;
            if (hidden) {
                return null;
            }
            return render ? (
                <Grid
                    container
                    item
                    className={classes.fieldWrapper}
                    direction="row"
                    justify="center"
                    key={name}
                    wrap="nowrap"
                >
                    <Grid
                        item
                        className={clsx(
                            classes.labelWrapper,
                            labelWrapperClass,
                        )}
                    >
                        <Typography className={classes.label} inline>
                            {label}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.componentWrapper}>
                        {this.renderFieldComponent({
                            ...field,
                            ...formikProps,
                        })}
                    </Grid>
                </Grid>
            ) : null;
        });
        return [
            renderedFields,
            this.renderActionButtons(
                formikProps.handleSubmit,
                formikProps.isValid,
            ),
        ];
    }

    render() {
        const {
            classes,
            initFormState,
            fields,
            loading,
            messages,
            removeFormMessage,
            onValidate,
            isInitialValid,
            confirmDialogText = DEFAULT_CONFIRM_DIALOG_TEXT,
            confirmDialogSubText = DEFAULT_CONFIRM_DIALOG_SUBTEXT,
            confirmDialogTitle = DEFAULT_CONFIRM_DIALOG_TITLE,
            validationSchema = this.getValidationSchemaFromFieldDefinition(
                fields,
            ),
            FormProps,
        } = this.props;

        return !loading ? (
            <Formik
                initialValues={initFormState}
                isInitialValid={(formikProps) =>
                    isInitialValid(
                        { ...this.props, validationSchema },
                        formikProps,
                    )
                }
                validationSchema={validationSchema}
                onSubmit={this.onSubmit}
                onValidate={onValidate}
                {...FormProps}
            >
                {(formikProps) => (
                    <Fragment>
                        <Grid
                            container
                            direction="row"
                            className={classes.container}
                            spacing={24}
                        >
                            <Grid
                                item
                                component={() => (
                                    <FormMessages
                                        messages={messages}
                                        onClose={removeFormMessage}
                                    />
                                )}
                            />
                            {this.renderFormBody(formikProps)}
                        </Grid>
                        <ConfirmDialog
                            open={this.state.showConfirmationDialog}
                            onConfirm={this.onDelete}
                            onCancel={this.toggleConfirmation}
                            title={confirmDialogTitle}
                            text={confirmDialogText}
                            subText={confirmDialogSubText}
                        />
                    </Fragment>
                )}
            </Formik>
        ) : (
            <Grid
                container
                direction="row"
                justify="center"
                className={classes.loadingContainer}
            >
                <Grid item>
                    <CircularProgress color="secondary" size={60} />
                </Grid>
            </Grid>
        );
    }
}

DetailForm.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // Text to be used for the submit action button text
    submitButtonText: PropTypes.string,

    // Function that will be called when the form is submitted.
    onSubmit: PropTypes.func.isRequired,

    // text to be used for delete button
    deleteButtonText: PropTypes.string,

    // Function called when onDelete is pressed
    onDelete: PropTypes.func.isRequired,

    // Boolean to indicate if the delete button should be shown or not.
    showDelete: PropTypes.bool,

    // Boolean to indicate if the confirm button should be shown or not.
    showConfirm: PropTypes.bool,

    // Function used to validate values for the form before submitting
    onValidate: PropTypes.func,

    isInitialValid: PropTypes.func.isRequired,

    // Yup schema to be used in validating every form
    validationSchema: PropTypes.object,

    // text to be used for confirm dialog
    confirmDialogText: PropTypes.string,

    // subtext to be used in confirm dialog
    confirmDialogSubText: PropTypes.string,

    // text to be used for confirm dialog title
    confirmDialogTitle: PropTypes.string,

    // bool to determine if the form is in a loading state
    loading: PropTypes.bool,

    // the fields to render for this form, any additional parameters are passed back through the render function
    fields: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            validation: PropTypes.object,
            // render(commonProps, allProps), see DetailForm.renderFieldComponent
            render: PropTypes.func.isRequired,
            hidden: PropTypes.bool,
        }),
    ),

    // contains the initial state of the form the be passed to formik
    initFormState: PropTypes.object,

    messages: formMessagesPropTypes.messages,

    // Any props that should be spread onto the Formik form
    FormProps: PropTypes.object,

    // Optional function to be called when the clear button is clicked on a form message.
    removeFormMessage: PropTypes.func,

    // Optional param to designate if the form submission is in progress. Simply disables the submit button
    formSubmitInProgress: PropTypes.bool,

    // Optional param to disable submit
    disableSubmit: PropTypes.bool,
};

DetailForm.defaultProps = {
    loading: false,
    submitButtonText: DEFAULT_SUBMIT_BUTTON_TEXT,
    deleteButtonText: DEFAULT_DELETE_BUTTON_TEXT,
    confirmDialogText: DEFAULT_CONFIRM_DIALOG_TEXT,
    confirmDialogSubText: DEFAULT_CONFIRM_DIALOG_SUBTEXT,
    confirmDialogTitle: DEFAULT_CONFIRM_DIALOG_TITLE,
    messages: [],
    /**
     * Default function to validate forms initial values.
     * Checks that the are no error messages being passed in and that the YUP schema is valid
     * @param messages
     * @param validationSchema
     * @param initFormState
     * @returns {boolean|*}
     */
    isInitialValid: ({ messages, validationSchema, initFormState }) => {
        return (
            messages.filter((message) => message.type === TYPE_ERROR).length ===
                0 && validationSchema.isValidSync(initFormState)
        );
    },
    showDelete: true,
    showConfirm: true,
    FormProps: {
        validateOnChange: true,
    },
    removeFormMessage: noop,
    onValidate: noop,
    onDelete: noop,
    formSubmitInProgress: false,
    disableSubmit: false,
};

export default withStyles(styles)(DetailForm);
