import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

import coloredLogo from './rigpark-logo-horizontal-color-high-res.png';
import whiteLogo from './rigpark-logo-horizontal-white-high-res.png';

const styles = (theme) => ({
    logo: {
        marginTop: 15,
        height: 60,
        width: 'auto',
        [theme.breakpoints.down('md')]: {
            height: 35,
        },
    },
});

const Logo = ({ classes, white }) => {
    return (
        <img
            className={classes.logo}
            src={white ? whiteLogo : coloredLogo}
            alt="RigPark"
        />
    );
};
Logo.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // A white icon is rendered when this is true, colored icon otherwise.
    white: PropTypes.bool,
};
Logo.defaultProps = {
    white: false,
};
export default withStyles(styles)(Logo);
