import React from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import styles from './styles';

const RangeLabel = ({ classes, children }) => (
    <Typography variant="body1" className={classes.labels} gutterBottom>
        {children}
    </Typography>
);

RangeLabel.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // This is for text is label the range item with, if any.
    children: PropTypes.node,
};

export default withStyles(styles)(RangeLabel);
