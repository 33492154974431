// Import actions
import {
    FETCH_AMENITIES_REQUESTED,
    FETCH_AMENITIES_SUCCEEDED,
    FETCH_AMENITIES_FAILED,
} from './apiActions';
import { LOGOUT } from '../auth/actions';

// Import initial state
import { amenitiesInitialState } from './initialState';

export default function(state = amenitiesInitialState, action = {}) {
    const { type } = action;
    switch (type) {
        case FETCH_AMENITIES_REQUESTED:
            return { loading: true, errors: [] };
        case FETCH_AMENITIES_SUCCEEDED:
            return { loading: false, data: action.data };
        case FETCH_AMENITIES_FAILED:
            return { loading: false, error: action.error };
        case LOGOUT:
            return amenitiesInitialState;
        default:
            return state;
    }
}
