const labelSize = 16;
const styles = (theme) => ({
    arrow: {
        color: theme.palette.secondary.main,
        // So the arrow is vertically centered with the range boxes we need a top margin equal to the label's size + bottom margin
        marginTop: labelSize + 16,
    },
    labels: {
        color: theme.palette.secondary.main,
        fontSize: labelSize,
        fontWeight: 'bold',
    },
});

export default styles;
