import React from 'react';
import PropTypes from 'prop-types';
import { TextField, withStyles } from '@material-ui/core';
import styles from './styles';

export const SIZE_LARGE = 'large';
export const SIZE_SMALL = 'small';
export const SIZES = [SIZE_LARGE, SIZE_SMALL];

const FormTextField = ({ classes, error, size, InputProps, ...props }) => {
    const isLargeSize = size === SIZE_LARGE;
    return (
        <TextField
            classes={{
                root: classes.root,
            }}
            variant="outlined"
            required
            InputLabelProps={{
                shrink: true,
                classes: {
                    root: classes.inputLabel,
                    shrink: classes.shrinkInputLabel,
                    disabled: classes.disabled,
                },
            }}
            InputProps={{
                classes: {
                    root: isLargeSize ? classes.largeInput : classes.smallInput,
                    notchedOutline: classes.inputNotchedOutline,
                    input: isLargeSize
                        ? classes.largeInputStyle
                        : classes.smallInputStyle,
                    disabled: classes.disabled,
                },
                ...InputProps,
            }}
            helperText={error}
            FormHelperTextProps={{
                classes: {
                    error: classes.helperTextError,
                },
            }}
            {...props}
            error={!!error}
        />
    );
};

FormTextField.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // Error text to be displayed, should be empty if there is no error.
    error: PropTypes.string,

    size: PropTypes.oneOf(SIZES),

    InputProps: PropTypes.object,
};

FormTextField.defaultProps = {
    size: SIZE_LARGE,
    InputProps: {},
};

export default withStyles(styles)(FormTextField);
