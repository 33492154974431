const styles = (theme) => ({
    root: {
        margin: 0,
        '&$disabled': {
            backgroundColor: theme.palette.primary.contrastText,
            opacity: 0.75,
        },
    },
    expanded: {
        margin: 0,
    },
    panel: {},
    defaultAccordion: {
        backgroundColor: theme.palette.primary.main,
    },
    defaultLabel: {
        color: theme.palette.primary.contrastText,
        fontSize: 22,
    },
    defaultChildLabel: {
        color: theme.palette.primary.contrastText,
        fontWeight: 'bold',
    },
    defaultIcon: {
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
    },
    defaultChildIcon: {
        color: theme.palette.primary.contrastText,
        fontWeight: 'bold',
    },
    defaultChildAccordion: {
        borderTopWidth: 1,
        borderTopColor: '#6ba0f3fa',
        borderTopStyle: 'solid',
        backgroundColor: theme.palette.secondary.main,
        textTransform: 'uppercase',
        letterSpacing: 2,
    },
    disabled: {},
});

export default styles;
