import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import styles from './styles';
import WeekdayPicker from '../WeekdayPicker';
import TimePicker from '../TimePicker';
import DatePropTypes from '../../propTypes/dates';
import { noop } from '../../utils';

class WeekdayTimePicker extends Component {
    render() {
        const {
            value: { day, time },
            onDayChange,
            onTimeChange,
        } = this.props;
        return (
            <Grid container direction="row" spacing={16}>
                <Grid item>
                    <WeekdayPicker value={day} onChange={onDayChange} />
                </Grid>
                <Grid item>
                    <TimePicker value={time} onChange={onTimeChange} />
                </Grid>
            </Grid>
        );
    }
}

WeekdayTimePicker.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // Value of the date and time that's selected.
    value: DatePropTypes.weekdayAndTime,

    // Function called when the day is changed.
    onDayChange: PropTypes.func,

    // Function called when the time is changed.
    onTimeChange: PropTypes.func,
};

WeekdayTimePicker.defaultProps = {
    value: {},
    onDayChange: noop,
    onTimeChange: noop,
};

export default withStyles(styles)(WeekdayTimePicker);
