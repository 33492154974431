const styles = (theme) => ({
    photoContainer: {
        position: 'relative',
    },
    photo: {
        objectFit: 'cover',
        fontFamily: 'object-fit: contain;', // IE compat
    },
    clearButton: {
        position: 'absolute',
        color: 'white',
        backgroundColor: theme.palette.error.main,
        top: 5,
        right: 5,
        padding: 4,
    },
    clearButtonIcon: {
        fontSize: 13,
    },
});

export default styles;
