export const PREFIX = 'regions/';

// Actions
export const FETCH_REGIONS_REQUESTED = `${PREFIX}FETCH_REGIONS_REQUESTED`;
export const FETCH_REGIONS_SUCCEEDED = `${PREFIX}FETCH_REGIONS_SUCCEEDED`;
export const FETCH_REGIONS_FAILED = `${PREFIX}FETCH_REGIONS_FAILED`;
export const FETCH_REGIONS_FINISHED = `${PREFIX}FETCH_REGIONS_FINISHED`;

export function fetchRegionsRequested(countryAbbr = 'US') {
    return {
        type: FETCH_REGIONS_REQUESTED,
        countryAbbr,
    };
}

export function fetchRegionsSucceeded(countryAbbr, data) {
    return {
        type: FETCH_REGIONS_SUCCEEDED,
        countryAbbr,
        data,
    };
}

export function fetchRegionsFailed() {
    return {
        type: FETCH_REGIONS_FAILED,
    };
}

export function fetchRegionsFinished() {
    return {
        type: FETCH_REGIONS_FINISHED,
    };
}
