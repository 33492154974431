import { createSelector } from 'reselect';
import { transformAmenitiesForCheckboxesInForm } from './transforms';

export const getAmenities = (state) => state.amenities.data;

export const getAmenitiesForCheckboxesInForm = createSelector(
    (state) => getAmenities(state),
    (amenities) => {
        return transformAmenitiesForCheckboxesInForm(amenities);
    },
);
