const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
    },
    expanded: {},
    actionsMenuAccordionExpanded: {},
    actionsMenuGrid: {
        marginTop: 5,
    },
    actionsDropDown: {
        marginBottom: 5,
        width: 'auto',
    },
    selectGridItem: {
        padding: '10px 20px',
    },
    buttonGridItem: {
        backgroundColor: theme.palette.primary.main,
        padding: '10px 20px',
    },
    applyButton: {
        color: theme.palette.primary.contrastText,
    },
});

export default styles;
