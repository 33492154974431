import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, Paper } from '@material-ui/core';
import styles from './styles';

const MessagePaper = ({ type, withGutter, classes, ...rest }) => {
    return (
        <Paper
            elevation={0}
            square
            className={classNames([
                classes.root,
                type !== null ? classes[type] : null,
                withGutter ? classes.withGutter : null,
            ])}
            {...rest}
        />
    );
};

MessagePaper.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,

    // The type must be error or success.
    type: PropTypes.oneOf(['error', 'success', 'info']),

    // Whether or not to add a bottom margin to the paper.
    withGutter: PropTypes.bool,
};

MessagePaper.defaultProps = {
    // No type by default.
    type: null,

    // No bottom margin by default.
    withGutter: false,
};

export default withStyles(styles)(MessagePaper);
