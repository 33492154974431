import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isInitialized, isInitializing } from './stores/auth/selectors';
import AppRouter from './landing/AdminRouter';
import AppGate from './landing/AppGate';

export const Root = ({
    initialized,
    initializing,
    checkInProgress,
    errors,
    history,
}) => {
    if (initialized && !checkInProgress) {
        return <AppRouter history={history} />;
    } else {
        return (
            <AppGate
                initializing={initializing}
                checkInProgress={checkInProgress}
                errors={errors}
            />
        );
    }
};

Root.propTypes = {
    initializing: PropTypes.bool,
    initialized: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.string),
    history: PropTypes.object,
    checkInProgress: PropTypes.bool,
};

Root.defaultProps = {
    initializing: true,
    initialized: false,
    errors: [],
    history: null,
};

const mapStateToProps = (state) => ({
    initializing: isInitializing(state),
    initialized: isInitialized(state),
    errors: state.auth.initializationErrors,
    checkInProgress: state.auth.checkInProgress,
});

export default connect(mapStateToProps)(Root);
