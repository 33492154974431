import background from './background.png';

const styles = (theme) => ({
    root: {
        backgroundImage: `url(${background})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    },
    content: {
        padding: `35px ${theme.custom.containerPadding}px`,
        maxWidth: theme.custom.containerMaxWidth,
    },
    titleContainer: {
        flex: 1,
    },
    linkContainer: {
        flex: 0,
        paddingLeft: 30,
        whiteSpace: 'nowrap',
    },
    title: {
        color: theme.palette.primary.contrastText,
    },
    backText: {
        color: theme.palette.primary.contrastText,
        fontSize: '1em',
    },
    link: {
        textDecoration: 'none',
    },
});

export default styles;
