import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import styles from './styles';
import AdminButton from '../../common/AdminButton';
import { noop } from '../../utils';

class FilterMenuButtons extends Component {
    render() {
        const { classes, onClickApply, onClickClear } = this.props;
        return (
            <Grid
                container
                direction="row"
                justify="center"
                className={classes.buttonGridContainer}
            >
                <Grid item>
                    <AdminButton
                        size="small"
                        className={classes.button}
                        onClick={onClickApply}
                    >
                        Apply Filters
                    </AdminButton>
                </Grid>
                <Grid item>
                    <AdminButton
                        size="small"
                        className={classes.button}
                        onClick={onClickClear}
                    >
                        Clear Filters
                    </AdminButton>
                </Grid>
            </Grid>
        );
    }
}

FilterMenuButtons.propTypes = {
    // From withStyles we expect to get classes
    classes: PropTypes.object.isRequired,
    onClickApply: PropTypes.func,
    onClickClear: PropTypes.func,
};

FilterMenuButtons.defaultProps = {
    onClickApply: noop,
    onClickClear: noop,
};

export default withStyles(styles)(FilterMenuButtons);
