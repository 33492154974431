import { generateTableColumnStyles } from '../../utils/styles';

export default (theme) => ({
    idHeadCell: theme.rigParkAdmin.tableHeadCells.id,
    photoHeadCell: theme.rigParkAdmin.tableHeadCells.photo,
    nameHeadCell: theme.rigParkAdmin.tableHeadCells.name,
    reservationIdHeadCell: {
        ...generateTableColumnStyles(150),
        [theme.breakpoints.down('md')]: {
            ...generateTableColumnStyles(100),
            whiteSpace: 'normal',
        },
    },
    durationHeadCell: {
        ...generateTableColumnStyles(250),
        [theme.breakpoints.down('md')]: {
            ...generateTableColumnStyles(175),
            whiteSpace: 'normal',
        },
    },
    propertyNameHeadCell: {
        ...generateTableColumnStyles(250),
        [theme.breakpoints.down('md')]: {
            ...generateTableColumnStyles(75),
            whiteSpace: 'normal',
        },
    },
    costHeadCell: {
        ...generateTableColumnStyles(150),
        [theme.breakpoints.down('md')]: {
            ...generateTableColumnStyles(25),
            whiteSpace: 'normal',
        },
    },
    plateHeadCell: {
        ...generateTableColumnStyles(200),
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            ...generateTableColumnStyles(125),
            whiteSpace: 'normal',
            wordBreak: 'break-word',
        },
    },
    userNameHeadCell: {
        ...generateTableColumnStyles(200),
        [theme.breakpoints.down('md')]: {
            ...generateTableColumnStyles(125),
            whiteSpace: 'normal',
            wordBreak: 'break-word',
        },
    },
    centeredColumn: {
        textAlign: 'center',
    },
});
